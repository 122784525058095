import $$ from 'dom7';
import app from '../../../app';
import controller from './ModuleController';

const moduleView = {
  module: null,

  filter: null,

  accordions: [],

  //iconDictionary : {"entscheidung" : "call_split", "info" : "info", "internet" : "wifi", "material" : "", "planung" : "fact_check", "profilrelevanz" : ""},

  init: function (module, filter) {
    this.module = module;
    this.filter = filter;
    this.loadMainToolbar();
    this.loadMainFAB();
    this.loadBackground();
    this.loadTitle();
    this.loadFilter();
    this.loadChapters();
    this.loadNextButton(module.number + 1);
    this.initOpenAccordions();
    this.loadPopovers();
    app.accordion.open(this.accordions[0]);
  },

  loadMainToolbar: function () {
    app.loadMainToolbar($$('.page[data-name="module"] .main-toolbar'));
  },

  loadMainFAB: function () {
    app.loadMainFAB(
      $$('.page[data-name="module"] .main-fab'),
      this.module.number,
    );
  },

  loadBackground: function () {
    $$('.page.background-image[data-name="module"]').css({
      'background-image': `url(${this.module.getBackgroundImage()})`,
    });
  },

  loadPopovers: function () {
    $$('.page[data-name="module"] .popover-open').each((i, el) => {
      el = $$(el);
      app.popoverOpenBelow(el, $$(el.attr('data-popover')));
    });
  },

  loadTitle: function () {
    $$('#module-page-content h1.page-title').empty();
    $$('#module-page-content h2.page-subtitle').empty();
    $$('#module-page-content h1.page-title').append(this.module.name);
    $$('#module-page-content h2.page-subtitle').append(
      'Schritt ' + this.module.number,
    );
  },

  loadFilter: function () {
    $$('#module-filters').empty();
    for (let state in this.filter.all) {
      if (this.filter.all.hasOwnProperty(state)) {
        let moduleFilters = $$('#module-filters');
        moduleFilters.append(`
                    <li>
                        <a id="module-filter-${state}" class="list-button item-link" href="#">${
          this.filter.get(state).name
        }</a>
                    </li>
                `);
        let filterLink = $$('#module-filter-' + state);
        if (this.filter.get(state).name === this.filter.current.name) {
          filterLink.addClass('selected');
        }
        filterLink.on('click', (f) => {
          this.filter = controller.applyFilter(state);
          moduleFilters.find('a[id^="module-filter-"]').each((ind, el) => {
            $$(el).removeClass('selected');
          });
          filterLink.addClass('selected');
          app.popover.get('#module-popover-sort').close();
        });
      }
    }
  },

  loadChapters: function () {
    this.module.chapters.sort(this.filter.current.sort);
    let columns = $$('#module-chapters > div[class|="column"]');
    columns.each((index, column) => {
      $$(column).empty();
    });
    this.accordions = [];
    for (let index = 0; index < this.module.chapters.length; index++) {
      let card = moduleView.getChapterCard(this.module.chapters[index]);
      this.accordions.push(card.find('.accordion-item'));
      if (index < parseInt((this.module.chapters.length + 1) / 2)) {
        columns
          .filter((index, column) => $$(column).hasClass('column-0'))
          .append(card);
      } else {
        columns
          .filter((index, column) => $$(column).hasClass('column-1'))
          .append(card);
      }
    }
  },

  getChapterCard: function (chapter) {
    const card = $$(document.createElement('div'));
    card.addClass('card card-line list');
    card.append(this.getChapter(chapter));
    chapter.exercises.forEach((exercise) => {
      $$(card)
        .find('.accordion-item-content ul')
        .append(this.getExercise(chapter, exercise));
    });
    return card;
  },

  getChapter: function (chapter) {
    return `
            <div class="card-content accordion-item">
                <a href="#" class="item-content item-link">
                    <div class="item-inner">
                        ${chapter.number + '. ' + chapter.name}
                        <div class="item-subtitle">
                            <span>${
                              chapter.state > 0
                                ? 'Bearbeitung fortsetzen'
                                : 'noch nicht bearbeitet'
                            }</span>
                        </div>
                    </div>
                </a>
                <div class ="accordion-item-content">
                    <div class="list no-hairlines-between">
                        <div class="list-group">
                            <ul></ul>
                        </div>
                    </div>
                </div>
            </div>
        `;
  },

  getExercise: function (chapter, exercise) {
    let list = $$(document.createElement('li'));
    list.html(`
            <li>
                <a href="/ex/${this.module.number}/${chapter.number}/${exercise.number}/0/" class="item-link item-content">
                    <div class="item-inner">
                        <span class="item-title">${exercise.name}</span>
                        <div class="item-subtitle">
                        </div>
                    </div>
                </a>
            </li>
        `);
    for (let index = 0; index < exercise.symbol.length; index++) {
      list
        .find('.item-subtitle')
        .append(`<i class="icon svg-icon-${exercise.symbol[index]}"></i>`); // ${this.iconDictionary[exercise.symbol[index]]}
    }
    list
      .find('.item-subtitle')
      .append(`<span>${exercise.getRemainingMinutes() + ' min+'}</span>`);
    return list;
  },

  initOpenAccordions: function () {
    for (let current of this.accordions) {
      current.on('accordion:beforeopen', (e) => {
        const opened = this.accordions.filter((acc) =>
          $$(acc).hasClass('accordion-item-opened'),
        );
        for (let acc of opened) {
          app.accordion.close(acc);
        }
      });
    }
  },
  loadNextButton: function (nextButton) {
    let nextButtonDOM,
      number = this.module.number;
    $$('.page-next #module-next-button').length != 0
      ? (nextButtonDOM = $$('.page-next #module-next-button'))
      : (nextButtonDOM = $$('#module-next-button'));
    if (number == 1 || number == 2 || number == 3 || number == 8) {
      let link;
      switch (number) {
        case 1:
          link = 'https://survey.deep-app.de/index.php/184243?lang=de';
          break;
        case 2:
          link = 'https://survey.deep-app.de/index.php/497759?lang=de';
          break;
        case 3:
          link = 'https://survey.deep-app.de/index.php/496469?lang=de';
          break;
        case 8:
          link = 'https://survey.deep-app.de/index.php/435662?lang=de';
          break;
      }
      nextButtonDOM.addClass('popup-open');
      nextButtonDOM.attr('data-popup', '.popup-survey');
      //$$(".survey-iframe").attr("src", link)
      $$('.survey-link').attr('href', link);
      $$('a.survey-popup').on('click', (e) =>
        controller.next('/mod/' + nextButton + '/'),
      );
      let p = $$('#step-number-paragraph');
      let list = p.html().split('##X##');
      p.html(list[0] + String(number) + list[1]);
    }
    if (number == 10) {
      nextButtonDOM.html('Zurück zur Startseite');
      nextButtonDOM.removeClass('popup-open');
      nextButtonDOM.attr('data-popup', '');
      controller.nextURL = '/';
      nextButtonDOM.on('click', (e) => {
        controller.next('/', true);
      });
    } else {
      nextButtonDOM.removeClass('popup-open');
      nextButtonDOM.attr('data-popup', '');
      controller.nextURL = '/mod/' + nextButton + '/';
      nextButtonDOM.on('click', (e) => {
        controller.next('/mod/' + nextButton + '/');
      });
    }
  },

  refreshFilter: function () {
    this.loadChapters();
  },
};
export default moduleView;
