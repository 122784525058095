import app from '../app';
import userData from '../model/user/UserData';
import dictionary from './Dictionary';
import Module from '../model/steps/Module.js';
import Notebook from '../model/notebook/Notebook.js';
import synchronisationController from './SynchronisationController.js';
import Profile from '../model/user/Profile.js';
import { v4 as uuidv4 } from 'uuid';
import tutorialController from './ui/tutorial/TutorialController';

const REFRESH_INTERVALL = 3600000,
  DOWNLOADANDSYNC_INTERVALL = 600000;

var userController = {
  _listeners: new Map(),

  _user: userData,
  startedTasks: [],
  _this: this,
  init: function (app) {
    this._listeners = new Map();
    this.app = app;
    let _this = this;
    this.connector = this.app.dbconnector;
    userData.notebook.init();
    this.startedTasks = [];
    // remove this after download is implemented
    userData.init();
    //this.loadModules(); Use this later when moduledownload is implemented

    this._initTaskStates();
    this._user.load();
    //synchronisationController.updateModules(this._user.modulesJson)
    this.connector.secureLoginWithJWT().then((result) => {
      if (result) {
        clearInterval(this.refreshInterval);
        clearInterval(this.downloadUserinfoInterval);
        clearInterval(this.syncUserDataInterval);
        this.downloadUserinfo();
        synchronisationController.syncUserData(app).then((result) => {
          this._initTaskStates();
          this._user.load();
        });
        this.refreshInterval = setInterval(() => {
          _this.refresh(_this.connector);
        }, REFRESH_INTERVALL);
        this.downloadUserinfoInterval = setInterval(() => {
          _this.downloadUserinfo();
        }, DOWNLOADANDSYNC_INTERVALL);
        this.syncUserDataInterval = setInterval(() => {
          synchronisationController.syncUserData(null, false);
        }, DOWNLOADANDSYNC_INTERVALL);
        this.init360feedback().then((result) => {
          Profile.evaluate360responses();
        });
      }
    });
    Profile.init();
  },
  /**
   * Call dbconnector to register user in mongoDB and awaits result from connector
   * @param username username for regisrations and future logins
   * @param email email for registration
   * @param password password used for future logins
   * @param licenseKey e.g. "123456789"
   * @param licenseType e.g. "volume"
   * @return true if dbconnector returns 200, false otherwise
   */
  register: async function (
    username,
    email,
    password,
    licenseKey,
    licenseType,
  ) {
    let _this = this;
    let resultcode;
    await app.dbconnector
      .registerUserLocal(email, password, username, licenseKey, licenseType)
      .then((result) => {
        resultcode = result.status;
        if (result.status !== 200) {
          app.dialog.alert(dictionary.getText('logintexts', 'registerfail'));
        } else if (process.env.NODE_ENV !== 'development') {
          this.init360feedback();
        }
      });
    if (resultcode == 200) return true;
    return false;
  },

  /**
   * Calls dbconnector to attempt to login
   * Alerts if login was unsuccessful or successful
   * TODO : Initiate Synchronisation?
   * @param username username for login
   * @param password password for login
   */
  login: async function (username, password) {
    let _this = this;
    let resultcode;
    await app.dbconnector.loginUserLocal(username, password).then((result) => {
      if (result) {
        resultcode = result.status;
        if (result.status == 200) {
          tutorialController.disable();
          this.downloadUserinfo();
          synchronisationController.syncUserData().then(() => {
            app.views.main.router.navigate(app.views.main.router.url, {
              reloadCurrent: true,
            });
          });
          this.refreshInterval = setInterval(() => {
            _this.refresh(_this.connector);
          }, REFRESH_INTERVALL);
          this.downloadUserinfoInterval = setInterval(() => {
            _this.downloadUserinfo();
          }, DOWNLOADANDSYNC_INTERVALL);
          this.syncUserDataInterval = setInterval(() => {
            synchronisationController.syncUserData((null, false));
          }, DOWNLOADANDSYNC_INTERVALL);
          _this.init360feedback();
        } else {
          app.dialog.alert(dictionary.getText('logintexts', 'loginfail'));
        }
      } else {
        app.dialog.alert(dictionary.getText('logintexts', 'loginfail'));
      }
    });
    return resultcode == 200;
  },
  /*
   * Attempts to synchronise all informations entered from user
   * */
  uploadUserinfo: async function () {
    let userdata = {
      username: this._user.username,
      gender: this._user.gender,
      fedstate: this._user.state,
    };
    this._user.birthday && this._user.birthday.toString() !== 'Invalid Date'
      ? (userdata.birthday = this._user.birthday.toISOString().split('T')[0])
      : '';
    this._user.graddate && this._user.graddate.toString() !== 'Invalid Date'
      ? (userdata.graddate = this._user.graduationdate
          .toISOString()
          .split('T')[0])
      : '';
    let resultcode;
    const isLoggedIn = await app.dbconnector.secureLoginWithJWT();
    if (isLoggedIn) {
      await app.dbconnector.updateUserData(userdata).then((result) => {
        if (result) {
          resultcode = result.status;
        }
      });
    }
    return resultcode == 200;
  },
  downloadUserinfo: async function () {
    let userdata;
    const isLoggedIn = await app.dbconnector.secureLoginWithJWT();
    if (isLoggedIn) {
      userdata = await this.connector.downloadUserData();
      if (userdata) {
        userController.setUserData('username', userdata.data.user.username);
        userController.setUserData(
          'birthday',
          userdata.data.user.birthday || '',
        );
        userController.setUserData(
          'graduationdate',
          userdata.data.user.graddate || '',
        );
        userController.setUserData('gender', userdata.data.user.gender || '');
        userController.setUserData('state', userdata.data.user.fedstate || '');
        localStorage.setItem('360-id', userdata.data.user.request);
      }
    }
    return userdata;
  },

  /**
   * Function to logout user. Tries to synchronize and asks for confirmation if that failed before logout.
   */
  logout: function () {
    if (this.isAnyObjectUnsynced()) {
      app.dialog.preloader('Ausloggen ...');
      app.dbconnector.secureLoginWithJWT().then((result) => {
        app.dialog.close();
        synchronisationController.syncUserData().then((result) => {
          if (result) {
            app.dbconnector.logout();
            this.afterLogout(true);
          } else {
            let last_synced = new Date(userData.last_synced).toLocaleString(),
              _this = this;
            app.dialog.confirm(
              dictionary.getText('misctexts', 'logoutsyncfail') +
                '&nbsp; Letzte Synchronisation: ' +
                last_synced,
              dictionary.getText('misctexts', 'logoutsyncfailtitle'),
              () => {
                app.dbconnector.logout();
                app.views.main.router.navigate(`/`);
              },
              () => {},
            );
          }
        });
      });
    } else {
      app.dbconnector.logout();
      this.afterLogout(true);
    }
  },

  changeAuthaAndLogout: function (newAuthData) {
    app.dbconnector.secureLoginWithJWT().then((result) => {
      if (result) {
        synchronisationController.syncUserData().then((result) => {
          if (result) {
            app.dbconnector.updateUserData(newAuthData).then((result) => {
              app.dialog.close();
              if (result.status == 200) {
                app.dialog.alert(
                  dictionary.getText('misctexts', 'authChangeSuccess'),
                );
                app.dbconnector.logout();
                this.afterLogout(true);
              } else {
                app.dialog.alert(
                  dictionary.getText('misctexts', 'authChangeFail'),
                );
              }
            });
          } else {
            let last_synced = new Date(userData.last_synced).toLocaleString(),
              _this = this;
            app.dialog.confirm(
              dictionary.getText('misctexts', 'logoutsyncfail') +
                '&nbsp; Letzte Synchronisation: ' +
                last_synced,
              dictionary.getText('misctexts', 'logoutsyncfailtitle'),
              () => {
                _this.afterLogout(true);
              },
              () => {},
            );
          }
        });
      }
    });
  },
  /**
   * Called after logout() if either synchronisation successful or confirmation of user.
   * */
  afterLogout: function (syncSuccess) {
    if (syncSuccess) {
      app.methods.afterLogout();
    }
    this.connector.logout();
    this.setUserData('last_synced', 0);
    clearInterval(this.refreshInterval);
    clearInterval(this.downloadUserinfoInterval);
    clearInterval(this.syncUserDataInterval);

    if (app.device.cordova) {
      app.views.main.router.navigate(app.views.main.router.url, {
        reloadCurrent: true,
      });
    }
    app.view.main.router.navigate('/onboarding/');
  },

  /**
   * Returns UserData-Object
   */
  getUser: function () {
    return this._user;
  },

  /**
   * Function to set an attribute of UserData with a given value
   * @param key The key for the attribute that receives new value
   * @param value new value for UserData
   */
  setUserData: function (key, value) {
    this._user[key] = value;
    this._user.save();
  },
  /**
   * Function to get the value of an attribute of userData
   * @param key key for the attribute of which the value is read
   */
  getUserData: function (key) {
    return this._user[key];
  },

  loadModules: function () {
    for (let i = 0; i < 10; i++) {
      let mod = JSON.parse(localStorage.getItem('module' + i + 'json'));
      this._user.modulesJson[i] = mod != null ? mod : modulesJson[i];
    }
  },
  //called first time app is run, to write modules to localStorage and deletes stored modulejson-files from installation
  modulesToLocalStorage: function () {
    this._user.modulesToLocalStorage();
  },
  getNextModule: function () {
    for (var mod in this._user.modulesJson) {
      var modData = localStorage.getItem(this._user.modulesJson[mod].number);
      if (modData == null || modData.state == 1) {
        return new Module(this._user.modulesJson[mod], mod);
      }
    }
    for (var mod in this._user.modulesJson) {
      var modData = localStorage.getItem(this._user.modulesJson[mod].number);
      if (modData == null || modData.state == 0) {
        return new Module(this._user.modulesJson[mod], mod);
      }
    }
    return new Module(this._user.modulesJson[0], mod);
  },
  getAllModules: function () {
    var modules = [];
    for (var mod in this._user.modulesJson) {
      if (parseInt(mod) < 0) continue;
      modules.push(new Module(this._user.modulesJson[mod], mod));
    }
    return modules; // Array with Modules
  },
  getModule: function (moduleNumber) {
    var mod = new Module(
      this._user.modulesJson[moduleNumber - 1],
      moduleNumber,
    );
    return mod;
  },
  getAllChapters: function () {
    let chapters = [];
    for (let module of this.getAllModules()) {
      for (let chapter of module.chapters) {
        chapters.push(chapter);
      }
    }
    return chapters;
  },
  getAllExercises: function () {
    return this.getAllExercisesAndTasks().exercises;
  },
  getAllExercisesAndTasks: function () {
    let modules = this.getAllModules().concat(
        new Module(this._user.modulesJson[-1]),
      ),
      exercises = [],
      tasks = [];
    for (let module of modules) {
      for (let chapter of module.chapters) {
        for (let exercise of chapter.exercises) {
          exercises.push(exercise);
          if (exercise.tasks.length > 0) tasks = tasks.concat(exercise.tasks);
        }
      }
    }
    return { exercises: exercises, tasks: tasks };
  },
  /**
   * Return the exercise or tasks corresponding to the id-parameter
   * @param {string} id
   */
  getExerciseOrTaskByID: function (id) {
    let exAndTasks = this.getAllExercisesAndTasks();
    return exAndTasks.exercises
      .filter((x) => x._id == id)
      .concat(exAndTasks.tasks.filter((x) => x._id == id))[0];
  },
  getExercise: function (moduleNumber, exerciseNumber) {
    var mod = new Module(
      this._user.modulesJson[moduleNumber - 1],
      moduleNumber,
    );
    return mod.exercises[exerciseNumber];
  },
  getAllTasks: function () {
    return this.getAllExercisesAndTasks().tasks;
  },
  getTask: function (moduleNumber, chapternumber, exerciseNumber, taskNumber) {
    var mod = new Module(
      this._user.modulesJson[moduleNumber - 1],
      moduleNumber,
    );
    return mod.chapters[chapternumber - 1].exercises[exerciseNumber - 1].tasks[
      taskNumber - 1
    ];
  },

  getModulesJson: function () {
    return this._user.modulesJson;
  },
  init360feedback: async function () {
    if (this.connector.isLoggedIn()) {
      const loginRes = await this.connector.secureLoginWithJWT();
      if (loginRes) {
        const init360Res = await this.init360Link();
        if (init360Res) {
          return await this.sync360feedback();
        }
      }
    } else if (localStorage.getItem('360-id')) {
      this.sync360feedbackLegacy();
    }
  },
  init360Link: async function () {
    if (!Boolean(localStorage.getItem('360-id'))) {
      const userInfoResponse = await this.downloadUserinfo();
      if (userInfoResponse.status == 200) {
        if (userInfoResponse.data.user.request) {
          localStorage.setItem('360-id', userInfoResponse.data.user.request);
        } else {
          const result = await this.connector.createNewThreesixtyRequest(
            localStorage.getItem('uid-user'),
          );
          if (result.status == 200) {
            localStorage.setItem('360-id', result.data.id);
            return true;
          } else {
            return false;
          }
        }
      }
    }
    return true;
  },

  sync360feedback: async function () {
    let _this = this;
    if (localStorage.getItem('360-id') != null) {
      const result = await this.connector.getThreesixtyResponses(
        localStorage.getItem('uid-user'),
      );
      if (result && result.status == 200) {
        _this._user.feedback360responses = [];
        for (const [index, value] of result.data.responses.entries()) {
          _this._user.feedback360responses.push(JSON.parse(value.content));
        }
        // notify '360sync' listeners
        if (_this._listeners.get('360sync')) {
          _this._listeners.get('360sync').forEach((handler) => handler());
        }
      }
    }
  },

  /**
   * DEPRECATED: fetching of 360feedback via secret. Exclusively used for users who initalized 360 feedback before accounts were implemented and still dont use an account.
   * */
  sync360feedbackLegacy: async function () {
    let _this = this;
    if (localStorage.getItem('360-id') != null) {
      this.connector
        .getThreesixtyResponsesLegacy(
          localStorage.getItem('360-id'),
          localStorage.getItem('uid-user'),
        )
        .then(function (result) {
          if (result && result.status == 200) {
            _this._user.feedback360responses = [];
            for (const [index, value] of result.data.responses.entries()) {
              _this._user.feedback360responses.push(JSON.parse(value.content));
            }
          }
        });
    }
  },
  /**
   * returns a list for all types of objects that get synchronised DB with those that have not been synced. Parses Data so that only relevant attributes are uploaded
   * */
  getUnsyncedObjects: function () {
    let last_synced = this.getUserData('last_synced');
    let exercisesAndTasks = this.getAllExercisesAndTasks(),
      notebook = this.getUserData('notebook');
    let notes = Notebook.notes,
      goals = this._parseGoalsToArray(Notebook.goals),
      keydates = Notebook.keydates,
      ideas = this._getIdeasAsArray();

    return {
      exercises: exercisesAndTasks.exercises
        .filter((x) => x.last_modified > last_synced)
        .map((x) => JSON.parse(localStorage.getItem(x._id)))
        .map(
          ({
            id,
            duration,
            bookmark,
            remainingMinute,
            remainingMilliseconds,
            chapter_number,
            subblocks,
            ...item
          }) => item,
        ),
      tasks: exercisesAndTasks.tasks
        .filter((x) => x.last_modified > last_synced)
        .map((x) => JSON.parse(localStorage.getItem(x._id))),
      notes: notes
        .filter((x) => x.last_modified > last_synced)
        .map((x) => JSON.parse(localStorage.getItem(x._id))),
      keydates: keydates
        .filter((x) => x.last_modified > last_synced)
        .map((x) => JSON.parse(localStorage.getItem(x._id))),
      goals: goals,
      ideas: ideas,
    };
  },

  /** Returns true if there exists any object that needs to be synced. */
  isAnyObjectUnsynced: function () {
    const unsyncedObjects = this.getUnsyncedObjects();
    return (
      Object.values(unsyncedObjects).filter(
        (unsyncedObjectList) => unsyncedObjectList.length > 0,
      ).length > 0
    );
  },

  /**
   * Gets an array with all locally stored ideas, compatible with synchronisation.
   * */
  _getIdeasAsArray: function () {
    let ideas = this.getUserData('profile').eduIdeas,
      formattedIdeas = [],
      last_synced = this.getUserData('last_synced');
    if (ideas) {
      for (const [id, idea] of Object.entries(ideas)) {
        if (idea.last_modified > last_synced) {
          let ideaLS;
          ideaLS = JSON.parse(localStorage.getItem(idea.id));
          ideaLS._id = ideaLS.id;
          formattedIdeas.push(ideaLS);
        }
      }
    }
    return formattedIdeas;
  },

  /**
   * Parses goals into a format, compatible with synchronisation
   * @param {any} goals all locally stored goals
   */
  _parseGoalsToArray: function (goals) {
    let formattedGoals = [],
      last_synced = this.getUserData('last_synced');
    if (goals) {
      for (const goals of Object.values(goals)) {
        if (goals.last_modified > last_synced) {
          let goalsLS;
          goalsLS = JSON.parse(localStorage.getItem(goals._id));
          formattedGoals.push(goalsLS);
        }
      }
    }
    return formattedGoals;
  },

  /**
   * Sets all Tasks, with state 0 to -1 once.
   *
   * Only used because old versions save them different. New states (-1, 0, 1) since 01.07.21
   *
   * @private
   */
  _initTaskStates() {
    if (!localStorage.getItem('task-states-initialized')) {
      // set all states to -1
      for (let task of this.getAllTasks()) {
        if (task.state === 0) {
          task.state = -1;
          task.save(true);
        }
      }

      localStorage.setItem('task-states-initialized', 'true');
    }
  },

  /**
   * Register a unique (by id) handler for getting called, after target was being modified.
   *
   * Only implemented for target "360sync" yet.
   *
   * @param {function} handler called on event
   * @param {string} target key for a specific event
   * @param {string} [id] for identifying unique handlers
   */
  addListener(target, handler, id) {
    if (!id) {
      id = uuidv4();
    }
    if (!this._listeners.get(target)) {
      this._listeners.set(target, new Map());
    }
    this._listeners.get(target).set(id, handler);
  },
};
export default userController;
