import app from '../../../app';
import view from './ModuleView.js';
import userController from '../../../core/UserController';
import Filter from '../../../core/Filter';

const moduleController = {
  module: null,
  _this: this,

  filter: new Filter(view, {
    default: {
      name: 'Kapitel',
      sort: function (first, second) {
        return first.number - second.number;
      },
    },
    name: {
      name: 'Name',
      sort: function (first, second) {
        if (first.name < second.name) {
          return -1;
        }
        if (first.name > second.name) {
          return 1;
        }
        return 0;
      },
    },
  }),
  /**
   * @param route: string to route to
   * @param noReload: set noReload to true, when leaving module page
   */
  next: function (route, noReload) {
    app.views.main.router.navigate(route, {
      reloadCurrent: !noReload,
    });
  },

  load: function (moduleNumber) {
    this.module = userController.getModule(moduleNumber);
    view.init(this.module, this.filter);
  },

  applyFilter: function (state) {
    this.filter.apply(state);
    return this.filter;
  },

  containsTask(exercise) {
    return exercise.containsTask();
  },
};
export default moduleController;
