import $$ from 'dom7';
import app from '../../app.js';

const adviseView = {
  accordions: [],
  loadMainToolbar: function () {
    app.loadMainToolbar($$('.page[data-name="advise"] .main-toolbar'));
  },
  init: function (adviseContent, pagecontentString) {
    this.loadMainToolbar();
    this.loadContent(adviseContent, pagecontentString);
  },

  loadContent: function (adviseContent, pageContentString) {
    let externalLinks;
    let cards = adviseContent.cards;
    if (pageContentString === 'adviseContent') {
      externalLinks = true;
    } else {
      externalLinks = false;
      cards = adviseContent.cards.sort((a, b) => {
        return ('' + a.title).localeCompare(b.title);
      });
    }

    $$('.advise-content').each(function (i, obj) {
      this.innerHTML = adviseContent.header + this.innerHTML;
    });
    let adviseContentMain = $$('.advise-content-main');

    adviseContentMain[0].innerHTML += `
			<div class="advise-cards columns">
				<div class="column-0"></div>
				<div class="column-1"></div>
			</div>
		`;
    let columns = $$('.advise-cards > div[class|="column"]');
    columns.each((index, column) => {
      $$(column).empty();
    });

    for (
      let cardIndex = 0;
      cardIndex < parseInt((cards.length + 1) / 2);
      cardIndex++
    ) {
      let card = this.getAdviseCard(cards[cardIndex], externalLinks);
      this.accordions[cardIndex] = card.find('.accordion-item');
      columns
        .filter((index, column) => $$(column).hasClass('column-0'))
        .append(card);
    }
    for (
      let cardIndex = parseInt((cards.length + 1) / 2);
      cardIndex < cards.length;
      cardIndex++
    ) {
      let card = this.getAdviseCard(cards[cardIndex]);
      this.accordions[cardIndex] = card.find('.accordion-item');
      columns
        .filter((index, column) => $$(column).hasClass('column-1'))
        .append(card);
    }
  },
  getAdviseCard: function (cardContent, externalLinks) {
    const card = $$(document.createElement('div'));
    card.addClass('card card-line-small list');
    card.append(`
			<div class="card-content accordion-item">
				<a href="#" class="item-content item-link">
					<div class="item-inner">
						... ${cardContent.title}
					</div>
				</a>
				<div class ="accordion-item-content">
					<div class="content">${cardContent.content}</div>
				</div>
            </div>



        `);
    if (cardContent.links != undefined) {
      let links = $$(document.createElement('div'));
      links.addClass('accordion-item-links');
      for (
        let linkIndex = 0;
        linkIndex < cardContent.links.length;
        linkIndex++
      ) {
        if (externalLinks) {
          links.append(`
					<a class="link external button button-raised button-fill color-white text-color-black" target="_blank" href="${cardContent.links[linkIndex].url}">${cardContent.links[linkIndex].buttonTitle}</a>
				`);
        } else {
          links.append(`
					<a class="link button button-raised button-fill color-white text-color-black" href="${cardContent.links[linkIndex].url}">${cardContent.links[linkIndex].buttonTitle}</a>
				`);
        }
      }
      card.find('.accordion-item-content').append(links);
    }
    return card;
  },
  addAdviseButton: function (button) {},
};

export default adviseView;
