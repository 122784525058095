import domHelper from './domHelper';

const touchController = {
  /**
   * Makes a Dom7 element draggable by mouse or touch.
   *
   * You can specify the drag-behavior by giving options as parameter:
   * {
   *     maxTop: int,                             // maximum amount of pixels the element is draggable in top direction
   *     maxRight: int,                           // maximum amount of pixels the element is draggable in right direction
   *     maxBottom: int,                          // maximum amount of pixels the element is draggable in bottom direction
   *     maxLeft: int,                            // maximum amount of pixels the element is draggable in left direction
   *     noBorderTop: boolean,                    // if true, its possible to drag the element out of the window in top direction
   *     absoluteOrigin: boolean,                 // on failed drag the element moves back to its first position instead of moving to the last position
   *     checkMovement: (x,y) => {accept(x,y)},   // function takes x and y distance from origin as parameters return true, to perform movement
   * }
   *
   * @param element the dom7 element
   * @param options an object with options to specify the drag-behavior
   * @param onTouchStart called on begin of dragging
   * @param onTouchMove called while dragging and takes x and y distance from origin as parameters
   * @param onTouchEnd called after dragging and takes x and y distance from origin as parameters
   * @param onTouchCancel called after dragging failed
   */
  makeDraggable: function (
    element,
    options,
    onTouchStart,
    onTouchMove,
    onTouchEnd,
    onTouchCancel,
  ) {
    // for desktops
    let pressed = false;

    let touchposition = { x: null, y: null }; // first contact with element on window
    let elementposition = { x: null, y: null }; // calm position of element on window
    let absoluteposition = { x: null, y: null }; // first position of element on page
    let lastVector = null; // last movement from absolute

    // save touchstart:
    let saveOrigins = function (event) {
      element.addClass('touching');

      touchposition.x = event.pageX;
      touchposition.y = event.pageY;
      elementposition.x = element.offset().left;
      elementposition.y =
        element.offset().top -
        parseInt(window.getComputedStyle(element[0]).marginTop.split('px')[0]);
      if (!absoluteposition.x || !absoluteposition.y) {
        absoluteposition.x = elementposition.x;
        absoluteposition.y = elementposition.y;
      }
      if (!lastVector) {
        lastVector = {
          x: elementposition.x - absoluteposition.x,
          y: elementposition.y - absoluteposition.y,
        };
      }
      if (onTouchStart) {
        onTouchStart();
      }

      element.attr(
        'style',
        'top: ' +
          parseInt(elementposition.y) +
          'px; left: ' +
          parseInt(elementposition.x) +
          'px; width:' +
          element.width() +
          'px;',
      );
      element.addClass('moving');
    };

    // save position as soon as possible
    domHelper.onElementInsertOnce(element, () => {
      elementposition.x = element.offset().left;
      elementposition.y =
        element.offset().top -
        parseInt(window.getComputedStyle(element[0]).marginTop.split('px')[0]);
      if (!absoluteposition.x || !absoluteposition.y) {
        absoluteposition.x = elementposition.x;
        absoluteposition.y = elementposition.y;
      }

      //save with for absolute position while moving
      element.attr('style', 'width: ' + element.width() + 'px;');
    });

    element.on(
      'touchstart',
      (event) => {
        saveOrigins(event.changedTouches[0]);
      },
      false,
    );
    element.on(
      'mousedown',
      (event) => {
        pressed = true;
        saveOrigins(event);
      },
      false,
    );

    // display motion:
    let displayMotion = function (event) {
      // calc movement
      let vector = {
        x: event.pageX - touchposition.x,
        y: event.pageY - touchposition.y,
      };
      let absoluteVector = {
        x: elementposition.x + vector.x - absoluteposition.x,
        y: elementposition.y + vector.y - absoluteposition.y,
      };

      // check maximum movement
      // dynamically
      if (
        options.checkMovement &&
        !options.checkMovement(absoluteVector.x, absoluteVector.y)
      ) {
        absoluteVector.x = lastVector.x;
        absoluteVector.y = lastVector.y;
      }
      // statically
      if (options.maxTop != null && absoluteVector.y < -options.maxTop) {
        absoluteVector.y = -options.maxTop;
      }
      if (options.maxRight != null && absoluteVector.x > options.maxRight) {
        absoluteVector.x = options.maxRight;
      }
      if (options.maxBottom != null && absoluteVector.y > options.maxBottom) {
        absoluteVector.y = options.maxBottom;
      }
      if (options.maxLeft != null && absoluteVector.x < -options.maxLeft) {
        absoluteVector.x = -options.maxLeft;
      }

      lastVector = { x: absoluteVector.x, y: absoluteVector.y };

      // apply new position to element
      element.attr(
        'style',
        'top: ' +
          parseInt(absoluteposition.y + absoluteVector.y) +
          'px; left: ' +
          parseInt(absoluteposition.x + absoluteVector.x) +
          'px; width:' +
          element.width() +
          'px;',
      );

      if (onTouchMove) {
        onTouchMove(absoluteVector.x, absoluteVector.y);
      }
    };
    element.on(
      'touchmove',
      (event) => {
        event.preventDefault();
        let touch = event.changedTouches[0];
        displayMotion(touch);

        // return to origin if touch leaves document
        if (
          !document
            .elementsFromPoint(touch.pageX, touch.pageY)
            .includes(document.getElementById('app'))
        ) {
          returnToOrigin(touch);
        }
      },
      false,
    );
    document.addEventListener(
      'mousemove',
      (event) => {
        event.preventDefault();
        if (pressed) {
          displayMotion(event);
        }
      },
      false,
    );

    // return to origin:
    let returnToOrigin = function (event) {
      element.removeClass('touching');

      if (options.noBorderTop && event && event.clientY < 0) {
        // do nothing
      } else {
        let position =
          options.absoluteOrigin || !lastVector
            ? absoluteposition
            : {
                x: absoluteposition.x + lastVector.x,
                y: absoluteposition.y + lastVector.y,
              };
        element.attr(
          'style',
          'top: ' +
            parseInt(position.y) +
            'px; left: ' +
            parseInt(position.x) +
            'px; width:' +
            element.width() +
            'px;',
        );
      }

      element.removeClass('moving');

      if (onTouchCancel) {
        onTouchCancel();
      }
    };
    element.on(
      'touchcancel',
      () => {
        returnToOrigin();
      },
      false,
    );
    document.addEventListener('mouseout', (event) => {
      if (pressed) {
        event = event ? event : window.event;
        let from = event.relatedTarget || event.toElement;
        if (!from || from.nodeName === 'HTML') {
          pressed = false;
          returnToOrigin(event);
        }
      }
    });

    // end touch
    let endTouch = function () {
      element.removeClass('touching');

      if (onTouchEnd) {
        onTouchEnd(
          element.offset().left - absoluteposition.x,
          element.offset().top - absoluteposition.y,
        );
      }

      element.removeClass('moving');
    };
    element.on(
      'touchend',
      (event) => {
        endTouch();
      },
      false,
    );
    document.addEventListener(
      'mouseup',
      (event) => {
        if (pressed) {
          event.preventDefault();
          pressed = false;
          endTouch();
        }
      },
      false,
    );
  },
};
export default touchController;
