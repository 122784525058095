import app from '../app.js';
import $$ from 'dom7';
import NotFoundPage from './404.f7.html';
import DynamicRoutePage from './dynamic-route.f7.html';
import RequestAndLoad from './request-and-load.f7.html';
import HelloWorldPage from './hello-world.f7.html';
import AboutPage from './about.f7.html';
import FormPage from './form.f7.html';

import StartPage from './start/start.f7.html';
import OnboardingPage from './onboarding/onboarding.f7.html';
import LoginPage from './login/login.f7.html';
import HomePage from './home/home.f7.html';
import AlltasksPage from './home/alltasks/alltasks.f7.html';
import AdvisePage from './advise/advise.f7.html';
import FeedbackformPage from './feedbackform/feedbackform.f7.html';
import ReminderPage from './reminder/reminder.f7.html';
import SettingsPage from './settings/settings.f7.html';
import UserInfoPage from './settings/userinfo.f7.html';
import AuthChangePage from './settings/authchange.f7.html';
import ProfilePage from './profile/profile.f7.html';
import ExpertisePage from './profile/expertise/expertise.f7.html';
import f360resultPage from './profile/f360result/f360result.f7.html';
import ExpertiseEditPage from './profile/expertise/edit/edit.f7.html';
import IdeaPage from './profile/idea/idea.f7.html';
import NotebookPage from './notebook/notebook.f7.html';
import NoteeditorPage from './notebook/noteeditor/noteeditor.f7.html';
import KeydatePage from './notebook/keydate/keydate.f7.html';
import ModulePage from './steps/module/module.f7.html';
import ExercisePage from './steps/exercise/exercise.f7.html';
import TaskPage from './steps/exercise/task/task.f7.html';
import ImpressumPage from './impressum.f7.html';
import AGBPage from './agb.f7.html';
import PrivacyPage from './privacypolicy.f7.html';
import startController from './start/StartController.js';
import onboardingController from './onboarding/OnboardingController.js';
import loginController from './login/LoginController.js';
import feedbackformController from './feedbackform/FeedbackformController.js';
import reminderController from './reminder/ReminderController';
import settingsController from './settings/SettingsController';
import profileController from './profile/ProfileController';
import ideaController from './profile/idea/IdeaController.js';
import expertiseController from './profile/expertise/ExpertiseController';
import expertiseEditController from './profile/expertise/edit/EditController';
import f360resultController from './profile/f360result/f360resultController';
import keydateController from './notebook/keydate/KeydateController';
import notebookController from './notebook/NotebookController';
import noteeditorController from './notebook/noteeditor/NoteeditorController';
import moduleController from './steps/module/ModuleController';
import exerciseController from './steps/exercise/ExerciseController';
import taskController from './steps/exercise/task/TaskController';
import goalController from './steps/exercise/task/types/goal/GoalController';
import homeController from './home/HomeController';
import alltasksController from './home/alltasks/AlltasksController';
import adviseController from './advise/AdviseController.js';

var routes = [
  {
    path: '/',
    component: HomePage,
    options: {
      animate: false,
    },
    on: {
      pageInit: function (e, page) {
        if (app.device.cordova) window.screen.orientation.unlock();
        homeController.load();
      },
    },
  },
  {
    path: '/alltasks/',
    component: AlltasksPage,
    on: {
      pageInit: function (e, page) {
        alltasksController.init();
      },
    },
  },
  {
    path: '/start/',
    component: StartPage,
    on: {
      pageInit: function (e, page) {
        startController.init();
      },
    },
  },
  {
    path: '/onboarding/',
    component: OnboardingPage,
    on: {
      pageAfterIn: function (e, page) {
        onboardingController.init();
      },
    },
  },
  {
    path: '/login/:startview/',
    component: LoginPage,
    on: {
      pageInit: function (e, page) {
        loginController.init(page.route.params['startview']);
      },
    },
  },
  {
    path: '/notebook/',
    component: NotebookPage,
    options: {
      animate: false,
    },
    on: {
      pageBeforeIn: function (e, page) {
        notebookController.refresh();
      },
      pageAfterOut: function (e, page) {
        notebookController.uninit();
      },
    },
  },
  {
    path: '/goal/:number/:step/',
    component: TaskPage,
    on: {
      pageInit: function (e, page) {
        goalController.loadGoal(
          page.route.params['number'],
          parseInt(page.route.params['step']),
        );
      },
    },
    options: {
      transition: 'f7-cover-v',
    },
  },
  {
    path: '/keydate/:number/:step/',
    component: KeydatePage,
    on: {
      pageInit: function (e, page) {
        keydateController.load(
          page.route.params['number'],
          parseInt(page.route.params['step']),
        );
      },
    },
    options: {
      transition: 'f7-cover-v',
    },
  },
  {
    path: '/noteeditor/:number/:step/',
    component: NoteeditorPage,
    on: {
      pageInit: function (e, page) {
        noteeditorController.load(
          page.route.params['number'],
          parseInt(page.route.params['step']),
        );
      },
    },
    options: {
      transition: 'f7-cover-v',
    },
    beforeLeave: function (routeTo, routeFrom, resolve, reject) {
      noteeditorController.beforeLeave();
      resolve();
    },
  },
  {
    path: '/profile/',
    component: ProfilePage,
    options: {
      animate: false,
    },
    on: {
      pageBeforeIn: function (e, page) {
        profileController.refresh();
      },
      pageAfterOut: function (e, page) {
        profileController.unload();
      },
    },
    routes: [
      {
        path: 'ideas/',
        options: {
          animate: false,
        },
        beforeEnter: function () {
          app.views.current.router.navigate('/profile/');
          profileController.openIdeasTab();
        },
      },
      {
        path: 'options/',
        options: {
          animate: false,
        },
        beforeEnter: function () {
          app.views.current.router.navigate('/profile/');
          profileController.openOptionsTab();
        },
      },
    ],
  },
  {
    path: '/idea/:id/',
    component: IdeaPage,
    on: {
      pageInit: function (e, page) {
        ideaController.load(page.route.params['id']);
      },
    },
  },
  {
    path: '/expertise/:id/',
    name: 'expertise',
    component: ExpertisePage,
    on: {
      pageBeforeIn: function (e, page) {
        expertiseController.load(page.route.params['id']);
      },
      pageAfterOut: function (e, page) {
        expertiseController.unload();
      },
    },
    routes: [
      {
        path: 'edit/',
        component: ExpertiseEditPage,
        options: {
          animate: false,
        },
        on: {
          pageInit: function (e, page) {
            expertiseEditController.init(page.route.params['id']);
          },
        },
      },
    ],
  },
  {
    path: '/hello/',
    component: HelloWorldPage,
  },
  {
    path: '/mod/:id/',
    component: ModulePage,
    on: {
      pageInit: function (e, page) {
        moduleController.load(parseInt(page.route.params['id']));
      },
    },
    options: {
      transition: 'f7-fade',
    },
  },
  {
    path: '/ex/:moduleID/:chapterID/:exerciseID/:subblockID/',
    component: ExercisePage,
    on: {
      pageInit: function (e, page) {
        exerciseController.entryTime = performance.now();
        exerciseController.load(
          parseInt(page.route.params['moduleID']),
          parseInt(page.route.params['chapterID']),
          parseInt(page.route.params['exerciseID']),
          parseInt(page.route.params['subblockID']),
        );
      },
    },
    options: {
      transition: 'f7-parallax',
    },
    beforeLeave: function (routeTo, routeFrom, resolve, reject) {
      exerciseController.updateTime(
        parseInt(routeFrom.params['moduleID']),
        parseInt(routeFrom.params['chapterID']),
        parseInt(routeFrom.params['exerciseID']),
      );
      resolve();
    },
  },
  {
    path: '/task/:moduleID/:chapterID/:exerciseID/:taskID/',
    component: TaskPage,
    on: {
      pageInit: function (e, page) {
        taskController.entryTime = performance.now();
        taskController.load(
          parseInt(page.route.params['moduleID']),
          parseInt(page.route.params['chapterID']),
          parseInt(page.route.params['exerciseID']),
          parseInt(page.route.params['taskID']),
        );
      },
    },
    options: {
      transition: 'f7-cover-v',
    },
    beforeLeave: function (routeTo, routeFrom, resolve, reject) {
      let moduleId = parseInt(routeFrom.params['moduleID']);
      if (moduleId > 0) {
        taskController.updateTime(
          moduleId,
          parseInt(routeFrom.params['chapterID']),
          parseInt(routeFrom.params['exerciseID']),
        );
      }
      resolve();
    },
  },
  {
    path: '/settings/',
    component: SettingsPage,
    on: {
      pageInit: settingsController.init(),
    },
    options: {
      transition: 'f7-cover-v',
    },
  },

  {
    path: '/userinfo/:afterregistration',
    component: UserInfoPage,
    on: {
      pageInit: function (e, page) {
        settingsController.loadUserinfoInput(
          parseInt(page.route.params['afterregistration']),
        );
      },
    },
  },
  {
    path: '/authchange/',
    component: AuthChangePage,
    on: {
      pageInit: function (e, page) {
        settingsController.loadAuthChange();
      },
    },
  },
  {
    path: '/advise/',
    component: AdvisePage,
    on: {
      pageInit: function (e, page) {
        adviseController.initView('adviseContent');
      },
    },
  },
  {
    path: '/findsubject/',
    component: AdvisePage,
    on: {
      pageInit: function (e, page) {
        adviseController.initView('findSubjectContent');
      },
    },
  },
  {
    path: '/feedbackform/',
    component: FeedbackformPage,
    on: {
      pageInit: function (e, page) {
        feedbackformController.init();
      },
    },
  },
  {
    path: '/f360result/:index/',
    component: f360resultPage,
    on: {
      pageBeforeIn: function (e, page) {
        f360resultController.load(page.route.params['index']);
      },
      pageAfterOut: function (e, page) {
        f360resultController.unload();
      },
    },
  },
  {
    path: '/impressum/',
    component: ImpressumPage,
  },
  {
    path: '/agb/',
    component: AGBPage,
    on: {
      pageAfterIn: function (e, page) {
        let agbs = JSON.parse(localStorage.getItem('AGBs-content'));
        $$('.page[data-name="agbs"] .page-content').html(agbs);
      },
    },
  },
  {
    path: '/privacypolicy/',
    component: PrivacyPage,
    on: {
      pageAfterIn: function (e, page) {
        let privacy = JSON.parse(localStorage.getItem('privacypolicy-content'));
        $$('.page[data-name="privacypolicy"] .page-content').html(privacy);
      },
    },
  },
  {
    path: '/about/',
    component: AboutPage,
  },
  {
    path: '/form/',
    component: FormPage,
  },
  {
    path: '/dynamic-route/blog/:blogId/post/:postId/',
    component: DynamicRoutePage,
  },
  {
    path: '/reminder/:moduleID/:chapterID/:exerciseID/',
    component: ReminderPage,
    on: {
      pageInit: function (e, page) {
        reminderController.load(
          parseInt(page.route.params['moduleID']),
          parseInt(page.route.params['chapterID']),
          parseInt(page.route.params['exerciseID']),
        );
      },
    },
    options: {
      transition: 'f7-cover-v',
    },
  },
  {
    path: '/request-and-load/user/:userId/',
    async: function (routeTo, routeFrom, resolve, reject) {
      // Router instance
      var router = this;

      // App instance
      var app = router.app;

      // Show Preloader
      app.preloader.show();

      // User ID from request
      var userId = routeTo.params.userId;

      // Simulate Ajax Request
      setTimeout(function () {
        // We got user data from request
        var user = {
          firstName: 'Vladimir',
          lastName: 'Kharlampidi',
          about: 'Hello, i am creator of Framework7! Hope you like it!',
          links: [
            {
              title: 'Framework7 Website',
              url: 'http://framework7.io',
            },
            {
              title: 'Framework7 Forum',
              url: 'http://forum.framework7.io',
            },
          ],
        };
        // Hide Preloader
        app.preloader.hide();

        // Resolve route to load page
        resolve(
          {
            component: RequestAndLoad,
          },
          {
            context: {
              user: user,
            },
          },
        );
      }, 1000);
    },
  },
  {
    path: '(.*)',
    component: NotFoundPage,
    options: {
      transition: 'f7-parallax',
    },
  },
];

export default routes;
