import './edit.css';

import $$ from 'dom7';
import app from '../../../../app';
import elementFactory from '../../../../core/ui/ElementFactory';

let controller;

var editView = {
  init(cntrllr) {
    controller = cntrllr;

    app.loadMainFAB($$('.page[data-name="expertise-edit"] .main-fab'));
    app.loadMainToolbar($$('.page[data-name="expertise-edit"] .main-toolbar'));
  },

  updateBackNavigation(handler) {
    $$('.page[data-name="expertise-edit"] .navbar a.link')
      .off('click')
      .on('click', () => {
        handler();
      });
  },

  loadTitle(title) {
    $$('#expertise-edit-title').empty().append(title);
  },

  loadText(text) {
    $$('#expertise-edit-text').empty().append(text);
  },

  loadEntries(entries) {
    $$('#expertise-edit-entries')
      .empty()
      .append(
        entries
          ? elementFactory.getSortableList(entries, (array) =>
              controller.change(array),
            )
          : '',
      );
  },

  loadButtons(buttonLeft, buttonRight) {
    $$('#expertise-edit-button-left')
      .empty()
      .append(buttonLeft.title)
      .off('click')
      .on('click', () => {
        buttonLeft.action();
      });

    $$('#expertise-edit-button-right')
      .empty()
      .append(buttonRight.title)
      .off('click')
      .on('click', () => {
        buttonRight.action();
      });
  },
};
export default editView;
