import Exercise from './Exercise';
import Chapter from './Chapter';
import userData from '../user/UserData';
import mediaController from '../../core/MediaController';

function Module(json, number) {
  this.number = number;
  this.id = number;
  this.state = 0;
  this.last_verified = 0;
  this.chapters = [];
  this.thumbnail = '';
  for (var key in json) if (key != 'chapters') this[key] = json[key]; // Alle Attribute aus der JSON kopieren
  var _this = this;
  json.chapters.forEach(function (chapter, index) {
    // Chapters überschreiben mit neuen Objekten vom Typ Chapter
    _this.chapters[index] = new Chapter(chapter, _this.number, index + 1);
  });
  var toLoad = JSON.parse(localStorage.getItem(this._id)); // Gespeicherte Daten vom Modul laden
  for (var key in toLoad) this[key] = toLoad[key]; // Gespeichert Daten ins Objekt schreiben
}

// Saves content of Module to LocalStorage
Module.prototype.save = function () {
  let toSave = {};
  for (var key in this) {
    if (!(key in modulesJson[this.number])) {
      toSave[key] = this[key];
    }
  }
  localStorage.setItem(this._id, JSON.stringify(toSave));
};

// Returns the first Exercise that is not finished
Module.prototype.getNextExercise = function () {
  for (ch of this.chapters) {
    let ex = ch.getNextExercise();
    if (ex.state == 0) {
      return ex;
    }
  }
  for (ch of this.chapters) {
    let ex = ch.getNextExercise();
    if (ex.state == 1) {
      return ex;
    }
  }
  return this.chapters[0].exercises[0];
};

// Returns the Exercise with the passed number
Module.prototype.getExercise = function (number) {
  return exercises[number];
};

// Returns all Exercises
Module.prototype.getExercises = function () {
  return exercises;
};

Module.prototype.getBackgroundImage = function () {
  return mediaController.getChapterBackgroundIllustraion(this.thumbnail);
};

Module.prototype.getPercentageFinishedSubblocks = function () {
  let subblocks = { finished: 0, total: 0 };

  for (let chap = 0; chap < this.chapters.length; chap++) {
    let chapSubblocks = this.chapters[chap].getAbsoluteFinishedSubblocks();
    subblocks.finished += chapSubblocks.finished;
    subblocks.total += chapSubblocks.total;
  }
  return subblocks.finished / subblocks.total;
};

export default Module;
