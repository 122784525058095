import app from "../app.js";
import userController from "../core/UserController";
import Dictionary from "../core/Dictionary";
import $$ from "dom7";

const syncController = {
  connector: null,
  init: function (connector, app) {
    this.connector = connector;
    this.app = app;
  },
  toSyncObjects: {}, // object with _id
  updateModules: async function (modulesJson) {
    /*let module;
		if(true){ //axiosInstance works
			let revisionlevels = await dbconnector.getModuleRevisionLevels()
			for(let i = 1; i < 10; i++){
				if(revisionlevels[i] > localStorage.getItem('module' + i + 'json').revision_level){
					//modulesJson[i] = await dbconnector.downloadModule(i); //removed exercises have to be found and put somewhere
					localStorage.setItem("module" + i + "json", JSON.stringify(modulesJson[i]));
				}
			}
		}*/
  },
  updateAdvise: async function () {
    let adviseJson = JSON.parse(localStorage.getItem("adviseJson"));
    let dbAdviseJson = await dbconnector.downloadAdvise();
    if (adviseJson.revision_level < dbAdviseJson.revision_level) {
      localStorage.setItem("adviseJson", JSON.stringify(dbAdviseJson));
    }
  },
  /**
   * Function that gets called from the outside to start synchronisation-process
   * @param {any} app object to call dialog-functions
   */
  syncUserData: async function (app, withDialog = true) {
    if (!app) app = this.app;
    let result = false;
    if (this.connector.isLoggedIn()) {
      // connection to db
      //synchronisationController.updateAdvise();
      //userController.updateModules();
      if (withDialog) {
        app.dialog.preloader(Dictionary.getText("misctexts", "syncawait"));
        result = await this._syncUserData();

        if (!result) {
          app.dialog.close();
          app.dialog.alert(Dictionary.getText("misctexts", "syncfail"));
        } else {
          app.dialog.close();
        }
      } else {
        result = await syncController._syncUserData();
      }

      userController.getAllTasks().forEach((task) => {
        task.writeToExplicitKey();
      });

      return result;
    }
  },
  /**
   * Syncs UserData with db for Exercises, Tasks, Notes, Goals and ToDos
   */
  _syncUserData: async function () {
    try {
      let newData = await this.connector.downloadNewUserInput(
          userController.getUserData("last_synced")
        ),
        unsyncedObjects = userController.getUnsyncedObjects();
      if (newData.status != 200) {
        return false;
      }
      newData = this._parseDownloadData(newData.data);
      let syncedData = await this._mergeLists(newData, unsyncedObjects);
      syncedData.dataToDB = this._parseUploadData(syncedData.dataToDB);
      //If there is Data to upload, upload the data and if that is succesful load data from DB to LS
      if (syncedData.dataToDB.inputs.length > 0) {
        let result = await this.connector.updateUserInput(syncedData.dataToDB);

        if (result && result.status == 200) {
          if (syncedData.dataToLS) {
            for (const object of syncedData.dataToLS) {
              this._storeUserDataFromDB(object);
            }
          }
          userController.setUserData("last_synced", Date.now());
          return true;
        }
      }
      //If no upload we load data from DB to LS immediately
      else if (syncedData.dataToLS) {
        for (const object of syncedData.dataToLS) {
          this._storeUserDataFromDB(object);
        }
        userController.setUserData("last_synced", Date.now());

        return true;
      }
    } catch (e) {
      console.error("Error during syncUserData.", e.message);
    }
  },
  /**
   * Stores an object from DB in localStorage and sets relevant localStorage-Entries accordingly
   * @param {any} object to be stored.
   */
  _storeUserDataFromDB: function (object) {
    let objectLS = JSON.parse(localStorage.getItem(object.input._id));
    if (objectLS) {
      for (const key in object.input) {
        objectLS[key] = object.input[key];
        localStorage.setItem(object.input._id, JSON.stringify(objectLS));
      }
    } else {
      localStorage.setItem(object.input._id, JSON.stringify(object.input));
      switch (object.iid.substring(0, 2)) {
        case "03":
          let noteNumbers = JSON.parse(localStorage.getItem("noteNumbers"));
          if (noteNumbers == null) noteNumbers = [];
          noteNumbers.push(object.input._id);
          localStorage.setItem("noteNumbers", JSON.stringify(noteNumbers));
          break;
        case "04":
          let goalNumbers = JSON.parse(localStorage.getItem("goalNumbers"));
          if (goalNumbers == null) goalNumbers = [];
          goalNumbers.push(object.input._id);
          localStorage.setItem("goalNumbers", JSON.stringify(goalNumbers));
          break;
        case "05":
          let keydateNumbers = JSON.parse(
            localStorage.getItem("keydateNumbers")
          );
          if (keydateNumbers == null) keydateNumbers = [];
          keydateNumbers.push(object.input._id);
          localStorage.setItem(
            "keydateNumbers",
            JSON.stringify(keydateNumbers)
          );
          break;
        case "06":
          let ideaIds = localStorage.getItem("profile-ideas-ids");
          if (!ideaIds) {
            ideaIds = [];
          } else {
            ideaIds = JSON.parse(ideaIds);
          }
          ideaIds.push(object.input._id);
          localStorage.setItem("profile-ideas-ids", JSON.stringify(ideaIds));
          userController.getUserData("profile").reinitIdeas();
          break;
      }
    }
  },
  /**
   * Flattens all lists of objects to upload into one list and gives each object an object-identifier 'iid'
   * @param dataToDB Object with all lists of objects that get uploaded
   * */
  _parseUploadData: function (dataToDB) {
    // Post all objects or only those that are new?
    let iidprefixes = {
      exercises: "01",
      tasks: "02",
      notes: "03",
      goals: "04",
      keydates: "05",
      ideas: "06",
    };
    let parsedData = [];
    for (const key in dataToDB) {
      for (let objectDB of dataToDB[key]) {
        parsedData.push({
          input: objectDB,
          iid: iidprefixes[key] + objectDB._id,
          level: objectDB.last_modified,
        });
      }
    }

    return { inputs: parsedData };
  },
  /**
   * Parses data from DB for easier synchronisation of local and remote data.
   * @param {any} dataFromDB all data from DB.
   */
  _parseDownloadData: function (dataFromDB) {
    dataFromDB.inputs = dataFromDB.inputs.map((input) => {
      input.input = input.input;
      return input;
    });
    return dataFromDB.inputs; //.map(x => JSON.parse(x.input))
  },
  /**
   * Resolves merge conflicts for all Objects that have not been synchronized
   * @param {List} objectsFromDB Object with Lists for all objects that get synchronized, with all unsychronised Objects from DB
   * @param {List} objectsFromLS Object with Lists for all objects that get synchronized, with all unsychronised Objects from LS
   * @param {int} startIndex Index from which to start the merging. This is used so that we can run through this function in multiple steps, while waiting for user-action in case of merge-conflict
   *
   */
  _mergeLists: async function (objectsFromDB, objectsFromLS, startIndex) {
    try {
      let oldObject, objectLS;
      //Update all objects from DB
      for (let [index, objectDB] of objectsFromDB.entries()) {
        oldObject = JSON.parse(localStorage.getItem(objectDB.input._id));
        if (
          oldObject &&
          oldObject.last_modified != null &&
          oldObject.last_modified > userController.getUserData("last_synced")
        ) {
          let iidNameMap = {
            "01": "exercises",
            "02": "tasks",
            "03": "notes",
            "04": "goals",
            "05": "keydates",
            "06": "ideas",
          };
          objectLS = objectsFromLS[
            iidNameMap[objectDB.iid.substring(0, 2)]
          ].find((x) => x._id == objectDB.input._id);
          let mergeResult = await this._handleMergeConflict(
            objectLS,
            objectDB.input,
            iidNameMap[objectDB.iid.substring(0, 2)]
          );
          if (mergeResult == "remote") {
            objectsFromLS[iidNameMap[objectDB.iid.substring(0, 2)]] =
              objectsFromLS[iidNameMap[objectDB.iid.substring(0, 2)]].filter(
                (x) => x._id !== oldObject._id
              );
          } else {
            objectsFromDB = objectsFromDB.filter((x) => x != objectDB);
          }
        }
      }
      return { dataToDB: objectsFromLS, dataToLS: objectsFromDB };
    } catch (e) {
      console.error("Error during _mergeLists", e.message);
    }
  },
  /**
   * Opens a dialog where user can decide whether to keep local or remote version of object
   * @param objectLS is the locally stored object
   * @param objectDB is the remotely stored object
   * @return string 'local' if local object is kept, 'remote' if remote object is kept.
   * */
  _handleMergeConflict: function (objectLS, objectDB, objectType) {
    app.dialog.close();
    const options = {
      minute: "2-digit",
      hour: "2-digit",
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    let localDescription, remoteDescription;

    switch (objectType) {
      case "exercises":
        return new Promise((resolve, reject) => resolve("remote"));
        break;
      case "tasks":
        let task = userController.getExerciseOrTaskByID(objectDB._id);
        localDescription = "Aufgabe: " + task.name;
        remoteDescription = "Aufgabe: " + task.name;
        break;
      case "notes":
        localDescription = "Notiz: " + objectLS.title;
        remoteDescription = "Notiz: " + objectDB.title;
        break;
      case "goals":
        localDescription = "Ziel: " + objectLS.specific;
        remoteDescription = "Ziel: " + objectDB.specific;
        break;
      case "keydates":
        localDescription = "Termin: " + objectLS.title;
        remoteDescription = "Termin" + objectDB.title;
        break;
      case "ideas":
        break;
    }
    app.dialog
      .create({
        title: `Synchronisationskonflikt`,
        text: `<div class="row">Es gab einen Konflikt. Bitte entscheide dich f&uumlr die lokale oder die Cloud Version.</div>
					<div class="row">
						<div class="col-50"><h4>Lokale Version</h4>
							<p>${localDescription}</p>
							<p><b>Datum: ${new Date(objectLS.last_modified).toLocaleDateString(
                "de-DE",
                options
              )}</b></p>		
						</div>
						<div class="col-50"><h4>Cloud-Version</h4>
							<p>${remoteDescription}</p>
							<p><b>Datum: ${new Date(objectDB.last_modified).toLocaleDateString(
                "de-DE",
                options
              )}</b></p>
						</div>
					</div>`,
        buttons: [
          {
            text: "Lokale Version behalten",
          },
          {
            text: "Cloud-Version behalten",
          },
        ],
        verticalButtons: true,
      })
      .open();
    $$(".dialog").attr("id", "merge-conflict-dialog");
    return new Promise(function (resolve, reject) {
      $$(".dialog-buttons")
        .children(":nth-child(1)")
        .once("click", function (e) {
          app.dialog.preloader(Dictionary.getText("misctexts", "syncawait"));
          resolve("local");
        });
      $$(".dialog-buttons")
        .children(":nth-child(2)")
        .once("click", function (e) {
          app.dialog.preloader(Dictionary.getText("misctexts", "syncawait"));
          resolve("remote");
        });
    });
  },
  /**
   * Implement this, when we compress all userdata and need to figure out which objects are new in the client
   * @param {any} userdata
   */
  _getModifiedUserData: function (userdata) {
    return userdata;
  },
};

export default syncController;

function memorySizeOf(obj) {
  var bytes = 0;

  function sizeOf(obj) {
    if (obj !== null && obj !== undefined) {
      switch (typeof obj) {
        case "number":
          bytes += 8;
          break;
        case "string":
          bytes += obj.length * 2;
          break;
        case "boolean":
          bytes += 4;
          break;
        case "object":
          var objClass = Object.prototype.toString.call(obj).slice(8, -1);
          if (objClass === "Object" || objClass === "Array") {
            for (var key in obj) {
              if (!obj.hasOwnProperty(key)) continue;
              sizeOf(obj[key]);
            }
          } else bytes += obj.toString().length * 2;
          break;
      }
    }
    return bytes;
  }

  function formatByteSize(bytes) {
    if (bytes < 1024) return bytes + " bytes";
    else if (bytes < 1048576) return (bytes / 1024).toFixed(3) + " KiB";
    else if (bytes < 1073741824) return (bytes / 1048576).toFixed(3) + " MiB";
    else return (bytes / 1073741824).toFixed(3) + " GiB";
  }

  return formatByteSize(sizeOf(obj));
}
