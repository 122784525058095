import settingsView from './SettingsView.js';
import elementFactory from '../../core/ui/ElementFactory';
import dictionary from '../../core/Dictionary';

const settingsController = {
  init: function () {
    settingsView.init();
  },
  /**
   * Load Input-Form for Userdata-input.
   * @param afterregistration is true, when this site if opened after registration. Routing is then different.
   */
  loadUserinfoInput: function (afterregistration) {
    settingsView.loadUserinfoView(afterregistration);
  },
  /**
   * load Form for chaning email or password.
   * */
  loadAuthChange: function () {
    settingsView.loadAuthChange();
  },
};
export default settingsController;
