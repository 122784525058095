import $$ from 'dom7';
import elementFactory from '../../../../../../core/ui/ElementFactory';
import inputController from '../../input/InputController';

let controller;

var ratingView = {
  init: function (cntrllr, topic, question, fields, first) {
    controller = cntrllr;
    this.loadTop();
    this.loadBottom();
    this.loadContent(topic, question, fields);
    if (!first) {
      this.hideLegend();
      this.showBackwards();
    }
    this.loadBackNavigation();
  },

  loadTop: function () {
    $$('#task-page-content .page-content-top')
      .empty()
      .append(
        elementFactory
          .getProgressbar(controller.getProgress())
          .attr('id', 'jobrating-progress')
          .addClass('ci-progressbar-top'),
      ).append(`
                <h1 id="task-title" class="page-title">Berufe-Rating</h1>
                <h2 id="task-subtitle" class="page-subtitle"></h2>
            `);
  },

  loadContent: function (topic, question, fields) {
    // check if als fields are answered
    let allAnswered = function () {
      let res = true;
      for (let field of fields) {
        if (!field.answer) {
          res = false;
        }
      }
      return res;
    };

    // scroll up
    $$('#task-page-content')[0].scrollTop = 0;

    let profileButton = $$(document.createElement('button'))
      .addClass('button button-fill color-white')
      .attr('id', 'jobrating-profile-button')
      .append('Profilbereich anzeigen')
      .on('click', () => {
        controller.displayProfilePreview();
      });

    let content = $$('#task-content')
      .empty()
      .append(topic)
      .append(
        $$(document.createElement('div'))
          .addClass('button-list shadowed jobrating-profile-button')
          .append(profileButton),
      )
      .append(question);

    // multiple lines of radio-buttons
    for (let fieldIndex in fields) {
      if (fields.hasOwnProperty(fieldIndex)) {
        content
          // heading
          .append(
            '<h3 class="jobrating-field-topic">' +
              fields[fieldIndex].name +
              '</h3>',
          )
          // buttons
          .append(
            inputController
              .getColoredRadioButtons(
                4,
                fields[fieldIndex].answer,
                (res) => {
                  // save
                  controller.save(fieldIndex, res);
                  // remember line answered
                  fields[fieldIndex].answer = res;

                  // if all lines answered wait and navigate next
                  if (allAnswered()) {
                    // no more changes possible
                    $$('#jobrating-profile-button').addClass('no-pointer');
                    $$('.jobrating-radios').addClass('no-pointer');
                    ratingView.hideLegend();
                    setTimeout(() => {
                      controller.next();
                    }, 300);
                  }
                },
                // legend only if last field in list
                fieldIndex < fields.length - 1
                  ? ''
                  : `
                                <div class="jobrating-rating-legend">
                                    <div class="jobrating-rating-low">passt nicht</div>
                                    <div class="jobrating-rating-back hide">
                                        Rückgängig
                                        <i class="icon material-icons">settings_backup_restore</i>
                                    </div>
                                </div>
                            `,
                fieldIndex < fields.length - 1
                  ? ''
                  : `
                                <div class="jobrating-rating-legend">
                                    <div class="jobrating-rating-high">passt voll</div>
                                </div>
                            `,
              )
              .addClass('jobrating-radios'),
          );
      }
    }

    // next-button if already answered
    let alreadyAnswerd = true;
    for (let field of fields) {
      if (field.answer == null || field.answer === 0) {
        alreadyAnswerd = false;
      }
    }
    if (alreadyAnswerd) {
      $$('#task-bottom .button-list').append(
        $$(document.createElement('button'))
          .addClass('button button-fill color-white')
          .append('weiter')
          .on('click', () => {
            controller.next();
          }),
      );
    }

    // back-button
    $$('.jobrating-rating-back').on('click', () => {
      controller.back();
    });
  },

  hideLegend: function () {
    $$('.jobrating-rating-low').addClass('hide');
    $$('.jobrating-rating-high').addClass('hide');
  },

  showBackwards: function () {
    $$('.jobrating-rating-back').removeClass('hide');
  },

  loadBottom: function () {
    $$('#task-bottom .button-list').empty();
  },

  loadBackNavigation: function () {
    const back = $$('.page[data-name="task"] .navbar .left a.link')
      .off('click')
      .removeClass('back');
    back.on('click', () => {
      back.off('click').addClass('back');
      controller.previous();
    });
  },
};
export default ratingView;
