import feedbackView from './FeedbackView.js';
import introView from '../../IntroView';
import exerciseController from '../../../ExerciseController';
import app from '../../../../../../app';
import mediaController from '../../../../../../core/MediaController';
import userController from '../../../../../../core/UserController';
import $$ from 'dom7';

const feedbackController = {
  task: null,

  _link: null,

  moduleNumber: null,
  chapterNumber: null,
  exerciseNumber: null,

  init: function (task, moduleNumber, chapterNumber, exerciseNumber) {
    this.moduleNumber = moduleNumber;
    this.chapterNumber = chapterNumber;
    this.exerciseNumber = exerciseNumber;
    this.task = task;
    this.displayText();

    // start task progress
    this.task.save();
  },

  displayText: function () {
    let _this = this;
    introView.clearButtons();
    introView.addButton('Mehr Info', () => _this.moreInfo());
    introView.addButton('QR-Code', () => _this.displayQR());
    introView.init(this, this.task);
    if (
      !userController.connector.isLoggedIn() &&
      !localStorage.getItem('360-id')
    ) {
      $$('#task-bottom .button-list .button').addClass('disabled');
      $$('#task-bottom').append(
        $$(document.createElement('div')).html(
          `<p style="color: red; text-align:center;">` +
            this.task.loginrequiredtext +
            `</p>`,
        ),
      );
    }
  },

  displayQR: function () {
    feedbackView.init(this, this.getLink());
    this.finish();
  },

  copyLink: function () {
    mediaController.copyTextToClipboard(this.getLink());
  },

  getLink: function () {
    if (!this._link) {
      this._link =
        'https://360.deep-app.de/?id=' + localStorage.getItem('360-id');
    }
    return this._link;
  },

  next: function () {
    exerciseController.next(true);
  },

  moreInfo: function () {
    app.views.main.router.back(
      `/ex/${this.moduleNumber}/${this.chapterNumber}/${this.exerciseNumber}/0/`,
      { force: true },
    );
  },

  finish: function () {
    if (!this.task) {
      this.task = userController.getTask(3, 1, 5, 1);
    }
    this.task.finish();
  },
};

export default feedbackController;
