import Filter from '../../../core/Filter';
import view from './f360resultView';
import userController from '../../../core/UserController';
import texts from '../../../download/feedback360results';

var f360resultController = {
  _index: null,
  _feedback: null,
  _unsortedEntries: {},
  _filter: null,

  _sortedEntries: {},
  _nonsortableEntries: {},

  __ORDER: [
    'strength',
    'skills',
    'social',
    'self',
    'methods',
    'subjects',
    'annotations',
    'interests',
    'jobs',
  ],

  load(index) {
    this._index = index;
    this._feedback = userController.getUserData('feedback360responses')[
      this._index
    ];

    // determine entries
    this._unsortedEntries = {};
    this._nonsortableEntries = {};
    for (let key in this._feedback) {
      if (this._feedback.hasOwnProperty(key) && !key.includes('_name')) {
        if (Array.isArray(this._feedback[key])) {
          let entries = [];
          for (let index = 0; index < this._feedback[key].length; index++) {
            // map entries
            if (this._feedback[key][index]) {
              entries.push({
                text: this.getTextFromIndexInSection(key, index),
                value: this._feedback[key][index],
                index: index,
              });
            }
          }
          if (entries.length > 0) {
            this._unsortedEntries[key] = {
              section: this.getTextFromIndexInSection(key),
              entries: entries.filter((obj) => obj.value),
            };
          }
        } else {
          if (this._feedback[key]) {
            this._nonsortableEntries[key] = {
              section: this.getTextFromIndexInSection(key),
              value: this._feedback[key],
            };
          }
        }
      }
    }

    this.initFilter();
    this._filter.apply('default');

    // ranked view
    view.init(this);

    // start refresh cycle
    this.refreshFilter();
  },

  refreshFilter() {
    if (view.initialized) {
      this._sortedEntries = {};
      for (let key in this._unsortedEntries) {
        if (this._unsortedEntries.hasOwnProperty(key)) {
          this._sortedEntries[key] = {
            section: this._unsortedEntries[key].section,
            entries: [...this._unsortedEntries[key].entries].sort(
              this.getFilter().sort,
            ),
          };
        }
      }
      view.loadSections();
    }
  },

  initFilter() {
    this._filter = new Filter(this, {
      default: {
        name: 'Wie im Fragebogen',
        sort: function (first, second) {
          return first.index - second.index;
        },
      },
      top: {
        name: 'Bestes zu erst',
        sort: function (first, second) {
          return second.value - first.value;
        },
      },
      flop: {
        name: 'Schwächstes zu erst',
        sort: function (first, second) {
          return first.value - second.value;
        },
      },
    });
  },

  getName() {
    return this._feedback ? this.getNameFromFeedback(this._feedback) : null;
  },

  getSections() {
    let sections = [];
    for (let key of this.__ORDER) {
      if (Object.keys(this._sortedEntries).includes(key)) {
        sections.push(this._sortedEntries[key]);
      } else if (Object.keys(this._nonsortableEntries).includes(key)) {
        sections.push(this._nonsortableEntries[key]);
      }
    }
    return sections;
  },

  getFilter(full) {
    if (!full) {
      return this._filter.current;
    } else {
      return this._filter;
    }
  },

  applyFilter(state) {
    return this._filter.apply(state);
  },

  unload() {
    view.onLeave();
  },

  getNameFromFeedback(feedback) {
    let name = '';
    if (feedback.first_name) {
      name += feedback.first_name;
      if (feedback.last_name) {
        name += ' ';
      }
    }
    if (feedback.last_name) {
      name += feedback.last_name;
    }
    return name;
  },

  getTextFromIndexInSection(section, index) {
    if (typeof texts[section] === 'object') {
      if (!index) {
        return texts[section].section;
      } else {
        return texts[section].entries[index];
      }
    } else {
      return texts[section];
    }
  },
};

export default f360resultController;
