import taskView from '../../TaskView';
import $$ from 'dom7';
import app from '../../../../../../app';

let controller;

var resultsView = {
  task: null,

  init: function (cntrllr, task, results, resultsOnly) {
    controller = cntrllr;
    this.task = task;
    taskView.loadTitle('Deine Ergebnisse');
    this.loadButtons();
    this.loadContent(results);
    this.loadBackNavigation(resultsOnly);
  },

  loadContent: function (results) {
    $$('#task-page-content')[0].scrollTop = 0;
    let outro = $$(document.createElement('div'))
      .attr('id', 'checkquestions-outro')
      .append(this.task.outro);
    const content = $$('#task-content').empty().append(outro);
    if (!results || results.length < 1) {
      content.append(`
                <div class="block placeholder-container">
                    <label class="placeholder">Du hast keiner Aussage zugestimmt.</label>
                </div>
            `);
    } else {
      results.forEach((res) => {
        content.append(this.getCard(res.title, res.content));
      });
    }
  },

  getCard: function (title, content) {
    const card = $$(document.createElement('div'));
    card.addClass('checkquestions-card card card-line-small list');
    card.append(`
            <div class="card-content accordion-item">
                <a href="#" class="item-content item-link">
                    <div class="item-inner">
                        <span class="item-title">${title}</span>
                    </div>
                </a>
                <div class ="accordion-item-content">
                    <div class="content">${content}</div>
                </div>
            </div>
        `);
    return card;
  },

  loadButtons: function () {
    /*let more = $$(document.createElement('button'));
        more.addClass("button button-fill color-white");
        more.append("mehr Info");
        more.on('click', (e) => {
            controller.moreInfo();
        });*/

    let nextButton = $$(document.createElement('button'))
      .addClass('button button-fill color-white')
      .append('Übung fortsetzen')
      .on('click', (e) => {
        controller.restart();
      });

    let profile = $$(document.createElement('button'));
    profile.addClass('button button-fill color-white');
    profile.append('zum Profil');
    profile.on('click', (e) => {
      app.views.main.router.navigate('/profile/');
      app.views.main.router.navigate('/', { reloadPrevious: true });
      app.views.main.router.history = ['/', '/profile/'];
    });
    const buttonList = $$('#task-bottom .button-list');
    buttonList.empty();
    //buttonList.append(more);
    if (!controller.isEnded()) {
      buttonList.append(nextButton);
    }
    buttonList.append(profile);

    // FAB
    let fab = $$(document.createElement('div'));
    fab.addClass('main-fab');
    fab.insertBefore('#task-page-content');
    app.loadMainFAB(fab);
  },

  loadBackNavigation: function (resultsOnly) {
    const back = $$('.page[data-name="task"] .navbar .left a.link')
      .off('click')
      .removeClass('back');
    back.on('click', () => {
      back.off('click').addClass('back');

      controller.displayBar(false, resultsOnly);
    });
  },
};

export default resultsView;
