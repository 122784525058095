import swotView from './SwotView';
import taskView from '../../TaskView';

const swotController = {
  init: function (task, moduleNumber, chapterNumber, exerciseNumber) {
    this.loadAllAnswer(task);
    taskView.init(task, true);
    taskView.loadMainFAB();
    swotView.init(task);
  },
  saveAnswer: function (task, localStorageKey, index, answer) {
    console.log("aufgerufen mit answer: " + answer);
    let answerIndex = getAnswerIndex(localStorageKey);

    if (
        task.answers == null ||
        task.answers[answerIndex] == null ||
        task.answers[answerIndex] === ''
    ) {
      task.answers[answerIndex] = [];
    }

    task.answers[answerIndex][index] = answer;
    task.save();
  },
  loadAllAnswer: function (task) {
    if (task.answers instanceof Array) {
      for (let answerIndex=0;answerIndex<task.answers.length;answerIndex++) {
        if (task.answers[answerIndex] instanceof Array) {
          let ls = task.answers[answerIndex]
          localStorage.setItem(getAnswerLocalStorageKey(answerIndex), JSON.stringify(ls))
        }
      }
    }
  }
};

function getAnswerIndex(localStorageKey) {
  switch (localStorageKey) {
    case 'swot-strength':
      return 0;
    case 'swot-weaknesses':
      return 1;
    case 'swot-chances':
      return 2;
    case 'swot-dangers':
      return 3;
    case 'swot-result-strength':
      return 4;
    default:
      console.error("swot: localStorageKey not found: " + localStorageKey)
      return null;
  }
}

function getAnswerLocalStorageKey(index) {
  switch (index) {
    case 0:
      return 'swot-strength';
    case 1:
      return 'swot-weaknesses';
    case 2:
      return 'swot-chances';
    case 3:
      return 'swot-dangers';
    case 4:
      return 'swot-result-strength';
    default:
      console.error("swot: localStorageKey not found: " + localStorageKey)
      return null;
  }
}

export default swotController;
