import introView from '../../IntroView';
import selectionView from './SelectionView';
import profileController from '../../../../../profile/ProfileController';
import app from '../../../../../../app';
import makedecisionsController from '../MakedecisionsController';
import ratingView from './RatingView';
import userController from '../../../../../../core/UserController';
import resultsView from './ResultsView';
import detailsView from './DetailsView';
import $$ from 'dom7';
import taskController from '../../TaskController';

var optionrankingController = {
  task: null,

  mode: null,

  options: null,

  // are there no missing answers or unfinished ratings?
  unchanged: null,
  defaultValue: 0.0,

  init: function (task) {
    this.task = task;
    this.mode = null;
    this.displayIntro();
  },

  displayIntro: function () {
    selectionView.loadTop();
    introView.updateBackNavigation(() => makedecisionsController.init());
    if (this.hasResults()) {
      introView.setButtons(introView.BUTTONSET_INFO_START_RESULTS);
    }
    introView.init(this, this.task);
  },

  displayTask: function () {
    introView.clearButtons();
    introView.addButton('Ausbildung', () => {
      optionrankingController.mode = 1;
      optionrankingController.displaySelection(true);
    });
    introView.addButton('Studium', () => {
      optionrankingController.mode = 2;
      optionrankingController.displaySelection(true);
    });
    introView.init(this, this.task);
  },

  displaySelection: function (firstTime) {
    this.unchanged = true;
    this.selection.display(firstTime);
  },

  displayRating: function (last) {
    this.rating.display(false, last);
  },

  displayResults: function (calling) {
    this.results.display(
      calling === introView || calling === true || calling === 'external',
      calling === 'external',
    );
  },

  displayDetails: function (all, extern) {
    this.details.display(all, extern);
  },

  moreInfo: function () {
    app.views.main.router.back('/ex/8/1/4/0/', { force: true });
    setTimeout(() => {
      $$('.page-current .page-content')[0].scrollTop = 0;
    }, 0);
  },

  selection: {
    amountOfSelectedOptions: 0,
    maxAmountOfSelectedOptions: 5,
    minAmountOfSelectedOptions: 2,

    // is selection changed?
    noChange: null,

    display: function (first) {
      // do not edit finished ratings, if first time opened
      this.noChange = !first;
      optionrankingController._getAllOptions();
      optionrankingController._prepareAnswers();
      selectionView.init(
        this,
        optionrankingController.task.selection_text,
        optionrankingController.options,
        optionrankingController.task.answers[optionrankingController.mode]
          .selectedOptions,
      );
    },

    next: function () {
      if (this.noChange) {
        // make rating editable
        optionrankingController.unchanged = false;
      }
      optionrankingController.displayRating();
    },

    previous: function () {
      optionrankingController.displayTask();
    },

    selectOption: function (id) {
      if (this.amountOfSelectedOptions < this.maxAmountOfSelectedOptions) {
        this.noChange = false;
        optionrankingController.task.answers[
          optionrankingController.mode
        ].selectedOptions.push(id);
        optionrankingController.task.save();
        this.amountOfSelectedOptions++;
        return true;
      }
      return false;
    },

    unSelectOption: function (id) {
      let index;
      while (
        (index =
          optionrankingController.task.answers[
            optionrankingController.mode
          ].selectedOptions.indexOf(id)) > -1
      ) {
        optionrankingController.task.answers[
          optionrankingController.mode
        ].selectedOptions.splice(index, 1);
        this.noChange = false;
      }
      optionrankingController.task.save();
      this.amountOfSelectedOptions =
        optionrankingController.task.answers[
          optionrankingController.mode
        ].selectedOptions.length;
    },

    isOptionSelectionValid: function () {
      return (
        this.maxAmountOfSelectedOptions >= this.amountOfSelectedOptions &&
        this.amountOfSelectedOptions >= this.minAmountOfSelectedOptions
      );
    },
  },

  rating: {
    currentKey: null,

    valuesMap: null,

    display: function (noInit, last) {
      if (!noInit) {
        // general
        optionrankingController._filterSelectedOptions();
        this._loadValuesFromProfile();
        optionrankingController.options.forEach((option) => {
          this._prepareAnswersForOption(option.id);
        });
        if (!this._isOptionsFinished()) {
          optionrankingController.unchanged = false;
        }
      }

      // display last rating for back navigation
      if (last) {
        this.currentKey = this.valuesMap.length - 1;
      }

      // key specific
      let key = this.valuesMap[this.currentKey][0];
      this._prepareAnswersforValue(key);

      // skip rating, if aleady rated and nothing changed
      if (optionrankingController.unchanged) {
        this.next();
      } else {
        //view
        ratingView.init(
          this,
          this._getValueText(key),
          optionrankingController.options,
          this.valuesMap[this.currentKey][1],
          this.valuesMap[this.currentKey][2],
        );
      }
    },

    next: function () {
      if (this.currentKey + 1 < this.valuesMap.length) {
        this.currentKey++;
        this.display(true);
      } else {
        this._finishoptions();
        optionrankingController.displayResults();
      }
    },

    previous: function () {
      if (this.currentKey > 0) {
        this.currentKey--;
        this.display(true);
      } else {
        optionrankingController.displaySelection();
      }
    },

    setPriority: function (prio) {
      optionrankingController.task.answers[optionrankingController.mode].prios[
        this.valuesMap[this.currentKey][0]
      ] = prio;

      optionrankingController.task.save();
    },

    getPriority: function () {
      return optionrankingController.task.answers[optionrankingController.mode]
        .prios[this.valuesMap[this.currentKey][0]];
    },

    setRating: function (option, rate) {
      optionrankingController.task.answers[optionrankingController.mode].rating[
        this._getIndexOfOption(option)
      ][this.valuesMap[this.currentKey][0]] = rate;

      optionrankingController.task.save();
    },
    getRating: function (option) {
      let res =
        optionrankingController.task.answers[optionrankingController.mode]
          .rating[this._getIndexOfOption(option)][
          this.valuesMap[this.currentKey][0]
        ];
      return res == null ? 0 : res;
    },

    getProgress: function () {
      return (this.currentKey / this.valuesMap.length) * 100;
    },

    _finishoptions: function () {
      optionrankingController.options.forEach((option) => {
        optionrankingController.task.answers[
          optionrankingController.mode
        ].rating[this._getIndexOfOption(option.id)].finished = true;
      });
      optionrankingController.task.finish();
    },

    _isOptionsFinished: function () {
      let finished = true;
      for (
        let i = 0;
        i <
        optionrankingController.task.answers[optionrankingController.mode]
          .rating.length;
        i++
      ) {
        if (
          !optionrankingController.task.answers[optionrankingController.mode]
            .rating[i].finished
        ) {
          finished = false;
        }
      }
      return finished;
    },

    _getIndexOfOption: function (id, searchAll) {
      for (let mode of searchAll ? [1, 2] : [optionrankingController.mode]) {
        for (
          let i = 0;
          i < optionrankingController.task.answers[mode].rating.length;
          i++
        ) {
          if (id === optionrankingController.task.answers[mode].rating[i].id) {
            return searchAll ? [mode, i] : i;
          }
        }
      }
    },

    _prepareAnswersForOption: function (id) {
      let exsists = false;
      optionrankingController.task.answers[
        optionrankingController.mode
      ].rating.forEach((option) => {
        if (id === option.id) {
          exsists = true;
        }
      });
      if (!exsists) {
        optionrankingController.task.answers[
          optionrankingController.mode
        ].rating.push({
          id: id,
          finished: false,
        });
        optionrankingController.unchanged = false;
      }

      optionrankingController.task.save();
    },

    _prepareAnswersforValue: function (key) {
      if (
        optionrankingController.task.answers[optionrankingController.mode]
          .prios[key] == null
      ) {
        optionrankingController.task.answers[
          optionrankingController.mode
        ].prios[key] = 1;
      }

      optionrankingController.task.save();
    },

    _loadValuesFromProfile: function () {
      this.valuesMap = [];
      let keys = [];
      // default keys
      for (let superValue in optionrankingController.task.values) {
        if (optionrankingController.task.values.hasOwnProperty(superValue)) {
          for (let value in optionrankingController.task.values[superValue]) {
            if (
              optionrankingController.task.values[superValue].hasOwnProperty(
                value,
              )
            ) {
              keys.push({
                superValue: superValue,
                value: value,
              });
            }
          }
        }
      }
      // training or studies keys
      switch (optionrankingController.mode) {
        case 1: // training
          for (let superValue in optionrankingController.task.trainingValues) {
            if (
              optionrankingController.task.trainingValues.hasOwnProperty(
                superValue,
              )
            ) {
              for (let value in optionrankingController.task.trainingValues[
                superValue
              ]) {
                if (
                  optionrankingController.task.trainingValues[
                    superValue
                  ].hasOwnProperty(value)
                ) {
                  keys.push({
                    superValue: superValue,
                    value: value,
                  });
                }
              }
            }
          }
          break;
        case 2: // studies
          for (let superValue in optionrankingController.task.studiesValues) {
            if (
              optionrankingController.task.studiesValues.hasOwnProperty(
                superValue,
              )
            ) {
              for (let value in optionrankingController.task.studiesValues[
                superValue
              ]) {
                if (
                  optionrankingController.task.studiesValues[
                    superValue
                  ].hasOwnProperty(value)
                ) {
                  keys.push({
                    superValue: superValue,
                    value: value,
                  });
                }
              }
            }
          }
          break;
        default:
          break;
      }

      // keys in profile
      keys = keys.filter((key) =>
        key.value.includes('&') || key.value.includes('.')
          ? this.valuesMap.push([
              key.value,
              key.value.split(','),
              key.superValue,
            ]) && false
          : true,
      );

      // keys not in profile
      for (let key of keys) {
        this.valuesMap.push([key.value, null, key.superValue]);
      }

      this.currentKey = 0;
    },

    _getValueText: function (key) {
      for (let superValue in optionrankingController.task.values) {
        if (
          optionrankingController.task.values.hasOwnProperty(superValue) &&
          optionrankingController.task.values[superValue][key]
        ) {
          return optionrankingController.task.values[superValue][key].text;
        }
      }
      if (optionrankingController.mode === 1) {
        for (let superValue in optionrankingController.task.trainingValues) {
          if (
            optionrankingController.task.trainingValues.hasOwnProperty(
              superValue,
            ) &&
            optionrankingController.task.trainingValues[superValue][key]
          ) {
            return optionrankingController.task.trainingValues[superValue][key]
              .text;
          }
        }
      } else if (optionrankingController.mode === 2) {
        for (let superValue in optionrankingController.task.studiesValues) {
          if (
            optionrankingController.task.studiesValues.hasOwnProperty(
              superValue,
            ) &&
            optionrankingController.task.studiesValues[superValue][key]
          ) {
            return optionrankingController.task.studiesValues[superValue][key]
              .text;
          }
        }
      }
      return '';
    },
  },

  results: {
    // directly navigated to results?
    directResult: null,
    externCall: null,

    results: null,

    display: function (direct, extern) {
      this.directResult = direct;
      this.externCall = extern;
      resultsView.init(
        this,
        optionrankingController.task.results_text,
        this.getResults(direct),
      );
    },

    next: function () {
      optionrankingController.displayDetails(
        this.directResult,
        this.externCall,
      );
    },

    previous: function () {
      if (this.externCall) {
        app.views.current.router.back();
      } else if (this.directResult) {
        optionrankingController.displayIntro();
      } else if (optionrankingController.unchanged) {
        optionrankingController.displaySelection();
      } else {
        // display last value rating
        optionrankingController.displayRating(true);
      }
    },

    navigateToMyOptions: function () {
      app.views.main.router.back('/profile/', { force: true });
      profileController.openOptionsTab();
    },

    getProgress: function () {
      return 100;
    },

    getResults: function (all) {
      if (optionrankingController.mode == null) {
        let res = {};
        let saveMode = optionrankingController.mode;
        for (let mode of [1, 2]) {
          optionrankingController.mode = mode;
          optionrankingController._getAllOptions();
          res = Object.assign(res, this._getResults(all));
        }
        optionrankingController.mode = saveMode;
        return this._sortResults(res);
      } else {
        this.results = this._sortResults(this._getResults());
      }
      return this.results;
    },

    _getResults: function (all) {
      if (
        optionrankingController.task.answers[optionrankingController.mode] ==
          null &&
        !this.directResult
      ) {
        return {};
      }
      let calcOption = function (id) {
        let mode = optionrankingController.mode;
        let index = optionrankingController.rating._getIndexOfOption(id, all);
        if (!index) {
          return 0;
        }
        if (all) {
          mode = index[0];
          index = index[1];
        }
        return optionrankingController.task.answers[mode]
          ? optionrankingController.getAVGOfRating(
              optionrankingController.task.answers[mode].rating[index],
              optionrankingController.task.answers[mode].prios,
            )
          : 0;
      };

      // calculate result
      let res = {};
      let options = all
        ? optionrankingController._getAllOptions(true)
        : optionrankingController.options;
      for (let option of options) {
        let rating = calcOption(option.id);
        if (rating > 0) {
          res[option.getName()] = rating;
        }
      }

      return res;
    },

    _sortResults: function (res) {
      let sorted = {};
      for (let i in res) {
        let max = -1;
        let element = null;
        for (let key in res) {
          if (res[key] == null) {
            continue;
          }
          if (res[key] > max) {
            max = res[key];
            element = key;
          }
        }
        sorted[element] = res[element];
        res[element] = null;
      }

      return sorted;
    },
  },

  details: {
    directResult: null,
    externCall: null,

    display: function (all, extern) {
      this.directResult = all;
      this.externCall = extern;
      detailsView.init(
        this,
        optionrankingController.task.details_text,
        this._getDetais(all),
      );
    },

    previous: function () {
      optionrankingController.displayResults(
        this.externCall ? 'external' : this.directResult,
      );
    },

    getProgress: function () {
      return 100;
    },

    _getDetais: function (all) {
      let details = [];
      let values = Object.assign(
        optionrankingController.task.values,
        optionrankingController.mode === 1
          ? optionrankingController.task.trainingValues
          : optionrankingController.task.studiesValues,
      );
      for (let superValue in values) {
        let lists = [];
        for (let value in values[superValue]) {
          let ratedOptions = [];
          let allOptions = all
            ? optionrankingController._getAllOptions(true)
            : optionrankingController.options;

          for (let option of allOptions) {
            let index = optionrankingController.rating._getIndexOfOption(
              option.id,
              all,
            );
            let mode = optionrankingController.mode;
            if (!index) {
              continue;
            }
            if (all) {
              mode = index[0];
              index = index[1];
            }
            let answer = optionrankingController.task.answers[mode]
              ? optionrankingController.task.answers[mode].rating[index][value]
              : 0;

            // options
            if (answer > 0) {
              ratedOptions.push({
                name: option.getName(),
                rate: answer,
              });
            }
          }

          // questions
          if (ratedOptions.length > 0) {
            lists.push({
              question: values[superValue][value].question,
              options: ratedOptions,
            });
          }
        }

        if (lists.length > 0) {
          // values
          details.push({
            title: superValue,
            lists: lists,
          });
        }
      }

      return details;
    },
  },

  getAVGOfRating: function (option, prios) {
    let sum = 0;
    let count = 0;
    // get average
    for (let value in option) {
      if (!['id', 'finished'].includes(value) && option[value] !== 0) {
        // priority counts n times
        let prio = prios[value] != null ? prios[value] : 1;
        sum += (option[value] - 1) * prio;
        count += prio;
      }
    }
    return count > 0 ? sum / count / 4 : optionrankingController.defaultValue;
  },

  //for profile
  createRankingGetter: function () {
    let optionRankingAnswers =
      userController.getModule(0).chapters[0].exercises[1].tasks[1].answers;
    // answered?
    if (
      optionRankingAnswers != null &&
      optionRankingAnswers.length != null &&
      optionRankingAnswers.length > 0
    ) {
      // combine study or training
      let totalRankings = [];
      for (let optionRankingAnswer of optionRankingAnswers) {
        if (optionRankingAnswer != null) {
          totalRankings = totalRankings.concat(optionRankingAnswer.rating);
        }
      }
      if (totalRankings.length > 0) {
        // ranking getter
        return function (option, isStudies, nullable) {
          if (optionRankingAnswers[isStudies ? 2 : 1] == null) {
            return nullable ? null : optionrankingController.defaultValue;
          }
          // study or training
          let rankings = optionRankingAnswers[isStudies ? 2 : 1].rating;
          let prios = optionRankingAnswers[isStudies ? 2 : 1].prios;
          // find option
          for (let ranking of rankings) {
            if (ranking.id === option) {
              return optionrankingController.getAVGOfRating(ranking, prios);
            }
          }
          return nullable ? null : optionrankingController.defaultValue;
        };
      }
    }
    return false; // no ranking yet
  },

  _prepareAnswers: function () {
    if (!this.task.answers) {
      this.task.answers = [];
      this.unchanged = false;
    }
    if (!this.task.answers[this.mode]) {
      this.task.answers[this.mode] = {};
      this.unchanged = false;
    }
    if (!this.task.answers[this.mode].selectedOptions) {
      this.task.answers[this.mode].selectedOptions = [];
      this.unchanged = false;
    }
    if (!this.task.answers[this.mode].rating) {
      this.task.answers[this.mode].rating = [];
      this.unchanged = false;
    }
    if (!this.task.answers[this.mode].prios) {
      this.task.answers[this.mode].prios = {};
      this.unchanged = false;
    }

    // only keep existing options
    if (this.options && this.options.length > 0) {
      let existingoptions = this.options.map((option) => option.id);

      // selected
      this.task.answers[this.mode].selectedOptions = this.task.answers[
        this.mode
      ].selectedOptions.filter((option) => existingoptions.includes(option));

      // rating
      this.task.answers[this.mode].rating = this.task.answers[
        this.mode
      ].rating.filter((option) => existingoptions.includes(option.id));
    } else {
      // empty
      this.task.answers[this.mode].selectedOptions = [];
      this.task.answers[this.mode].rating = [];
      this.unchanged = false;
    }

    //update amount of selected options
    this.selection.amountOfSelectedOptions =
      this.task.answers[this.mode].selectedOptions.length;

    this.task.save();
  },

  _getAllOptions: function (allModes) {
    let options = profileController.getOptionsAndIdeas().options;
    if (!allModes) {
      return options.filter((option) =>
        option.isStudies ? this.mode === 2 : this.mode === 1,
      );
    } else {
      this.options = options;
      return this.options;
    }
  },

  _filterSelectedOptions: function () {
    this.options = profileController
      .getOptionsAndIdeas()
      .options.filter((option) =>
        this.task.answers[this.mode].selectedOptions.includes(option.id),
      );
  },

  hasResults: function () {
    if (!this.task) {
      this.task = taskController.getTask(0, 1, 2, 2);
    }
    if (
      this.task.answers &&
      typeof this.task.answers === 'object' &&
      Object.keys(this.task.answers).length > 0
    ) {
      return Object.keys(this.results.getResults(true)).length > 0;
    }
    return false;
  },

  getGraphic: function () {
    if (!this.task) {
      this.task = taskController.getTask(0, 1, 2, 2);
    }

    // calc results
    let res = this.results.results
      ? this.results.results
      : this.results.getResults(true);
    res = Object.assign({}, res);

    // limit to 3 results
    let index = 0;
    for (let key in res) {
      if (res.hasOwnProperty(key)) {
        if (index >= 3 || res[key] === 0) {
          delete res[key];
        }
        index++;
      }
    }

    return resultsView.getGraphic(res);
  },
};
export default optionrankingController;
