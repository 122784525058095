import $$ from 'dom7';
import elementFactory from '../../../../../../core/ui/ElementFactory';
import inputController from '../../input/InputController';
import profileController from '../../../../../profile/ProfileController';

let controller;

var ratingView = {
  hearts: null,

  init: function (cntrllr, intro, ideas, values) {
    controller = cntrllr;
    this.loadTop();
    this.loadText(intro);
    this.loadContent(ideas, values);
    this.loadBottom();
    this.selectHearts(controller.getPriority() - 1);
    this.loadBackNavigation();
  },

  loadTop: function () {
    $$('#task-page-content .page-content-top')
      .empty()
      .append(
        elementFactory
          .getProgressbar(controller.getProgress())
          .attr('id', 'idearanking-progress'),
      ).append(`
                <h1 id="task-title" class="page-title">Ideen-Ranking</h1>
                <h2 id="task-subtitle" class="page-subtitle"></h2>
            `);
  },

  loadText: function (intro) {
    $$('#task-page-content')[0].scrollTop = 0;
    let content = $$('#task-content');
    content.empty();
    content.append(intro);
  },

  loadContent: function (ideas, values) {
    let content = $$('#task-content');

    //values
    if (values && values.length > 0) {
      values.forEach((value) => {
        content.append(
          profileController
            .getProfileElement(value)
            .addClass('idearanking-values'),
        );
      });
    }

    //ideas
    ideas.forEach((idea) => {
      content.append(
        $$(document.createElement('div'))
          .addClass('idearanking-rating-idea')
          .append(
            $$(document.createElement('div'))
              .addClass('idearanking-rating-idea-title')
              .append(idea.name),
          )
          .append(
            inputController.getColoredRadioButtons(
              5,
              controller.getRating(idea.id),
              (res) => {
                controller.setRating(idea.id, res);
              },
              'passt nicht',
              'passt voll',
            ),
          ),
      );
    });
  },

  loadBottom: function () {
    const buttonsList = $$('#task-bottom .button-list').removeClass(
      'horizontal',
    );
    buttonsList.empty();

    // prioritize
    let prio = $$(document.createElement('div'))
      .attr('id', 'idearanking-rating-priority')
      .addClass('button-list-before-right')
      .append('Relevanz erhöhen');
    this.hearts = [];
    for (let i = 0; i < 2; i++) {
      this.hearts[i] = $$(document.createElement('i'))
        .addClass('icon material-icons')
        .append('favorite')
        .on('click', () => {
          if (
            this.hearts[i].hasClass('selected') &&
            !(this.hearts[i + 1] && this.hearts[i + 1].hasClass('selected'))
          ) {
            ratingView.selectHearts(i);
          } else {
            ratingView.selectHearts(i + 1);
          }
        });
      prio.append(this.hearts[i]);
    }
    buttonsList.append(prio);

    //next
    buttonsList.append(
      $$(document.createElement('button'))
        .addClass('button button-fill color-white')
        .append('Weiter')
        .on('click', () => {
          controller.next();
        }),
    );
  },

  loadBackNavigation: function () {
    const back = $$('.page[data-name="task"] .navbar .left a.link')
      .off('click')
      .removeClass('back');
    back.on('click', () => {
      back.off('click').addClass('back');
      controller.previous();
    });
  },

  selectHearts: function (number) {
    for (let i = 0; i < number; i++) {
      this.hearts[i].addClass('selected');
    }
    for (let i = number; i < this.hearts.length; i++) {
      this.hearts[i].removeClass('selected');
    }
    controller.setPriority(number + 1);
  },
};
export default ratingView;
