import taskView from '../../TaskView';
import $$ from 'dom7';
import elementFactory from '../../../../../../core/ui/ElementFactory';
import app from '../../../../../../app';

let controller;

var barView = {
  task: null,

  init: function (cntrllr, task, result, left, resultsOnly) {
    controller = cntrllr;
    this.task = task;
    taskView.loadTitle('Richtungs-Check');
    this.loadButtons(resultsOnly);
    this.loadContent(result, left);
    this.loadBackNavigation(resultsOnly);
  },

  loadContent: function (entries, left) {
    $$('#task-page-content')[0].scrollTop = 0;
    const content = $$('#task-content');
    content.empty();
    content.append(
      'Hier kannst du sehen, wie gut ein Bereich auf deine Antworten passt. Du kannst die Aufgabe insgesamt ' +
        left +
        ' Mal wiederholen bis du alle Checkfragen beantwortet hast.',
    );
    content.append(this.getBars(entries));
  },

  getBars: function (result, external) {
    let res = elementFactory
      .getProgressBarsCard(result)
      .attr('id', 'checkquestions-bars-card');
    if (external) {
      res.addClass('external-result bars-card-external');
    }
    return res;
  },

  loadButtons: function (resultsOnly) {
    let nextButton = $$(document.createElement('button'))
      .addClass('button button-fill color-white')
      .append('Übung fortsetzen')
      .on('click', (e) => {
        controller.restart();
      });

    let profileButton = $$(document.createElement('button'));
    profileButton.addClass('button button-fill color-white');
    profileButton.append('zum Profil');
    profileButton.on('click', (e) => {
      app.views.main.router.navigate('/profile/');
      app.views.main.router.navigate('/', { reloadPrevious: true });
      app.views.main.router.history = ['/', '/profile/'];
    });

    let detailsButton = $$(document.createElement('button'));
    detailsButton.addClass('button button-fill color-white');
    detailsButton.append('Details');
    detailsButton.on('click', (e) => {
      controller.displayResults(false, resultsOnly);
    });

    let buttonList = $$('#task-bottom .button-list').empty();
    if (!controller.isEnded()) {
      buttonList.append(nextButton);
    }
    buttonList.append(profileButton).append(detailsButton);

    // FAB
    let fab = $$(document.createElement('div'));
    fab.addClass('main-fab');
    fab.insertBefore('#task-page-content');
    app.loadMainFAB(fab);
  },

  loadBackNavigation: function (resultsOnly) {
    const back = $$('.page[data-name="task"] .navbar .left a.link')
      .off('click')
      .removeClass('back');
    back.on('click', () => {
      back.off('click').addClass('back');

      if (resultsOnly) {
        controller.displayIntro();
      } else {
        controller.displayTask(false, true);
      }
    });
  },
};

export default barView;
