export default {
  id: '847465117e',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class="page steps-page background-image" data-name=exercise><!-- Navbar --><div class="navbar navbar-transparent no-shadow no-hairline"><div class=navbar-inner><div class=left><a class="link link-back"><i class="icon material-icons">navigate_before</i></a></div></div></div><!-- Toolbar --><div class=main-toolbar></div><!-- Floating Action Button --><div class=main-fab></div><!-- Background --><div class=background-image-filter></div><div class=background-image-color></div><!-- Scrollable page content--><div id=exercise-page-content class=page-content><div class=page-content-top><h1 class=page-title></h1><h2 class=page-subtitle></h2></div><div class=page-content-main><div class="row exercise-toggle-row"><div class=exercise-toggle-container><span>Ansicht wechseln</span> <label class="toggle color-deep-blue"><input type=checkbox> <span class=toggle-icon></span></label></div></div><a name=exercise-swiper-top></a><div id=exercise-subblocks class="columns row"><div class=column-0></div><div class=column-1></div></div><div id=accordion-button-list class=button-list></div></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};