import $$ from 'dom7';

let controller;

const interactionView = {
  task: null,

  counter: '',

  button: null,

  init: function (cntrllr, task) {
    controller = cntrllr;
    this.task = task;
    this.emptyAndLoadText();
    this.loadContent();
    this.loadButton();
    this.updateContext();
  },

  emptyAndLoadText: function () {
    $$('#task-page-content')[0].scrollTop = 0;
    this.counter = $$(document.createElement('span')).addClass('bold');
    this.counter.attr('id', 'task-gummybear-counter');
    this.counter.append(
      controller.setGummybears + '/' + controller.maxGummybears,
    );

    const content = $$('#task-content');
    content
      .empty()
      .append(
        'Verteile insgesamt ' +
          controller.maxGummybears +
          ' Punkte in 10 Bereichen.<br>',
      )
      .append(this.counter)
      .append(" <span class='bold'>Gummibärchen</span> verteilt");
  },

  updateContext: function () {
    this.counter.empty();
    if (controller.setGummybears > controller.maxGummybears) {
      this.counter.addClass('red');
    } else {
      this.counter.removeClass('red');
    }
    this.counter.append(
      controller.setGummybears + '/' + controller.maxGummybears,
    );

    if (controller.setGummybears === controller.maxGummybears) {
      this.button.removeClass('disabled');
    } else {
      this.button.addClass('disabled');
    }
  },

  loadContent: function () {
    const content = $$('#task-content');

    // display gummybears correctly
    let updateGummybears = function () {
      content.find('.task-gummybear-row i').each((index, icon) => {
        icon = $$(icon);
        if (
          icon.hasClass('selected') &&
          icon.hasClass('svg-icon-gummybear-empty')
        ) {
          icon.removeClass('svg-icon-gummybear-empty');
          icon.addClass(
            'svg-icon-gummybear-' + controller.getRandomGummybear(),
          );
        } else if (
          !icon.hasClass('selected') &&
          !icon.hasClass('svg-icon-gummybear-empty')
        ) {
          icon.removeClass('svg-icon-gummybear-red');
          icon.removeClass('svg-icon-gummybear-lightgreen');
          icon.removeClass('svg-icon-gummybear-darkgreen');
          icon.removeClass('svg-icon-gummybear-yellowgreen');
          icon.addClass('svg-icon-gummybear-empty');
        }
      });
    };

    for (let row in this.task.inputs[controller.inputindex].rows) {
      let gummybears = [null];
      // init gummybears
      for (let index = 1; index <= 5; index++) {
        // create gummybears
        gummybears.push(
          $$(document.createElement('i'))
            .addClass('icon svg-icon-gummybear-empty')
            .attr('id', 'task-gummybear-row-' + row + '-' + index),
        );

        // init listener
        gummybears[index].on('click', () => {
          // check if valid
          if (!gummybears[index].hasClass('selected')) {
            let selected = 0;
            for (let i = 1; i <= 5; i++) {
              if (gummybears[i].hasClass('selected')) {
                selected = i;
              } else {
                break;
              }
            }
            if (controller.isToExpensive(index - selected)) {
              return;
            }
          }

          // change states
          if (
            index === 1 &&
            gummybears[1].hasClass('selected') &&
            !gummybears[2].hasClass('selected')
          ) {
            // select none
            for (let i = index; i <= 5; i++) {
              gummybears[i].removeClass('selected');
            }
            controller.saveAnswer(row, 0);
          } else {
            // select until index
            for (let i = 1; i <= index; i++) {
              gummybears[i].addClass('selected');
            }
            for (let i = index + 1; i <= 5; i++) {
              gummybears[i].removeClass('selected');
            }
            controller.saveAnswer(row, index);
          }
          updateGummybears();
          interactionView.updateContext();
        });
      }

      // load answers
      for (let i = 1; i <= controller.getAnswer(row); i++) {
        gummybears[i].addClass('selected');
      }

      // create row
      content.append(`
                <div id="task-gummybear-row-${row}" class="task-gummybear-row">
                    <span>${
                      this.task.inputs[controller.inputindex].rows[row]
                    }</span>
                    <div class="task-gummybear-row-content"></div>
                </div>
            `);

      // add gummybears
      gummybears.forEach((gummybear) => {
        if (gummybear) {
          content
            .find('#task-gummybear-row-' + row + ' .task-gummybear-row-content')
            .append(gummybear);
        }
      });
    }

    updateGummybears();
  },

  loadButton: function () {
    const list = $$('#task-bottom .button-list');
    list.empty();
    list.addClass('horizontal shadowed');

    this.button = $$(document.createElement('button'));
    this.button.addClass('button button-fill color-white');
    this.button.append('weiter');
    this.button.on('click', () => {
      controller.displayResults();
    });
    list.append(this.button);
  },
};
export default interactionView;
