import $$ from "dom7";
import app from "../../app.js";
import elementFactory from "../../core/ui/ElementFactory";
import dateController from "../../core/DateController";
import gummybearController from "../steps/exercise/task/types/gummybear/GummybearController";
import dictionary from "../../core/Dictionary";
import userController from "../../core/UserController";

let controller;

let get = function (notNull, getElement, placeholder) {
  if (notNull) {
    return getElement().addClass("profile-element");
  } else {
    if (placeholder) {
      return getElement(true);
    }
    return "";
  }
};

const profileView = {
  initialized: false,

  ideas: null,
  options: null,

  init: function (cntrllr, name, ideas, options, oldIdeas) {
    this.ideas = ideas.concat(oldIdeas);
    this.options = options;
    controller = cntrllr;
    this.loadTexts();
    this.loadMainToolbar();
    this.loadMainFAB();
    this.loadName(name);
    this.hideAllBlocks();
    this.loadButtons();
    this.loadFilters();
    this.loadIdeas();
    //this.loadIdeas(oldIdeas)
    this.loadOptions();
    this.loadPopovers();
    this.initialized = true;
  },

  loadTexts: function () {
    dictionary.loadModuleToDOM(
      "profiletexts",
      $$('.page[data-name="profile"]')
    );
  },

  onLeave: function () {
    this.initialized = false;
  },

  loadMainToolbar: function () {
    app.loadMainToolbar($$('.page[data-name="profile"] .main-toolbar'));
  },

  loadMainFAB: function () {
    app.loadMainFAB($$('.page[data-name="profile"] .main-fab'));
  },

  loadName: function (name) {
    const title = $$("#profile-title");
    title.empty();
    title.append("Hey " + name + "!");
  },

  hideAllBlocks: function () {
    $$(".profile-block").forEach((block) => {
      $$(block).addClass("hide");
    });
  },

  loadButtons: function () {
    $$("#profile-generate-ideas").on("click", () => {
      app.views.main.router.navigate("/task/0/1/1/1/");
    });
    $$("#profile-make-decisions").on("click", () => {
      app.views.main.router.navigate("/task/0/1/2/1/");
    });
  },

  expertise: {
    controller: null,

    init: function (cntrllr) {
      this.controller = cntrllr;
    },

    load: function () {
      $$("#profile-expertise").removeClass("hide");
      profileView.loadReflectionCard(
        this._loadContent(
          $$("#profile-expertise .profile-block-content").empty()
        ).find(".cards-scroll"),
        1
      );
    },

    getContent: function () {
      return this._loadContent(
        $$(document.createElement("div")).addClass("profile-block-content")
      );
    },

    _loadContent: function (content) {
      return content.append(
        get(true, () => {
          let entries = Object.keys(this.controller.entries);
          let expertisesDiv = $$(document.createElement("div")).addClass(
            "profile-expertise-cards-container profile-expertise-cards-container-multi-rows"
          );
          for (let index = 0; index < 2; index++) {
            // cards
            let row = $$(document.createElement("div")) // skip this layer for single row and remove .profile-expertise-cards-container-multi-rows from expertisesDiv
              .addClass("profile-expertise-cards-row");
            for (let expertise of entries.slice(
              index === 0 ? 0 : (entries.length + 1) / 2,
              index === 0 ? (entries.length + 1) / 2 : entries.length
            )) {
              row.append(this.getCard(expertise));
            }
            expertisesDiv.append(row);
          }
          return $$(document.createElement("div"))
            .addClass("cards-scroll")
            .append(expertisesDiv);
        })
      );
    },

    loadTestCard: function () {
      profileView.loadTestCard(
        $$("#profile-expertise .profile-block-content").find(".cards-scroll"),
        1
      );
    },

    getCard: function (target) {
      let _this = this;
      return get(
        this.controller.entries[target].content,
        (empty) => {
          // card init
          let card = $$(document.createElement("div"));
          card.addClass("profile-expertise-card white-card");
          card.append(`
                        <h3>
                            ${this.controller.entries[target].icon}
                            ${this.controller.entries[target].title}
                        </h3>
                        <div class="profile-expertise-blocks profile-empty-insert"></div>
                    `);

          if (empty) {
            profileView.loadEmptyPanel(
              card,
              _this.controller.getTaskNavigator(target)
            );
          } else {
            card.on("click", () => {
              app.views.main.router.navigate(
                this.controller.entries[target].link
                  ? this.controller.entries[target].link
                  : "/expertise/" + this.controller.entries[target].key + "/"
              ); // link expertise
            });

            // two blocks self and external assessment
            for (let key of ["self", "external"]) {
              let title =
                key === "self"
                  ? this.controller.entries[target].selfTitle
                    ? this.controller.entries[target].selfTitle
                    : "Selbsteinschätzung"
                  : this.controller.entries[target].externalTitle
                  ? this.controller.entries[target].externalTitle
                  : "Fremdeinschätzung";

              let blockDiv = $$(document.createElement("div"));
              blockDiv.addClass("profile-expertise-block");
              blockDiv.append(`
                                    <h4>${title}</h4>
                                `);
              let blockContents = $$(
                document.createElement(
                  this.controller.entries[target].ordered ? "ol" : "ul"
                )
              ).addClass("profile-expertise-block-contents");
              for (let content of this.controller.entries[target][key]) {
                blockContents.append(`
                                    <li>${content}</li>
                                `);
              }
              blockDiv.append(blockContents);
              card.find(".profile-expertise-blocks").append(blockDiv);
            }
          }

          return card;
        },
        true
      );
    },
  },

  interests: {
    controller: null,

    init: function (cntrllr) {
      this.controller = cntrllr;
    },

    load: function () {
      $$("#profile-interests").removeClass("hide");
      let content = this._loadContent(
        $$("#profile-interests .profile-block-content").empty()
      );
      profileView.loadReflectionCard(content.find(".word-collection"), 2);
    },

    loadTestCard: function () {
      profileView.loadTestCard(
        $$("#profile-interests .profile-block-content").find(".cards-scroll"),
        2
      );
    },

    getContent: function () {
      return this._loadContent(
        $$(document.createElement("div")).addClass("profile-block-content")
      );
    },

    _loadContent: function (content) {
      content.append(this.getHitlistAndShitlist());
      content.append(this.getRiasec());
      return content;
    },

    getHitlistAndShitlist: function () {
      let _this = this;
      return get(
        _this.controller.entries["hitlist"],
        (empty) => {
          if (empty) {
            return profileView.loadEmptyPanel(
              elementFactory.getTitledWordCollectionPlaceholder(
                "Hitlist",
                "Shitlist",
                $$(document.createElement("div")).append(
                  '<div class="white-card profile-empty-insert"></div>'
                )
              ),
              _this.controller.getTaskNavigator("hitlist shitlist")
            );
          } else {
            if (
              _this.controller.entries["hitlist"] &&
              _this.controller.entries["shitlist"]
            ) {
              // both
              return $$(document.createElement("div")).append(
                elementFactory.getTitledWordCollection(
                  "Hitlist",
                  "Shitlist",
                  _this.controller.entries["hitlist"],
                  _this.controller.entries["shitlist"]
                )
              );
            } else if (_this.controller.entries["hitlist"]) {
              // hitlist
              return _this.getHitlist();
            } else if (_this.controller.entries["shitlist"]) {
              // shitlist
              return _this.getShitlist();
            }
          }
        },
        true
      );
    },

    getHitlist: function () {
      let _this = this;
      return get(
        _this.controller.entries["hitlist"],
        (empty) => {
          if (empty) {
            return profileView.loadEmptyPanel(
              elementFactory.getTitledWordCollectionPlaceholder(
                "Hitlist",
                "",
                $$(document.createElement("div")).append(
                  '<div class="white-card profile-empty-insert"></div>'
                )
              ),
              _this.controller.getTaskNavigator("hitlist shitlist")
            );
          } else {
            return elementFactory.getWordCollection(
              _this.controller.entries["hitlist"],
              [...Array(_this.controller.entries["hitlist"].length).keys()],
              []
            );
          }
        },
        true
      );
    },

    getShitlist: function () {
      let _this = this;
      return get(
        _this.controller.entries["shitlist"],
        (empty) => {
          if (empty) {
            return profileView.loadEmptyPanel(
              elementFactory.getTitledWordCollectionPlaceholder(
                "",
                "Shitlist",
                $$(document.createElement("div")).append(
                  '<div class="white-card profile-empty-insert"></div>'
                )
              ),
              _this.controller.getTaskNavigator("hitlist shitlist")
            );
          } else {
            return elementFactory.getWordCollection(
              _this.controller.entries["shitlist"],
              [],
              [...Array(_this.controller.entries["shitlist"].length).keys()]
            );
          }
        },
        true
      );
    },

    getRiasec: function () {
      let _this = this;
      return get(
        _this.controller.entries["riasec"],
        (empty) => {
          // cards
          let cards = $$(document.createElement("div"));
          if (empty || !_this.controller.entries["riasec"].internal) {
            cards.append(
              profileView.loadEmptyPanel(
                elementFactory
                  .getFadeoutListing("Interessenstest (RIASEC)", [])
                  .addClass("profile-interests-fadeout-listening white-card")
                  .append('<div class="profile-empty-insert"></div>'),
                _this.controller.getTaskNavigator("riasec")
              )
            );
          } else {
            cards.append(
              elementFactory
                .getFadeoutListing(
                  "Interessenstest (RIASEC)",
                  _this.controller.entries["riasec"].internal
                )
                .addClass("profile-interests-fadeout-listening white-card")
            );
          }
          if (
            _this.controller.entries["riasec"] &&
            _this.controller.entries["riasec"].external
          ) {
            cards.append(
              elementFactory
                .getFadeoutListing(
                  "Interessenstest (RIASEC) - Check-U",
                  _this.controller.entries["riasec"].external
                )
                .addClass("profile-interests-fadeout-listening white-card")
            );
          }

          return $$(document.createElement("div"))
            .addClass("cards-scroll profile-interests-cards-scroll")
            .append(cards);
        },
        true
      );
    },
  },

  career: {
    controller: null,

    init: function (cntrllr) {
      this.controller = cntrllr;
    },

    load: function () {
      $$("#profile-career").removeClass("hide");
      let content = this._loadContent(
        $$("#profile-career .profile-block-content").empty()
      );
      profileView.loadReflectionCard(content.find(".word-collection"), 3);
    },

    getContent: function () {
      return this._loadContent(
        $$(document.createElement("div")).addClass("profile-block-content")
      );
    },

    _loadContent: function (content) {
      content.append(this.getMustHaveAndNogo());
      content.append(this.getJobvaluesAndSalaryAndQuotes());
      return content;
    },

    getMustHaveAndNogo: function () {
      let _this = this;
      return get(
        _this.controller.entries["nogo"] ||
          _this.controller.entries["mustHave"],
        (empty) => {
          if (empty) {
            return profileView.loadEmptyPanel(
              elementFactory.getTitledWordCollectionPlaceholder(
                "Must Haves",
                "No Gos",
                $$(document.createElement("div")).append(
                  '<div class="white-card profile-empty-insert"></div>'
                )
              ),
              _this.controller.getTaskNavigator("mustHave nogo")
            );
          }

          if (
            _this.controller.entries["nogo"] &&
            _this.controller.entries["mustHave"]
          ) {
            // both
            return $$(document.createElement("div")).append(
              elementFactory.getTitledWordCollection(
                "Must Haves",
                "No Gos",
                _this.controller.entries["mustHave"],
                _this.controller.entries["nogo"]
              )
            );
          } else if (_this.controller.entries["mustHave"]) {
            // mustHave
            return _this.getMustHave();
          } else if (_this.controller.entries["nogo"]) {
            // nogo
            return _this.getNogo();
          }
        },
        true
      );
    },

    getMustHave: function () {
      let _this = this;
      return get(
        _this.controller.entries["mustHave"],
        (empty) => {
          if (empty) {
            return profileView.loadEmptyPanel(
              elementFactory.getTitledWordCollectionPlaceholder(
                "Must Haves",
                "",
                $$(document.createElement("div")).append(
                  '<div class="white-card profile-empty-insert"></div>'
                )
              ),
              _this.controller.getTaskNavigator("mustHave nogo")
            );
          }
          return $$(document.createElement("div")).append(
            elementFactory.getWordCollection(
              _this.controller.entries["mustHave"],
              [...Array(_this.controller.entries["mustHave"].length).keys()],
              []
            )
          );
        },
        true
      );
    },

    getNogo: function () {
      let _this = this;
      return get(
        _this.controller.entries["nogo"],
        (empty) => {
          if (empty) {
            return profileView.loadEmptyPanel(
              elementFactory.getTitledWordCollectionPlaceholder(
                "",
                "No Gos",
                $$(document.createElement("div")).append(
                  '<div class="white-card profile-empty-insert"></div>'
                )
              ),
              _this.controller.getTaskNavigator("mustHave nogo")
            );
          }
          return $$(document.createElement("div")).append(
            elementFactory.getWordCollection(
              _this.controller.entries["nogo"],
              [],
              [...Array(_this.controller.entries["nogo"].length).keys()]
            )
          );
        },
        true
      );
    },

    getJobvaluesAndSalaryAndQuotes: function () {
      let _this = this;
      return get(
        controller.showEmptyProfileSections ||
          _this.controller.entries["jobvalues"] ||
          _this.controller.entries["salary"] ||
          _this.controller.entries["quotes"],
        () => {
          let salary = _this.getJobvaluesAndSalary();
          return salary !== ""
            ? salary
                .append(_this.getQuotes().children("*"))
                .addClass("profile-jobvaluesandsalaryandquotes")
            : this.getQuotes();
        }
      );
    },

    getJobvaluesAndSalary: function () {
      let _this = this;
      return get(
        controller.showEmptyProfileSections ||
          _this.controller.entries["jobvalues"] ||
          _this.controller.entries["salary"],
        () => {
          let cards = $$(document.createElement("div"));

          // jobvalues
          if (
            controller.showEmptyProfileSections ||
            _this.controller.entries["jobvalues"]
          ) {
            cards.append(_this.getJobvalues());
          }

          // salary
          if (
            controller.showEmptyProfileSections ||
            _this.controller.entries["salary"]
          ) {
            cards.append(_this.getSalary());
          }

          return $$(document.createElement("div"))
            .addClass("cards-scroll profile-cards-scroll-later")
            .append(cards);
        }
      );
    },

    getJobvalues: function () {
      let _this = this;
      return get(
        _this.controller.entries["jobvalues"],
        (empty) => {
          let card = elementFactory.getThemeCard(
            "Berufliche Werte",
            "md work",
            empty ? [] : _this.controller.entries["jobvalues"]
          );
          if (empty) {
            card.append(
              empty ? '<div class="profile-empty-insert"></div>' : ""
            );
            profileView.loadEmptyPanel(
              card,
              _this.controller.getTaskNavigator("jobvalues")
            );
          }
          return card;
        },
        true
      );
    },

    getSalary: function () {
      let _this = this;
      return get(
        _this.controller.entries["salary"],
        (empty) => {
          let card = elementFactory
            .getThemeCard(
              "Gehalts&shyvorstellung",
              "md attach_money",
              empty ? [] : _this.controller.entries["salary"]
            )
            .addClass("no-list");
          if (empty) {
            card.append(
              empty ? '<div class="profile-empty-insert"></div>' : ""
            );
            profileView.loadEmptyPanel(
              card,
              _this.controller.getTaskNavigator("salary")
            );
          }
          return card;
        },
        true
      );
    },

    getQuotes: function () {
      let _this = this;
      return get(_this.controller.entries["quotes"], () => {
        let cards = $$(document.createElement("div"));

        for (
          let quoteIndex = 0;
          quoteIndex < _this.controller.entries["quotes"].length;
          quoteIndex++
        ) {
          cards.append(_this.getQuote(quoteIndex));
        }

        return $$(document.createElement("div"))
          .addClass("cards-scroll profile-cards-scroll-later")
          .append(cards);
      });
    },

    getQuote: function (index) {
      let _this = this;
      return get(
        _this.controller.entries["quotes"] &&
          _this.controller.entries["quotes"][index] &&
          _this.controller.entries["quotes"][index].entry,
        (empty) => {
          let card = elementFactory.getQuoteCard(
            empty
              ? '<div class="profile-empty-insert"></div>'
              : _this.controller.entries["quotes"][index].entry
          );
          if (empty) {
            profileView.loadEmptyPanel(
              card,
              _this.controller.getTaskNavigator(
                _this.controller.entries["quotes"][index].key
              )
            );
          }
          return card;
        },
        true
      );
    },
  },

  personality: {
    controller: null,

    init: function (cntrllr) {
      this.controller = cntrllr;
    },

    load: function () {
      $$("#profile-personality").removeClass("hide");
      let content = this._loadContent(
        $$("#profile-personality .profile-block-content").empty()
      );
      profileView.loadReflectionCard(
        content.find(".cards-scroll:first-child"),
        4
      );
    },

    loadTestCard: function () {
      profileView.loadTestCard(
        $$("#profile-personality .profile-block-content").find(".cards-scroll"),
        3
      );
    },

    getContent: function () {
      return this._loadContent(
        $$(document.createElement("div")).addClass("profile-block-content")
      );
    },

    _loadContent: function (content) {
      content.append(this.getValuesAndPriorities());
      content.append(this.getMotivesAndSystemtype());
      content.append(this.getPersonality());
      return content;
    },

    getValues: function () {
      let _this = this;
      return get(
        _this.controller.entries["values"],
        (empty) => {
          let card = elementFactory.getThemeCard(
            "Werte",
            "md person",
            empty ? [] : _this.controller.entries["values"]
          );
          if (empty) {
            card.append(
              empty ? '<div class="profile-empty-insert"></div>' : ""
            );
            profileView.loadEmptyPanel(
              card,
              _this.controller.getTaskNavigator("values")
            );
          }
          return card;
        },
        true
      );
    },

    getPriorities: function () {
      let _this = this;
      return get(
        _this.controller.entries["priorities"],
        (empty) => {
          let card = elementFactory.getThemeCard(
            "Prioritäten",
            "md priority_high",
            empty ? [] : gummybearController.getGraphic()
          );
          if (empty) {
            card.append(
              empty ? '<div class="profile-empty-insert"></div>' : ""
            );
            profileView.loadEmptyPanel(
              card,
              _this.controller.getTaskNavigator("priorities")
            );
          }
          return card;
        },
        true
      );
    },

    getValuesAndPriorities: function () {
      let _this = this;
      return get(
        controller.showEmptyProfileSections ||
          _this.controller.entries["values"] ||
          _this.controller.entries["priorities"],
        () => {
          let cards = $$(document.createElement("div"));

          // values
          if (
            controller.showEmptyProfileSections ||
            _this.controller.entries["values"]
          ) {
            cards.append(_this.getValues());
          }

          // priorities
          if (
            controller.showEmptyProfileSections ||
            _this.controller.entries["priorities"]
          ) {
            cards.append(_this.getPriorities());
          }

          return $$(document.createElement("div"))
            .addClass("cards-scroll")
            .append(cards);
        }
      );
    },

    getMotives: function () {
      let _this = this;
      return get(
        _this.controller.entries["motives"],
        (empty) =>
          empty
            ? profileView.loadEmptyPanel(
                $$(document.createElement("div")).addClass(
                  "white-card profile-empty-card-placeholder"
                ).append(`
                                 <h3 class="font-h3">Meine Motive und Persönlich&shykeits&shytendenz</h3>
                                 <div class="profile-empty-insert"></div>
                            `),
                _this.controller.getTaskNavigator("motives")
              )
            : elementFactory.getFadeoutListing(
                "Meine Motive und Persönlichkeitstendenz",
                _this.controller.entries["motives"]
              ),
        true
      );
    },

    getSystemtype: function () {
      let _this = this;
      return get(
        _this.controller.entries["systemtype"],
        (empty) => {
          let card = elementFactory.getQuoteCard(
            empty
              ? '<div class="profile-empty-insert"></div>'
              : _this.controller.entries["systemtype"]
          );
          if (empty) {
            profileView.loadEmptyPanel(
              card,
              _this.controller.getTaskNavigator("systemtype")
            );
          }
          return card;
        },
        true
      );
    },

    getMotivesAndSystemtype: function () {
      let _this = this;
      return get(
        controller.showEmptyProfileSections ||
          _this.controller.entries["motives"] ||
          _this.controller.entries["systemtype"],
        () => {
          let row = $$(document.createElement("div")).addClass(
            "profile-personality-row"
          );

          // systemtype
          if (
            controller.showEmptyProfileSections ||
            _this.controller.entries["systemtype"]
          ) {
            row.append(_this.getSystemtype());
          }

          // motives
          if (
            controller.showEmptyProfileSections ||
            _this.controller.entries["motives"]
          ) {
            row.append(_this.getMotives());
          }

          return row;
        }
      );
    },

    getPersonality: function () {
      let _this = this;
      return get(
        _this.controller.entries["personality"],
        (empty) => {
          return $$(document.createElement("div"))
            .addClass("cards-scroll profile-personality-cards-scroll")
            .append(
              $$(document.createElement("div"))
                .append(
                  empty || !_this.controller.entries["personality"].internal
                    ? profileView.loadEmptyPanel(
                        $$(document.createElement("div")).addClass(
                          "white-card profile-empty-card-placeholder"
                        ).append(`
                                                <h3 class="font-h3">Meine Einschätzung von Big 5</h3>
                                                <div class="profile-empty-insert"></div>
                                            `),
                        _this.controller.getTaskNavigator("personality")
                      )
                    : $$(document.createElement("div"))
                        .addClass("profile-personality-bigfive white-card")
                        .append("<h3>Meine Einschätzung der BigFive</h3>")
                        .append(
                          elementFactory.getBarChart(
                            _this.controller.entries["personality"].internal
                          )
                        )
                )
                .append(
                  _this.controller.entries["personality"] &&
                    _this.controller.entries["personality"].external
                    ? $$(document.createElement("div"))
                        .addClass("profile-personality-bigfive white-card")
                        .append("<h3>Meine Einschätzung der BigFive</h3>")
                        .append(
                          elementFactory.getBarChart(
                            _this.controller.entries["personality"].external
                          )
                        )
                    : ""
                )
            );
        },
        true
      );
    },
  },

  loadEmptyPanel: function (element, navigator) {
    element
      .find(".profile-empty-insert")
      .empty()
      .append(
        $$(document.createElement("div"))
          .addClass("profile-empty-placeholder")
          .attr("data-dic-key", "myprofile-empty-placeholder")
      );
    let button = $$(document.createElement("div"))
      .addClass("profile-empty-button")
      .append(
        `
                <span data-dic-key="myprofile-empty-task"></span>
                <i class="icon material-icons">navigate_next</i>
            `
      )
      .on("click", () => {
        navigator();
      });
    let card = element;
    if (!card.hasClass("white-card")) {
      card = element.find(".white-card");
    }
    card.append(
      $$(document.createElement("div"))
        .addClass("profile-empty-panel")
        .append(button)
    );
    dictionary.loadModuleToDOM("profiletexts", card);
    return element;
  },

  loadReflectionCard: function (scroll, number) {
    if (scroll.length > 0) {
      scroll.children("*").addClass("next-to-reflection-card");
      scroll.prepend(profileView.getReflectionCard(number)); // insert Reflection-Card
      scroll[0].scrollLeft = 142;
    }
    return scroll;
  },

  getReflectionCard: function (number) {
    return get(true, () =>
      $$(document.createElement("div"))
        .addClass("profile-reflection-card white-card")
        .append(
          `
                        <div>Reflexionsfragen</div>
                        <div class="profile-reflection-card-image"></div>
                    `
        )
        .on("click", () => {
          app.views.main.router.navigate("/task/0/2/1/" + number + "/");
        })
    );
  },

  loadTestCard: function (scroll, number) {
    if (scroll.length > 0) {
      if (!scroll.hasClass("cards-scroll")) {
        scroll.append(
          $$(document.createElement("div")).addClass(
            "profile-test-card-container"
          )
        );
        scroll = scroll.children(".profile-test-card-container");
      }
      scroll.append(profileView.getTestCard(number)); // insert Test-Card
    }
  },

  getTestCard: function (number) {
    return get(true, () =>
      $$(document.createElement("div"))
        .addClass("white-card profile-test-card")
        .append(
          `
                <div>
                    <i class="icon material-icons">add</i>
                    Testergebnis hinzufügen
                </div>
            `
        )
        .on("click", () => {
          app.views.current.router.navigate("/task/0/2/2/" + number + "/");
        })
    );
  },

  getFeedbackResultsCard: function (index, name) {
    return $$(document.createElement("div"))
      .addClass(
        "white-card profile-result-card-width profile-result-card-feedback"
      )
      .append(
        `
                <div>
                    <i class="icon material-icons">person</i>
                    <span class="font-text">Rückmeldung von ${
                      name ? name : "einem/r Freund:in"
                    }</span>
                </div>
            `
      )
      .on("click", () => {
        app.views.current.router.navigate("/f360result/" + index + "/");
      });
  },

  loadResults: function (target, result) {
      console.log('target', target)
      console.log('result', result)
    let selector = "#profile-results-" + target

    if (!result) {
        console.log(target + " this happens!")
      $$(selector).removeClass("hide");
      $$(selector + " .profile-block-content").empty();
        $$(selector + " .profile-block-content").html(`
          <div class="white-card profile-empty-card-placeholder profile-result-card-width">
            <div class="profile-empty-insert">
              <div class="profile-empty-placeholder" data-dic-key="myprofile-empty-placeholder">- Noch keine Einträge vorhanden -</div>
            </div>
          </div>`);
    } else {
      $$(selector).removeClass("hide");
      let content = $$(
        selector + " .profile-block-content .cards-scroll > div"
      );
      if (content.length < 1) {
        content = $$(document.createElement("div"));
        $$(selector + " .profile-block-content").append(
          $$(document.createElement("div"))
            .addClass("cards-scroll")
            .append(content)
        );
      }
      if (result.hasClass("white-card")) {
        content.append(result);
      } else {
        content.append(
          $$(document.createElement("div"))
            .addClass("white-card")
            .append(result)
        );
      }
    }
  },

  getEmptyResultPlaceholderCard: function (navigator) {
    return this.loadEmptyPanel(
      $$(document.createElement("div")).addClass(
        "white-card profile-empty-card-placeholder profile-result-card-width"
      ).append(`
                    <div class="profile-empty-insert"></div>
                `),
      navigator
    );
  },

  loadFilters: function () {
    let load = function (target, container) {
      container.empty();
      let filter = controller.getFilter(target, true);
      for (let state in filter.all) {
        if (filter.all.hasOwnProperty(state)) {
          container.append(`
                        <li>
                            <a id="profile-filter-${target}-${state}" class="list-button item-link" href="#">${
            filter.get(state).name
          }</a>
                        </li>
                    `);
          let filterLink = $$("#profile-filter-" + target + "-" + state);
          if (filter.get(state).name === filter.current.name) {
            filterLink.addClass("selected");
          }
          filterLink.on("click", () => {
            controller.applyFilter(target, state);
            container.find('a[id^="profile-filter-"]').each((ind, el) => {
              $$(el).removeClass("selected");
            });
            filterLink.addClass("selected");
            app.popover.get("#profile-popover-" + target + "-sort").close();
          });
        }
      }
    };

    load("ideas", $$("#profile-ideas-filters"));
    load("options", $$("#profile-options-filters"));
  },

  loadIdeas: function (ideas) {
    if (ideas != null) {
      this.ideas = ideas;
    }
    this.load(
      "ideas",
      `<i class="icon svg-icon-note-outline"></i>`,
      (idea) => (idea.isStudies ? "Studium: " : "Ausbildung: ") + idea.name,
      (idea) =>
        "Zuletzt geändert am " +
        dateController.getStringFromDate(idea.last_modified),
      (idea) => controller.rankIdea(idea),
      (idea) => "/idea/" + idea.id + "/",
      (ideas, filter) => ideas.sort(filter.sort),
      (idea) => idea.id
    );
  },

  loadOptions: function (options) {
    if (options != null) {
      this.options = options;
    }
    this.load(
      "options",
      `<i class="icon svg-icon-note-outline"></i>`,
      (option) => option.getName(),
      (option) =>
        "Zuletzt geändert am " +
        dateController.getStringFromDate(option.last_modified),
      (option) => controller.rankOption(option),
      (option) => "/idea/" + option.idea + "/",
      (options, filter) => options.sort(filter.sort),
      (option) => option.id
    );
  },

  load: function (
    target,
    iconElement,
    getTitle,
    getSubtitle,
    getRanking,
    getLink,
    map,
    getId
  ) {
    const lists = $$("#profile-" + target + "-list");
    if (profileView[target] != null && profileView[target].length > 0) {
      lists.each((index, container) => {
        container = $$(container);
        let list = container.find(
          ".profile-idea-cards .profile-idea-cards-container"
        );
        list.empty();
        const filter = controller.getFilter(target);
        let elements = map(profileView[target], filter);
        if (elements.length <= 0) {
          lists.find(".placeholder-container").removeClass("hide");
        } else {
          lists.find(".placeholder-container").addClass("hide");
          for (let key in elements) {
            if (elements.hasOwnProperty(key)) {
              list.append(
                `
                                <div class="card card-line card-representer list">
                                    <div class="card-content">
                                        <a id="profile-${target}-link_${getId(
                  elements[key]
                )}" href="${getLink(
                  elements[key]
                )}" class="item-content item-link profile-${target}-link">
                                            <div class="item-inner">
                                                <span class="title">
                                                    ${getTitle(elements[key])}
                                                    <span class="after-title">${getRanking(
                                                      elements[key]
                                                    )}</span>
                                                </span>
                                                <div class="item-subtitle">
                                                    ${iconElement}
                                                    <span>${getSubtitle(
                                                      elements[key]
                                                    )}</span>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            `
              );
            }
          }
        }
      });
    } else {
      lists.find(".profile-idea-cards .profile-idea-cards-container").empty();
      lists.find(".placeholder-container").removeClass("hide");
    }
  },

  renewOnOptionClicked: function (callback) {
    const list = $$("#profile-options-list");
    list.find(".profile-options-link").each((index, optionEl) => {
      optionEl = $$(optionEl);
      let optionId = optionEl.attr("id").split("_")[1];
      $$("#profile-options-link_" + optionId)
        .attr("href", "#")
        .on("click", () => {
          callback(optionId);
        });
    });
  },

  loadPopovers: function () {
    $$('.page[data-name="profile"] .popover-open').each((i, el) => {
      el = $$(el);
      app.popoverOpenBelow(el, $$(el.attr("data-popover")));
    });
    $$("#profile-export-button").on("click", () => {
      if (app.popover.get("#profile-popover-menu")) {
        app.popover.get("#profile-popover-menu").close();
      }
      controller.export();
    });
  },
};
export default profileView;
