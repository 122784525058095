import view from './ElementView';

const elementFactory = {
  swiperMap: new Map(),

  /**
   * Returns a listing, which is more fading out the last entries, than the first ones.
   *
   * @param heading a string as heading of the listing
   * @param entries an array of strings for the listing
   * @returns the element
   */
  /*
    * Example:
    *
        $$("...").append(elementFactory.getFadeoutListing(["first line", "second line", "last line"]]);

    * */
  getFadeoutListing: function (heading, entries) {
    return view.getFadeoutListing(heading, entries);
  },

  /**
   * Returns a card with a quotes icon.
   *
   * @param quote the content
   * @returns the element
   */
  /*
    * Example:
    *
        $$("...").append(elementFactory.getQuoteCard("This is a quote.");

    * */
  getQuoteCard: function (quote) {
    return view.getQuoteCard(quote);
  },

  /**
   * Returns a theme car with theme, picture and word-list.
   *
   * @param theme the title
   * @param icon path of the picture
   * @param words an array for the sorted word list
   * @returns the element
   */
  /*
    * Example:
    *
        $$("...").append(elementFactory.getThemeCard("Selbsteinschätzung", "static/icons/lightbulb", ["word1", "word2", "word3"]));

    * */
  getThemeCard: function (theme, icon, words) {
    if (Array.isArray(words)) {
      return view.getThemeCard(theme, icon, words);
    } else {
      return view.getThemeCardFromObj(theme, icon, words);
    }
  },

  /**
   * Returns a multiline-bar of words in boxes.
   *
   * @param words an array of words to display
   * @param green an array of indices to highlight in green. If null, nothing will be highlighted.
   * @param red an array of indices to highlight in red. If null, nothing will be highlighted.
   * @param arrowCallback a function called on arrow clicked. If null, no arrow will be displayed.
   * @returns the element
   */
  /*
    * Example:
    *
        let onArrow = function () {
            console.log("arrow clicked!");
        };
        $$("...").append(elementFactory.getWordCollection(["word0", "word1", "word2", "word3", "word5"], null, null, onArrow));
        $$("...").append(elementFactory.getWordCollection(["word0", "word1", "word2", "word3", "word5"], [2], [0]));
        $$("...").append(elementFactory.getWordCollection(["word0", "word1", "word2", "word3", "word5"], null, [2,3]));
        $$("...").append(elementFactory.getWordCollection(["some", "other", "words"]));

    * */
  getWordCollection: function (words, green, red, arrowCallback) {
    if (!green) {
      green = [];
    }
    if (!red) {
      red = [];
    }
    return view.getWordCollection(words, green, red, arrowCallback);
  },

  /**
   * Returns a of words in boxes, with one title per line.
   *
   * @param titleGreen title for green words
   * @param titleRed title for red words
   * @param green an array of words to highlight in green.
   * @param red an array of words to highlight in red.
   * @returns the element
   */
  /*
    * Example:
    *
        $$("...").append(elementFactory.getTitledWordCollection("Grün", "Rot", ["word0", "word1", "word2"], ["word3", "word5"]));

    * */
  getTitledWordCollection: function (titleGreen, titleRed, green, red) {
    if (!green) {
      green = [];
    }
    if (!red) {
      red = [];
    }
    return view.getTitledWordCollection(titleGreen, titleRed, green, red);
  },

  /**
   * Returns a placeholder for a titled word collection.
   *
   * @param titleGreen title for green words
   * @param titleRed title for red words
   * @param content the placeholder to be shown.
   * @returns the element
   */
  /*
    * Example:
    *
        $$("...").append(elementFactory.getTitledWordCollectionPlaceholder("Grün", "Rot", DOM7Element));

    * */
  getTitledWordCollectionPlaceholder: function (titleGreen, titleRed, content) {
    return view.getTitledWordCollectionPlaceholder(
      titleGreen,
      titleRed,
      content,
    );
  },

  /**
   * Returns a progressbar-bar.
   *
   * @param initValue the initial value of this bar in percent. If null, value is 0.
   * @returns the element
   */
  /*
    * Example:
    *
        $$("...").append(elementFactory.getProgressbar(30));

    * */
  getProgressbar: function (initValue) {
    return view.getProgressbar(initValue);
  },

  /**
   * Sets the value of a progressbar-bar.
   *
   * @param bar the progressbar to set
   * @param value the value to set in percent. If null, value is 0.
   */
  /*
    * Example:
    *
        elementFactory.setProgressbar(progressbar, 30);

    * */
  setProgressbar: function (bar, value) {
    view.setProgressbar(bar, value);
  },

  /**
   * Returns a swiper.
   *
   * @param strings an array of initial strings of card titles in the swiper. No cards in swiper if null or empty.
   * @param onLeft is a method called on a swipe to left side. It gets the index and title of the swiped card as parameter.
   * @param onRight is a method called on a swipe to right side. It gets the index and title of the swiped card as parameter.
   * @returns the element
   */
  /*
    * Example:
    *
        $$("...").append(elementFactory.createSwiper(admit, deny, doNothing, ["word1", "word2", "word3", "word5"]));
        $$("...").append(elementFactory.createSwiper(admit, deny, doNothing));

    * */
  getSwiper: function (onLeft, onRight, strings) {
    let id = '' + this.swiperMap.size;
    let swiperObj = {
      element: view.createSwiper(id),
      previousSlides: [],
    };
    this.swiperMap.set(id, swiperObj);
    if (strings) {
      strings.forEach((string) =>
        elementFactory._addToSwiper(swiperObj.element, string, onLeft, onRight),
      );
    }
    return swiperObj.element;
  },

  navigateSwiperForewardTo: function (swiper, indexToDisplay) {
    let swiperObj = this.swiperMap.get(view.getSwiperId(swiper));
    while (view.getSwipersTopCardIndex(swiper) < indexToDisplay) {
      view.removeTopCardFromSwiper(swiper, (slide) =>
        swiperObj.previousSlides.push(slide),
      );
    }
  },

  /**
   * Displays the last previous slide of the swiper again. Check if there are such slides before use!
   *
   * @param swiper the swiper to navigate back in
   * @see {@link elementFactory.hasPreviousSwiperSlide}
   */
  /*
    * Example:
    *
        var swiper = $$("...");
        if(elementFactory.hasPreviousSwiperSlide(swiper)) {
            elementFactory.previousSwiperSlide(swiper);
        }

    * */
  previousSwiperSlide: function (swiper) {
    let toAdd = this.swiperMap
      .get(view.getSwiperId(swiper))
      .previousSlides.pop();
    view.addToSwiper(swiper, toAdd, true);
    return view.getSwiperCardIndex(toAdd);
  },

  /**
   * Returns true, if swiper has one or more previous slides to navigate to.
   *
   * @param swiper the swiper to navigate back in
   * @returns whether there are previous slides or not
   */
  /*
    * Example:
    *
        if(elementFactory.hasPreviousSwiperSlide($$("..."))) {...}

    * */
  hasPreviousSwiperSlide: function (swiper) {
    return (
      this.swiperMap.get(view.getSwiperId(swiper)).previousSlides.length > 0
    );
  },

  _addToSwiper: function (swiper, string, onLeft, onRight) {
    let swiperObj = this.swiperMap.get(view.getSwiperId(swiper));
    let prevAdder = function (slide) {
      swiperObj.previousSlides.push(slide);
    };
    const index = view.getSwipersLastIndex(swiper) + 1;
    const card = view.getSwiperCard(string, index);
    let handler = view.getSwiperHandler(
      () => {
        view.removeTopCardFromSwiper(swiper, prevAdder);
        onLeft(index, string);
      },
      () => {
        view.removeTopCardFromSwiper(swiper, prevAdder);
        onRight(index, string);
      },
    );
    view.initSwiperDragAndDrop(
      swiper,
      card,
      handler.handleDrag,
      handler.handleDrop,
    );
    view.addToSwiper(swiper, card);
  },

  /**
   * Return a labeled textinput
   * @param label labe above textinput
   * @param placeholder placeholder for textinput
   * @param userinput previously entered userinput that gets displayed
   **/
  getLabeledTextInput: function (label, placeholder, userinput) {
    return view.getLabeledTextInput(label, placeholder, userinput);
  },

  /**
   * Returns a labeled textinput as seen in Login/register
   * @param {any} userinput previously entered userinput that gets displayed
   * @param {any} placeholder placeholder for the input
   * @param {any} icon if string is given, the corresponding material icon is displayed left of input
   * @param {string} type determines type of html-input
   */
  getUnderlinedInput: function (placeholder, userinput, icon, type) {
    return view.getUnderlinedInput(placeholder, userinput, icon, type);
  },
  /**
   * Returns a gauge.
   *
   * @param titles an array of strings responding to values with the same index
   * @param values an array of up to 5 numbers - absolute or relative (5 colors implemented yet)
   * @returns the element
   */
  /*
    * Example:
    *
        $$("...").append(elementFactory.getGauge(["Uni", "FH", "Duales Studium", "Ausbildung"], [5, 5, 5, 9]);

    * */
  getGauge: function (titles, values, legendTitle) {
    return view.getGauge(titles, this._relativeValues(values), legendTitle);
  },

  /**
   * Returns a bar graph.
   *
   * @param entries an object of attributes with each a key and a value
   * @returns the element
   */
  /*
    * Example:
    *
        $$("...").append(elementFactory.getBarChart({key1: 2, key2: 3});

    * */
  getBarChart: function (entries) {
    let keys = [];
    let values = [];
    for (let key in entries) {
      keys.push(key);
      values.push(entries[key] != null ? entries[key] : 0);
    }
    return view.getBarChart(keys, this._relativeValuesMax(values));
  },

  /**
   * Returns a block of progressbars as card.
   *
   * @param entries an array of objects with each a key and a value attribute
   * @returns the element
   */
  /*
    * Example:
    *
        $$("...").append(elementFactory.getProgressBarsCard({key1: 2, key2: 3});

    * */
  getProgressBarsCard: function (entries) {
    let keys = [];
    let values = [];
    for (let key in entries) {
      keys.push(key);
      values.push(entries[key] != null ? entries[key] : 0);
    }
    return view.getProgressBarsCard(this.getProgressbar, keys, values);
  },

  /**
   * Returns a slider input.
   *
   * @param value default value
   * @param callback function called on change. Takes an integer as parameter
   * @returns the element
   */
  /*
    * Example:
    *
        $$("...").append(elementFactory.getSlider(0, result => save(result));

    * */
  getSlider: function (value, callback) {
    return view.getSlider(value, callback);
  },

  /**
   * Returns a card with titles and sliders. Created from given sliders by {@link elementFactory.getSlider()}.
   *
   * structure of the array parameter:
   *
   * [
   *     {
   *         title: string,               // title for that slider
   *         slider: [value, callback]    // parameters for that slider see {@link elementFactory.getSlider()}
   *     },
   *     ...
   * ]
   *
   * @param array array of objects containing title and slider
   * @returns the element
   */
  /*
    * Example:
    *
        $$("...").append(elementFactory.getSliderCard([{title: "Test1", slider: [0, save]}, {title: "Test2", slider: [0, save]]}]);

    * */
  getSliderCard: function (array) {
    let titles = [];
    let sliders = [];
    for (let dto of array) {
      titles.push(dto.title);
      sliders.push(this.getSlider(dto.slider[0], dto.slider[1]));
    }
    return view.getSliderCard(titles, sliders);
  },

  /**
   * Returns a list which order is editable by swiping.
   *
   * @param initArray the list in initial order
   * @param onChange a handler for order changes
   * @returns the element
   */
  /*
    * Example:
    *
        $$("...").append(elementFactory.getSortableList(["Test1", "Test3", "Test2"], newArray => save(newArray));

    * */
  getSortableList: function (initArray, onChange) {
    return view.getSortableList(initArray, onChange);
  },

  getDropdownMenu: function (
    userinput,
    options,
    placeholder,
    icon,
    whitebackground,
  ) {
    return view.getDropdownMenu(
      userinput,
      options,
      placeholder,
      icon,
      whitebackground,
    );
  },

  _relativeValues: function (values) {
    let sum = 0;
    for (let index = 0; index < values.length; index++) {
      sum += values[index];
    }
    for (let index = 0; index < values.length; index++) {
      values[index] = (values[index] / sum) * 100;
    }
    return values;
  },

  _relativeValuesMax: function (values) {
    let max = 0;
    for (let index = 0; index < values.length; index++) {
      if (values[index] > max) {
        max = values[index];
      }
    }
    for (let index = 0; index < values.length; index++) {
      values[index] = (values[index] / max) * 100;
    }
    return values;
  },

  getItemCard: function (title, subtitle, icon) {
    return view.getItemCard(title, subtitle, icon);
  },
};
export default elementFactory;
