import $$ from 'dom7';
import Framework7 from 'framework7/framework7.esm.bundle.js';
import customUI from './plugins/customUIPlugin.js';
import { v4 as uuidv4 } from 'uuid';
import Connector from './db/connector.js';
import Dictionary from './core/Dictionary.js';
import Notebook from './model/notebook/Notebook.js';
import tutorialController from './core/ui/tutorial/TutorialController';

import userController from './core/UserController.js';
import adviseList from './model/AdviseList.js';
import syncController from './core/SynchronisationController.js';
// Import F7 Styles
import 'framework7/css/framework7.bundle.css';

// Import Icons and App Custom Styles
import './app.css';
import './components/elements/barchart.css';
import './components/elements/fadeoutlistening.css';
import './components/elements/gauge.css';
import './components/elements/progressbar.css';
import './components/elements/progressbarscard.css';
import './components/elements/quote-card.css';
import './components/elements/slider.css';
import './components/elements/sortable-list.css';
import './components/elements/swiper.css';
import './components/elements/theme-card.css';
import './components/elements/word-collection.css';
import './components/inputs/colored-radio-button.css';
import './components/inputs/input.css';
import './components/inputs/note.css';
import './components/inputs/table.css';
import './components/inputs/list.css';
import './components/inputs/textarea.css';
import './components/actions-modal.css';
import './components/audioplayer.css';
import './components/block.css';
import './components/button.css';
import './components/calendar.css';
import './components/card.css';
import './components/colors.css';
import './components/column.css';
import './components/content.css';
import './components/dialog.css';
import './components/dropdown.css';
import './components/dialog.css';
import './components/filter.css';
import './components/font.css';
import './components/icon.css';
import './components/image.css';
import './components/list.css';
import './components/navbar.css';
import './components/notification.css';
import './components/overlay.css';
import './components/page.css';
import './components/placeholder.css';
import './components/popover.css';
import './components/toolbar.css';
import './components/table.css';
import './components/typography.css';
import './pages/advise/advise.css';
import './pages/feedbackform/feedbackform.css';
import './pages/home/home.css';
import './pages/start/start.css';
import './pages/onboarding/onboarding.css';
import './pages/login/login.css';
import './pages/notebook/notebook.css';
import './pages/notebook/keydate/keydate.css';
import './pages/reminder/reminder.css';
import './pages/settings/settings.css';
import './pages/profile/profile.css';
import './pages/steps/exercise/task/types/reflection/reflection.css';
import './pages/profile/expertise/expertise.css';
import './pages/profile/idea/idea.css';
import './pages/steps/exercise/exercise.css';
import './pages/notebook/noteeditor/noteeditor.css';
import './pages/steps/exercise/task/task.css';
import './pages/steps/exercise/task/types/checkquestions/checkquestions.css';
import './pages/steps/exercise/task/types/coursecatalog/coursecatalog.css';
import './pages/steps/exercise/task/types/costcalculation/costcalculation.css';
import './pages/steps/exercise/task/types/educationpath/educationpath.css';
import './pages/steps/exercise/task/types/feedback/feedback.css';
import './pages/steps/exercise/task/types/goal/goal.css';
import './pages/steps/exercise/task/types/gummybear/gummybear.css';
import './pages/steps/exercise/task/types/idearanking/idearanking.css';
import './pages/steps/exercise/task/types/jobrating/jobrating.css';
import './pages/steps/exercise/task/types/optionranking/optionranking.css';
//import '~node_modules/material-icons/iconfont/material-icons.css';
import 'material-icons';
// Import Cordova APIs
import cordovaApp from './cordova-app.js';
// Import Routes
import routes from './pages/routes.js';

// Import Controller
import './pages/home/HomeController.js';
import './pages/steps/module/ModuleController.js';
import './pages/reminder/ReminderController';
import './pages/profile/ProfileController.js';
import './pages/notebook/NotebookController.js';
import './pages/advise/AdviseController.js';
import notificationController from './core/NotificationController.js';

require.context(
  '!file-loader?name=[path][name].[ext]&context=node_modules/tinymce!tinymce/skins',
  true,
  /.*/,
);

// Import TinyMCE
import tinymce from 'tinymce/tinymce';

import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/skins/ui/oxide/content.min.css';

// A theme is also required
import 'tinymce/themes/silver';

// Any plugins you want to use has to be imported
import 'tinymce/plugins/paste';
import 'tinymce/plugins/link';

//Import and start Bugsnag
import Bugsnag from '@bugsnag/js';

var webserverUrl = 'https://db1.deep-app.de/api';

if (process.env.NODE_ENV !== 'development') {
  var config = Bugsnag.start({ apiKey: '9350a89e60269afc4c9cc30e4c83ce2f' });
}

async function fetchAsync(url) {
  let response = await fetch(url);
  let data = await response.json();
  return data;
}

// Import JSON for testing TODO: optimize the data handling
document.addEventListener('onLoad', function () {}, false);

Framework7.use(customUI);

var app = new Framework7({
  root: '#app', // App root element
  name: 'DEEP', // App name
  theme: 'auto', // Automatic theme detection
  // App root data
  data: function () {
    return {
      user: {
        firstName: 'John',
        lastName: 'Doe',
      },
    };
  },
  // App root methods
  methods: {
    /**
     * Reinitalizes necessary localStorage-entries after logout
     * */
    afterLogout: function () {
      let agbs = JSON.parse(localStorage.getItem('AGBs-content'));
      let privacypolicy = JSON.parse(
        localStorage.getItem('privacypolicy-content'),
      );
      let tutorials = localStorage.getItem(
        this.__LOCALSTORAGE_KEY_DISABLED_TUTORIALS,
      );

      localStorage.clear();
      Notebook.clear();

      localStorage.setItem('isLoggedIn', 'false');
      localStorage.setItem('AGBs-content', JSON.stringify(agbs));
      localStorage.setItem(
        'privacypolicy-content',
        JSON.stringify(privacypolicy),
      );
      localStorage.setItem(
        this.__LOCALSTORAGE_KEY_DISABLED_TUTORIALS,
        tutorials,
      );

      if (false && process.env.NODE_ENV !== 'development') {
        localStorage.getItem('uid-user') == null
          ? localStorage.setItem('uid-user', uuidv4())
          : null;
      }
      userController.init(this);

      localStorage.setItem(
        'currentSubblock',
        JSON.stringify({
          module: 1,
          chapter: 1,
          exercise: 1,
          subblock: 1,
        }),
      );

      localStorage.setItem('firstStart', false);

      userController.modulesToLocalStorage();
      adviseList.adviseToLocalStorage();
      adviseList.findSubjectToLocalStorage();

      notificationController.initNotifications();
      app.views.main.router.navigate(`/`);
    },
  },
  // App routes
  routes: routes,
  // Register service worker
  serviceWorker: {
    path: process.env.NODE_ENV !== 'development' && '/service-worker.js',
  },
  view: {
    iosDynamicNavbar: false,
    pushState: false,
  },

  // Input settings
  input: {
    scrollIntoViewOnFocus:
      Framework7.device.cordova && !Framework7.device.electron,
    scrollIntoViewCentered:
      Framework7.device.cordova && !Framework7.device.electron,
  },
  // Cordova Statusbar settings
  statusbar: {
    iosOverlaysWebView: true,
    androidOverlaysWebView: false,
    androidTextColor: 'white',
  },
  toolbar: {
    hideOnPageScroll: true,
  },
  calendar: {
    url: 'calendar/',
    dateFormat: 'dd.mm.yyyy',
  },
  on: {
    init: function () {
      var f7 = this;
      if (f7.device.cordova) {
        // Init cordova APIs (see cordova-app.js)
        cordovaApp.init(f7);
        $$(document).on('deviceready', function () {
          window.open = window.cordova.InAppBrowser.open;
          $$(document).on('click', function (e) {
            var t = $$(e.target);
            if (t.is('a') && t.hasClass('external')) {
              e.preventDefault();
              window.open(t.attr('href'), '_system');
            }
          });
        });
        $$(document).on('deviceready', function () {
          notificationController.initLocalNotifications(this);
        });

        window.screen.orientation.lock('portrait');
        if (false) {
          document.documentElement.style.setProperty(
            '--system-info-bar',
            '0px',
          );
        }
      }
      document.documentElement.style.setProperty(
        '--page-content-side-padding',
        '25px',
      );
      // This doesn't work as it should. Should only connect to remote server in production-builds
      if (false && process.env.NODE_ENV == 'development') {
        this.dbconnector = new Connector(
          f7.device.cordova,
          'http://localhost:5000/api',
        );
      } else {
        this.dbconnector = new Connector(f7.device.cordova, webserverUrl);
      }

      syncController.init(this.dbconnector, this);

      if (false && process.env.NODE_ENV !== 'development') {
        localStorage.getItem('uid-user') == null
          ? localStorage.setItem('uid-user', uuidv4())
          : null;
      }

      userController.init(this);

      let firstStart = localStorage.getItem('firstStart');
      if (firstStart == null) {
        localStorage.setItem(
          'currentSubblock',
          JSON.stringify({
            module: 1,
            chapter: 1,
            exercise: 1,
            subblock: 1,
          }),
        );
        localStorage.setItem('firstStart', false);
      }

      userController.modulesToLocalStorage();
      adviseList.adviseToLocalStorage();
      adviseList.findSubjectToLocalStorage();

      // Get current version of AGBs and privacy policy and store it in localStorage
      try {
        fetchAsync('https://www.iubenda.com/api/privacy-policy/25035371').then(
          (data) => {
            localStorage.setItem('AGBs-content', JSON.stringify(data.content));
          },
        );
        fetchAsync('https://www.iubenda.com/api/privacy-policy/25035371').then(
          (data) => {
            localStorage.setItem(
              'privacypolicy-content',
              JSON.stringify(data.content),
            );
          },
        );
      } catch (e) {}

      notificationController.initNotifications();
      window.addEventListener(
        'beforeunload',
        function (e) {
          //synchronisationController.syncUserData();
        },
        false,
      );

      //tutorial
      tutorialController.init();


      userController.getAllTasks().forEach((task) => {
        task.writeToExplicitKey();
      });
    },
  },
});

if (!window.cordova) {
  app.dbconnector.secureLoginWithJWT().then((result) => {
    if (result) {
      app.views.main.router.navigate(`/`);
    } else {
      if (!localStorage.getItem('skip-onboarding')) {
        var mainView = app.views.create('.view-main', { url: '/onboarding/' });
      } else if (process.env.NODE_ENV === 'development') {
        var mainView = app.views.create('.view-main', {
          url: '/onboarding/',
        });
      } else {
        var mainView = app.views.create('.view-main', {
          url: '/login/login/',
        });
      }
    }
  });
}
document.addEventListener('deviceready', () => {
  if (window.cordova) {
    if (window.cordova.plugins.notification.local.launchDetails) {
      const currentSubblock = JSON.parse(
        localStorage.getItem('currentSubblock'),
      );
      switch (window.cordova.plugins.notification.local.launchDetails.id) {
        case 1000:
          var mainView = app.views.create('.view-main', { url: '/mod/1/' });
          break;
        case 1001:
          var mainView = app.views.create('.view-main', {
            url:
              '/ex/' +
              currentSubblock.module +
              '/' +
              currentSubblock.chapter +
              '/' +
              currentSubblock.exercise +
              '/0/',
          });
          break;
        case 1002:
          var mainView = app.views.create('.view-main', {
            url:
              '/ex/' +
              currentSubblock.module +
              '/' +
              currentSubblock.chapter +
              '/' +
              currentSubblock.exercise +
              '/0/',
          });
          break;
        case 1003:
          var mainView = app.views.create('.view-main', {
            url:
              '/ex/' +
              currentSubblock.module +
              '/' +
              currentSubblock.chapter +
              '/' +
              currentSubblock.exercise +
              '/0/',
          });
          break;
        case 1004:
          var mainView = app.views.create('.view-main', {
            url:
              '/ex/' +
              currentSubblock.module +
              '/' +
              currentSubblock.chapter +
              '/' +
              currentSubblock.exercise +
              '/0/',
          });
          break;
        case 1005:
          var mainView = app.views.create('.view-main', {
            url:
              '/ex/' +
              currentSubblock.module +
              '/' +
              currentSubblock.chapter +
              '/' +
              currentSubblock.exercise +
              '/0/',
          });
          break;
        case 1006:
          var mainView = app.views.create('.view-main', {
            url: '/task/0/1/1/1/',
          });
          break;
      }
    } else {
      if (!localStorage.getItem('skip-onboarding'))
        var mainView = app.views.create('.view-main', { url: '/onboarding/' });
    }
  }
});

export default app; // access app from other files
