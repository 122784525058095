import $$ from 'dom7';
import adviseView from './AdviseView.js';

const adviseController = {
  initView: function (pageContentString) {
    let externalLinks = false;
    if (pageContentString === 'adviseContent') externalLinks = true;
    let adviseContent = JSON.parse(localStorage.getItem(pageContentString));
    adviseView.init(adviseContent, pageContentString);
  },
};

export default adviseController;
