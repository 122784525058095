import $$ from 'dom7';
import app from '../../../../../../app';
import elementFactory from '../../../../../../core/ui/ElementFactory';
import inputController from '../../input/InputController';
import swotController from "./SwotController";

const swotView = {
  pages: {},

  started: false,

  end: false,

  task: null,

  init: function (task) {
    this.task = task;
    this.pages = task.slides;
    this.initPage(task);
    this.loadPage(0);
  },
  initPage: function (task) {
    let contentTop = $$('.page-content-top');
    contentTop.empty();
    contentTop.append(
      elementFactory.getProgressbar((1 / (this.pages.length - 1)) * 100),
    );
    contentTop.append(
      $$(document.createElement('h1'))
        .html(task.name)
        .addClass('page-title')
        .attr('id', 'task-title'),
    );
    $$('#task-bottom').empty();
  },
  loadPage: function (index) {
    let _this = this,
      page = this.pages[index],
      content = $$('#task-content'),
      bottom = $$('#task-bottom'),
      input = $$('#swot-input input'),
      progressbar = $$('.ci-progressbar'),
      nav = $$('.navbar-inner a.link');

    nav.off('click');
    nav.addClass('back');
    if (index > 0) {
      nav.removeClass('back');
      nav.on('click', () => {
        _this.loadPage(index - 1);
      });
    }

    content.empty();
    content.css('margin-bottom', '0px');
    bottom.empty();

    elementFactory.setProgressbar(
      progressbar,
      (index / (this.pages.length - 1)) * 100,
    );
    if (index / (this.pages.length - 1) >= 0.99) {
      this.end = true;
    }
    content.append(
      $$(document.createElement('div'))
        .attr('id', 'swot-content')
        .html(`<p>${page.question}</p>`),
    );
    if (
      page.loadLocalStorage &&
      JSON.parse(localStorage.getItem(page.loadLocalStorage)) != null
    ) {
      content.append(
        elementFactory.getWordCollection(
          JSON.parse(localStorage.getItem(page.loadLocalStorage)),
        ),
      );
    }
    content.append(
      inputController.getListInput(
        page.inputLabel,
        1,
        true,
        page.placeholder,
        JSON.parse(localStorage.getItem(page.saveLocalStorage)),
        this.saveListInput,
        page.saveLocalStorage,
        'swot',
      ),
    );

    if (index < this.pages.length - 1) {
      let button = bottom.append(
        $$(document.createElement('button'))
          .addClass('button button-fill color-white shadowed')
          .html('Weiter')
          .attr('id', 'task-button'),
      );
      button.on('click', function () {
        _this.loadPage(index + 1);
      });
    } else {
      //bottom.append($$(document.createElement("button")).addClass("button button-fill color-white half-width half-left shadowed").html("PDF exportieren").attr("id", "pdf-export-button"))
      let nextbutton = $$(document.createElement('button'))
        .addClass(
          'button button-fill color-white full-width half-right shadowed',
        )
        .html('Nächstes Thema')
        .attr('id', 'next-subject-button');
      nextbutton.off('click');
      nextbutton.on('click', () => {
        app.views.main.router.navigate('/ex/3/1/3/0/', { reloadAll: true });
      });
      bottom.append(nextbutton);
    }
  },
  saveListInput: function (localStorageKey, index) {
    return function (answer) {
      if (!swotView.started) {
        swotView.task.save();
        swotView.started = true;
      }
      if (swotView.end) {
        swotView.task.finish();
      }
      let ls = JSON.parse(localStorage.getItem(localStorageKey));
      ls == null ? (ls = []) : null;
      ls[index] = answer;
      console.log(localStorageKey)
      localStorage.setItem(localStorageKey, JSON.stringify(ls));
      swotController.saveAnswer(swotView.task, localStorageKey, index, answer);
    };
  },
};

export default swotView;
