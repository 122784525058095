import $$ from 'dom7';
import elementFactory from '../../../../../../core/ui/ElementFactory';
import inputController from '../../input/InputController';

let controller;

var rateView = {
  task: null,

  init: function (cntrllr, task, title, note) {
    controller = cntrllr;
    this.task = task;
    this.loadContent(title, note);
    this.loadButtons();
  },

  loadContent: function (title, note) {
    $$('#task-page-content')[0].scrollTop = 0;
    let content = $$('#task-content');
    content.empty();
    content.append(`
            <div id="coursecatalog-lecture-title">${title}</div>
            <div id="coursecatalog-lecture-note">${note}</div>
            <div id="coursecatalog-lecture-like">Zu dieser Veranstaltung gehe ich hin ...</div>
        `);
    content.append(
      inputController.getColoredRadioButtons(
        5,
        0,
        (res) => {
          setTimeout(() => {
            controller.saveRateLecuture(res);
          }, 200);
        },
        'gar nicht',
        'auf jeden Fall',
      ),
    );
  },

  loadButtons: function () {
    const list = $$('#task-bottom .button-list');
    list.empty();
  },
};

export default rateView;
