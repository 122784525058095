import view from './EditView';
import dictionary from '../../../../core/Dictionary';
import expertiseController from '../ExpertiseController';
import app from '../../../../app';
import userController from '../../../../core/UserController';

var editController = {
  entries: [],
  mode: null,
  key: null,

  init(key) {
    this.key = key;
    view.init(this);

    this.loadOverview();
  },

  loadOverview() {
    this.mode = null;
    view.loadTitle(
      dictionary
        .getText('profiletexts', 'expertise-edit-title')
        .replace('***', expertiseController.expertise.subtitle),
    );
    view.loadText(
      dictionary.getText('profiletexts', 'expertise-edit-overview-text'),
    );
    view.loadEntries();
    view.loadButtons(
      {
        title: dictionary.getText('profiletexts', 'expertise-self_assessment'),
        action() {
          editController.loadSelf();
        },
      },
      {
        title: dictionary.getText(
          'profiletexts',
          'expertise-external_assessment',
        ),
        action() {
          editController.loadExternal();
        },
      },
    );
    view.updateBackNavigation(() => app.views.current.router.back());
  },

  loadPage(key) {
    view.loadTitle(
      dictionary
        .getText('profiletexts', 'expertise-edit-title')
        .replace(
          '***',
          'Meine ' +
            dictionary.getText(
              'profiletexts',
              'expertise-' + key + '_assessment',
            ),
        ),
    );
    view.loadText(dictionary.getText('profiletexts', 'expertise-edit-text'));
    view.loadEntries(this.entries);
    view.loadButtons(
      {
        title: dictionary.getText(null, 'cancel'),
        action() {
          editController.cancel();
        },
      },
      {
        title: dictionary.getText(null, 'ready'),
        action() {
          editController.ready();
        },
      },
    );
    view.updateBackNavigation(() => editController.loadOverview());
  },

  loadSelf() {
    this.mode = 'self';
    this.entries = userController
      .getUserData('profile')
      .data.expertise[this.key].entries[0].map((entry) =>
        typeof entry !== 'object' ? entry : entry.name,
      );

    this.loadPage('self');
  },

  loadExternal() {
    this.mode = 'external';
    this.entries = userController
      .getUserData('profile')
      .data.expertise[this.key].entries[1].map((entry) =>
        typeof entry !== 'object' ? entry : entry.name,
      );

    this.loadPage('external');
  },

  change(array) {
    this.entries = array;
  },

  cancel() {
    app.views.current.router.back();
  },

  ready() {
    this.save();
    app.views.current.router.back();
  },

  save() {
    if (this.mode) {
      let self =
        userController.getUserData('profile').data.expertise[this.key]
          .entries[0];
      let external =
        userController.getUserData('profile').data.expertise[this.key]
          .entries[1];

      if (this.mode === 'self' && self.length > 0) {
        if (typeof self[0] === 'object') {
          let tmp = new Array(self.length).fill(null);
          for (let entry of self) {
            tmp[this.entries.indexOf(entry.name)] = entry;
          }
          self = tmp;
        } else {
          self = this.entries;
        }
      } else if (this.mode === 'external' && external.length > 0) {
        if (typeof external[0] === 'object') {
          let tmp = new Array(external.length).fill(null);
          for (let entry of external) {
            tmp[this.entries.indexOf(entry.name)] = entry;
          }
          self = tmp;
        } else {
          self = this.entries;
        }
      }

      userController
        .getUserData('profile')
        .reorderProfileExpertise(this.key, self, external);
    }
  },

  delete(key) {
    userController
      .getUserData('profile')
      .reorderProfileExpertise(key ? key : this.key, [], []);
  },
};

export default editController;
