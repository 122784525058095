const adviseList = {
  adviseToLocalStorage: function () {
    localStorage.setItem(
      'adviseContent',
      JSON.stringify(require('../download/advise.json')),
    );
  },
  findSubjectToLocalStorage: function () {
    localStorage.setItem(
      'findSubjectContent',
      JSON.stringify(require('../download/findsubject.json')),
    );
  },
};

export default adviseList;
