import interactionView from './InteractionView';
import introView from '../../IntroView';
import resultsView from './ResultsView';
import exerciseController from '../../../ExerciseController';
import taskController from '../../TaskController';
import app from '../../../../../../app';

const gummybearController = {
  task: null,

  inputindex: 0,

  setGummybears: 0,
  maxGummybears: 20,

  load: function (task) {
    this.task = task;
    this.displayIntro();
  },

  displayIntro: function () {
    introView.addButton(introView.INFO_TEXT, () => this.moreInfo());
    if (this.task.answers && this.task.answers.length === 2) {
      introView.addButton(introView.REPEAT_TEXT, () => {
        this.task.answers = [];
        this.task.save();
        this.displayTask();
      });
      introView.addButton(introView.RESULTS_TEXT, () => this.displayResults());
    } else {
      introView.addButton(introView.START_TEXT, () => this.displayTask());
    }
    introView.init(this, this.task);
  },

  displayTask: function () {
    gummybearController.updateAvailableGummybears(20);
    if (this.setGummybears === this.maxGummybears) {
      gummybearController.save20();
      gummybearController.updateAvailableGummybears(10);
    }
    interactionView.init(this, this.task);
  },

  displayResults: function () {
    gummybearController.updateAvailableGummybears(
      this.task.answers.length === 2 ? 10 : 20,
    );
    if (this.maxGummybears === 20) {
      this.displayMidIntro();
    } else {
      this.finish();
      resultsView.init(this, this.getAllAnswers(1), this.task.outro);
    }
  },

  displayDetails: function () {
    resultsView.init20Gummybears(this.getAllAnswers(0));
  },

  displayMidIntro: function () {
    introView.clearButtons();
    introView.addButton('weiter', () => {
      gummybearController.save20();
      gummybearController.updateAvailableGummybears(10);
      interactionView.init(this, this.task);
    });
    this.task.intro =
      'Wenn du deine Verteilung abgeschlossen hast, kannst du die Übung noch verschärfen: Im Leben gibt es immer Dinge und Vorfälle, die unsere Ressourcen einschränken – in diesem Fall werden deine Ressourcen halbiert. Du hast nur noch 10 Punkte. Reduziere deine verteilten Punkte um 10. Welche interessanten Verschiebungen in den Prioritäten ergeben sich daraus? Wie hast du deine Entscheidungen getroffen? Hier wird deutlich, was dir wirklich wichtig ist.';
    introView.init(this, this.task);
  },

  updateAvailableGummybears: function (count) {
    this.maxGummybears = count;
    let mode = this.maxGummybears === 20 ? 0 : 1;
    for (let index = 0; index < this.task.inputs.length; index++) {
      if (this.task.inputs[index].type === 4) {
        this.inputindex = index;
        if (this.task.answers[mode]) {
          this.setGummybears = 0;
          this.task.answers[mode].forEach((row) => {
            if (row) {
              this.setGummybears += parseInt(row);
            }
          });
        }
        break;
      }
    }
  },

  isToExpensive: function (number) {
    return number > this.maxGummybears - this.setGummybears;
  },

  saveAnswer: function (row, value) {
    if (this.maxGummybears === 20) {
      if (
        this.task.answers == null ||
        this.task.answers[0] == null ||
        this.task.answers[0] === ''
      ) {
        this.task.answers[0] = [];
      }
      this.task.answers[0][row] = value;
      this.updateAvailableGummybears(this.maxGummybears);
      this.task.save();
    } else if (this.maxGummybears === 10) {
      if (
        this.task.answers == null ||
        this.task.answers[1] == null ||
        this.task.answers[1] === ''
      ) {
        this.task.answers[1] = [];
      }
      this.task.answers[1][row] = value;
      this.updateAvailableGummybears(this.maxGummybears);
      this.task.save();
    }
  },

  save20: function () {
    if (
      this.task.answers == null ||
      this.task.answers[1] == null ||
      this.task.answers[1] === '' ||
      this.task.answers[1].length < 1
    ) {
      this.task.answers[1] = [...this.task.answers[0]];
    }
  },

  finish: function () {
    this.task.finish();
  },

  getAnswer: function (row) {
    if (this.maxGummybears === 20) {
      if (
        this.task.answers == null ||
        this.task.answers[0] == null ||
        this.task.answers[0] === ''
      ) {
        return;
      }
      return this.task.answers[0][row];
    } else if (this.maxGummybears === 10) {
      if (
        this.task.answers == null ||
        this.task.answers[1] == null ||
        this.task.answers[1] === ''
      ) {
        return;
      }
      return this.task.answers[1][row];
    }
  },

  getAllAnswers: function (mode) {
    let res = [];
    for (let row in this.task.inputs[this.inputindex].rows) {
      if (this.task.answers[mode][row] > 0) {
        let title = this.task.inputs[this.inputindex].rows[row];
        //remove brackets
        let indexStart = title.indexOf('(');
        let indexEnd = title.indexOf(')');
        if (indexStart >= 0 && indexEnd >= indexStart) {
          title = title.replace(title.substring(indexStart, indexEnd + 1), '');
        }
        res.push({
          title: title,
          value: this.task.answers[mode][row],
        });
      }
    }
    return res;
  },

  getRandomGummybear: function () {
    let gummybears = ['red', 'lightgreen', 'darkgreen', 'yellow'];
    return gummybears[Math.floor(Math.random() * gummybears.length)];
  },

  nextTopic: function () {
    exerciseController.next(true);
  },

  hasResults: function () {
    if (!this.task) {
      this.task = taskController.getTask(2, 1, 3, 1);
    }
    return (
      this.task.answers &&
      this.task.answers.length &&
      this.task.answers.length > 0 &&
      this.task.answers[0].length &&
      this.task.answers[0].length > 0
    );
  },

  getGraphic: function () {
    if (!this.task) {
      this.task = taskController.getTask(2, 1, 3, 1);
    }
    let mode = this.task.answers[1] && this.task.answers[1].length > 0 ? 1 : 0;
    this.updateAvailableGummybears(20 - mode * 10);
    let results = this.getAllAnswers(mode);

    // top 3
    results.sort((first, second) => second.value - first.value);
    results = results.slice(0, 3);

    resultsView.setController(this);
    return resultsView.getTable(results, mode, true);
  },

  moreInfo: function () {
    app.views.main.router.back(`/ex/2/1/3/0/`, { force: true });
  },
};
export default gummybearController;
