import introView from '../IntroView';
import idearankingController from './idearanking/IdearankingController';
import optionrankingController from './optionranking/OptionrankingController';
import app from '../../../../../app';

var makedecisionsController = {
  exercise: null,

  init: function (exercise) {
    if (exercise) {
      this.exercise = exercise;
    }
    this.displayIntro();
  },

  displayIntro: function () {
    introView.addButton('Ideen-Ranking', () => {
      makedecisionsController.displayIdearanking();
    });
    introView.addButton('Optionen-Ranking', () => {
      makedecisionsController.displayOptionranking();
    });
    introView.updateBackNavigation(() => app.views.main.router.back());
    introView.init(null, {
      name: 'Entscheidungen treffen',
      intro:
        'Du hast bereits viele Berufs- und Studienideen gesammelt und dein Profil ist schon gut gefüllt? Dann ist es für dich an der Zeit, Entscheidungen zu treffen. Mit dem Ideen-Ranking kannst du verschiedene Studiums- und Ausbildungsideen anhand deines Profils bewerten. So kommen deine Ideen in ein erstes Ranking. Sind deine Ideen schon konkreter oder du hast dich schon für eine bestimmte Ausbildungs- oder Studienrichtung entschieden, kannst du mit dem Optionen-Ranking den nächsten Schritt tun. Damit kannst du konkrete Optionen (Ausbildungsunternehmen, Hochschulen) in Bezug auf sinnvolle Auswahlkriterien einschätzen. So kannst du zu deiner Wunschoption kommen bzw. die Optionen sinnvoll einschränken.',
    });
    introView.loadMainFAB();
  },

  displayIdearanking: function () {
    idearankingController.init(makedecisionsController.exercise.tasks[0]);
  },

  displayOptionranking: function () {
    optionrankingController.init(makedecisionsController.exercise.tasks[1]);
  },
};
export default makedecisionsController;
