import { v4 as uuidv4 } from 'uuid';

const globalElementsController = {
  globalElements: new Map(),

  getElement: function (id) {
    return this.globalElements.get(id);
  },

  putElement: function (id, element) {
    return this.globalElements.set(id, element);
  },

  hasElement: function (id) {
    return this.globalElements.has(id);
  },

  addElement: function (element) {
    let id;
    do {
      id = uuidv4();
    } while (!this.globalElements.has(id));
    this.putElement(id, element);
    return id;
  },
};
export default globalElementsController;
