import Module from '../steps/Module.js';
import Notebook from '../notebook/Notebook.js';
import Connector from '../../db/connector.js';
import app from '../../app.js';
import synchronisationController from '../../core/SynchronisationController.js';
import Profile from './Profile.js';

var modulesJson = [];

let firstStart = localStorage.getItem('firstStart');

var userData = {
  initalized: 0,
  prename: '',
  surname: '',
  username: '',
  age: 0,
  gender: '',
  mail: '',
  zipcode: 0,
  nationality: '',
  state: '',
  birthday: '',
  graduationdate: '',
  notebook: Notebook,
  profile: Profile,
  profilePicture: 'https://cdn.framework7.io/placeholder/nature-500x500-4.jpg', //"https://brunswikdesign.de/wp-content/uploads/2014/02/no-user.jpg",
  last_modified: 0,
  last_synced: 0,
  modulesJson: [],
  init: function (app) {
    // remove this after download is implemented
    this.modulesJson[-1] = require('../../download/specialtasks.json');
    this.modulesJson[0] = require('../../download/modules/module1.json');
    this.modulesJson[1] = require('../../download/modules/module2.json');
    this.modulesJson[2] = require('../../download/modules/module3.json');
    this.modulesJson[3] = require('../../download/modules/module4.json');
    this.modulesJson[4] = require('../../download/modules/module5.json');
    this.modulesJson[5] = require('../../download/modules/module6.json');
    this.modulesJson[6] = require('../../download/modules/module7.json');
    this.modulesJson[7] = require('../../download/modules/module8.json');
    this.modulesJson[8] = require('../../download/modules/module9.json');
    this.modulesJson[9] = require('../../download/modules/module10.json');
    //this.loadModules(); Use this later when moduledownload is implemented
  },

  //called first time app is run, to write modules to localStorage and deletes stored modulejson-files from installation
  modulesToLocalStorage: function () {
    for (let i = 0; i < modulesJson.length; i++) {
      localStorage.setItem(
        'module' + i + 'json',
        JSON.stringify(modulesJson[i]),
      );
    }
  },
  getAllModules: function () {
    var modules = [];
    for (var mod in this.modulesJson) {
      if (parseInt(mod) < 0) continue;
      modules.push(new Module(this.modulesJson[mod], mod));
    }
    return modules; // Array with Modules
  },
  getModule: function (moduleNumber) {
    var mod = new Module(this.modulesJson[moduleNumber - 1], moduleNumber);
    return mod;
  },
  getExercise: function (moduleNumber, exerciseNumber) {
    var mod = new Module(this.modulesJson[moduleNumber - 1], moduleNumber);
    return mod.exercises[exerciseNumber];
  },
  getTask: function (moduleNumber, exerciseNumber, taskNumber) {
    var mod = new Module(this.modulesJson[moduleNumber - 1], moduleNumber);
    return mod.exercises[exerciseNumber - 1].tasks[taskNumber - 1];
  },

  getModulesJson: function () {
    return this.modulesJson;
  },

  save: function () {
    let toSave = {};
    for (let [key, value] of Object.entries(this)) {
      if (
        typeof value !== 'function' &&
        key !== 'app' &&
        [
          'last_synced',
          'username',
          'birthday',
          'graduationdate',
          'gender',
          'state',
        ].includes(key)
      ) {
        toSave[key] = value;
      }
    }

    localStorage.setItem('userdata', JSON.stringify(toSave));
  },
  load: function () {
    let toLoad = JSON.parse(localStorage.getItem('userdata'));
    this.last_synced = 0;
    this.username = '';
    this.birthday = '';
    this.graduationdate = '';
    this.gender = '';
    this.state = '';
    if (!toLoad) return;

    for (let [key, value] of Object.entries(toLoad)) {
      if (
        [
          'last_synced',
          'username',
          'birthday',
          'graduationdate',
          'gender',
          'state',
        ].includes(key)
      ) {
        this[key] = value;
      } else {
      }
    }
  },
};

export default userData;
