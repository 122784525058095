export default {
  id: '8c0657628d',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class="page settings-page" data-name=settings><div class=navbar><div class=navbar-inner><div class=left><a class="link back"><i class="icon icon-back"></i></a></div><div class=title>Einstellungen</div></div></div><div class=page-content><div class=block-title>Account</div><a href=#><h2>Mein Konto</h2><i class="icon icon-next"></i> </a><a href=#><h2>Pushbenachrichtigungen</h2><i class="icon icon-next"></i> </a><a href=#><h2>Impressum</h2><i class="icon icon-next"></i> </a><a href=#><h2>Feedback</h2><i class="icon icon-next"></i></a></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};