import $$ from 'dom7';
import app from '../../../app.js';
import dateController from '../../../core/DateController';

let controller;

var keydateView = {
  keydate: null,

  mode: null,

  init: function (cntrllr, keydate, newKeydate) {
    this.mode = null;
    this.keydate = keydate;
    controller = cntrllr;
    this.loadMainToolbar();
    if (newKeydate) {
      this.enableCreateMode();
    } else {
      this.enableDisplayMode();
    }
  },

  loadMainToolbar: function () {
    app.loadMainToolbar($$('.page[data-name="keydate"] .main-toolbar'));
  },

  enableDisplayMode: function () {
    if (this.mode === 'display') {
      return;
    }
    this.loadNormalNavbar();
    this.loadTitle('Mein Termin');
    this.loadKeydate();
    $$('#keydate-page-content .list').addClass('disabled-list');
    let inputs = $$('#keydate-page-content input');
    inputs.on('click', () => {
      controller.showEditMode();
    });
    this.hideUnused();
    this.mode = 'display';
  },

  enableEditMode: function () {
    if (this.mode === 'edit') {
      return;
    }
    this.loadFormNavbar(
      'Termin bearbeiten',
      () => {
        controller.save(keydateView.getValues());
        controller.showDisplayMode();
        // app.views.main.router.back();
      },
      () => {
        controller.showDisplayMode();
        // app.views.main.router.back();
      },
    );
    this.loadTitle('Termin bearbeiten');
    this.unhideAll();
    this.loadKeydate();
    $$('#keydate-page-content .list').removeClass('disabled-list');
    this.mode = 'edit';
  },

  enableCreateMode: function () {
    if (this.mode === 'create') {
      return;
    }
    this.loadFormNavbar(
      'Termin erstellen',
      () => {
        controller.save(keydateView.getValues());
        controller.showDisplayMode();
      },
      () => {
        app.views.main.router.back();
      },
    );
    this.loadTitle('Neuer Termin');
    this.unhideAll();
    this.loadKeydate();
    $$('#keydate-page-content .list').removeClass('disabled-list');
    this.mode = 'create';
  },

  loadNormalNavbar: function () {
    const navbar = $$('.page[data-name="keydate"] .navbar');
    navbar.removeClass('form-navbar');
    navbar.empty();
    navbar.addClass('navbar-transparent no-hairline');
    navbar.append(`
            <div class="navbar-inner">
                <div class="left">
                    <a class="link back">
                        <i class="icon material-icons">navigate_before</i>
                    </a>
                </div>
                <div class="right">
                    <a class="link popover-open" href="#" data-popover="#keydate-popover-menu">
                        <i class="icon material-icons">more_vert</i>
                    </a>
                </div>
            </div>
        `);
    $$('#keydate-popover-delete').on('click', () => {
      app.popover.close('#keydate-popover-menu');
      app.dialog.confirm('Termin wirklich löschen?', function () {
        controller.delete();
        app.views.main.router.back();
      });
    });
    $$('#keydate-popover-export').on('click', () => {
      app.popover.close('#keydate-popover-menu');
      controller.export();
    });
    $$('#keydate-popover-edit').on('click', () => {
      app.popover.close('#keydate-popover-menu');
      controller.showEditMode();
    });
  },

  loadFormNavbar: function (title, onReady, onCancel) {
    const navbar = $$('.page[data-name="keydate"] .navbar');
    navbar.removeClass('navbar no-shadow navbar-transparent no-hairline');
    navbar.empty();
    app.loadFormNavbar(navbar, title, onReady, onCancel);
  },

  loadTitle: function (title) {
    $$('#keydate-title').empty();
    $$('#keydate-title').append(title);
  },

  loadKeydate: function () {
    let inputs = $$('.keydate-input');
    inputs.each((index, input) => {
      input = $$(input);
      let key = input.attr('id').split('-')[2];
      input.val(this.keydate[key]);
      if (input.attr('type') === 'datetime-local' && this.keydate[key]) {
        input.addClass('input-with-value');
      }
    });
  },

  getValues: function () {
    let values = {};
    let inputs = $$('.keydate-input');
    inputs.each((index, input) => {
      input = $$(input);
      let key = input.attr('id').split('-')[2];
      values[key] = input.val();
    });
    return values;
  },

  hideUnused: function () {
    //TODO: für verstecken leerer inputs Kommentare entfernen:
    $$('#keydate-page-content .list').each((index, list) => {
      list = $$(list);
      let empty = true;
      list.find('li').each((index, li) => {
        li = $$(li);
        let input = li.find('input');
        if (input) {
          //                    if (input.val()) {
          empty = false;
          if (input.attr('type') === 'datetime-local') {
            li.addClass('hide');
            let key = input.attr('id').split('-')[2];
            let simpleDate = $$('#keydate-simple-date-' + key);
            let simpleDateLi = simpleDate.closest('li');
            simpleDate.empty();
            simpleDate.append(
              this.keydate[key]
                ? dateController.getStringFromDateTime(
                    new Date(this.keydate[key]),
                  ) + ' Uhr'
                : 'Datum auswählen',
            );
            simpleDateLi.removeClass('hide');
            simpleDateLi.on('click', () => {
              controller.showEditMode();
            });
          }
          //                   } else {
          //                        li.addClass('hide');
          //                    }
        }
      });
      if (empty) {
        //                list.addClass('hide');
      }
    });
  },

  unhideAll: function () {
    $$('.hide').removeClass('hide');
    $$('.keydate-simple-date').addClass('hide');
  },
};

export default keydateView;
