import introView from '../../IntroView';
import app from '../../../../../../app';
import exerciseController from '../../../ExerciseController';
import profileController from '../../../../../profile/ProfileController';
import newView from './NewView';
import rateView from './RateView';
import resultsView from './ResultsView';
import taskController from '../../TaskController';

var coursecatalogController = {
  task: null,

  idea: null,
  ideaIndex: null,

  moduleNumber: null,
  chapterNumber: null,
  exerciseNumber: null,

  init: function (task, moduleNumber, chapterNumber, exerciseNumber) {
    this.moduleNumber = moduleNumber;
    this.chapterNumber = chapterNumber;
    this.exerciseNumber = exerciseNumber;
    this.task = task;
    this._deleteOptionlessRatings();
    this.displayIntro();
  },

  displayIntro: function () {
    this.task.intro =
      'Bewerte die einzelnen Veranstaltung aus dem ersten Semester eines Studiengangs und teste, ob dieser Studiengang etwas für dich ist. Wähle dazu eine konkrete Idee aus, die du bereits in deinen Ideen eingetragen hast oder lege eine neue Idee an.';
    if (profileController.optionsPresent()) {
      introView.addButton('Idee auswählen', function () {
        coursecatalogController.displayChooseIdea();
      });
    } else {
      introView.addButton('noch keine Ideen erstellt', function () {
        //do nothing
      });
    }
    introView.init(this, this.task);
  },

  displayChooseIdea: function () {
    app.views.main.router.navigate('/profile/');
    profileController.selectOption((optionId) => {
      coursecatalogController._navigateBackToThisPage();
      setTimeout(() => {
        coursecatalogController.displaySecondIntro();
      }, 0);
      this.idea = optionId;
      if (!this.task.answers) {
        this.task.answers = [];
      }
      let found = null;
      this.task.answers.forEach((ideaAnswer, index) => {
        if (ideaAnswer.idea === this.idea) {
          found = index;
        }
      });
      if (found == null) {
        found = this.task.answers.push({ idea: this.idea, rates: [] }) - 1;
      }
      this.ideaIndex = found;
      this.task.save();
    });
  },

  displaySecondIntro: function () {
    this.task.intro =
      'Suche die Veranstaltungen heraus, die du in dem jeweiligen Studiengang in den ersten Semestern belegen kannst (bzw. musst). Trage Titel und Stichpunkte zu den Inhalten in die Tabelle ein und nehme deine Einschätzung vor. Schaue am Ende im Überblick, wie sehr du glaubst dich für die Inhalte des Faches, die Veranstaltungen (Vorlesungen, Seminar etc.) begeistern zu können.';
    introView.addButton('Module eingeben', function () {
      coursecatalogController.displayTask(this.task);
    });
    introView.init(this, this.task);
  },

  displayTask: function (task) {
    if (task) {
      this.task = task;
    }
    this.displayNewLecture();
  },

  displayNewLecture: function () {
    newView.init(
      this,
      this.task,
      this.task.answers[this.ideaIndex].rates.length > 0,
    );
  },

  displayRateLecuture: function (title, note) {
    rateView.init(this, this.task, title, note);
  },

  displayResults: function () {
    resultsView.init(this, this.evaluateResults(this.ideaIndex));
  },

  evaluateResults: function (ideaIndex) {
    let results = [0, 0, 0, 0, 0];
    let sum = 0.0;
    let count = 0.0;
    this.task.answers[ideaIndex].rates.forEach((rate) => {
      if (rate !== 0) {
        results[5 - rate]++;
        sum += 6 - rate;
        count++;
      }
    });
    return {
      results: results,
      avg: Math.round((sum / count) * 10) / 10.0,
    };
  },

  saveRateLecuture: function (rate) {
    this.task.answers[this.ideaIndex].rates.push(rate);
    this.task.save();
    this.displayNewLecture();
  },

  finish: function () {
    this.task.finish();
  },

  nextExercise: function () {
    exerciseController.next(true);
  },

  moreInfo: function () {
    app.views.main.router.back(
      `/ex/${this.moduleNumber}/${this.chapterNumber}/${this.exerciseNumber}/0/`,
      { force: true },
    );
  },

  hasResults: function () {
    if (!this.task) {
      this.task = taskController.getTask(6, 1, 15, 1);
      this._deleteOptionlessRatings();
    }
    return (
      this.task.answers &&
      this.task.answers.length &&
      this.task.answers.length > 0
    );
  },

  getGraphic: function () {
    if (!this.task) {
      this.task = taskController.getTask(6, 1, 15, 1);
    }

    let options = profileController.getOptionsAndIdeas().options;
    let ideaAvgs = [];
    let top5 = [];
    for (
      let answerIndex = 0;
      answerIndex < this.task.answers.length;
      answerIndex++
    ) {
      let value = 5 - this.evaluateResults(answerIndex).avg;
      let option = options.filter(
        (option) => option.id === this.task.answers[answerIndex].idea,
      );
      ideaAvgs.push({
        title: option.length > 0 ? option[0].getName() : 'gelöscht',
        value: value,
      });
      top5.push(value);
    }

    // filter top 5
    if (ideaAvgs.length > 5) {
      top5.sort();
      let max = top5[top5.length - 5];
      ideaAvgs = ideaAvgs.filter((idea) => idea.value >= max).slice(0, 5);
    }

    let results = {
      titles: [],
      values: [],
    };
    for (let idea of ideaAvgs) {
      results.titles.push(idea.title);
      results.values.push(idea.value);
    }

    return resultsView.getGraphic(results, true);
  },

  _navigateBackToThisPage: function () {
    app.views.main.router.back(
      `/task/${this.moduleNumber}/${this.chapterNumber}/${this.exerciseNumber}/${this.task.number}/`,
      { force: true },
    );
  },

  _deleteOptionlessRatings: function () {
    if (this.task.answers && this.task.answers.length) {
      let options = profileController.getOptionsAndIdeas().options;
      this.task.answers = this.task.answers.filter(
        (answer) =>
          options.filter((option) => option.id === answer.idea).length > 0,
      );
    }
  },
};
export default coursecatalogController;
