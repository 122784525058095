import $$ from 'dom7';
import app from '../../app';
import dateController from '../../core/DateController';

let controller;

var notebookView = {
  initialized: false,

  goals: null,
  keydates: null,
  notes: null,

  init: function (cntrllr, goals, keydates, notes) {
    controller = cntrllr;
    this.goals = goals;
    this.keydates = keydates;
    this.notes = notes;
    this.loadMainToolbar();
    this.loadMainFAB();
    this.initLinks();
    this.loadGoals();
    this.loadKeydates();
    this.loadNotes();
    this.initialized = true;
  },

  onLeave: function () {
    this.initialized = false;
  },

  loadMainToolbar: function () {
    app.loadMainToolbar(
      $$('.page[data-name="notebook"] .main-toolbar'),
      'notebook',
    );
  },

  loadMainFAB: function () {
    app.loadMainFAB($$('.page[data-name="notebook"] .main-fab'));
  },

  initLinks: function () {
    $$('#notebook-tab-goals .toolbar-inner a').on('click', () =>
      controller.refresh('goals'),
    );
    $$('#notebook-tab-keydates .toolbar-inner a').on('click', () =>
      controller.refresh('keydates'),
    );
    $$('#notebook-tab-notes .toolbar-inner a').on('click', () =>
      controller.refresh('notes'),
    );
  },

  loadGoals: function (goals) {
    if (goals != null) {
      this.goals = goals;
    }
    this.load(
      'goals',
      `<i class="icon svg-icon-check-circle-gray"></i>`,
      (goal) => goal.getName(),
      (goal) => {
        let achievedCount = goal.actionoriented
          .map((x) => x.achieved)
          .filter((x) => x === true).length;
        return `${achievedCount}/${goal.actionoriented.length} Aufgaben erledigt`;
      },
      (goal) => goal.step,
      (goal) => `/goal/${goal._id}/0/`,
      (goals, filter) => goals.filter(filter.is),
    );
  },

  loadKeydates: function (keydates) {
    if (keydates != null) {
      this.keydates = keydates;
    }
    this.load(
      'keydates',
      `<i class="icon material-icons">date_range</i>`,
      (keydate) => keydate.title,
      (keydate) =>
        keydate.start &&
        Date.now() < dateController.getDateFromString(keydate.start)
          ? `Fällig am ${dateController.getStringFromDate(keydate.start)}`
          : keydate.start
          ? 'Vergangenheit'
          : 'keine Zeitangabe',
      (keydate) => keydate.step,
      (keydate) => `/keydate/${keydate._id}/0/`,
      (keydates, filter) => keydates.filter(filter.is),
    );
  },

  loadNotes: function (notes) {
    if (notes != null) {
      this.notes = notes;
    }
    this.load(
      'notes',
      `<i class="icon svg-icon-note-outline"></i>`,
      (note) => note.title,
      (note) =>
        `Zuletzt geändert am ${dateController.getStringFromDate(
          note.last_modified,
        )}`,
      (note) => note.step,
      (note) => `/noteeditor/${note._id}/0/`,
      (notes, filter) => notes.sort(filter.sort),
    );
  },

  load: function (
    target,
    iconElement,
    getTitle,
    getSubtitle,
    getStep,
    getLink,
    map,
  ) {
    const lists = $$('#notebook-tab-' + target + ' .notebook-representer-list');
    if (notebookView[target] != null && notebookView[target].length > 0) {
      lists.each((index, container) => {
        container = $$(container);
        let list = container.find('.notebook-representer-elements');
        list.empty();
        const filter = controller.getFilter(
          target,
          container.attr('id').split('-')[2],
        );
        let elements = map(notebookView[target], filter);
        if (elements.length <= 0) {
          container.find('.placeholder-container').removeClass('hide');
        } else {
          container.find('.placeholder-container').addClass('hide');
          elements.forEach((element) => {
            list.append(
              `
                                <div class="card card-line card-representer list">
                                    <div class="card-content">
                                        <a href="${getLink(
                                          element,
                                        )}" class="item-content item-link">
                                            <div class="item-inner">
                                                ${
                                                  (getStep(element)
                                                    ? 'Schritt ' +
                                                      getStep(element) +
                                                      ' - '
                                                    : '') + getTitle(element)
                                                }
                                                <div class="item-subtitle">
                                                    ${iconElement}
                                                    <span>${getSubtitle(
                                                      element,
                                                    )}</span>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            `,
            );
          });
        }
      });
    } else {
      lists.find('.notebook-representer-elements').empty();
      lists.find('.placeholder-container').removeClass('hide');
    }
  },
};
export default notebookView;
