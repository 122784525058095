import $$ from 'dom7';
import elementFactory from '../../../../../../core/ui/ElementFactory';
import taskView from '../../TaskView';

let controller;

var addtestresultsView = {
  init: function (cntrllr) {
    controller = cntrllr;
  },

  loadRating: function (topics, title, progress, last) {
    // calc for element factory
    let dto = [];
    for (let [index, topic] of topics.entries()) {
      dto.push({
        title: topic.title,
        slider: [topic.value, (value) => controller.save(index, value)],
      });
    }

    // load content
    $$('#task-page-content')[0].scrollTop = 0;
    $$('#task-content')
      .empty()
      .append(
        elementFactory.getSliderCard(dto).attr('id', 'addtestresults-card'),
      );

    taskView.loadTitle(title);
    this.loadButton(last ? 'Fertig' : 'Weiter');

    // load progress
    this.removeProgress();
    if (progress != null) {
      $$('#task-page-content .page-content-top').prepend(
        elementFactory
          .getProgressbar(progress)
          .attr('id', 'addtestresults-progress')
          .addClass('ci-progressbar-top'),
      );
    }
  },

  removeProgress: function () {
    $$('#addtestresults-progress').remove();
  },

  loadButton: function (title) {
    $$('#task-bottom > .button-list')
      .empty()
      .append(
        $$(document.createElement('button'))
          .addClass('button button-fill color-white')
          .append(title)
          .on('click', () => {
            controller.next();
          }),
      );
  },
};
export default addtestresultsView;
