import app from '../../app';
import view from './ProfileView.js';
import userController from '../../core/UserController';
import Filter from '../../core/Filter';
import $$ from 'dom7';
import idearankingController from '../steps/exercise/task/types/idearanking/IdearankingController';
import optionrankingController from '../steps/exercise/task/types/optionranking/OptionrankingController';
import checkQuestionsController from '../steps/exercise/task/types/checkquestions/CheckquestionsController';
import educationPathController from '../steps/exercise/task/types/educationpath/EducationpathController';
import coursecatalogController from '../steps/exercise/task/types/coursecatalog/CoursecatalogController';
import jobratingController from '../steps/exercise/task/types/jobrating/JobratingController';
import generateideasController from '../steps/exercise/task/types/GenerateideasController';
import makedecisionsController from '../steps/exercise/task/types/MakedecisionsController';
import pdfController from './PdfController';
import f360resultController from './f360result/f360resultController';

let ideasAndOptionsfilters = {
  date: {
    name: 'Datum',
    sort: function (first, second) {
      return first.last_modified - second.last_modified;
    },
  },
  alphabet: {
    name: 'Alphabet',
    sort: function (first, second) {
      // options
      if (first.name === second.name && first.location && second.location) {
        if (first.location < second.location) {
          return -1;
        }
        if (first.location > second.location) {
          return 1;
        }
      }
      if (first.name < second.name) {
        return -1;
      }
      if (first.name > second.name) {
        return 1;
      }
      return 0;
    },
  },
};

let getIdeaRanking;
let getOptionRanking;

const profileController = {
  ideasFilter: null,

  optionsFilter: null,

  // determines whether totally empty sections in profile shall be displayed
  showEmptyProfileSections: true,

  load: function () {
    //TODO: remove test
    //userController.getUserData('profile').setProfileDataTestModeEnabled();

    getIdeaRanking = idearankingController.createRankingGetter();
    getOptionRanking = optionrankingController.createRankingGetter();

    userController.getUserData('profile').evaluate360responses();
    userController.getUserData('profile').getProfileData();
    let eduIdeas = this.getOptionsAndIdeas();

    // ideas filter
    let ideaFilters = {};
    Object.assign(ideaFilters, ideasAndOptionsfilters);
    // if there are ranked ideas
    if (getIdeaRanking) {
      // add ranking filter to ideas
      ideaFilters['ranking'] = {
        name: 'Ranking',
        sort: function (first, second) {
          return (
            getIdeaRanking(second.id, second.isStudies) -
            getIdeaRanking(first.id, first.isStudies)
          );
        },
      };
    }
    this.ideasFilter = new Filter(
      {
        refreshFilter: function () {
          if (view.initialized) {
            profileController.refresh('ideas');
          }
        },
      },
      ideaFilters,
    );
    this.ideasFilter.apply('date');

    //options filter
    let optionFilters = {};
    Object.assign(optionFilters, ideasAndOptionsfilters);
    // if there are ranked options
    if (getOptionRanking) {
      // add ranking filter to options
      optionFilters['ranking'] = {
        name: 'Ranking',
        sort: function (first, second) {
          return (
            getOptionRanking(second.id, second.isStudies) -
            getOptionRanking(first.id, first.isStudies)
          );
        },
      };
    }
    this.optionsFilter = new Filter(
      {
        refreshFilter: function () {
          if (view.initialized) {
            profileController.refresh('options');
          }
        },
      },
      optionFilters,
    );
    this.optionsFilter.apply('date');
    view.init(
      this,
      userController.getUserData('prename')
        ? userController.getUserData('prename')
        : 'du',
      eduIdeas.ideas,
      eduIdeas.options,
      eduIdeas.oldIdeas,
    );

    this.expertise.load();
    this.interests.load();
    this.career.load();
    this.personality.load();
    this.loadResults();
  },

  refresh: function (target) {
    if (view.initialized) {
      let eduIdeas = this.getOptionsAndIdeas();
      if (target == null) {
        view.loadIdeas(eduIdeas.ideas);
        view.loadOptions(eduIdeas.options);
      } else if (target === 'ideas') {
        view.loadIdeas(eduIdeas.ideas);
      } else if (target === 'options') {
        view.loadOptions(eduIdeas.options);
      }
    } else {
      this.load();
    }
  },

  getOptionsAndIdeas: function () {
    return this._calcOptionsAndIdeas(
      userController.getUserData('profile').eduIdeas,
      userController.getUserData('profile').eduOptions,
      userController.getUserData('profile').oldIdeas,
    );
  },

  _calcOptionsAndIdeas: function (eduIdeas, eduOptions, eduOldIdeas) {
    let ideas = [];
    for (let key in eduIdeas) {
      if (
        eduIdeas.hasOwnProperty(key) &&
        eduIdeas[key] != null &&
        Object.keys(eduIdeas[key]).length > 0
      ) {
        ideas.push(eduIdeas[key]);
      }
    }
    let oldIdeas = [];
    for (let key in eduOldIdeas) {
      if (
        eduOldIdeas.hasOwnProperty(key) &&
        eduOldIdeas[key] != null &&
        Object.keys(eduOldIdeas[key]).length > 0
      ) {
        eduOldIdeas[key].name =
          eduOldIdeas[key].name.split(
            '(Veraltete Version. Bitte Idee neu erstellen)',
          )[0] + '(Veraltete Version. Bitte Idee neu erstellen)';
        oldIdeas.push(eduOldIdeas[key]);
      }
    }
    return {
      ideas: ideas,
      options: eduOptions.filter(
        (option) => option != null && Object.keys(option).length > 0,
      ),
      oldIdeas: oldIdeas,
    };
  },

  expertise: {
    entries: null,

    ordered: [
      'skillAreas',
      'bestsubjects',
      'professionalexpertise',
      'selfexpertise',
      'socialexpertise',
      'methodexpertise',
    ],

    view: null,

    _hasEntries: function () {
      let entries = false;
      for (let key in this.entries) {
        if (this.entries.hasOwnProperty(key)) {
          if (this.entries[key]) {
            entries = true;
          }
        }
      }
      return entries;
    },

    _init: function () {
      let expertise = userController.getUserData('profile').data.expertise;
      let checkU = userController
        .getUserData('profile')
        .getProfileExpertiseCheckU();

      this.entries = {
        strength: null,
        weaknesses: null,
        skillAreas: null,
        bestsubjects: null,
        professionalexpertise: null,
        selfexpertise: null,
        socialexpertise: null,
        methodexpertise: null,
      };

      if (
        !userController
          .getUserData('profile')
          .getCheckUMergeToExpertiseModeEnabled() &&
        checkU.skills.content.length > 0 &&
        checkU.social.content.length > 0
      ) {
        this.entries.checkU = {
          key: 'checkU',
          title: 'Testergebnisse Check-U Verfahren',
          icon: '<i class="icon material-icons">add</i>',
          ordered: true,
          content: true,
          self: checkU.skills.content
            .filter((obj) => obj)
            .slice(0, 3)
            .map((obj) => (typeof obj === 'object' ? obj.name : obj)),
          external: checkU.social.content
            .filter((obj) => obj)
            .slice(0, 3)
            .map((obj) => (typeof obj === 'object' ? obj.name : obj)),
          selfTitle: checkU.skills.title,
          externalTitle: checkU.social.title,
          link: '/task/0/2/2/1/',
        };
      }

      for (let key in this.entries) {
        if (this.entries.hasOwnProperty(key) && expertise.hasOwnProperty(key)) {
          // dto
          let entries = {};
          entries.key = key;
          entries.title = expertise[key].subtitle;
          entries.ordered = this.ordered.includes(key);
          switch (key) {
            case 'strength':
              entries.icon =
                '<i class="icon material-icons">fitness_center</i>';
              break;
            case 'weaknesses':
              entries.icon =
                '<i class="icon svg-icon-do-not-disturb-on-outline"></i>';
              break;
            case 'skillAreas':
              entries.icon =
                '<i class="icon svg-icon-emoji-objects-outline"></i>';
              break;
            case 'bestsubjects':
              entries.icon = '<i class="icon svg-icon-auto-stories"></i>';
              break;
            case 'professionalexpertise':
              entries.icon = '<i class="icon svg-icon-psychology-outline"></i>';
              break;
            case 'selfexpertise':
              entries.icon = '<i class="icon svg-icon-people-outline"></i>';
              break;
            case 'socialexpertise':
              entries.icon =
                '<i class="icon material-icons">person_outline</i><i class="icon svg-icon-sync-alt"></i><i class="icon material-icons">person_outline</i>';
              break;
            case 'methodexpertise':
              entries.icon =
                '<i class="icon material-icons">format_list_numbered</i>';
              break;
            default:
              entries.icon = '';
              break;
          }

          // save in this.entries if not empty
          entries.content = false;
          if (
            expertise[key].entries &&
            expertise[key].entries.length &&
            expertise[key].entries.length >= 2
          ) {
            entries.self = [];
            entries.external = [];
            if (!entries.icon) entries.icon = '';
            // self assessment
            if (
              expertise[key].entries[0] &&
              expertise[key].entries[0].length &&
              expertise[key].entries[0].length > 0
            ) {
              entries.content = true;
              entries.self = expertise[key].entries[0]
                .filter((obj) => obj)
                .slice(0, 3)
                .map((obj) => (typeof obj === 'object' ? obj.name : obj));
            }
            // external assessment
            if (
              expertise[key].entries[1] &&
              expertise[key].entries[1].length &&
              expertise[key].entries[1].length > 0
            ) {
              entries.content = true;
              entries.external = expertise[key].entries[1]
                .filter((obj) => obj)
                .slice(0, 3)
                .map((obj) => (typeof obj === 'object' ? obj.name : obj));
            }
          }
          if (entries.content || profileController.showEmptyProfileSections) {
            this.entries[key] = entries;
          }
        }
      }

      this.view = view.expertise;
      this.view.init(this);
    },

    load: function () {
      this._init();
      if (profileController.showEmptyProfileSections || this._hasEntries()) {
        this.view.load();

        if (
          !this.entries.checkU ||
          userController.getUserData('profile').getProfileExpertiseCheckU()
            .mode !== 'complete'
        ) {
          this.view.loadTestCard();
        }
      }
    },

    getElement: function (key) {
      this._init();
      if (Object.keys(this.entries).includes(key)) {
        return this.view.getCard(key);
      }
      switch (key) {
        case 'all':
        default:
          return this.view.getContent();
      }
    },

    getTaskNavigator: function (target) {
      return () =>
        profileController.navigateToExercise(
          userController.getUserData('profile').data.expertise[target],
        );
    },
  },

  interests: {
    entries: {
      hitlist: null,
      shitlist: null,
      riasec: null,
    },

    view: null,

    _hasEntries: function () {
      let entries = false;
      for (let key in this.entries) {
        if (this.entries.hasOwnProperty(key)) {
          if (this.entries[key]) {
            entries = true;
          }
        }
      }
      return entries;
    },

    _init: function () {
      let interests = userController.getUserData('profile').data.interests;

      for (let key in this.entries) {
        if (this.entries.hasOwnProperty(key) && interests.hasOwnProperty(key)) {
          // save in this.entries if not empty
          switch (key) {
            case 'riasec':
              if (
                interests[key].entries &&
                interests[key].entries.length &&
                interests[key].entries.length > 0
              ) {
                if (interests[key].entries[0].length > 0) {
                  let empty = true;
                  for (let entry of interests[key].entries[0]) {
                    if (entry) {
                      empty = false;
                    }
                  }
                  if (!empty) {
                    if (!this.entries[key]) {
                      this.entries[key] = {};
                    }
                    this.entries[key].internal = interests[key].entries[0];
                  }
                }

                if (interests[key].entries[1].length > 0) {
                  let empty = true;
                  for (let entry of interests[key].entries[1]) {
                    if (entry) {
                      empty = false;
                    }
                  }
                  if (!empty) {
                    if (!this.entries[key]) {
                      this.entries[key] = {};
                    }
                    this.entries[key].external = interests[key].entries[1];
                  }
                }
              }
              break;
            default:
              if (
                interests[key].entries &&
                interests[key].entries.length &&
                interests[key].entries.length > 0
              ) {
                let empty = true;
                for (let entry of interests[key].entries) {
                  if (entry) {
                    empty = false;
                  }
                }
                if (!empty) {
                  this.entries[key] = interests[key].entries;
                }
              }
              break;
          }
        }
      }

      this.view = view.interests;
      this.view.init(this);
    },

    load: function () {
      this._init();
      if (profileController.showEmptyProfileSections || this._hasEntries()) {
        this.view.load();

        if (!this.entries.riasec || !this.entries.riasec.external) {
          this.view.loadTestCard();
        }
      }
    },

    getElement: function (key) {
      this._init();
      switch (key) {
        case 'hitlist':
          return this.view.getHitlist();
        case 'shitlist':
          return this.view.getShitlist();
        case 'hitlist&shitlist':
        case 'shitlist&hitlist':
          return this.view.getHitlistAndShitlist();
        case 'riasec':
          return this.view.getRiasec();
        case 'all':
        default:
          return this.view.getContent();
      }
    },

    getTaskNavigator: function (target) {
      if (target.includes(' ')) {
        // hit- and shitlist
        target = target.split(' ')[0];
      }
      return () =>
        profileController.navigateToExercise(
          userController.getUserData('profile').data.interests[target],
        );
    },
  },

  career: {
    entries: {
      mustHave: null,
      nogo: null,
      jobvalues: null,
      salary: null,
      quotes: null,
    },

    view: null,

    _hasEntries: function () {
      let entries = false;
      for (let key in this.entries) {
        if (this.entries.hasOwnProperty(key)) {
          if (key === 'quotes') {
            if (
              this.entries[key] &&
              (this.entries[key][0] || this.entries[key][1])
            ) {
              entries = true;
            }
          } else if (this.entries[key]) {
            entries = true;
          }
        }
      }
      return entries;
    },

    _init: function () {
      let career = userController.getUserData('profile').data.career;

      for (let key in this.entries) {
        if (
          this.entries.hasOwnProperty(key) &&
          (career.hasOwnProperty(key) || key === 'quotes')
        ) {
          // save in this.entries if not empty
          switch (key) {
            case 'quotes':
              let quotes = [];
              let quoteLinks = ['selfrealization', 'salarygoal'];
              for (let quoteKey of quoteLinks) {
                if (career[quoteKey] && career[quoteKey].entries) {
                  // not empty
                  quotes.push({
                    entry: career[quoteKey].entries,
                    key: quoteKey,
                  });
                } else if (profileController.showEmptyProfileSections) {
                  // empty
                  quotes.push({ entry: false, key: quoteKey });
                }
              }

              if (quotes.length) {
                this.entries[key] = quotes;
              }
              break;
            default:
              if (
                career[key].entries &&
                career[key].entries.length &&
                career[key].entries.length > 0
              ) {
                let empty = true;
                for (let entry of career[key].entries) {
                  if (entry) {
                    empty = false;
                  }
                }
                if (!empty) {
                  this.entries[key] = career[key].entries;
                }
              }
              break;
          }
        }
      }

      this.view = view.career;
      this.view.init(this);
    },

    load: function () {
      this._init();
      if (profileController.showEmptyProfileSections || this._hasEntries()) {
        this.view.load();
      }
    },

    getElement: function (key) {
      this._init();
      switch (key) {
        case 'mustHave':
          return this.view.getMustHave();
        case 'nogo':
          return this.view.getNogo();
        case 'mustHave&nogo':
        case 'nogo&mustHave':
          return this.view.getMustHaveAndNogo();
        case 'jobvalues':
          return this.view.getJobvalues();
        case 'salary':
          return this.view.getSalary();
        case 'jobvalues&salary':
        case 'salary&jobvalues':
          return this.view.getJobvaluesAndSalary();
        case 'selfrealization&salarygoal':
        case 'salarygoal&selfrealization':
          return this.view.getQuotes();
        case 'selfrealization':
          return this.view.getQuote(0);
        case 'salarygoal':
          return this.view.getQuote(1);
        case 'all':
        default:
          return this.view.getContent();
      }
    },

    getTaskNavigator: function (target) {
      if (target.includes(' ')) {
        // nogo and musthave
        target = target.split(' ')[0];
      }
      return () =>
        profileController.navigateToExercise(
          userController.getUserData('profile').data.career[target],
        );
    },
  },

  personality: {
    entries: {
      values: null,
      motives: null,
      personality: null,
      systemtype: null,
      priorities: null,
    },

    view: null,

    _hasEntries: function () {
      let entries = false;
      for (let key in this.entries) {
        if (this.entries.hasOwnProperty(key)) {
          if (
            this.entries[key] &&
            (typeof this.entries[key] !== 'object' ||
              Object.keys(this.entries[key]).length > 0)
          ) {
            entries = true;
          }
        }
      }
      return entries;
    },

    _init: function () {
      let personality = userController.getUserData('profile').data.personality;
      for (let key in this.entries) {
        if (
          this.entries.hasOwnProperty(key) &&
          personality.hasOwnProperty(key)
        ) {
          // save in this.entries if not empty
          switch (key) {
            case 'personality':
              for (let entryKey in personality[key].entries[0]) {
                if (
                  personality[key].entries[0].hasOwnProperty(entryKey) &&
                  personality[key].entries[0][entryKey] > 0
                ) {
                  if (!this.entries[key]) {
                    this.entries[key] = {};
                  }
                  this.entries[key].internal = personality[key].entries[0];
                  break;
                }
              }

              for (let entryKey in personality[key].entries[1]) {
                if (
                  personality[key].entries[1].hasOwnProperty(entryKey) &&
                  personality[key].entries[1][entryKey] > 0
                ) {
                  if (!this.entries[key]) {
                    this.entries[key] = {};
                  }
                  this.entries[key].external = personality[key].entries[1];
                  break;
                }
              }
              break;
            case 'systemtype':
              if (personality[key].entries) {
                this.entries[key] = personality[key].entries;
              }
              break;
            case 'priorities':
              // empty
              this.entries[key] = false;

              // not empty
              if (Object.keys(personality[key].entries).length > 0) {
                this.entries[key] = personality[key].entries;
              }
              break;
            default:
              if (
                personality[key].entries &&
                personality[key].entries.length &&
                personality[key].entries.length > 0
              ) {
                let empty = true;
                for (let entry of personality[key].entries) {
                  if (entry) {
                    empty = false;
                  }
                }
                if (!empty) {
                  this.entries[key] = personality[key].entries;
                }
              }
              break;
          }
        }
      }

      this.view = view.personality;
      this.view.init(this);
    },

    load: function () {
      this._init();
      if (profileController.showEmptyProfileSections || this._hasEntries()) {
        this.view.load();

        if (!this.entries.personality || !this.entries.personality.external) {
          this.view.loadTestCard();
        }
      }
    },

    getElement: function (key) {
      this._init();
      switch (key) {
        case 'personality':
          return this.view.getPersonality();
        case 'values':
          return this.view.getValues();
        case 'priorities':
          return this.view.getPriorities();
        case 'values&priorities':
        case 'priorities&values':
          return this.view.getValuesAndPriorities();
        case 'motives':
          return this.view.getMotives();
        case 'systemtype':
          return this.view.getSystemtype();
        case 'motives&systemtype':
        case 'systemtype&motives':
          return this.view.getMotivesAndSystemtype();
        case 'all':
        default:
          return this.view.getContent();
      }
    },

    getTaskNavigator: function (target) {
      return () =>
        profileController.navigateToExercise(
          userController.getUserData('profile').data.personality[target],
        );
    },
  },

  loadResults: function () {
    // empty
    view.loadResults();

    // feedback
    let loadFeedback = function () {
      view.loadResults('feedback'); // empty
      let feedback = userController.getUserData('feedback360responses');
      if (feedback) {
        for (let index = 0; index < feedback.length; index++) {
          view.loadResults(
            'feedback',
            view.getFeedbackResultsCard(
              index,
              f360resultController.getNameFromFeedback(feedback[index]),
            ),
          );
        }
      }
    };
    // reload after sync
    userController.addListener(
      '360sync',
      () => {
        if (view.initialized) loadFeedback();
      },
      'profileController',
    );
    loadFeedback();

    // tasks
    let tasks = [
      'educationpath',
      'checkquestions',
      'jobrating',
      'coursecatalog',
    ];
    for (let task of tasks) {
      let toLoad;
      switch (task) {
        default:
          break;
        case 'checkquestions': // Richtungs-Check
          toLoad = checkQuestionsController.hasResults()
            ? checkQuestionsController.getGraphic()
            : view.getEmptyResultPlaceholderCard(() => {
                this.navigateToTask(0, 1, 1, 1);
                setTimeout(generateideasController.displayCheckquestions);
              });
          break;
        case 'jobrating': // Berufe-Rating
          toLoad = jobratingController.hasResults()
            ? jobratingController.getGraphic()
            : view.getEmptyResultPlaceholderCard(() => {
                this.navigateToTask(0, 1, 1, 2);
                setTimeout(generateideasController.displayJobrating);
              });
          break;
        case 'educationpath': // Kurzcheck Uni/FH
          toLoad = educationPathController.hasResults()
            ? educationPathController.getGraphic()
            : view.getEmptyResultPlaceholderCard(() =>
                this.navigateToTask(6, 1, 8, 1),
              );
          break;
        case 'coursecatalog': // Vorlesungsverzeichnis
          toLoad = coursecatalogController.hasResults()
            ? coursecatalogController.getGraphic()
            : view.getEmptyResultPlaceholderCard(() =>
                this.navigateToTask(6, 1, 15, 1),
              );
          break;
      }
      view.loadResults('tasks', toLoad);
    }

    // rankings
    if (idearankingController.hasResults()) {
      view.loadResults('rankings', idearankingController.getGraphic());
    } else {
      view.loadResults(
        'rankings',
        view.getEmptyResultPlaceholderCard(() => {
          this.navigateToTask(0, 1, 2, 1);
          setTimeout(makedecisionsController.displayIdearanking);
        }),
      );
    }
    if (optionrankingController.hasResults()) {
      view.loadResults('rankings', optionrankingController.getGraphic());
    } else {
      view.loadResults(
        'rankings',
        view.getEmptyResultPlaceholderCard(() => {
          this.navigateToTask(0, 1, 2, 2);
          setTimeout(makedecisionsController.displayOptionranking);
        }),
      );
    }
  },

  /**
   *  <p>Returns specific profile elements.</p>
   *
   *  <p>Returns a Dom7 element containing one or more profile elements identified by a given key.</p>
   *
   *  <p>
   *      The elements can be returned in three different ways:
   *      <ul>
   *          <li>
   *              <code>$superkey</code> returns a whole section. The <code>$superkey</code> can be any section of the profile, e.g.
   *              "expertise"
   *          </li>
   *          <li>
   *              <code>$superkey.$subkey</code> returns single elements from a section. <code>$superkey</code>  specifies the
   *              section and <code>$subkey</code> the element by the parameter name of <code>profile.data</code>, e.g. "career.mustHave"
   *          </li>
   *          <li>
   *              <code>$superkey.$subkey1&$subkey1</code> returns multiple elements from a section, that are combined. <code>$superkey</code>  specifies the
   *              section and <code>$subkey1</code> the first element and <code>$subkey2</code> the second element, e.g. "career.mustHave&nogo".<br>
   *              The order of the subkey does not matter, but this way only works for the elements shown together in profile.
   *          </li>
   *      </ul>
   *  </p>
   *
   * @param key the key to identify profile element
   * @returns {Dom7Instance, string} the profile element
   */
  getProfileElement: function (key) {
    let keys = key.split('.');
    let superKey = keys[0];
    key = keys.length > 1 ? keys[1] : '';

    switch (superKey) {
      case 'personality':
        return this.personality.getElement(key);
      case 'career':
        return this.career.getElement(key);
      case 'interests':
        return this.interests.getElement(key);
      case 'expertise':
        return this.expertise.getElement(key);
      default:
        return '';
    }
  },

  getName: function (key, only) {
    switch (key) {
      case 'expertise':
        return (only ? '' : 'Meine ') + 'Kompetenzen';
      case 'personality':
        return (only ? '' : 'Meine ') + 'Persönlichkeit';
      case 'interests':
        return (only ? '' : 'Meine ') + 'Interessen';
      case 'career':
        return (only ? '' : 'Meine ') + 'beruflichen Vorstellungen';
      default:
        return undefined;
    }
  },

  navigateToExercise: function (profile) {
    this._navigateTo(
      '/ex/' +
        profile.module +
        '/' +
        profile.chapter +
        '/' +
        profile.exercise +
        '/0/',
    );
  },

  navigateToTask: function (module, chapter, exercise, task) {
    this._navigateTo(
      '/task/' + module + '/' + chapter + '/' + exercise + '/' + task + '/',
    );
  },

  _navigateTo(path) {
    app.views.main.router.navigate(path);
    app.views.main.router.navigate('/', { reloadPrevious: true });
    app.views.main.router.history = ['/', path];
  },

  getFilter: function (target, full) {
    if (!full && target === 'ideas') {
      return this.ideasFilter.current;
    } else if (!full && target === 'options') {
      return this.optionsFilter.current;
    } else if (target === 'ideas') {
      return this.ideasFilter;
    } else if (target === 'options') {
      return this.optionsFilter;
    }
  },

  applyFilter: function (target, state) {
    if (target === 'ideas') {
      return this.ideasFilter.apply(state);
    } else if (target === 'options') {
      return this.optionsFilter.apply(state);
    }
  },

  selectOption: function (callback) {
    setTimeout(() => {
      app.tab.show('#profile-tab-myideas', false);
      app.tab.show('#profile-tab-myideas-options', false);
      view.renewOnOptionClicked((optionId) => {
        callback(optionId);
      });
    }, 0);
  },

  rankIdea: function (idea) {
    if (getIdeaRanking) {
      let ranking = getIdeaRanking(idea.id, idea.isStudies, true);
      return ranking !== null
        ? '(Ranking: ' + Math.round(ranking * 100) + '%)'
        : '';
    }
    return '';
  },

  rankOption: function (option) {
    if (getOptionRanking) {
      let ranking = getOptionRanking(option.id, option.isStudies, true);
      return ranking !== null
        ? '(Ranking: ' + Math.round(ranking * 100) + '%)'
        : '';
    }
    return '';
  },

  openIdeasTab: function () {
    setTimeout(() => {
      $$('#profile-page-content')[0].scrollTop = 0;
      app.tab.show('#profile-tab-myideas', false);
    }, 0);
  },

  openOptionsTab: function () {
    setTimeout(() => {
      $$('#profile-page-content')[0].scrollTop = 0;
      app.tab.show('#profile-tab-myideas', false);
      app.tab.show('#profile-tab-myideas-options', false);
    }, 0);
  },

  optionsPresent: function () {
    return userController.getUserData('profile').eduOptions.length > 0;
  },

  unload: function () {
    view.onLeave();
  },

  export: function () {
    pdfController.export();
  },
};
export default profileController;
