export default {
  id: 'b5256f14a3',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<a href=/onboarding/ ><div class="page page-background-image" data-name=start><div id=start-page-content class=page-content></div></div></a>';
      return r;
    }(this);
  },

  styleScoped: false
};