import taskView from './TaskView';
import $$ from 'dom7';

let controller;

let __START_TEXT = "Los geht's";
let __INFO_TEXT = 'mehr Info';
let __RESULTS_TEXT = 'Ergebnisse';
let __REPEAT_TEXT = 'nochmal';

let __defaultInfoButton = {
  title: __INFO_TEXT,
  action: () => controller.moreInfo(),
};
let __defaultStartButton = {
  title: __START_TEXT,
  action: function () {
    let keys = Object.keys(controller);
    if (keys.includes('displayTask')) {
      controller.displayTask(introView._task);
    } else if (keys.includes('next')) {
      controller.next();
    }
  },
};
let __defaultResultsButton = {
  title: __RESULTS_TEXT,
  action: () => controller.displayResults(introView),
};

var introView = {
  _task: null,

  _buttons: [],

  _defaultInfoButton: __defaultInfoButton,
  _defaultStartButton: __defaultStartButton,
  _defaultResultsButton: __defaultResultsButton,

  START_TEXT: __START_TEXT,
  INFO_TEXT: __INFO_TEXT,
  RESULTS_TEXT: __RESULTS_TEXT,
  REPEAT_TEXT: __REPEAT_TEXT,

  BUTTONSET_INFO_START_RESULTS: [
    __defaultInfoButton,
    __defaultStartButton,
    __defaultResultsButton,
  ],
  BUTTONSET_INFO_START: [__defaultInfoButton, __defaultStartButton],
  BUTTONSET_START: [__defaultStartButton],

  init: function (cntrllr, task) {
    controller = cntrllr;
    this._task = task;

    // default buttons
    if (!this._buttons || this._buttons.length < 1) {
      this.setButtons(this.BUTTONSET_INFO_START);
    }

    // default back-navigation
    if (controller != null && controller.previous != null) {
      this.updateBackNavigation(() => controller.previous());
    }

    // load Page
    taskView.init(this._task);
    this._loadContent();
    this._loadButtons();

    // reset buttons
    this.clearButtons();
  },

  reset: function () {
    this.clearButtons();
    this.resetBackNavigation();
  },

  clearButtons: function () {
    this._buttons = [];
  },

  addButton: function (title, action) {
    this._buttons.push({ title: title, action: action });
  },

  setButtons: function (buttonSet) {
    this._buttons = [];
    for (let button of buttonSet) {
      this._buttons.push(button);
    }
  },

  resetBackNavigation: function () {
    $$('.page[data-name="task"] .navbar .left a.link')
      .off('click')
      .addClass('back');
  },

  updateBackNavigation: function (execute) {
    taskView.updateBackNavigation(execute);
  },

  loadMainFAB: function () {
    taskView.loadMainFAB();
  },

  _loadContent: function () {
    $$('#task-page-content')[0].scrollTop = 0;
    let content = $$('#task-content');
    content.empty();
    content.append(introView._task.intro);
  },

  _loadButtons: function () {
    const list = $$('#task-bottom > .button-list');
    list.empty();
    list.addClass('horizontal shadowed');
    this._buttons.forEach((button) => {
      list.append(
        $$(document.createElement('button'))
          .addClass('button button-fill color-white')
          .append(button.title)
          .on('click', () => {
            button.action();
          }),
      );
    });
  },
};

export default introView;
