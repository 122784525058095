import './alltasks.css';
import $$ from 'dom7';
import app from '../../../app';
import dictionary from '../../../core/Dictionary';

let controller;

const alltasksView = {
  /** initializes page without contents */
  init(cntrllr) {
    controller = cntrllr;

    // load FAB and toolbar
    app.loadMainToolbar($$('.page[data-name="alltasks"] .main-toolbar'));
    app.loadMainFAB($$('.page[data-name="alltasks"] .main-fab'));

    this.loadSort();

    // load texts
    dictionary.loadModuleToDOM('hometexts', $$('#alltasks-page'));
  },

  /** displays options to select from sort in controller */
  loadSort() {
    const list = $$('#alltasks-sort-list').empty();
    for (let state in controller.sort.all) {
      if (controller.sort.all.hasOwnProperty(state)) {
        let link = $$(document.createElement('a'))
          .addClass(
            'list-button item-link' +
              (controller.sort.get(state).name === controller.sort.current.name
                ? ' selected'
                : ''),
          ) // currently selected
          .attr('href', '#')
          .attr('id', 'alltasks-sort-link-' + state)
          .append(controller.sort.get(state).name)
          .on('click', () => {
            controller.sort.apply(state);
            list.find('a[id^="alltasks-sort-link-"]').each((ind, el) => {
              $$(el).removeClass('selected');
            });
            link.addClass('selected');
            app.popover.get('#alltasks-popover-tasks-sort').close();
          });

        list.append($$(document.createElement('li')).append(link));
      }
    }
  },

  /** loads all task cards from given dto array */
  loadTasks(tasks) {
    const list = $$('#alltasks-task-cards-container').empty();
    const placeholder = $$('#alltasks-tasks-placeholder');
    if (tasks.length > 0) {
      placeholder.addClass('hide');
      for (let task of tasks) {
        list.append(this.getTaskCard(task));
      }
    } else {
      placeholder.removeClass('hide');
    }
  },

  /** retruns a task cards from given dto */
  getTaskCard(task) {
    return dictionary.loadModuleToDOM(
      'hometexts',
      $$(document.createElement('div')).addClass(
        'card card-line card-representer list',
      ).append(`
                    <div class="card-content">
                        <a href="${task.link}" class="item-content item-link alltasks-task-link">
                            <div class="item-inner">
                                <span class="title">
                                    ${task.title}
                                </span>
                                <div class="item-subtitle" data-dic-key="alltasks-tasks-sort-${task.state}"></div>
                            </div>
                        </a>
                    </div>
                `),
    );
  },
};
export default alltasksView;
