import introView from '../../IntroView';
import selectionView from './SelectionView';
import profileController from '../../../../../profile/ProfileController';
import app from '../../../../../../app';
import makedecisionsController from '../MakedecisionsController';
import ratingView from './RatingView';
import userController from '../../../../../../core/UserController';
import resultsView from './ResultsView';
import detailsView from './DetailsView';
import $$ from 'dom7';
import taskController from '../../TaskController';

var idearankingController = {
  task: null,

  mode: null,

  ideas: null,

  // are there no missing answers or unfinished ratings?
  unchanged: null,
  defaultValue: 0.0,

  init: function (task) {
    this.task = task;
    this.mode = null;
    this.displayIntro();
  },

  displayIntro: function () {
    selectionView.loadTop();
    introView.updateBackNavigation(() => makedecisionsController.init());
    if (this.hasResults()) {
      introView.setButtons(introView.BUTTONSET_INFO_START_RESULTS);
    }
    introView.init(this, this.task);
  },

  displayTask: function () {
    introView.clearButtons();
    introView.addButton('Berufsfelder', () => {
      idearankingController.mode = 1;
      idearankingController.displaySelection(true);
    });
    introView.addButton('Studienfelder', () => {
      idearankingController.mode = 2;
      idearankingController.displaySelection(true);
    });
    introView.init(this, this.task);
  },

  displaySelection: function (firstTime) {
    this.unchanged = true;
    this.selection.display(firstTime);
  },

  displayRating: function (last) {
    this.rating.display(false, last);
  },

  displayResults: function (calling) {
    this.results.display(
      calling === introView || calling === true || calling === 'external',
      calling === 'external',
    );
  },

  displayDetails: function (all, extern) {
    this.details.display(all, extern);
  },

  moreInfo: function () {
    app.views.main.router.back('/ex/8/1/4/0/', { force: true });
    setTimeout(() => {
      $$('.page-current .page-content')[0].scrollTop = 0;
    }, 0);
  },

  selection: {
    amountOfSelectedIdeas: 0,
    maxAmountOfSelectedIdeas: 5,
    minAmountOfSelectedIdeas: 2,

    // is selection changed?
    noChange: null,

    display: function (first) {
      // do not edit finished ratings, if first time opened
      this.noChange = !first;
      idearankingController._getAllIdeas();
      idearankingController._prepareAnswers();
      selectionView.init(
        this,
        idearankingController.task.selection_text,
        idearankingController.ideas,
        idearankingController.task.answers[idearankingController.mode]
          .selectedIdeas,
      );
    },

    next: function () {
      if (this.noChange) {
        // make rating editable
        idearankingController.unchanged = false;
      }
      idearankingController.displayRating();
    },

    previous: function () {
      idearankingController.displayTask();
    },

    selectIdea: function (id) {
      if (this.amountOfSelectedIdeas < this.maxAmountOfSelectedIdeas) {
        this.noChange = false;
        idearankingController.task.answers[
          idearankingController.mode
        ].selectedIdeas.push(id);
        idearankingController.task.save();
        this.amountOfSelectedIdeas++;
        return true;
      }
      return false;
    },

    unSelectIdea: function (id) {
      let index;
      while (
        (index =
          idearankingController.task.answers[
            idearankingController.mode
          ].selectedIdeas.indexOf(id)) > -1
      ) {
        idearankingController.task.answers[
          idearankingController.mode
        ].selectedIdeas.splice(index, 1);
        this.noChange = false;
      }
      idearankingController.task.save();
      this.amountOfSelectedIdeas =
        idearankingController.task.answers[
          idearankingController.mode
        ].selectedIdeas.length;
    },

    isIdeaSelectionValid: function () {
      return (
        this.maxAmountOfSelectedIdeas >= this.amountOfSelectedIdeas &&
        this.amountOfSelectedIdeas >= this.minAmountOfSelectedIdeas
      );
    },
  },

  rating: {
    currentKey: null,

    valuesMap: null,

    display: function (noInit, last) {
      if (!noInit) {
        // general
        idearankingController._filterSelectedIdeas();
        this._loadValuesFromProfile();
        idearankingController.ideas.forEach((idea) => {
          this._prepareAnswersforIdea(idea.id);
        });
        if (!this._isIdeasFinished()) {
          idearankingController.unchanged = false;
        }
      }

      // display last rating for back navigation
      if (last) {
        this.currentKey = this.valuesMap.length - 1;
      }

      // key specific
      let key = this.valuesMap[this.currentKey][0];
      this._prepareAnswersforValue(key);

      // skip rating, if aleady rated and nothing changed
      if (idearankingController.unchanged) {
        this.next();
      } else {
        //view
        ratingView.init(
          this,
          this._getValueText(key),
          idearankingController.ideas,
          this.valuesMap[this.currentKey][1],
        );
      }
    },

    next: function () {
      if (this.currentKey + 1 < this.valuesMap.length) {
        this.currentKey++;
        this.display(true);
      } else {
        this._finishIdeas();
        idearankingController.displayResults();
      }
    },

    previous: function () {
      if (this.currentKey > 0) {
        this.currentKey--;
        this.display(true);
      } else {
        idearankingController.displaySelection();
      }
    },

    setPriority: function (prio) {
      idearankingController.task.answers[idearankingController.mode].prios[
        this.valuesMap[this.currentKey][0]
      ] = prio;

      idearankingController.task.save();
    },

    getPriority: function () {
      return idearankingController.task.answers[idearankingController.mode]
        .prios[this.valuesMap[this.currentKey][0]];
    },

    setRating: function (idea, rate) {
      idearankingController.task.answers[idearankingController.mode].rating[
        this._getIndexOfIdea(idea)
      ][this.valuesMap[this.currentKey][0]] = rate;

      idearankingController.task.save();
    },
    getRating: function (idea) {
      let res =
        idearankingController.task.answers[idearankingController.mode].rating[
          this._getIndexOfIdea(idea)
        ][this.valuesMap[this.currentKey][0]];
      return res == null ? 0 : res;
    },

    getProgress: function () {
      return (this.currentKey / this.valuesMap.length) * 100;
    },

    _finishIdeas: function () {
      idearankingController.ideas.forEach((idea) => {
        idearankingController.task.answers[idearankingController.mode].rating[
          this._getIndexOfIdea(idea.id)
        ].finished = true;
      });
      idearankingController.task.finish();
    },

    _isIdeasFinished: function () {
      let finished = true;
      for (
        let i = 0;
        i <
        idearankingController.task.answers[idearankingController.mode].rating
          .length;
        i++
      ) {
        if (
          !idearankingController.task.answers[idearankingController.mode]
            .rating[i].finished
        ) {
          finished = false;
        }
      }
      return finished;
    },

    _getIndexOfIdea: function (id, searchAll) {
      for (let mode of searchAll ? [1, 2] : [idearankingController.mode]) {
        for (
          let i = 0;
          i < idearankingController.task.answers[mode].rating.length;
          i++
        ) {
          if (id === idearankingController.task.answers[mode].rating[i].id) {
            return searchAll ? [mode, i] : i;
          }
        }
      }
    },

    _prepareAnswersforIdea: function (id) {
      let exsists = false;
      idearankingController.task.answers[
        idearankingController.mode
      ].rating.forEach((idea) => {
        if (id === idea.id) {
          exsists = true;
        }
      });
      if (!exsists) {
        idearankingController.task.answers[
          idearankingController.mode
        ].rating.push({
          id: id,
          finished: false,
        });
        idearankingController.unchanged = false;
      }

      idearankingController.task.save();
    },

    _prepareAnswersforValue: function (key) {
      if (
        idearankingController.task.answers[idearankingController.mode].prios[
          key
        ] == null
      ) {
        idearankingController.task.answers[idearankingController.mode].prios[
          key
        ] = 1;
      }

      idearankingController.task.save();
    },

    _loadValuesFromProfile: function () {
      this.valuesMap = [];
      let keys = [];
      for (let superValue in idearankingController.task.values) {
        if (idearankingController.task.values.hasOwnProperty(superValue)) {
          keys = keys.concat(
            Object.keys(idearankingController.task.values[superValue]),
          );
        }
      }

      // profile keys
      keys = keys.filter((key) =>
        key.includes('&') || key.includes('.')
          ? this.valuesMap.push([key, key.split(',')]) && false
          : true,
      );

      // not profile keys
      for (let key of keys) {
        this.valuesMap.push([key, null]);
      }

      this.currentKey = 0;
    },

    _getValueText: function (key) {
      for (let superValue in idearankingController.task.values) {
        if (
          idearankingController.task.values.hasOwnProperty(superValue) &&
          idearankingController.task.values[superValue][key]
        ) {
          return idearankingController.task.values[superValue][key].text;
        }
      }
      return '';
    },
  },

  results: {
    // directly navigated to results?
    directResult: null,
    externCall: null,

    results: null,

    display: function (direct, extern) {
      this.directResult = direct;
      this.externCall = extern;
      resultsView.init(
        this,
        idearankingController.task.results_text,
        this.getResults(direct),
      );
    },

    next: function () {
      idearankingController.displayDetails(this.directResult, this.externCall);
    },

    previous: function () {
      if (this.externCall) {
        app.views.current.router.back();
      } else if (this.directResult) {
        idearankingController.displayIntro();
      } else if (idearankingController.unchanged) {
        idearankingController.displaySelection();
      } else {
        // display last value rating
        idearankingController.displayRating(true);
      }
    },

    navigateToMyIdeas: function () {
      app.views.main.router.back('/profile/', { force: true });
      profileController.openIdeasTab();
    },

    getProgress: function () {
      return 100;
    },

    getResults: function (all) {
      if (idearankingController.mode == null || all) {
        let res = {};
        let saveMode = idearankingController.mode;
        for (let mode of [1, 2]) {
          idearankingController.mode = mode;
          idearankingController._getAllIdeas();
          res = Object.assign(res, this._getResults(all));
        }
        idearankingController.mode = saveMode;
        return this._sortResults(res);
      } else {
        this.results = this._sortResults(this._getResults());
      }
      return this.results;
    },

    _getResults: function (all) {
      if (
        idearankingController.task.answers[idearankingController.mode] ==
          null &&
        !this.directResult
      ) {
        return {};
      }
      let calcIdea = function (id) {
        let mode = idearankingController.mode;
        let index = idearankingController.rating._getIndexOfIdea(id, all);
        if (!index) {
          return 0;
        }
        if (all) {
          mode = index[0];
          index = index[1];
        }
        return idearankingController.task.answers[mode]
          ? idearankingController.getAVGOfRating(
              idearankingController.task.answers[mode].rating[index],
              idearankingController.task.answers[mode].prios,
            )
          : 0;
      };

      // calculate result
      let res = {};
      let ideas = all
        ? idearankingController._getAllIdeas(true)
        : idearankingController.ideas;
      for (let idea of ideas) {
        let rating = calcIdea(idea.id);
        if (rating > 0) {
          res[idea.name] = rating;
        }
      }

      return res;
    },

    _sortResults: function (res) {
      let sorted = {};
      for (let i in res) {
        let max = -1;
        let element = null;
        for (let key in res) {
          if (res[key] == null) {
            continue;
          }
          if (res[key] > max) {
            max = res[key];
            element = key;
          }
        }
        sorted[element] = res[element];
        res[element] = null;
      }

      return sorted;
    },
  },

  details: {
    directResult: null,
    externCall: null,

    display: function (all, extern) {
      this.directResult = all;
      this.externCall = extern;
      detailsView.init(
        this,
        idearankingController.task.details_text,
        this._getDetais(all),
      );
    },

    previous: function () {
      idearankingController.displayResults(
        this.externCall ? 'external' : this.directResult,
      );
    },

    getProgress: function () {
      return 100;
    },

    _getDetais: function (all) {
      let details = [];
      for (let superValue in idearankingController.task.values) {
        let lists = [];
        for (let value in idearankingController.task.values[superValue]) {
          let ratedIdeas = [];
          let allIdeas = all
            ? idearankingController._getAllIdeas(true)
            : idearankingController.ideas;

          for (let idea of allIdeas) {
            let index = idearankingController.rating._getIndexOfIdea(
              idea.id,
              all,
            );
            let mode = idearankingController.mode;
            if (!index) {
              continue;
            }
            if (all) {
              mode = index[0];
              index = index[1];
            }
            let answer = idearankingController.task.answers[mode]
              ? idearankingController.task.answers[mode].rating[index][value]
              : 0;

            // ideas
            if (answer > 0) {
              ratedIdeas.push({
                name: idea.name,
                rate: answer,
              });
            }
          }

          // questions
          if (ratedIdeas.length > 0) {
            lists.push({
              question:
                idearankingController.task.values[superValue][value].question,
              ideas: ratedIdeas,
            });
          }
        }

        if (lists.length > 0) {
          // values
          details.push({
            title: superValue,
            lists: lists,
          });
        }
      }

      return details;
    },
  },

  getAVGOfRating: function (idea, prios) {
    let sum = 0;
    let count = 0;
    // get average
    for (let value in idea) {
      if (!['id', 'finished'].includes(value) && idea[value] !== 0) {
        // priority counts n times
        let prio = prios[value] != null ? prios[value] : 1;
        sum += (idea[value] - 1) * prio;
        count += prio;
      }
    }
    return count > 0 ? sum / count / 4 : idearankingController.defaultValue;
  },

  //for profile
  createRankingGetter: function () {
    let ideaRankingAnswers =
      userController.getModule(0).chapters[0].exercises[1].tasks[0].answers;
    // answered?
    if (
      ideaRankingAnswers != null &&
      ideaRankingAnswers.length != null &&
      ideaRankingAnswers.length > 0
    ) {
      // combine study or training
      let totalRankings = [];
      for (let ideaRankingAnswer of ideaRankingAnswers) {
        if (ideaRankingAnswer != null) {
          totalRankings = totalRankings.concat(ideaRankingAnswer.rating);
        }
      }
      if (totalRankings.length > 0) {
        // ranking getter
        return function (idea, isStudies, nullable) {
          if (ideaRankingAnswers[isStudies ? 2 : 1] == null) {
            return nullable ? null : idearankingController.defaultValue;
          }
          // study or training
          let rankings = ideaRankingAnswers[isStudies ? 2 : 1].rating;
          let prios = ideaRankingAnswers[isStudies ? 2 : 1].prios;
          // find idea
          for (let ranking of rankings) {
            if (ranking.id === idea) {
              return idearankingController.getAVGOfRating(ranking, prios);
            }
          }
          return nullable ? null : idearankingController.defaultValue;
        };
      }
    }
    return false; // no ranking yet
  },

  _prepareAnswers: function () {
    if (!this.task.answers) {
      this.task.answers = [];
      this.unchanged = false;
    }
    if (!this.task.answers[this.mode]) {
      this.task.answers[this.mode] = {};
      this.unchanged = false;
    }
    if (!this.task.answers[this.mode].selectedIdeas) {
      this.task.answers[this.mode].selectedIdeas = [];
      this.unchanged = false;
    }
    if (!this.task.answers[this.mode].rating) {
      this.task.answers[this.mode].rating = [];
      this.unchanged = false;
    }
    if (!this.task.answers[this.mode].prios) {
      this.task.answers[this.mode].prios = {};
      this.unchanged = false;
    }

    // only keep existing ideas
    if (this.ideas && this.ideas.length > 0) {
      let existingIdeas = this.ideas.map((idea) => idea.id);

      // selected
      this.task.answers[this.mode].selectedIdeas = this.task.answers[
        this.mode
      ].selectedIdeas.filter((idea) => existingIdeas.includes(idea));

      // rating
      this.task.answers[this.mode].rating = this.task.answers[
        this.mode
      ].rating.filter((idea) => existingIdeas.includes(idea.id));
    } else {
      // empty
      this.task.answers[this.mode].selectedIdeas = [];
      this.task.answers[this.mode].rating = [];
      this.unchanged = false;
    }

    //update amount of selected Ideas
    this.selection.amountOfSelectedIdeas =
      this.task.answers[this.mode].selectedIdeas.length;

    this.task.save();
  },

  _getAllIdeas: function (allModes) {
    let ideas = profileController.getOptionsAndIdeas().ideas;
    if (!allModes) {
      return ideas.filter((idea) =>
        idea.isStudies
          ? idearankingController.mode === 2
          : idearankingController.mode === 1,
      );
    } else {
      this.ideas = ideas;
      return this.ideas;
    }
  },

  _filterSelectedIdeas: function () {
    this.ideas = profileController
      .getOptionsAndIdeas()
      .ideas.filter((idea) =>
        this.task.answers[this.mode].selectedIdeas.includes(idea.id),
      );
  },

  hasResults: function () {
    if (!this.task) {
      this.task = taskController.getTask(0, 1, 2, 1);
    }
    if (
      this.task.answers &&
      typeof this.task.answers === 'object' &&
      Object.keys(this.task.answers).length > 0
    ) {
      return Object.keys(this.results.getResults(true)).length > 0;
    }
    return false;
  },

  getGraphic: function () {
    if (!this.task) {
      this.task = taskController.getTask(0, 1, 2, 1);
    }

    // calc results
    let res = this.results.getResults(true);

    res = Object.assign({}, res);

    // limit to 3 results
    let index = 0;
    for (let key in res) {
      if (res.hasOwnProperty(key)) {
        if (index >= 3 || res[key] === 0) {
          delete res[key];
        }
        index++;
      }
    }

    return resultsView.getGraphic(res);
  },
};
export default idearankingController;
