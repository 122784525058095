import Notebook from '../../../model/notebook/Notebook.js';
import view from './NoteeditorView.js';
import Note from '../../../model/notebook/Note';
import userController from '../../../core/UserController';
import app from '../../../app';
import $$ from 'dom7';

var noteeditorController = {
  note: null,

  created: null,

  load: function (number, step) {
    let allSteps = [];
    userController.getAllModules().forEach((module) => {
      allSteps.push(module.number);
    });
    if (number == 0) {
      this.note = new Note(-1);
      this.note.creation_time = null;
      this.created = false;
      if (!isNaN(step) && step > 0) {
        this.note.step = step;
      }
      view.init(this, this.note, allSteps, true);
    } else {
      this.note = Notebook.getNote(number);
      this.created = true;
      view.init(this, this.note, allSteps);
    }
  },

  newNoteWithContent: function (title, content, step) {
    app.views.main.router.navigate(
      '/noteeditor/0/' + (step != null ? step : 0) + '/',
    );
    $$(document).once('page:afterin', function () {
      view.enableCreateMode();
      view.emptyEditor();
      if (title) {
        view.insertToEditor(title, true);
      }
      if (content) {
        view.insertToEditor(content);
      }
    });
  },

  delete: function () {
    Notebook.deleteNote(this.note);
  },

  save: function (values) {
    if (!this.created) {
      let note = this.note;
      this.note = Notebook.createNote();
      this.note.step = note.step;
      if (!note.number < 0) {
        this.note.number = note.number;
      }
      this.created = true;
    }
    if (values.content != null) {
      this.note.setContent(values.content);
    }
    if (values.title != null) {
      this.note.setTitle(values.title);
    }
    if (values.step != null) {
      this.note.step = values.step;
    }
    this.note.save();
    view.note = this.note;
  },

  export: function () {
    //TODO: implement
  },

  showDisplayMode: function () {
    view.enableDisplayMode();
  },

  showEditMode: function (focusEditor) {
    view.enableEditMode(focusEditor);
  },

  openExternalLink: function (url, event) {
    url = url.toLowerCase();
    if (url.startsWith('www.')) {
      url = 'http://' + url;
    }
    if (url.startsWith('http://') || url.startsWith('https://')) {
      event.preventDefault();
      event.stopPropagation();
      window.open(url, '_system'); // App is currently not using system browser for some reason
    }
  },

  beforeLeave: function () {
    view.closeEditor();
  },
};
export default noteeditorController;
