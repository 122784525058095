import Exercise from './Exercise';
import userController from '../../core/UserController';

function Chapter(json, module_number, number) {
  this.number = number;
  this.module_number = module_number;
  this.state = 0;
  this.last_verified = 0;
  this.exercises = [];
  for (var key in json) if (key != 'exercises') this[key] = json[key]; // Alle Attribute aus der JSON kopieren
  var _this = this;
  json.exercises.forEach(function (exercise, index) {
    _this.exercises[index] = new Exercise(
      exercise,
      _this.module_number,
      _this.number,
      index + 1,
    );
  }); // Exercises überschreiben mit neuen Objekten vom Typ Exercise
  var toLoad = JSON.parse(localStorage.getItem(this._id)); // Gespeicherte Daten vom Modul laden
  for (var key in toLoad) this[key] = toLoad[key]; // Gespeichert Daten ins Objekt schreiben
}

Chapter.prototype.save = function () {
  let toSave = {};
  let modulesJson = userController.getModulesJson();
  let chapterJson = modulesJson[this.module_number].chapters[number];
  for (var key in this) {
    if (!(key in chapterJson)) {
      toSave[key] = this[key];
    }
  }
  localStorage.setItem(this._id, JSON.stringify(toSave));
};

// returns state of exercise
Chapter.prototype.getState = function () {
  return this.state;
};

// returns all task of exercise
Chapter.prototype.getTasks = function () {
  return this.exercises;
};

Chapter.prototype.getNextExercise = function () {
  for (let exercise in this.exercises) {
    if (this.exercises[exercise].state == 1) {
      return this.exercises[exercise];
    }
  }
  for (let exercise in this.exercises) {
    if (this.exercises[exercise].state == 0) {
      return this.exercises[exercise];
    }
  }
  return this.exercises[0];
};

Chapter.prototype.getAbsoluteFinishedSubblocks = function () {
  let subblocks = { finished: 0, total: 0 };

  for (let ex = 0; ex < this.exercises.length; ex++) {
    let exSubblocks = this.exercises[ex].getAbsoluteFinishedSubblocks();
    subblocks.finished += exSubblocks.finished;
    subblocks.total += exSubblocks.total;
  }
  return subblocks;
};

export default Chapter;
