import structuredinterestsView from './StructuredinterestsView';
import taskView from '../../TaskView';

const structuredinterestsController = {
  init: function (task, moduleNumber, chapterNumber, exerciseNumber) {
    taskView.init(task, true);
    taskView.loadMainFAB();
    if (
      JSON.stringify(task.answers) == JSON.stringify(['']) ||
      task.answers.length == 0
    ) {
      task.answers = [
        ['', '', '', '', ''],
        ['', '', '', '', ''],
        ['', '', '', '', ''],
      ];
      task.save();
    }
    structuredinterestsView.init(task);
  },
};

export default structuredinterestsController;
