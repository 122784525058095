import Notebook from './Notebook.js';
import { v4 as uuidv4 } from 'uuid';

let getNextAvailableGoalNumber = function () {
  let available = 1;
  while (
    JSON.parse(localStorage.getItem('Goal: ' + available)) &&
    JSON.parse(localStorage.getItem('Goal: ' + available))['number'] >= 0
  ) {
    available++;
  }
  return available;
};

// Constructor can be called just with the number attribute to receive already existing goal.
function Goal(number, step, id) {
  this.specific = null;
  this.measurable = null;
  this.actionoriented = [];
  this.relevant = null;
  this.terminates = null;
  this.created = false;
  this.name = null;
  this.step = null;
  this.last_modified = null;
  this.last_synced = null;
  if (Boolean(number) && number !== '0') {
    this.number = number;
    this.load();
  } else {
    this.number = uuidv4();
    if (step) {
      this.step = step;
    }
  }

  if (this.number > 0) this.save();
}

Goal.prototype.isAchieved = function () {
  if (this.actionoriented.length < 1) {
    return false;
  }
  let res = true;
  for (var key in this.actionoriented) {
    if (!this.actionoriented[key].achieved) {
      res = false;
    }
  }
  return res;
};

Goal.prototype.save = function () {
  if (!this.created) {
    return;
  }
  let toSave = {};
  for (var act in this.actionoriented) {
    if (!this.actionoriented[act] || !this.actionoriented[act].action) {
      this.actionoriented.splice(act, 1);
    }
  }
  for (var key in this) {
    toSave[key] = this[key];
  }
  toSave.last_modified = Date.now();
  toSave._id = this.number;
  localStorage.setItem(this.number, JSON.stringify(toSave));
};

Goal.prototype.load = function () {
  if (this.number < 0) return;
  let toLoad = JSON.parse(localStorage.getItem(this.number));
  if (!toLoad)
    toLoad = JSON.parse(localStorage.getItem('Goal: ' + this.number));
  if (!toLoad || !toLoad['created']) return;
  for (var key in toLoad) {
    this[key] = toLoad[key];
  }
  if (toLoad && !toLoad._id) this.save();
};

Goal.prototype.setAttribute = function (key, value, index, state) {
  if (key === 'actionoriented') {
    if (value != null) {
      this[key][index] = { action: value, achieved: state ? state : false };
    } else if (index != null) {
      if (this[key][index] && state != null) {
        this[key][index].achieved = state;
      }
    } else {
      this[key] = [];
    }
  } else {
    this[key] = value;
  }

  if (!this.created) {
    this.created = true;
    Notebook.addGoal(this);
  }
  this.save();
};

Goal.prototype.clear = function () {
  this.specific = null;
  this.measurable = null;
  this.actionoriented = [];
  this.relevant = null;
  this.terminates = null;
  this.created = false;
  this.save();
};

Goal.prototype.delete = function () {
  this.clear();
  let empty = new Goal(-1);
  localStorage.setItem(this.number, JSON.stringify(empty));
  Notebook.removeGoal(this.number);
  localStorage.removeItem(this.number);
};

Goal.prototype.getName = function () {
  let res = this.name ? this.name : this.specific ? this.specific.title : null;
  return res ? res : 'Neues To-Do';
};
export default Goal;
