import view from './IdeaView.js';
import EduIdea from '../../../model/user/EduIdea.js';
import userController from '../../../core/UserController';

const ideaController = {
  idea: null,

  oldVersion: null,

  created: null,

  load: function (number) {
    this.oldVersion = false;
    if (number === '0') {
      this.idea = new EduIdea();
      this.created = false;
      view.init(this, this.idea, true);
    } else {
      this.idea = userController.getUserData('profile').eduIdeas[number];
      if (!this.idea) {
        this.idea = userController.getUserData('profile').oldIdeas[number];
        this.oldVersion = true;
      }
      this.created = true;
      view.init(this, this.idea);
    }
  },

  delete: function () {
    userController.getUserData('profile').deleteIdea(this.idea.id);
  },

  save: function (values) {
    // create
    if (!this.created) {
      userController.getUserData('profile').addIdea(this.idea);
      this.created = true;
    }

    // set values
    for (let key in values) {
      if (values.hasOwnProperty(key)) {
        if (typeof values[key] === 'object') {
          for (let index in values[key]) {
            if (values[key].hasOwnProperty(index)) {
              this.idea.setAttribute(key, index, values[key][index], false);
            }
          }
        } else {
          this.idea.setAttribute(key, null, values[key], false);
        }
      }
    }

    // save
    this.idea.save();
  },

  export: function () {
    //TODO: implement
  },

  showDisplayMode: function () {
    view.enableDisplayMode();
  },

  showEditMode: function () {
    view.enableEditMode();
  },
};
export default ideaController;
