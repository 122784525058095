import interactionView from './InteractionView';
import Goal from '../../../../../../model/notebook/Goal';
import app from '../../../../../../app.js';

var goalController = {
  task: null,

  goal: null,

  load: function (task) {
    this.task = task;
    this.loadGoal(0);

    // start task progress
    this.task.save();
  },

  loadGoal: function (number, step) {
    if (!number) {
      this.goal = new Goal(number, step ? step : 0);
      interactionView.init(this, this.goal, true);
    } else {
      this.goal = new Goal(number);
      interactionView.init(this, this.goal);
    }

    this.initCalendar();
  },

  initCalendar: function () {
    let cal = app.calendar.create({
      inputEl: '#goal-input-terminates',
      dateFormat: { month: 'numeric', day: 'numeric', year: 'numeric' },
    });
  },

  save: function (values, part) {
    if (!part) {
      this.goal.clear();
    }
    for (let key in values) {
      if (key === 'actionoriented') {
        let actionCount = 0;
        for (let subkey in values[key]) {
          let value = values[key][subkey]['value'];
          let state = values[key][subkey]['state'];
          if (value) {
            this.goal.setAttribute(
              key,
              value,
              actionCount,
              values[key][subkey]['state'],
            );
            actionCount++;
          } else if (state != null) {
            this.goal.setAttribute(
              key,
              null,
              subkey,
              values[key][subkey]['state'],
            );
          }
        }
      } else {
        this.goal.setAttribute(key, values[key]);
      }
    }
    if (this.task) {
      this.task.finish();
    }
  },

  delete: function () {
    this.goal.delete();
  },

  showEditMode: function () {
    interactionView.enableEditMode();
  },

  showDisplayMode: function () {
    interactionView.enableDisplayMode();
  },

  export: function () {
    //TODO: implement
  },
};
export default goalController;
