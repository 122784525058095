import costcalculationView from './CostcalculationView';
import taskView from '../../TaskView';

const costcalculationController = {
  init: function (task, moduleNumber, chapterNumber, exerciseNumber) {
    taskView.init(task, true);
    taskView.loadMainFAB();
    if (
      JSON.stringify(task.answers) == JSON.stringify(['']) ||
      task.answers.length == 0
    ) {
      task.answers = [
        [
          ['', ''],
          ['', ''],
          ['', ''],
          ['', ''],
          ['', ''],
          ['', ''],
          ['', ''],
          ['', ''],
        ],
        [
          ['', ''],
          ['', ''],
          ['', ''],
          ['', ''],
          ['', ''],
          ['', ''],
          ['', ''],
          ['', ''],
        ],
      ];
      task.save();
    }
    if (!JSON.parse(localStorage.getItem('deletedcostcalculation1'))) {
      localStorage.setItem('deletedcostcalculation1', JSON.stringify(true));
      task.answers = [
        [
          ['', ''],
          ['', ''],
          ['', ''],
          ['', ''],
          ['', ''],
          ['', ''],
          ['', ''],
          ['', ''],
        ],
        [
          ['', ''],
          ['', ''],
          ['', ''],
          ['', ''],
          ['', ''],
          ['', ''],
          ['', ''],
          ['', ''],
        ],
      ];
      task.save();
    }
    costcalculationView.init(task);
  },
};

export default costcalculationController;
