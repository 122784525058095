import $$ from 'dom7';
import elementFactory from '../../../../../../core/ui/ElementFactory';
import app from '../../../../../../app';
import idearankingController from './IdearankingController';
import makedecisionsController from '../MakedecisionsController';

let controller;

var resultsView = {
  init: function (cntrllr, intro, results) {
    controller = cntrllr;
    this.loadTop();
    this.loadText(intro);
    this.loadContent(results);
    this.loadBottom();
    this.loadBackNavigation();
  },

  loadTop: function () {
    $$('#task-page-content .page-content-top')
      .empty()
      .append(
        elementFactory
          .getProgressbar(controller.getProgress())
          .attr('id', 'idearanking-progress'),
      ).append(`
                <h1 id="task-title" class="page-title">Ideen-Ranking</h1>
                <h2 id="task-subtitle" class="page-subtitle"></h2>
            `);
  },

  loadText: function (intro) {
    $$('#task-page-content')[0].scrollTop = 0;
    let content = $$('#task-content');
    content.empty();
    content.append(intro);
  },

  loadContent: function (results) {
    $$('#task-content').append(
      this._getGraphic(results).attr('id', 'idearanking-results-card'),
    );
  },

  _getGraphic: function (results) {
    return elementFactory.getProgressBarsCard(results);
  },

  getGraphic: function (results) {
    return this._getGraphic(results)
      .addClass('bars-card-external external-result')
      .on('click', () => {
        app.views.current.router.navigate('/task/0/1/2/1/');
        setTimeout(() => {
          makedecisionsController.displayOptionranking();
          idearankingController.displayResults('external');
        });
      });
  },

  loadBottom: function () {
    let ideas = $$(document.createElement('button'))
      .addClass('button button-fill color-white')
      .append('zu meinen Ideen')
      .on('click', () => {
        controller.navigateToMyIdeas();
      });

    let details = $$(document.createElement('button'))
      .addClass('button button-fill color-white')
      .append('Details')
      .on('click', () => {
        controller.next();
      });

    const buttonsList = $$('#task-bottom .button-list').addClass('horizontal');
    buttonsList.empty().append(ideas).append(details);
  },

  loadBackNavigation: function () {
    const back = $$('.page[data-name="task"] .navbar .left a.link')
      .off('click')
      .removeClass('back');
    back.on('click', () => {
      back.off('click').addClass('back');
      controller.previous();
    });
  },
};
export default resultsView;
