import startView from './StartView.js';

const startController = {
  init: function () {
    this.initView();
  },
  initView: function () {
    startView.init();
  },
};

export default startController;
