import $$ from 'dom7';
import app from '../../../../../../app';
import elementFactory from '../../../../../../core/ui/ElementFactory';
import inputController from '../../input/InputController';

const structuredinterestsView = {
  amount: 5,

  init: function (task) {
    this.initPage(task);
    this.loadIntroduction(task);
  },

  initPage: function (task) {
    $$('#task-title').html(task.name);
    $$('#task-bottom .button-list').append(
      $$(document.createElement('button')).addClass(
        'button button-fill color-white full-width next-button',
      ),
    );
    $$('#task-bottom .button-list').append(
      $$(document.createElement('button'))
        .addClass('button button-fill color-white full-width exit-button')
        .html('Aufgabe abschließen'),
    );
  },
  loadIntroduction: function (task) {
    let content = $$('#task-content'),
      button = $$('#task-bottom .next-button'),
      exitbutton = $$('#task-bottom .exit-button'),
      nav = $$('.navbar-inner  a.link'),
      _this = this;

    nav.off('click');
    nav.addClass('back');

    content.empty();
    content.append(
      $$(document.createElement('div')).html(`<p>${task.introduction}</p>`),
    );

    if (
      task.name == 'Berufe finden oder erfinden' &&
      (task.interests == undefined ||
        task.interests.filter((x) => x != '').length == 0)
    ) {
      $$('#task-content')
        .find('div')
        .append(
          $$(document.createElement('p')).html(
            'Um diese Aufgabe zu beginnen musst du zuerst die vorherige Aufgabe "Interessen und Präferenzen strukturiert beschreiben" abschließen, damit du dann hier mit den Ergebnissen weiterarbeiten kannst.',
          ),
        );
      button.addClass('disabled');
    }
    button.off('click');
    button.html("Los geht's");
    button.on('click', () => _this.loadPage(0, task));
    exitbutton.hide();
  },
  loadPage: function (index, task) {
    let content = $$('#task-content'),
      button = $$('#task-bottom .next-button'),
      exitbutton = $$('#task-bottom .exit-button'),
      nav = $$('.navbar-inner a.link'),
      _this = this;
    let interestcontainer = $$(document.createElement('div'));

    nav.removeClass('back');
    nav.off('click');
    nav.on('click', () => {
      index == 0
        ? _this.loadIntroduction(task)
        : _this.loadPage(index - 1, task);
    });

    content.empty();
    content.css('margin-bottom', '0px');

    content.append(
      $$(document.createElement('div'))
        .html(`<p>${task.first_option[0]}</p>`)
        .append(
          elementFactory.getDropdownMenu(
            task.answers[0][index],
            task.interests,
            task.first_option[1],
            false,
            true,
          ),
        ),
    );
    content.append(
      $$(document.createElement('div'))
        .html(`<p>${task.second_option[0]}</p>`)
        .append(
          elementFactory.getDropdownMenu(
            task.answers[1][index],
            task.actions,
            task.second_option[1],
            false,
            true,
          ),
        ),
    );
    content.append(
      elementFactory.getLabeledTextInput(
        task.result[0],
        task.result[1],
        task.answers[2][index],
      ),
    );

    //content.find(".dropdown-menu .item-content").addClass("white-background")
    button.html('Weiter');
    button.off('click');
    button.on('click', () => {
      content.find('input').forEach((e, i) => {
        task.answers[i][index] = e.value;
      });
      task.save();
      index < _this.amount - 1
        ? _this.loadPage(index + 1, task)
        : _this.loadResult(task);
    });
    exitbutton.show();
    exitbutton.off('click');
    exitbutton.on('click', () => {
      content.find('input').forEach((e, i) => {
        task.answers[i][index] = e.value;
      });
      task.finish();
      _this.loadResult(task);
    });
  },

  loadResult: function (task) {
    let content = $$('#task-content'),
      nav = $$('.navbar-inner a.link'),
      button = $$('#task-bottom .next-button'),
      exitbutton = $$('#task-bottom .exit-button'),
      _this = this;
    content.empty();
    content.html(`<p>${task.closing_text}</p>`);
    let card = $$(document.createElement('div'))
      .addClass('card')
      .html(`<div class="card-content"></div>`);
    for (let i = 0; i < 5; i++) {
      if (task.answers[2][i] != '') {
        card
          .find('.card-content')
          .append(
            $$(document.createElement('div'))
              .addClass('item-content')
              .html(
                `<div class="item-inner"><span class="item-title">${task.answers[2][i]}</span><div class="item-subtitle"><span>Aus ${task.answers[0][i]} und ${task.answers[1][i]}</span></div></div>`,
              ),
          );
      }
    }
    content.append(card);

    button.off('click');
    button.html('Nächstes Thema');
    button.on('click', () => {
      app.views.main.router.navigate('/ex/2/5/8/0/');
    });
    exitbutton.hide();
  },
};

export default structuredinterestsView;
