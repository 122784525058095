import $$ from 'dom7';
import '../../../components/tutorial.css';
import domHelper from '../../domHelper';
import html2canvas from 'html2canvas';

//TODO: anordnung der Kopien in overlay überarbeiten
//TODO: üher farbgebung und so in css animation nachdenken -> so lassen oder schöner?
class Tutorial {
  // contents
  _page = null;
  _text = '';
  _overlay = null;
  _elements = [];

  // controls
  _promise = null;

  constructor(page) {
    let _this = this;
    page = $$(page);
    if (!page.hasClass('page-content')) {
      page = $$(page.find('.page-content')[0]);
    }
    this._page = page;
    this._overlay = $$(document.createElement('div'))
      .addClass('overlay tutorial hide')
      .on('click', () => {
        _this.hide();
      });
    this._overlay.insertAfter(this._page);
    this._initPromise();
  }

  addElement(element, shape) {
    element = $$(element);
    this._elements.push({ dom: element, shape: shape });
    return this;
  }

  setText(text) {
    this._text = text;
    return this;
  }

  show() {
    let _this = this;
    // text
    this._overlay
      .empty()
      .append(
        $$(document.createElement('div'))
          .addClass('tutorial-text')
          .append(this._text),
      );

    // elements
    // append screenshot
    let addToOverlay = (element) => {
      setTimeout(() => {
        let canvas = element.dom[0].cloneNode(true);
        domHelper.adoptComputedStyle(element.dom[0], canvas, true);
        _this._overlay.append(
          $$(document.createElement('div'))
            .addClass(
              'tutorial-canvas' + (element.shape ? ' ' + element.shape : ''),
            ) // apply shapes to new element
            .append(canvas)
            .css('top', element.dom.offset().top + 'px')
            .css('left', element.dom.offset().left + 'px')
            .on('click', () => {
              element.dom.click();
              _this.hide(element.dom);
            }),
        );
        element.dom.addClass('tutorial-element');
      });
    };

    // do for all elements
    for (let element of this._elements) {
      if (domHelper.isInDocument(element.dom)) {
        addToOverlay(element);
      } else {
        // wait for insert
        domHelper.onElementInsertOnce(element.dom, () => {
          addToOverlay(element);
        });
      }
    }

    // show overlay
    this._overlay.removeClass('hide');

    this._initPromise();
    return this._promise;
  }

  hide(_element) {
    this._overlay.addClass('hide');
    for (let element of this._elements) {
      element.dom.removeClass('tutorial-element');
    }
    this._overlay.empty();

    this._promise._resolve(_element);
    return this;
  }

  cancel() {
    this._overlay.addClass('hide');
    for (let element of this._elements) {
      element.dom.removeClass('tutorial-element');
    }
    this.empty();
    this._overlay.remove();
    this._promise._reject('canceled');
  }

  empty() {
    this._overlay.empty();
    this._elements = [];
    this.text = '';
    return this;
  }

  _initPromise() {
    this._promise = {
      fired: false,
      __thenHandler(element) {
        // initially nothing happens
      },
      __catchHandler(element) {
        // initially nothing happens
      },
      __finallyHandler(element) {
        // initially nothing happens
      },
      then(handler) {
        this.__thenHandler = (element) => handler(element);
      },
      catch(handler) {
        this.__catchHandler = (error) => handler(error);
      },
      finally(handler) {
        this.__finallyHandler = () => handler();
      },
      _resolve(element) {
        if (!this.fired) {
          this.fired = true;
          this.__thenHandler(element);
          this.__finallyHandler();
        }
      },
      _reject(error) {
        if (!this.fired) {
          this.fired = true;
          this.__catchHandler(error);
          this.__finallyHandler();
        }
      },
    };
  }
}

export default Tutorial;
