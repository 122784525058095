import Task from './Task';
import userController from '../../core/UserController';

function Exercise(json, module_number, chapter_number, number) {
  this.module_number = module_number;
  this.chapter_number = chapter_number;
  this.number = number;
  this.id = number;
  this.duration = 20;
  this.spentMilliseconds = 0;
  this.finishedSubblocks = new Array(json.description_long.length).fill(false);
  this.tasks = [];
  this.bookmark = 0;
  for (var key in json) if (key != 'tasks') this[key] = json[key]; // Alle Attribute aus der JSON kopieren
  if (json.tasks != undefined) {
    var _this = this;
    json.tasks.forEach(function (task, index) {
      _this.tasks[index] = new Task(
        task,
        _this.module_number,
        _this.chapter_number,
        _this.number,
        index + 1,
      ); // Tasks überschreiben mit neuen Objekten vom Typ Exercise
    });
  }
  var toLoad = JSON.parse(localStorage.getItem(this._id)); // Gespeicherte Daten vom Modul laden

  for (var key in toLoad) this[key] = toLoad[key];
  this.createSubblocks();
  if (!toLoad || !toLoad._id) this.save(true);
}

// Saves content of exercise to LocalStorage
Exercise.prototype.save = function (notLastModified) {
  let toSave = {};
  let modulesJson = userController.getModulesJson();
  let exerciseJson =
    modulesJson[this.module_number - 1].chapters[this.chapter_number - 1]
      .exercises[this.number - 1];
  for (var key in this) {
    if (!(key in exerciseJson)) {
      toSave[key] = this[key];
    }
  }
  toSave._id = this._id;
  if (!notLastModified) {
    toSave.last_modified = Date.now();
  }
  localStorage.setItem(this._id, JSON.stringify(toSave));
};

/* Creates Exercise Subblocks for ExerciseView
 */
Exercise.prototype.createSubblocks = function () {
  this.subblocks = [];
  let taskindex = 0;
  for (
    let blockindex = 0;
    blockindex < this.description_long.length;
    blockindex++
  ) {
    if (this.description_long[blockindex] === '##TASK##') {
      this.subblocks.push({
        isTask: true,
        number: blockindex + 1,
        title: this.tasks[taskindex].name,
        task: this.tasks[taskindex],
      });
      taskindex++;
    } else {
      this.subblocks.push({
        isTask: false,
        number: blockindex + 1,
        title: this.subblock_titles[blockindex],
        content: this.description_long[blockindex],
      });
    }
  }
};

// Returns true if exercise contains one or more task
Exercise.prototype.containsTask = function () {
  for (let subblock of this.subblocks) {
    if (subblock.isTask) return true;
  }
  return false;
};

// returns all task of exercise
Exercise.prototype.getTasks = function () {
  return this.tasks;
};

Exercise.prototype.getCurrentSubblock = function () {
  return this.subblocks[this.bookmark];
};

// returns the next unfinished task
Exercise.prototype.getNextTask = function () {
  for (task in this.tasks) {
    if (this.tasks[task].state == 1) {
      return this.tasks[task];
    }
  }
  for (task in this.tasks) {
    if (this.tasks[task].state == 0) {
      return this.tasks[task];
    }
  }
};

Exercise.prototype.updateTime = function (spentTime) {
  this.spentMilliseconds += spentTime;
  //this.remainingMilliseconds = Math.max(this.remainingMilliseconds - spentTime, 0);
  //this.remainingMinutes = this.remainingMilliseconds / (1000 * 60)
  this.save();
};

Exercise.prototype.getRemainingMinutes = function () {
  return parseInt(
    Math.max(this.duration - this.spentMilliseconds / (1000 * 60), 0),
  );
};

Exercise.prototype.setFinishSubblock = function (subblockIndex, isFinished) {
  this.finishedSubblocks[subblockIndex] = isFinished;
  this.save();
};

//Looks for finished flags in tasks and set corrs
Exercise.prototype.setFinishTaskSubblocks = function () {
  for (let index = 0; index < this.subblocks.length; index++) {
    if (this.subblocks[index].isTask) {
      this.finishedSubblocks[index] = this.subblocks[index].task.state >= 1;
    }
  }
};

Exercise.prototype.getAbsoluteFinishedSubblocks = function () {
  this.setFinishTaskSubblocks();
  return {
    finished: this.finishedSubblocks.filter((x) => x == true).length,
    total: this.finishedSubblocks.length,
  };
};

export default Exercise;
