import $$ from 'dom7';
import app from '../../../app';
import './f360result.css';
import { v4 as uuidv4 } from 'uuid';

let controller;

var f360resultView = {
  initialized: false,

  _loadMainToolbar() {
    app.loadMainToolbar($$('.page[data-name="f360result"] .main-toolbar'));
  },

  _loadMainFAB() {
    app.loadMainFAB($$('.page[data-name="f360result"] .main-fab'));
  },

  _loadTitle() {
    let name = controller.getName();
    $$('#f360result-title')
      .empty()
      .append('Rückmeldung von ' + (name ? name : 'einem/r Freund:in'));
  },

  init(cntrllr) {
    controller = cntrllr;
    this._loadMainToolbar();
    this._loadMainFAB();
    this._loadTitle();
    this._loadFilter();
    this._loadPopovers();
    this.initialized = true;
  },

  _loadPopovers() {
    $$('.page[data-name="f360result"] .popover-open').each((i, el) => {
      el = $$(el);
      app.popoverOpenBelow(el, $$(el.attr('data-popover')));
    });
  },

  onLeave() {
    this.initialized = false;
  },

  loadSections() {
    $$('#f360result-sections').empty();
    let sections = controller.getSections();
    if (sections.length === 0) {
      this._loadPlaceholder();
      return;
    }
    for (let section of sections) {
      if (section.entries) {
        this._loadSection(section.section, section.entries, true);
      } else if (section.value) {
        this._loadSection(section.section, section.value);
      }
    }
  },

  _loadPlaceholder() {
    $$('#f360result-sections').empty().append(`
                <div class="block placeholder-container">
                    <label class="placeholder">Diese Rückmeldung ist leer.</label>
                </div>
            `);
  },

  _loadSection(name, content, multipleEntries) {
    let section = $$(document.createElement('div')).addClass(
      'f360result-section white-card',
    ).append(`
                <span class="font-h2 f360result-section-title">${name}</span>
            `);
    $$('#f360result-sections').append(section);
    if (multipleEntries) {
      if (content && content.length > 0 && isNaN(content[0].value)) {
        // string array
        let entries = $$(document.createElement('ul')).addClass(
          'f360result-section-content-string-entries',
        );
        section.append(entries);
        for (let entry of content) {
          entries.append(`
                        <li class="font-text">${entry.value}</li>
                    `);
        }
      } else {
        // number array
        let entries = $$(document.createElement('div')).addClass(
          'f360result-section-content-number-entries',
        );
        section.append(entries);
        for (let entry of content) {
          let gaugeId = uuidv4();
          entries.append(`
                        <div class="f360result-number-entry">
                            <span class="font-text">${entry.text}</span>
                            <div id="f360result-entry-gauge-${gaugeId}" class="f360result-entry-gauge"><div>
                        </div>
                    `);
          app.gauge.create({
            el: '#f360result-entry-gauge-' + gaugeId,
            type: 'circle',
            value: entry.value / 5,
            valueText: entry.value,
            valueFontSize: 52,
            valueTextColor: '#151C23',
            borderWidth: 20,
            borderColor: '#7EB82B',
            borderBgColor: '#9DA2A5',
          });
        }
      }
    } else {
      // string
      section.append(`
                    <span class="f360result-section-content-single font-text">${content}</span>
                `);
    }
  },

  _loadFilter() {
    let container = $$('#f360result-filter');
    container.empty();
    let filter = controller.getFilter(true);
    for (let state in filter.all) {
      if (filter.all.hasOwnProperty(state)) {
        container.append(`
                        <li>
                            <a id="f360result-filter-${state}" class="list-button item-link" href="#">${
          filter.get(state).name
        }</a>
                        </li>
                    `);
        let filterLink = container.find('#f360result-filter-' + state);
        if (filter.get(state).name === filter.current.name) {
          filterLink.addClass('selected');
        }
        filterLink.on('click', () => {
          controller.applyFilter(state);
          container.find('a[id^="f360result-filter-"]').each((ind, el) => {
            $$(el).removeClass('selected');
          });
          filterLink.addClass('selected');
          app.popover.get('#f360result-popover-filter').close();
        });
      }
    }
  },
};
export default f360resultView;
