import $$ from 'dom7';
import app from '../../../app';
import { v4 as uuidv4 } from 'uuid';
import expertiseView from './ExpertiseView';

let controller;

var rankedView = {
  initialized: false,

  init: function (cntrllr, title, count, path, id, del) {
    controller = cntrllr;
    expertiseView.loadMainToolbar();
    expertiseView.loadMainFAB();
    expertiseView.loadTitle(title);
    this.loadFilter();
    expertiseView.loadExternalCounter(count);
    expertiseView.linkButton(path, id, del);
    this.loadPopovers();
    this.initialized = true;
  },

  loadPopovers: function () {
    $$('.page[data-name="expertise"] .popover-open').each((i, el) => {
      el = $$(el);
      app.popoverOpenBelow(el, $$(el.attr('data-popover')));
    });
  },

  onLeave: function () {
    this.initialized = false;
  },

  loadEntries: function (entries) {
    $$('#expertise-entries').empty();
    for (let entry of entries) {
      this.loadEntry(entry);
    }
  },

  loadEntry: function (entry) {
    let entryId = uuidv4();
    $$('#expertise-entries').append(`
                <div class="expertise-entry">
                    <span class="font-h2 expertise-entry-title">${entry.name}</span>
                    <div id="expertise-entry-content-${entryId}"></div>
                </div>
            `);
    if (entry.self) {
      let gaugeId = uuidv4();
      $$('#expertise-entry-content-' + entryId).append(`
                <div class="expertise-entry-self">
                    <div id="expertise-gauge-${gaugeId}"></div>
                    <span class="font-text">Selbsteinschätzung</span>
                </div>
            `);
      app.gauge.create({
        el: '#expertise-gauge-' + gaugeId,
        type: 'circle',
        value: entry.self / controller.maxValue,
        valueText: entry.self,
        valueFontSize: 52,
        valueTextColor: '#151C23',
        borderWidth: 20,
        borderColor: '#40ADFA',
        borderBgColor: '#9DA2A5',
      });
    } else {
      $$('#expertise-entry-content-' + entryId).append('<div></div>');
    }
    if (entry.external) {
      let gaugeId = uuidv4();
      $$('#expertise-entry-content-' + entryId).append(`
                <div class="expertise-entry-external">
                    <div id="expertise-gauge-${gaugeId}"></div>
                    <span class="font-text">Fremdeinschätzung</span>
                </div>
            `);
      app.gauge.create({
        el: '#expertise-gauge-' + gaugeId,
        type: 'circle',
        value: entry.external / controller.maxValue,
        valueText: entry.external,
        valueFontSize: 52,
        valueTextColor: '#151C23',
        borderWidth: 20,
        borderColor: '#7EB82B',
        borderBgColor: '#9DA2A5',
      });
    } else {
      $$('#expertise-entry-content-' + entryId).append('<div></div>');
    }
  },

  loadFilter: function () {
    let container = $$('#expertise-filter');
    container.empty();
    let filter = controller.getFilter(true);
    for (let state in filter.all) {
      if (filter.all.hasOwnProperty(state)) {
        container.append(`
                        <li>
                            <a id="expertise-filter-${state}" class="list-button item-link" href="#">${
          filter.get(state).name
        }</a>
                        </li>
                    `);
        let filterLink = container.find('#expertise-filter-' + state);
        if (filter.get(state).name === filter.current.name) {
          filterLink.addClass('selected');
        }
        filterLink.on('click', () => {
          controller.applyFilter(state);
          container.find('a[id^="expertise-filter-"]').each((ind, el) => {
            $$(el).removeClass('selected');
          });
          filterLink.addClass('selected');
          app.popover.get('#expertise-popover-filter').close();
        });
      }
    }
  },
};
export default rankedView;
