function Filter(view, filters) {
  this.all = filters
    ? filters
    : {
        default: {
          name: 'Standard',
          is: function (item) {
            return true;
          },
        },
      };
  this.current = this.all['default'];
  this.view = view;
}

Filter.prototype.get = function (state) {
  return this.all[state];
};

Filter.prototype.apply = function (state) {
  this.current = this.get(state);
  this.view.refreshFilter();
};

export default Filter;
