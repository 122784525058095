import $$ from 'dom7';
import elementFactory from '../../../../../../core/ui/ElementFactory';

let controller;

var profilePreviewView = {
  init: function (cntrllr, intro, topic, profile, keepProgressbar) {
    controller = cntrllr;
    this.loadTop(keepProgressbar);
    this.loadText(topic, intro);
    this.loadContent(profile);
    this.loadBottom();
    this.loadBackNavigation();
  },

  loadTop: function (keepProgressbar) {
    if (keepProgressbar) {
      $$('#task-page-content .page-content-top')
        .empty()
        .append(
          elementFactory
            .getProgressbar(controller.getProgress())
            .attr('id', 'jobrating-progress'),
        ).append(`
                <h1 id="task-title" class="page-title">Berufe-Rating</h1>
                <h2 id="task-subtitle" class="page-subtitle"></h2>
            `);
    } else {
      $$('#task-page-content .page-content-top').empty().append(`
                <h1 id="task-title" class="page-title">Berufe-Rating</h1>
                <h2 id="task-subtitle" class="page-subtitle"></h2>
            `);
    }
  },

  loadText: function (topic, intro) {
    $$('#task-page-content')[0].scrollTop = 0;
    $$('#task-content')
      .empty()
      .append('<h3 id="jobrating-profile-topic">' + topic + '</h3>')
      .append(intro);
  },

  loadContent: function (profile) {
    $$('#task-content').append(profile.addClass('jobrating-profile-element'));
  },

  loadBottom: function () {
    let next = $$(document.createElement('button'))
      .addClass('button button-fill color-white')
      .append('Weiter')
      .on('click', () => {
        controller.next();
      });
    $$('#task-bottom .button-list').empty().addClass('horizontal').append(next);
  },

  loadBackNavigation: function () {
    const back = $$('.page[data-name="task"] .navbar .left a.link')
      .off('click')
      .removeClass('back');
    back.on('click', () => {
      back.off('click').addClass('back');
      controller.previous();
    });
  },
};
export default profilePreviewView;
