import $$ from 'dom7';
import app from '../../app.js';
import reminderView from './ReminderView.js';
import notificationController from '../../core/NotificationController.js';
import userData from '../../model/user/UserData.js';

const reminderController = {
  load: function (moduleNumber, chapterNumber, exerciseNumber) {
    reminderView.init();
    this.initEventHandler(moduleNumber, chapterNumber, exerciseNumber);
  },
  initEventHandler: function (moduleNumber, chapterNumber, exerciseNumber) {
    if (exerciseNumber != undefined) {
      let exerciseName =
        userData.getModule(moduleNumber).chapters[chapterNumber - 1].exercises[
          exerciseNumber - 1
        ].name;
      var notificationContent = {
        title: 'Erinnerung!',
        text:
          'Du wolltest dich jetzt um die Aufgabe "' +
          exerciseName +
          '" (' +
          moduleNumber +
          '.' +
          chapterNumber +
          '.' +
          exerciseNumber +
          ') kümmern!',
        closeButton: true,
      };
    }

    $$('#set-reminder-button').on('click', function (e, obj) {
      let day = $$('#reminder-date')[0].value;
      let time = $$('#reminder-time')[0].value;

      if (day == '' || time == '') {
        let notification = app.notification.create({
          text: 'Bitte fülle alle Felder aus.',
          closeButton: true,
        });
        notification.open();
      } else {
        let date = new Date(day + 'T' + time);
        notificationController.setTimedNotification(date, notificationContent);
        let notification = app.notification.create({
          text: 'Die Erinnerung wurde erstellt.',
          closeButton: true,
        });
        notification.open();
      }
    });
  },
  /*'initCalendar': function(){
        var cal = app.calendar.create({
            'inputEL' : '#calendar-reminder'
        });
        cal.open();
    }*/
};

export default reminderController;
