const dateController = {
  getDateFromString: function (something) {
    let date = new Date(something);
    if (!date || date.toDateString() === 'Invalid Date') {
      let tmp = Date.parse(something);
      if (tmp instanceof Date) date = tmp;
    }
    if (!date || date.toDateString() === 'Invalid Date') {
      let tmp = something.split('.');
      if (tmp.length >= 3)
        date = new Date(
          parseInt(tmp[2]),
          parseInt(tmp[1]) - 1,
          parseInt(tmp[0]),
        );
    }
    if (!date || date.toDateString() === 'Invalid Date') {
      let tmp = something.split('/');
      if (tmp.length >= 3)
        date = new Date(
          parseInt(tmp[2]),
          parseInt(tmp[1]) - 1,
          parseInt(tmp[0]),
        );
    }
    return date;
  },

  getStringFromDate: function (something) {
    let date = this.getDateFromString(something);
    let month = '' + (date.getMonth() + 1);
    let day = '' + date.getDate();
    let year = date.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return day + '.' + month + '.' + year;
  },
  getDMYStringFromDate: function (something) {
    let date = this.getDateFromDMYString(something);
    let month = '' + (date.getMonth() + 1);
    let day = '' + date.getDate();
    let year = date.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return day + '.' + month + '.' + year;
  },

  getStringFromTime: function (something) {
    let date = this.getDateFromString(something);
    let hour = '' + date.getHours();
    if (hour.length < 2) hour = '0' + hour;
    let minute = '' + date.getMinutes();
    if (minute.length < 2) minute = '0' + minute;
    return hour + ':' + minute;
  },

  getStringFromDateTime: function (something) {
    return (
      this.getStringFromDate(something) +
      '  ' +
      this.getStringFromTime(something)
    );
  },

  getRelativeStringFromDate: function (something) {
    let date = this.getDateFromString(something);
    let now = new Date();
    let yesterday = new Date();
    yesterday.setDate(now.getDate() - 1);
    let theDayBefore = new Date();
    theDayBefore.setDate(now.getDate() - 2);
    if (this.isSameDay(date, now)) {
      return 'heute';
    } else if (this.isSameDay(date, now.s)) {
      return 'gestern';
    } else if (this.isSameDay(date, now)) {
      return 'vorgestern';
    } else {
      return this.getStringFromDate(date);
    }
  },

  getRelativeStringFromDateTime: function (something) {
    return (
      this.getRelativeStringFromDate(something) +
      '  ' +
      this.getStringFromTime(something)
    );
  },

  getWeek: function (date) {
    var dt = new Date(date.getTime());
    dt.setHours(0, 0, 0, 0);
    // Thursday in current week decides the year.
    dt.setDate(dt.getDate() + 3 - ((dt.getDay() + 6) % 7));
    // January 4 is always in week 1.
    var week1 = new Date(dt.getFullYear(), 0, 4);
    // Adjust to Thursday in week 1 and count number of weeks from date to week1.
    return (
      1 +
      Math.round(
        ((dt.getTime() - week1.getTime()) / 86400000 -
          3 +
          ((week1.getDay() + 6) % 7)) /
          7,
      )
    );
  },

  getWeekYear: function (date) {
    var dt = new Date(date.getTime());
    dt.setDate(dt.getDate() + 3 - ((dt.getDay() + 6) % 7));
    return dt.getFullYear();
  },

  isSameDay: function (first, second) {
    return (
      first.getFullYear() === second.getFullYear() &&
      first.getMonth() === second.getMonth() &&
      first.getDate() === second.getDate()
    );
  },

  isSameWeek: function (first, second) {
    return (
      dateController.getWeek(first) === dateController.getWeek(second) &&
      dateController.getWeekYear(first) === dateController.getWeekYear(second)
    );
  },

  isSameMonth: function (first, second) {
    return (
      first.getMonth() === second.getMonth() &&
      first.getUTCFullYear() === second.getUTCFullYear()
    );
  },

  getMinutesStringFromFloat: function (float) {
    let minus = float < 0;
    if (minus) {
      float = -float;
    }
    let hours = parseInt(float / 3600);
    let minutes = parseInt((float - hours * 60) / 60);
    let seconds = parseInt(float - hours * 3600 - minutes * 60);
    return (
      (minus ? '-' : '') +
      (hours ? hours + ':' : '') +
      minutes +
      ':' +
      ('0' + seconds).slice(-2)
    );
  },
};
export default dateController;
