import Tutorial from './Tutorial';
import $$ from 'dom7';
import app from '../../../app';

var tutorialController = {
  __LOCALSTORAGE_KEY_ENABLED: 'tutorials-enabled',
  __LOCALSTORAGE_KEY_DISABLED_TUTORIALS: 'tutorial-disabled',

  _json: require('../../../download/tutorial'),
  _enabled: null,
  _tutorial: null,

  init() {
    this.isEnabled();
    if (this._enabled) {
      this._start();
    }
  },

  _start() {
    let _this = this;
    $$(document).on('page:afterin', function () {
      _this.loadTutorial();
    });
  },

  loadTutorial() {
    let _this = this;
    let page = $$('.page.page-current');
    let pageName = page.attr('data-name');
    if (this.tutorial != null) {
      this.tutorial.cancel();
    }
    this.tutorial = null;

    let handleTutorial = function (tutorials, index) {
      // if there are more
      if (tutorials && tutorials.length > index) {
        if (_this._isTutorialEnabled(tutorials[index]._id)) {
          // enabled
          // create tutorial
          if (_this.tutorial == null) {
            _this.tutorial = new Tutorial(page);
          }
          _this.tutorial.empty().setText(tutorials[index].text);
          for (let element of tutorials[index].elements) {
            if (typeof element === 'string') {
              _this.tutorial.addElement($$('.page-current ' + element));
            } else {
              _this.tutorial.addElement(
                $$('.page-current ' + element.selector),
                element.shape,
              );
            }
          }
          // prepare page
          let prepareTime = 0;
          if (tutorials[index].open) {
            for (let click of tutorials[index].open) {
              if (click === '#main-fab') {
                app.fab.open('.page-current #main-fab');
                prepareTime = 300;
              } else if (click === '#main-fab-notebook') {
                setTimeout(() => {
                  $$('.page-current #main-fab-notebook').click();
                }, 100);
              } else {
                $$('.page-current ' + click).click();
              }
            }
          }

          // time to get page prepared
          setTimeout(() => {
            if (!_this.tutorial) {
              return;
            }
            _this.tutorial
              .show()
              // after closed
              .then((element) => {
                _this._setTutorialEnabled(tutorials[index]._id, false);
                setTimeout(
                  () => {
                    // repair page
                    if (tutorials[index].close) {
                      for (let click of tutorials[index].close) {
                        if (click === '#main-fab') {
                          app.fab.close('.page-current #main-fab');
                        } else {
                          $$('.page-current ' + click).click();
                        }
                      }
                    }

                    // handle next
                    if (!element) {
                      // clicked on nothing
                      handleTutorial(tutorials, index + 1);
                    } else {
                      setTimeout(() => {
                        if (
                          $$('.page.page-current').attr('data-name') ===
                          pageName
                        ) {
                          // page not changed
                          handleTutorial(tutorials, index + 1);
                        }
                      });
                    }
                  },
                  tutorials[index].close ? prepareTime : 0,
                );
              });
          }, prepareTime);
        } else {
          // disabled
          handleTutorial(tutorials, index + 1);
        }
      }
    };

    let tutorials = this._json.tutorials[pageName];
    // handle first
    handleTutorial(tutorials, 0);
  },

  _isTutorialEnabled(id) {
    return !this.__getDisabledTutorials().includes(id);
  },

  _setTutorialEnabled(id, enabled) {
    let disabledTutorials = this.__getDisabledTutorials();
    if (enabled && disabledTutorials.includes(id)) {
      // remove item from array
      let index = disabledTutorials.indexOf(id);
      while (index > -1) {
        disabledTutorials.splice(index, 1);
        index = disabledTutorials.indexOf(id);
      }
    } else if (!enabled && !disabledTutorials.includes(id)) {
      disabledTutorials.push(id);
    } else {
      return;
    }
    this.__setDisabledTutorials(disabledTutorials);
  },

  __getDisabledTutorials() {
    return JSON.parse(
      localStorage.getItem(this.__LOCALSTORAGE_KEY_DISABLED_TUTORIALS) || '[]',
    );
  },

  __setDisabledTutorials(value) {
    return localStorage.setItem(
      this.__LOCALSTORAGE_KEY_DISABLED_TUTORIALS,
      JSON.stringify(value),
    );
  },

  enable() {
    this._enabled = true;
    this.__setEnabled(this._enabled);
  },

  disable() {
    this._enabled = false;
    this.__setEnabled(this._enabled);
  },

  isEnabled() {
    if (this._enabled == null) {
      this._enabled = this.__getEnabled();
    }
    return this._enabled;
  },

  __getEnabled() {
    return JSON.parse(
      localStorage.getItem(this.__LOCALSTORAGE_KEY_ENABLED) || 'true',
    );
  },

  __setEnabled(value) {
    localStorage.setItem(
      this.__LOCALSTORAGE_KEY_ENABLED,
      JSON.stringify(value),
    );
  },
};
export default tutorialController;
