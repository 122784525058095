function Observer() {
  this.handlers = [];
}

Observer.prototype.addOberserver = function (observer) {
  this.handlers.push(observer);
};

Observer.prototype.notify = function (...args) {
  for (let handler of this.handlers) {
    handler(...args);
  }
};

Observer.prototype.removeOberserver = function (observer) {
  const index = this.handlers.indexOf(observer);
  if (index > -1) {
    this.handlers.splice(index, 1);
  }
};
export default Observer;
