import taskView from './TaskView';
import inputController from './input/InputController';
import goalController from './types/goal/GoalController';
import coursecatalogController from './types/coursecatalog/CoursecatalogController';
import educationPathController from './types/educationpath/EducationpathController';
import swotController from './types/swot/SwotController';
import generateideasController from './types/GenerateideasController';
import costcalculationController from './types/costcalculation/CostcalculationController';
import structuredinterestsController from './types/structuredinterests/StructuredinterestsController';
import gummybearController from './types/gummybear/GummybearController';
import feedbackController from './types/feedback/FeedbackController';
import userController from '../../../../core/UserController';
import makedecisionsController from './types/MakedecisionsController';
import reflectionController from './types/reflection/ReflectionController';
import addtestresultsController from './types/addtestresults/AddtestresultsController';

const taskController = {
  task: null,

  entryTime: 0,

  load: function (moduleNumber, chapterNumber, exerciseNumber, taskNumber) {
    this.task = this.getTask(
      moduleNumber,
      chapterNumber,
      exerciseNumber,
      taskNumber,
    );
    switch (this.task.type) {
      default:
        break;
      case -5:
        addtestresultsController.init(this.task);
        break;
      case -4:
        reflectionController.init(this.task);
        break;
      case -3:
        makedecisionsController.init(
          this.getExercise(moduleNumber, chapterNumber, exerciseNumber),
        );
        break;
      case -2:
        generateideasController.init(
          this.getExercise(moduleNumber, chapterNumber, exerciseNumber),
        );
        break;
      // 0 and -1 are reserved for invalid values
      case 1:
        taskView.load(this.task, null, true);
        break;
      case 2:
        goalController.load(this.task);
        break;
      case 3:
        gummybearController.load(this.task);
        break;
      case 9:
        educationPathController.init(
          this.task,
          moduleNumber,
          chapterNumber,
          exerciseNumber,
        );
        break;
      case 11:
        coursecatalogController.init(
          this.task,
          moduleNumber,
          chapterNumber,
          exerciseNumber,
        );
        break;
      case 12:
        swotController.init(
          this.task,
          moduleNumber,
          chapterNumber,
          exerciseNumber,
        );
        break;
      case 14:
        costcalculationController.init(this.task);
        break;
      case 15:
        structuredinterestsController.init(this.task);
        break;
      case 16:
        feedbackController.init(
          this.task,
          moduleNumber,
          chapterNumber,
          exerciseNumber,
        );
        break;
    }
  },

  getTaskInputElements: function (task) {
    let res = [];
    for (let inputindex in task.inputs) {
      res.push(inputController.getInput(task, inputindex));
    }
    return res;
  },

  finishTask: function () {
    this.task.finish();
  },

  updateTime: function (moduleNumber, chapterNumber, exerciseNumber) {
    var module = userController.getModule(moduleNumber);
    var chapter = module.chapters[chapterNumber - 1];
    var exercise = chapter.exercises[exerciseNumber - 1];
    exercise.updateTime(performance.now() - this.entryTime);
  },

  getTask: function (moduleNumber, chapterNumber, exerciseNumber, taskNumber) {
    return this.getExercise(moduleNumber, chapterNumber, exerciseNumber).tasks[
      taskNumber - 1
    ];
  },

  getExercise: function (moduleNumber, chapterNumber, exerciseNumber) {
    let module = userController.getModule(moduleNumber);
    let chapter = module.chapters[chapterNumber - 1];
    return chapter.exercises[exerciseNumber - 1];
  },
};

export default taskController;
