export default {
  id: '9decd7868c',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class="page expertises-page background-image background-mountain" data-name=expertise><!-- Navbar --><div class="navbar navbar-transparent no-shadow no-hairline"><div class=navbar-inner><div class=left><a class="link back"><i class="icon material-icons">navigate_before</i></a></div><div class=right><a class="link popover-open" href=# data-popover=#expertise-popover-menu><i class="icon material-icons">more_vert</i></a></div></div></div><!-- Toolbar--><div class=main-toolbar></div><!-- Floating Action Button --><div class=main-fab></div><!-- Scrollable page content--><div id=expertise-page-content class=page-content><div class=page-content-top><h1 id=expertise-title class=page-title></h1></div><div class=page-content-main><div class=filter-cards><a class=popover-open href=# data-popover=#expertise-popover-filter><label>Sortieren nach</label> <i class="icon material-icons">navigate_next</i></a></div><div id=expertise-entries class=white-card></div></div><div class=page-content-bottom><div class="button-list shadowed"><div id=expertise-external-counter class=button-list-before-right>Bewertungen abgegeben <i class="icon material-icons">person</i> <i class="icon material-icons">person</i> <i class="icon material-icons">person</i></div><button class="button button-fill color-white full-width expertise-task-button">zur Aufgabe</button></div></div></div><div id=expertise-popover-menu class=popover><div class=popover-inner><div class=list><ul><li><a class="list-button item-link expertise-edit-button" href=#>Ranking bearbeiten</a></li><li><a class="list-button item-link expertise-task-button" href=#>Zur Aufgabe</a></li><li><a class="list-button item-link expertise-delete-button" href=#>Ergebnisse löschen</a></li></ul></div></div></div><div id=expertise-popover-filter class=popover><div class=popover-inner><div class=list><ul id=expertise-filter></ul></div></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};