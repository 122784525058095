import introView from '../IntroView';
import checkQuestionsController from './checkquestions/CheckquestionsController';
import jobratingController from './jobrating/JobratingController';
import app from '../../../../../app';

var generateideasController = {
  exercise: null,

  init: function (exercise) {
    if (exercise) {
      this.exercise = exercise;
    }
    this.displayIntro();
  },

  displayIntro: function () {
    introView.addButton('Richtungs-Check', () => {
      generateideasController.displayCheckquestions();
    });
    introView.addButton('Berufe-Rating', () => {
      generateideasController.displayJobrating();
    });
    introView.updateBackNavigation(() => app.views.main.router.back());
    introView.init(null, {
      name: 'Ideen generieren',
      intro:
        'Du hast jetzt die Möglichkeit, dir mit deinen gesammelten Werten aus dem Profil Berufsideen zu generieren. Die Aufgabe Richtungs-Check hilft dir, Berufsfelder zu entdecken und zu bewerten. Beim Berufe-Rating vergleichst du die Werte aus deinem Profil mit den Berufsfeldern.',
    });
    introView.loadMainFAB();
  },

  displayCheckquestions: function () {
    checkQuestionsController.init(generateideasController.exercise.tasks[0]);
  },

  displayJobrating: function () {
    jobratingController.init(generateideasController.exercise.tasks[1]);
  },
};
export default generateideasController;
