import $$ from 'dom7';
import app from '../../../app';
import controller from './ExerciseController';
import mediaController from '../../../core/MediaController';

const exerciseView = {
  module: null,

  chapter: null,

  exercise: null,

  accordions: [],

  nextButton: null,

  init: function (module, chapter, exercise, nextButton) {
    let _this = this;

    this.module = module;
    this.chapter = chapter;
    this.exercise = exercise;
    this.previousOpenAccordion = null;
    this.nextButton = nextButton;
    this.loadBackNavigation();
    this.loadMainToolbar();
    this.loadMainFAB();
    this.loadBackground();
    this.loadTitle();
    let toggle = localStorage.getItem('swiper-accordion-toggle');
    if (toggle === null) {
      toggle = 'true';
      localStorage.setItem('swiper-accordion-toggle', true);
    }
    toggle = toggle == 'true';
    this.loadContentSwiper();
    if (toggle) {
      this.loadContentSwiper();
    } else {
      this.loadContentAccordions();
    }
    this.initToggleListener();
  },

  // handle init of view-elements in here
  elements: {
    // called on creating content to initialize elements
    init: function (content) {
      for (let key in this) {
        if (
          this.hasOwnProperty(key) &&
          key.startsWith('init') &&
          // exceptions
          !['init', 'initInlineTask'].includes(key)
        ) {
          // calls all functions starting with 'init' in elements
          this[key](content);
        }
      }
    },

    initInputs: function (content) {
      let tasks = controller.getInlineTasksFromContent(content);
      tasks.each((ix, el) => {
        let task = $$(el);
        this.initInlineTask(task.attr('id').split('-')[1], task);
      });
    },

    initInlineTask: function (taskNumber, element) {
      element.empty();

      if (element.hasClass('button-link')) {
        element.hide();
      }
      controller.getInlineTask(taskNumber).forEach((input) => {
        element.append(input);
      });
    },

    initAudioPlayer: function (content) {
      let audioElement = content.find('audio');
      if (audioElement.length > 0) {
        audioElement.each((index, element) => {
          element = $$(element);

          // separate title from src
          let title = element.attr('src').split('/').pop();
          if (title.endsWith(mediaController.AUDIO_SUFFIX)) {
            title = title.split('.').slice(0, -1).join('.');
          }

          // view elements
          let audioPlayer = mediaController.getStepsAudioPlayer(
            title,
            exerciseView.getTitle(),
          );
          let playButton = $$(document.createElement('div'))
            .addClass(
              'exercise-read-play-button' +
                (audioPlayer.isPlaying() ? ' playing' : ''),
            )
            .append(
              `
                            <i class="icon material-icons">volume_up</i>
                            <span>Abschnitt anhören</span>
                        `,
            )
            .on('click', () => {
              if (audioPlayer.isShowing()) {
                audioPlayer.hide();
                audioPlayer.pause();
              } else {
                audioPlayer.show();
                audioPlayer.play();
              }
            });
          audioPlayer.onPlayStateChanges((state) => {
            if (state === 'playing') {
              playButton.addClass('playing');
            } else {
              playButton.removeClass('playing');
            }
          });
          playButton.insertBefore(element);
          element.remove();
        });
      }
    },
  },

  loadBackNavigation: function () {
    $$('.page[data-name="exercise"] .link-back').on('click', (e) => {
      e.preventDefault();
      app.views.main.router.back(`/mod/${this.module.number}/`, {
        force: true,
      });
    });
  },

  loadMainToolbar: function () {
    app.loadMainToolbar($$('.page[data-name="exercise"] .main-toolbar'));
  },

  loadMainFAB: function () {
    app.loadMainFAB(
      $$('.page[data-name="exercise"] .main-fab'),
      this.module.number,
    );
  },

  loadBackground: function () {
    $$('.page.background-image[data-name="exercise"]').css({
      'background-image': `url(${this.module.getBackgroundImage()})`,
    });
  },

  loadTitle: function () {
    $$('#exercise-page-content h1.page-title').empty();
    $$('#exercise-page-content h2.page-subtitle').empty();
    $$('#exercise-page-content h1.page-title').append(this.exercise.name);
    $$('#exercise-page-content h2.page-subtitle').append(this.getTitle());
  },

  getTitle: function () {
    return 'Schritt ' + this.module.number + ' - Block ' + this.chapter.number;
  },

  initToggleListener: function () {
    app.toggle.destroy('.toggle');
    let _this = this;

    let toggle = app.toggle.create({
      el: '.toggle',
      params: {
        checked: false,
      },
      checked: false,
      on: {
        change: function () {
          toggle.checked
            ? _this.loadContentSwiper()
            : _this.loadContentAccordions();
          localStorage.setItem(
            'swiper-accordion-toggle',
            String(toggle.checked),
          );
        },
      },
    });
    toggle.checked = localStorage.getItem('swiper-accordion-toggle') == 'true';
  },
  loadContentSwiper: function (exercise) {
    let nextbutton = $$('#exercise-next-button').addClass('shadowed'),
      swipercontainer = $$('#exercise-subblocks')
        .empty()
        .removeClass('columns'),
      _this = this;

    $$('#accordion-button-list').empty();
    //app.swiper.destroy('#exercise-subblocks');
    app.swiper.destroy(swipercontainer);
    swipercontainer.empty().removeClass('columns');
    swipercontainer.html(
      `<div class="swiper-pagination"></div><div class="swiper-wrapper margin-bottom"></div>`,
    );
    //swipercontainer.html(`<div class="swiper-pagination"></div><div class="swiper-wrapper"></div>`).attr("data-space-between", "50").attr("data-pagination", '{"el": ".swiper-pagination"}').attr('data-prevent-clicks', 'false').attr('data-prevent-clicks-propagation', 'false')

    let swiperwrapper = swipercontainer.find('.swiper-wrapper');
    let lasttextblock = 0;
    for (const [index, subblock] of this.exercise.subblocks.entries()) {
      if (!subblock.isTask) lasttextblock = index;
    }
    for (const [index, subblock] of this.exercise.subblocks.entries()) {
      if (subblock.isTask) {
        continue;
      }
      let slide = this.getContentCard(subblock)
        .find('.accordion-item-content')
        .removeClass('accordion-item-content')
        .addClass('swiper-slide');
      slide.prepend(
        $$(document.createElement('div'))
          .addClass('block-title')
          .html(subblock.title),
      );
      let nextindex = index + 1;
      while (
        this.exercise.subblocks[nextindex] &&
        this.exercise.subblocks[nextindex].isTask == true
      ) {
        slide
          .find('.content')
          .append(
            this.getTaskButton(this.exercise.subblocks[nextindex]).addClass(
              'shadowed',
            ),
          );
        nextindex++;
      }
      if (index == lasttextblock) {
        slide
          .find('.content')
          .append(
            $$(document.createElement('div')).attr('id', 'swiper-button-list'),
          );
      }
      swiperwrapper.append(slide);
    }
    swipercontainer.addClass('swiper-container exercise-swiper');
    this.loadNextButton(
      this.nextButton,
      swiperwrapper.find('#swiper-button-list'),
    );
    if (this.exercise.actions) {
      for (let action of this.exercise.actions) {
        _this.loadActionButton(
          action,
          swiperwrapper.find('#swiper-button-list'),
        );
      }
    }
    swipercontainer.addClass('swiper-container exercise-swiper');
    var mySwiper = app.swiper.create(swipercontainer, {
      spaceBetween: 50,
      direction: 'horizontal',
      pagination: { el: '.swiper-pagination', type: 'progressbar' },
      //speed: 400,
      preventClicks: false,
      preventClicksPropagation: false,
      autoHeight: true,
      on: {
        slideChange: function (e) {
          $$(document).scrollTop();

          $$('.page-content').scrollTop(0, 600);
          if (this.swipeDirection == 'next') {
            _this.exercise.setFinishSubblock(this.activeIndex - 1, true);
            _this.finishTaskOnSlide(this.activeIndex - 1);
          }
        },
      },
    });
  },
  loadContentAccordions: function (exercise) {
    //let nextbutton = $$('#exercise-next-button').removeClass('shadowed')
    //$$('.button-list').empty().append(nextbutton)

    app.swiper.destroy($$('#exercise-subblocks'));
    $$('#exercise-subblocks')
      .removeClass('swiper-container exercise-swiper')
      .addClass('container')
      .empty()
      .html(`<div class="column-0"></div><div class="column-1"></div>`);
    let columns = $$('#exercise-subblocks > div[class|="column"]'),
      _this = this;
    columns.each((index, column) => {
      $$(column).empty();
    });
    this.accordions = [];
    for (let index = 0; index < this.exercise.subblocks.length; index++) {
      let onlyButton = true;
      let card = exerciseView.getSubblockCard(this.exercise.subblocks[index]);
      if (card.hasClass('button')) {
        if (index === this.exercise.subblocks.length - 1 && onlyButton) {
          card.addClass('button-top');
          //$$('#exercise-page-content > .page-content-main > .button-list').prepend(card);
          //continue;
        }
        onlyButton = false;
      } else {
        this.accordions[this.exercise.subblocks[index].number] =
          card.find('.accordion-item');
      }
      if (index < parseInt((this.exercise.subblocks.length + 1) / 2)) {
        columns
          .filter((index, column) => $$(column).hasClass('column-0'))
          .append(card);
      } else {
        columns
          .filter((index, column) => $$(column).hasClass('column-1'))
          .append(card);
      }
    }
    $$('#accordion-button-list').empty();
    this.loadNextButton(this.nextButton, $$('#accordion-button-list'));
    if (this.exercise.actions) {
      for (let action of this.exercise.actions) {
        _this.loadActionButton(action, $$('#accordion-button-list'));
      }
    }
    this.initOpenAccordions();
  },

  getSubblockCard: function (subblock) {
    return subblock.isTask
      ? this.getTaskButton(subblock)
      : this.getContentCard(subblock);
  },

  getContentCard: function (subblock) {
    const card = $$(document.createElement('div'));
    card.addClass('card card-line-small list');
    card.append(`
            <div class="card-content accordion-item">
                <a href="#" class="item-content item-link">
                    <div class="item-inner">
                        ${subblock.title}
                    </div>
                </a>
                <div class ="accordion-item-content">
                    <div class="content list">${subblock.content}</div>
                </div>
            </div>
        `);

    this.elements.init(card.find('.content'));
    return card;
  },

  getTaskButton: function (subblock) {
    const button = $$(document.createElement('button'));
    button.addClass('button button-fill color-white task-button-link');
    button.attr('id', subblock.task.name.replace(' ', '-'));
    button.append(subblock.title);
    button.on('click', () => {
      app.views.main.router.navigate(
        '/task/' +
          subblock.task.module_number +
          '/' +
          subblock.task.chapter_number +
          '/' +
          subblock.task.exercise_number +
          '/' +
          subblock.task.number +
          '/',
      );
    });
    return button;
  },

  loadNextButton: function (nextButton, container) {
    let nextButtonDOM = $$(document.createElement('button')).addClass(
        'button button-fill color-white full-width shadowed',
      ),
      _this = this;
    nextButtonDOM.off('click');
    if (nextButton) {
      nextButtonDOM.html(nextButton.title);

      nextButtonDOM.on('click', (e) => {
        $$(e.target).attr('id', '');
        _this.exercise.setFinishSubblock(
          this.exercise.finishedSubblocks.length - 1,
          true,
        );
        _this.finishTaskOnSlide(this.exercise.description_long.length - 1);
        controller.next();
      });
      nextButtonDOM.removeClass('hide');
    } else {
      nextButtonDOM.addClass('hide');
    }
    container.append(nextButtonDOM);
  },
  loadActionButton: function (button, container) {
    if (button.link) {
      let buttonDOM = $$(document.createElement('a'))
        .addClass('button button-fill color-white full-width shadowed')
        .attr('href', button.link)
        .html(button.title);
      button.external
        ? buttonDOM.addClass('link external').attr('target', '_system')
        : null;
      container.append(buttonDOM);
    } else if (button.function) {
    }
  },

  initOpenAccordions: function () {
    let _this = this;
    for (let current in this.accordions) {
      this.accordions[current].on('accordion:beforeopen', () => {
        const opened = this.accordions.filter((acc) =>
          $$(acc).hasClass('accordion-item-opened'),
        );
        for (let acc of opened) {
          app.accordion.close(acc);
        }
        controller.saveSubblock(
          _this.module.number,
          _this.chapter.number,
          _this.exercise.number,
          current,
        );

        this.exercise.setFinishSubblock(current - 1, false);
        if (this.previousOpenAccordion != null) {
          this.exercise.setFinishSubblock(this.previousOpenAccordion - 1, true);
        }
        this.previousOpenAccordion = current;
      });
    }
  },

  openSubblock: function (subblockNumber) {
    app.accordion.open(this.accordions[subblockNumber]);
    this.exercise.bookmark = subblockNumber;
  },

  forOpenAccordions: function (func) {
    for (let current in this.accordions) {
      if ($$(this.accordions[current]).hasClass('accordion-item-opened')) {
        func(this.previousOpenAccordion);
      }
    }
  },

  finishTaskOnSlide: function (slideIndex) {
    let taskIndex,
      hasTask = this.exercise.description_long[slideIndex].includes('task-');
    if (hasTask) {
      taskIndex = this.exercise.description_long[slideIndex]
        .split("<div id='task-")[1]
        .split("'></div>")[0];
      this.exercise.tasks[taskIndex - 1].finish();
    }
  },
};
export default exerciseView;
