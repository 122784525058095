import $$ from 'dom7';
import app from '../../../../../../app';
import elementFactory from '../../../../../../core/ui/ElementFactory';

const costcalculationView = {
  pages: {},

  init: function (task) {
    this.costcategories = task.costcategories;
    this.incomecategories = task.incomecategories;
    this.initPage(task);
    this.loadIntroduction(task, true);
  },

  initPage: function (task) {
    $$('#task-title').html(task.name);
  },

  loadIntroduction: function (task, costs) {
    let content = $$('#task-content'),
      _this = this,
      text,
      link;

    // button
    $$('#task-bottom > .button-list')
      .empty()
      .append(
        $$(document.createElement('button'))
          .addClass('button button-fill color-white')
          .append("Los geht's")
          .on('click', () => _this.loadPage(0, task, costs)),
      );

    if (costs) {
      text = task.intro_text_costs;
    } else {
      text = task.intro_text_income;
    }
    content.empty();
    content.append($$(document.createElement('div')).html(`<p>${text}</p>`));

    //back navigation
    let nav = $$('.navbar-inner a.link');
    nav.off('click');
    nav.addClass('back');
    if (!costs) {
      nav.removeClass('back');
      nav.on('click', () => {
        _this.loadResult(task, !costs);
      });
    }
  },

  loadPage: function (index, task, costs, edit) {
    let content = $$('#task-content'),
      _this = this,
      page;
    let answerindex, placeholder, categories;
    if (costs) {
      placeholder = ' Kosten für ';
      categories = this.costcategories;
      answerindex = 0;
    } else {
      placeholder = ' Einnahmen durch ';
      categories = this.incomecategories;
      answerindex = 1;
    }
    page = categories[index];

    //back navigation
    let nav = $$('.navbar-inner a.link');
    nav.off('click');
    nav.removeClass('back');
    if (index > 0) {
      nav.on('click', () => {
        _this.loadPage(index - 1, task, costs);
      });
    } else {
      nav.on('click', () => {
        _this.loadIntroduction(task, costs);
      });
    }

    content.empty();
    content.css('margin-bottom', '0px');
    content.append(
      $$(document.createElement('div'))
        .attr('id', 'costcalculation-content')
        .html(`<p>${page.question}?</p>`),
    );
    content.append(
      elementFactory.getLabeledTextInput(
        'Mindesteinschätzung',
        'Tippen, um' + placeholder + page.categorie + ' einzugeben',
        task.answers[answerindex][index][0],
      ),
    );
    content.append(
      elementFactory.getLabeledTextInput(
        'Optimale Schätzung',
        'Tippen, um' + placeholder + page.categorie + ' einzugeben',
        task.answers[answerindex][index][1],
      ),
    );
    content.find('input').attr('type', 'number');

    // button
    $$('#task-bottom > .button-list')
      .empty()
      .append(
        edit
          ? $$(document.createElement('button'))
              .addClass('button button-fill color-white')
              .append('fertig')
              .on('click', () => {
                $$('input').forEach((e, i) => {
                  e.value == ''
                    ? (task.answers[answerindex][index][i - 1] = 0)
                    : (task.answers[answerindex][index][i - 1] = parseInt(
                        e.value,
                        10,
                      ));
                });
                task.finish();
                _this.loadResult(task, costs);
              })
          : $$(document.createElement('button'))
              .addClass('button button-fill color-white')
              .append('Weiter')
              .on('click', () => {
                $$('input').forEach((e, i) => {
                  e.value == ''
                    ? (task.answers[answerindex][index][i - 1] = 0)
                    : (task.answers[answerindex][index][i - 1] = parseInt(
                        e.value,
                        10,
                      ));
                });
                task.save();
                index < categories.length - 1
                  ? _this.loadPage(index + 1, task, costs)
                  : _this.loadResult(task, costs);
              }),
      );
  },
  loadResult: function (task, costs) {
    let content = $$('#task-content'),
      _this = this;
    let totalmin = task.answers[0].map((el) => el[0]).reduce((a, c) => a + c),
      totalmax = task.answers[0].map((el) => el[1]).reduce((a, c) => a + c),
      incomemin = task.answers[1].map((el) => el[0]).reduce((a, c) => a + c),
      incomemax = task.answers[1].map((el) => el[1]).reduce((a, c) => a + c);
    let mincolor = incomemin - totalmin < 0 ? '#D8307D' : 'null',
      maxcolor = incomemax - totalmax < 0 ? '#D8307D' : '';

    let answerindex, placeholder, categories;
    if (costs) {
      placeholder = ' Kosten für ';
      categories = this.costcategories;
      answerindex = 0;
    } else {
      placeholder = ' Einnahmen durch ';
      categories = this.incomecategories;
      answerindex = 1;
    }

    //back navigation
    let nav = $$('.navbar-inner a.link');
    nav.off('click');
    nav.removeClass('back');
    nav.on('click', () => _this.loadPage(categories.length - 1, task, costs));

    content.empty();
    content.append(
      $$(document.createElement('div'))
        .attr('id', 'costcalculation-content')
        .html(`<p>${task.closing_text_costs}?</p>`),
    );
    let card = $$(document.createElement('div')).addClass(
      'card data-table card-full-width',
    ).html(`<div class="card-top"></div>
                <table>
                    <thead>
                        <tr>
                            <th></th>
                            <th style="hypens: auto;">Mindest&shyschätzung</th>
                            <th style="hyphens: auto; wordbreak: auto;">Optimale Schätzung</th>
                        </tr>
                    </thead>
                    <tbody></tbody>
                </table>
            `);
    card.find('.card-top').append(
      // edit
      $$(document.createElement('i'))
        .addClass('icon material-icons')
        .append('edit')
        .on('click', () => {
          _this.enableEditMode();
        }),
    );
    let table = card.find('tbody');
    for (let i = 0; i < categories.length; i++) {
      table.append(
        $$(document.createElement('tr'))
          .html(
            `<td class="label-cell">${categories[i].categorie}</td><td class="numeric-cell">${task.answers[answerindex][i][0]}</td><td class="numeric-cell">${task.answers[answerindex][i][1]}</td><td class="icon-cell"><i class="icon material-icons">edit</i></td>`,
          )
          .on('click', () => {
            _this.disableEditMode();
            costcalculationView.loadPage(i, task, costs, true);
          }),
      );
    }

    if (!costs) {
      table.append(
        $$(document.createElement('tr')).html(
          `<td class="label-cell"><b>Gesamt</b></td><td class="numeric-cell">${incomemin}</td><td class="numeric-cell">${incomemax}</td>`,
        ),
      );
      table.append(
        $$(document.createElement('tr')).html(
          `<td class="label-cell margin-top">Ausgaben</td><td  class="numeric-cell">${totalmin}</td><td  class="numeric-cell">${totalmax}</td>`,
        ),
      );
      table.append(
        $$(document.createElement('tr')).html(
          `<td class="label-cell"><b>Differenz</b></td><td style="color:${mincolor};" class="numeric-cell">${
            incomemin - totalmin
          }</td><td style="color:${maxcolor};" class="numeric-cell">${
            incomemax - totalmax
          }</td>`,
        ),
      );
    } else {
      table.append(
        $$(document.createElement('tr')).html(
          `<td class="label-cell"><b>Gesamt</b></td><td class="numeric-cell">${totalmin}</td><td class="numeric-cell">${totalmax}</td>`,
        ),
      );
    }
    content = $$('#costcalculation-content');
    content.append(card);

    // buttons
    let buttonList = $$('#task-bottom > .button-list').empty();

    if (costs) {
      buttonList.append(
        $$(document.createElement('button'))
          .attr('id', 'costcalculation-income-button')
          .addClass('button button-fill color-white')
          .append('Einkommen berechnen')
          .on('click', () => this.loadIntroduction(task, false)),
      );
    }
    buttonList.append(
      $$(document.createElement('button'))
        .attr('id', 'costcalculation-next-button')
        .addClass('button button-fill color-white')
        .append('Nächstes Thema')
        .on('click', () => app.views.main.router.navigate('/ex/7/2/4/0/')),
    );
  },

  enableEditMode: function () {
    $$('#costcalculation-content').addClass('edit-mode');
    $$('#costcalculation-income-button').addClass('hide');
    $$('#costcalculation-next-button').addClass('disabled');
    this.loadFormNavbar();
  },

  disableEditMode: function () {
    $$('#costcalculation-content').removeClass('edit-mode');
    $$('#costcalculation-income-button').removeClass('hide');
    $$('#costcalculation-next-button').removeClass('disabled');
    this.loadNormalNavbar();
  },

  loadFormNavbar: function () {
    let _this = this;
    const navbar = $$('.page[data-name="task"] .navbar');
    navbar.removeClass('navbar no-shadow navbar-transparent no-hairline');
    navbar.empty();
    app.loadFormNavbar(
      navbar,
      'Bearbeiten',
      () => _this.disableEditMode(),
      () => _this.disableEditMode(),
    );
  },

  loadNormalNavbar: function () {
    const navbar = $$('.page[data-name="task"] .navbar');
    navbar.removeClass('form-navbar');
    navbar.empty();
    navbar.addClass('navbar-transparent no-hairline');
    navbar.append(`
            <div class="navbar-inner">
                <div class="left">
                    <a class="link back">
                        <i class="icon material-icons">navigate_before</i>
                    </a>
                </div>
            </div>
        `);
  },
};

export default costcalculationView;
