import userController from '../../core/UserController';
import EduIdea from './EduIdea.js';
import { v4 as uuidv4 } from 'uuid';
import Bugsnag from '@bugsnag/js';
import dictionary from '../../core/Dictionary';
import feedbackController from '../../pages/steps/exercise/task/types/feedback/FeedbackController';

var Profile = {
  data: {},
  feedback360: {
    strength: [],
    'profile-userdata-skillareas': [],
    'profile-userdata-professionalexpertise': [],
    'profile-userdata-selfexpertise': [],
    'profile-userdata-socialexpertise': [],
    'profile-userdata-methodexpertise': [],
    interests: [],
    jobs: [],
  },
  mergeCheckUToExpertise: false,
  profileDataTestMode: false,
  eduOptions: [],
  calcOptions: function () {
    this.eduOptions = [];
    for (let ideaKey in this.eduIdeas) {
      if (
        this.eduIdeas[ideaKey] == null ||
        Object.keys(this.eduIdeas[ideaKey]).length < 1 ||
        this.eduIdeas[ideaKey].options == null
      ) {
        continue;
      }
      if (!this.eduIdeas[ideaKey].optionIds) {
        this.eduIdeas[ideaKey].optionIds = [];
      }

      let optionids = [];
      let change = false;

      for (let option of this.eduIdeas[ideaKey].options) {
        let eduOption = {};
        for (let key in this.eduIdeas[ideaKey]) {
          if (!['options', 'id'].includes(key)) {
            eduOption[key] = this.eduIdeas[ideaKey][key];
          }
        }

        /* get option id */
        let id = null;
        for (let idObject of this.eduIdeas[ideaKey].optionIds) {
          if (idObject.name === option) {
            id = idObject.id;
          }
        }
        if (!id) {
          id = uuidv4();
          this.eduIdeas[ideaKey].optionIds.push({
            id: id,
            name: option,
          });
          change = true;
        }
        optionids.push(id);

        eduOption['id'] = id;
        eduOption['idea'] = this.eduIdeas[ideaKey].id;
        eduOption['location'] = option;
        eduOption['getName'] = () =>
          (eduOption.isStudies ? 'Studium: ' : 'Ausbildung: ') +
          eduOption.name +
          (eduOption.isStudies ? ' in/an ' : ' bei ') +
          eduOption.location;
        this.eduOptions.push(eduOption);
      }

      /* delete unused option ids */
      let lenghtbefore = this.eduIdeas[ideaKey].optionIds.length;
      this.eduIdeas[ideaKey].optionIds = this.eduIdeas[
        ideaKey
      ].optionIds.filter((idObj) => optionids.includes(idObj.id));
      change = change
        ? true
        : lenghtbefore < this.eduIdeas[ideaKey].optionIds.length;

      if (change) {
        this.eduIdeas[ideaKey].save();
      }
    }
  },
  removeOldIdeas: function () {
    if (JSON.parse(localStorage.getItem('idea-rework-build-22')) == null) {
      let ids = localStorage.getItem('profile-ideas-ids');
      localStorage.setItem('profile-ideas-ids-22', ids);
      localStorage.removeItem('profile-ideas-ids');
      localStorage.setItem('idea-rework-build-22', 'false');
    }
  },
  getOptionById: function (id) {
    for (let i = 0; i < this.eduOptions.length; i++) {
      if (this.eduOptions[i].id === id) {
        return this.eduOptions[i];
      }
    }
  },
  eduIdeas: [],
  init: function () {
    let ids = JSON.parse(localStorage.getItem('profile-ideas-ids'));
    this.eduIdeas = [];
    for (const value of ids ? ids : []) {
      this.eduIdeas[value] = new EduIdea(value);
    }

    /*Versuch Daten zu retten die von Build 22 oder älter kommen. 05.03.2021*/
    this.oldIdeas = [];
    ids = JSON.parse(localStorage.getItem('profile-ideas-ids-22'));
    for (const value of ids ? ids : []) {
      this.oldIdeas[value] = new EduIdea(value);
    }
    this.calcOptions();
    let modules = userController.getAllModules();
    if (!this.modules) {
      Object.defineProperty(this, 'modules', {
        value: modules,
        writeable: false,
      });
    }
  },
  reinitIdeas: function () {
    let ids = JSON.parse(localStorage.getItem('profile-ideas-ids'));
    this.eduIdeas = [];
    for (const value of ids ? ids : []) {
      this.eduIdeas[value] = new EduIdea(value);
    }
    this.calcOptions();
  },
  addIdea: function (idea) {
    this.eduIdeas[idea.id] = idea;
    idea.save();
    let ids = JSON.parse(localStorage.getItem('profile-ideas-ids'));
    if (ids == null) ids = [];
    ids.push(idea.id);
    localStorage.setItem('profile-ideas-ids', JSON.stringify(ids));
  },
  deleteIdea: function (id) {
    let allIds = JSON.parse(localStorage.getItem('profile-ideas-ids'));
    if (allIds) {
      allIds = allIds.filter((idea) => idea !== id);
      localStorage.setItem('profile-ideas-ids', JSON.stringify(allIds));
    }
    this.eduIdeas[id] = null;
    localStorage.removeItem(id);
  },
  getProfileDataByKey: function (key) {
    switch (key) {
      case 'values':
        return this.getProfileValues();
        break;
      case 'motives':
        return this.getProfileMotives();
        break;
      case 'personality':
        return this.getProfilePersonalityfactors();
        break;
      case 'mustHave':
        return this.getProfileMusthave();
        break;
      case 'nogo':
        return this.getProfileNogo();
        break;
      case 'jobvalues':
        return this.getProfileJobvalues();
        break;
      case 'salary':
        return this.getProfileSalary();
        break;
      case 'selfrealization':
        return this.getProfileSelfrealization();
        break;
      case 'hitlist':
        return this.getProfileInterests().hitlist;
        break;
      case 'shitlist':
        return this.getProfileInterests().shitlist;
        break;
      /*case 'strengths':
                return this.getProfileStrength().strengths
                break;
            case 'weaknesses':
                return this.getProfileStrength().weaknesses
                break;*/
      case 'bestsubjects':
        return this.getProfileBestSubjects();
        break;
      case 'skillAreas':
        return this.getProfileSingleExpertise(
          'Meine Fähigkeitsbereiche',
          'profile-userdata-skillareas',
          userController.getAllModules(),
        );
        break;
      case 'professionalexpertise':
        return this.getProfileSingleExpertise(
          'Fachkompetenzen',
          'profile-userdata-professionalexpertise',
          userController.getAllModules(),
        );
        break;
      case 'selfexpertise':
        return this.getProfileSingleExpertise(
          'Selbstkompetenzen',
          'profile-userdata-selfexpertise',
          userController.getAllModules(),
        );
        break;
      case 'socialexpertise':
        return this.getProfileSingleExpertise(
          'Sozialkompetenzen',
          'profile-userdata-socialexpertise',
          userController.getAllModules(),
        );
        break;
      case 'methodexpertise':
        return this.getProfileSingleExpertise(
          'Methodenkompetenzen',
          'profile-userdata-methodexpertise',
          userController.getAllModules(),
        );
        break;
      case 'riasec':
        return this.getProfileRiasec();
        break;
    }
  },
  getProfileData: function () {
    let modules = userController.getAllModules();
    if (!this.profileDataTestMode) {
      this.data.personality = this.getProfilePersonality(modules);
      this.data.career = this.getProfileCareer(modules);
      this.data.interests = this.getProfileInterests(modules);
      //this.data.strengthsAndWeaknesses = this.getProfileStrength(modules);
      this.data.expertise = this.getProfileExpertise(modules);
    }
    return this.data;
  },
  setCheckUMergeToExpertiseModeEnabled: function (boolean) {
    this.mergeCheckUToExpertise = boolean;
    this.getProfileData();
  },
  getCheckUMergeToExpertiseModeEnabled: function () {
    return this.mergeCheckUToExpertise;
  },
  setProfileDataTestModeEnabled: function (boolean) {
    this.profileDataTestMode = boolean !== false;
    if (this.profileDataTestMode) {
      // Test content:
      this.data.expertise = {
        bestsubjects: {
          subtitle: 'Meine besten Fächer',
          entries: [
            [
              'Allgemeinwissen',
              'Naturwissenschaften',
              'Kommunikationsfähigkeit',
            ],
            ['Allgemeinwissen', 'Naturwissenschaften', 'Allgemeinwissen'],
          ],
          responses: 2,
          module: 2,
          chapter: 5,
          exercise: 3,
        },
        skillAreas: {
          subtitle: 'Meine Fähigkeitsbereiche',
          entries: [
            [
              { name: 'Allgemeinwissen', weight: 1 },
              {
                name: 'Naturwissenschaften',
                weight: 2,
              },
              { name: 'Kommunikationsfähigkeit', weight: 5 },
              { name: 'Teamfähigkeit', weight: 4 },
            ],
            [
              { name: 'Allgemeinwissen', weight: 3 },
              {
                name: 'Naturwissenschaften',
                weight: 2,
              },
              { name: 'Kommunikationsfähigkeit', weight: 4 },
              {
                name: 'Teamfähigkeit',
                weight: 5,
              },
              { name: 'Wasserfestigkeit', weight: 2 },
            ],
          ],
          responses: 2,
          module: 3,
          chapter: 1,
          exercise: 3,
        },
        professionalexpertise: {
          subtitle: 'Fachkompetenzen',
          entries: [
            [
              {
                name: 'Allgemeinwissen/Naturwissenschaften',
                weight: 3,
              },
              {
                name: 'Allgemeinwissen/Naturwissenschaften/Kommunikationsfähigkeit',
                weight: 2,
              },
              {
                name: 'Allgemeinwissen/Naturwissenschaften/Kommunikationsfähigkeit',
                weight: 5,
              },
            ],
            [
              { name: 'Allgemeinwissen', weight: 3 },
              {
                name: 'Naturwissenschaften',
                weight: 2,
              },
              { name: 'Kommunikationsfähigkeit', weight: 5 },
            ],
          ],
          responses: 2,
          module: 3,
          chapter: 1,
          exercise: 4,
        },
        selfexpertise: {
          subtitle: 'Selbstkompetenzen',
          entries: [
            [
              {
                name: 'Allgemeinwissen/ Naturwissenschaften/ Kommunikationsfähigkeit',
                weight: 3,
              },
              {
                name: 'Allgemeinwissen/ Naturwissenschaften/ Kommunikationsfähigkeit',
                weight: 2,
              },
              {
                name: 'Allgemeinwissen/ Naturwissenschaften/ Kommunikationsfähigkeit',
                weight: 5,
              },
            ],
            [
              {
                name: 'Allgemeinwissen/ Naturwissenschaften/ Kommunikationsfähigkeit',
                weight: 3,
              },
              {
                name: 'Allgemeinwissen/ Naturwissenschaften/ Kommunikationsfähigkeit',
                weight: 2,
              },
              {
                name: 'Allgemeinwissen/ Naturwissenschaften/ Kommunikationsfähigkeit',
                weight: 5,
              },
            ],
          ],
          responses: 2,
          module: 3,
          chapter: 1,
          exercise: 4,
        },
        socialexpertise: {
          subtitle: 'Sozialkompetenzen',
          entries: [
            [
              { name: 'Allgemeinwissen', weight: 3 },
              {
                name: 'Naturwissenschaften',
                weight: 2,
              },
              { name: 'Kommunikationsfähigkeit', weight: 5 },
            ],
            [
              { name: 'Allgemeinwissen', weight: 3 },
              {
                name: 'Naturwissenschaften',
                weight: 2,
              },
              { name: 'Allgemeinwissen', weight: 1 },
            ],
          ],
          responses: 2,
          module: 3,
          chapter: 1,
          exercise: 4,
        },
        methodexpertise: {
          subtitle: 'Methodenkompetenzen',
          entries: [
            [
              { name: 'Allgemeinwissen', weight: 3 },
              {
                name: 'Naturwissenschaften',
                weight: 2,
              },
              { name: 'Kommunikationsfähigkeit', weight: 5 },
            ],
            [
              { name: 'Allgemeinwissen', weight: 3 },
              {
                name: 'Naturwissenschaften',
                weight: 2,
              },
              { name: 'Allgemeinwissen', weight: 1 },
            ],
          ],
          responses: 2,
          module: 3,
          chapter: 1,
          exercise: 4,
        },
        strength: {
          subtitle: 'Meine Stärken',
          entries: [
            [
              'Allgemeinwissen',
              'Naturwissenschaften',
              'Kommunikationsfähigkeit',
              'was anderes',
            ],
            ['Allgemeinwissen', 'Naturwissenschaften', 'Testen', 'was anderes'],
          ],
          responses: 2,
          module: 3,
          chapter: 1,
          exercise: 2,
        },
        weaknesses: {
          subtitle: 'Meine Schwächen',
          entries: [
            [
              'Allgemeinwissen',
              'Naturwissenschaften',
              'Kommunikationsfähigkeit',
            ],
            ['Allgemeinwissen', 'Naturwissenschaften', 'Allgemeinwissen'],
          ],
          responses: 2,
          module: 3,
          chapter: 1,
          exercise: 2,
        },
      };
      this.data.interests = {
        hitlist: {
          subtitle: 'Meine Hitliste',
          entries: [
            'Menschen psychologisch verstehen',
            'Designs gestalten',
            'Verhandlungen führen',
          ],
          module: 2,
          chapter: 5,
          exercise: 3,
        },
        shitlist: {
          subtitle: 'Meine Shitliste',
          entries: ['Verhandlungen führen', 'Handarbeit'],
          module: 2,
          chapter: 5,
          exercise: 3,
        },
        riasec: {
          subtitle: 'Interessenstest (RIASEC)',
          entries: [
            [
              'Inverstigiative',
              'Social',
              'Enterprising',
              'Inverstigiative',
              'Social',
              'Enterprising',
            ],
            ['Social', 'Enterprising', 'Inverstigiative'],
          ],
          module: 2,
          chapter: 5,
          exercise: 9,
        },
      };
      this.data.career = {
        mustHave: {
          subtitle: 'Mein Must-Have',
          entries: [
            'Sicherheit',
            'Reisen',
            'wenig Teamarbeit',
            'Flexible Arbeitszeiten',
          ],
          module: 2,
          chapter: 4,
          exercise: 3,
        },
        nogo: {
          subtitle: 'Mein No-Go',
          entries: ['Nur am PC', 'zu viel Hierarchie'],
          module: 2,
          chapter: 4,
          exercise: 3,
        },
        jobvalues: {
          subtitle: 'Meine beruflichen Vorstellungen',
          entries: [
            'kognitive Herausforderungen',
            'direktes Feedback',
            'Flexibilität',
            'Hohe Freiheit',
            'Forschung und Entwicklung',
          ],
          module: 2,
          chapter: 4,
          exercise: 4,
        },
        salary: {
          subtitle: 'Meine Gehaltsvorstellung',
          entries: [
            'kognitive Herausforderungen',
            'direktes Feedback',
            'Flexibilität',
            'Hohe Freiheit',
            'Forschung und Entwicklung',
          ],
          module: 2,
          chapter: 4,
          exercise: 5,
        },
        selfrealization: {
          subtitle: 'So will ich mich selbst verwirklichen',
          entries:
            'Ich möchte frei entscheiden können, was ich an einem Arbeitstag mache.',
          module: 2,
          chapter: 4,
          exercise: 5,
        },
        salarygoal: {
          subtitle: 'Dafür ist mir Gehalt wichtig',
          entries: 'Ich möchte einmal viel Geld verdienen können.',
          module: 2,
          chapter: 4,
          exercise: 5,
        },
      };
      this.data.personality = {
        values: {
          subtitle: 'Meine beruflichen Vorstellungen',
          entries: [
            'Neugier und Anregung',
            'Macht und Verantwortung',
            'Fürsorge und Familie',
            'Umweltschutz',
            'Freiheit',
          ],
          module: 2,
          chapter: 1,
          exercise: 4,
        },
        motives: {
          subtitle: 'Meine Motive',
          entries: ['Macht', 'Freiheit', 'Anschluss', 'Leistung'],
          module: 2,
          chapter: 2,
          exercise: 1,
        },
        personality: {
          subtitle: 'Meine Persönlichkeit',
          entries: [
            {
              Offenheit: 5,
              'Emotionale Stabilität': 2,
              Gewissenhaftigkeit: 3,
              'Extra-version': 4,
              Verträglichkeit: 3.5,
            },
            {
              Offenheit: 2,
              'Emotionale Stabilität': 5,
              Gewissenhaftigkeit: 3,
              'Extra-version': 1,
              Verträglichkeit: 2.5,
            },
          ],
          module: 2,
          chapter: 3,
          exercise: 1,
        },
        systemtype: {
          subtitle: 'Mein Hauptsystemtyp',
          entries: 'Ich bin ein*e Visionär*in (Extensionsgedächtnis)',
          module: 2,
          chapter: 3,
          exercise: 2,
        },
        priorities: {
          subtitle: 'Meine Prioritäten',
          entries: {
            'Lernen und Beruf (Kompetenz&shy/entwicklung und Karriere)': 3,
            'Religiosität&shy/Spiritualität&shy/Lebensphilosophie': 1,
            'Soziales&shy/politisches Engagement': 1,
            'Zeit für Partnerschaft&shy/Beziehung': 1,
            'Zeit für Schlaf und Entspannung': 1,
            'Zeit für Sport und Gesundheit': 1,
            'Zeit für die Familie (Eltern, Geschwister, später eigene Kinder)': 2,
          },
          chapter: 1,
          exercise: 3,
          module: 2,
        },
      };

      //this.data.strengthsAndWeaknesses = {...};
    } else {
      this.getProfileData();
    }
  },
  getProfilePersonality: function (modules) {
    try {
      let data = {};

      data.values = this.getProfileValues();
      data.motives = this.getProfileMotives();
      data.personality = this.getProfilePersonalityfactors();
      data.systemtype = this.getProfileSystemtype();
      data.priorities = this.getProfilePriorities();
      return data;
    } catch (e) {
      Bugsnag.notify(e);
      console.error(e);
    }
  },
  getProfilePriorities: function () {
    //module2, chapter1, exercise3, task1

    let localStoragePrios = JSON.parse(
      localStorage.getItem('profile-userdata-priorities'),
    );
    let texts = userController.getTask(
      localStoragePrios.module,
      localStoragePrios.chapter,
      localStoragePrios.exercise,
      localStoragePrios.task,
    ).inputs[0].rows;
    let answered = localStoragePrios.answers.length - 1;

    let entries = {};
    if (answered > 0) {
      for (let index = 0; index < texts.length; index++) {
        if (localStoragePrios.answers[answered][index]) {
          entries[texts[index]] = localStoragePrios.answers[answered][index];
        }
      }
    }

    return {
      subtitle: 'Meine Prioritäten',
      entries: entries,
      module: localStoragePrios.module,
      chapter: localStoragePrios.chapter,
      exercise: localStoragePrios.exercise,
    };
  },
  getProfileValues: function () {
    //module2, chapter3, exercise5, task1, input 1-5

    let valuestrings = dictionary.getText('profiletexts', 'values');
    let values = [];
    for (let string of valuestrings) {
      values.push({ value: string });
    }
    let localStorageValues = JSON.parse(
      localStorage.getItem('profile-userdata-values'),
    );
    if (!localStorageValues) return null;
    for (let [i, value] of localStorageValues.answers.entries()) {
      if (i % 2 == 0) values[i / 2].weight = value;
    }
    values = values.filter((x) => x.weight != '');
    values.sort((a, b) => b.weight - a.weight);
    values = values.slice(0, 5);
    values = values.map((x) => x.value);
    return {
      subtitle: 'Meine beruflichen Vorstellungen',
      entries: values,
      module: localStorageValues.module,
      chapter: localStorageValues.chapter,
      exercise: localStorageValues.exercise,
    };
  },
  getProfileMotives: function () {
    try {
      let motives = [
        { motive: 'Anschluss' },
        { motive: 'Leistung' },
        { motive: 'Macht' },
        { motive: 'Freiheit' },
      ];
      let localStorageValues = JSON.parse(
        localStorage.getItem('profile-userdata-motives'),
      );
      if (!localStorageValues) return null;
      for (let i = 0; i < motives.length; i++) {
        motives[i].weight = localStorageValues.answers[i * 2];
      }
      motives = motives.filter((x) => x.weight !== '');
      motives.sort((a, b) => b.weight - a.weight);
      motives = motives.map((a) => a.motive);

      return {
        subtitle: 'Meine Motive',
        entries: motives,
        module: localStorageValues.module,
        chapter: localStorageValues.chapter,
        exercise: localStorageValues.exercise,
      };
    } catch (e) {
      Bugsnag.notify(e);
    }
  },
  getProfileSystemtype: function () {
    let localStorageValues = JSON.parse(
      localStorage.getItem('profile-userdata-mainsystemtype'),
    );
    if (!localStorageValues) return null;
    return {
      subtitle: 'Mein Hauptsystemtyp',
      entries: localStorageValues.answers[0],
      module: localStorageValues.module,
      chapter: localStorageValues.chapter,
      exercise: localStorageValues.exercise,
    };
  },
  getProfilePersonalityfactors: function () {
    let keys = [
      'Extra&shyversion',
      'Emotionale Stabilität',
      'Offenheit',
      'Gewissen&shyhaftigkeit',
      'Verträg&shylichkeit',
    ];

    // internal
    let localStorageValues = JSON.parse(
      localStorage.getItem('profile-userdata-personality'),
    );
    if (!localStorageValues) return null;
    localStorageValues.answers = localStorageValues.answers.map((x) =>
      x == '' ? (x = 0) : x,
    );
    let internalPersonality = {};
    for (let index = 0; index < keys.length; index++) {
      internalPersonality[keys[index]] = localStorageValues.answers[index];
    }

    // external
    let localStorageCheckU = JSON.parse(
      localStorage.getItem('profile-userdata-checku-personality'),
    );
    let externalPersonality = {};
    if (localStorageCheckU && localStorageCheckU.answers) {
      for (let key of keys) {
        let entry = localStorageCheckU.answers.find(
          (entry) => entry[0] === key,
        );
        externalPersonality[key] = entry ? entry[1] : 0;
      }
    }

    return {
      subtitle: 'Meine Persönlichkeit',
      entries: [internalPersonality, externalPersonality],
      module: localStorageValues.module,
      chapter: localStorageValues.chapter,
      exercise: localStorageValues.exercise,
    };
  },

  getProfileCareer: function (modules) {
    try {
      let data = {};
      // Beruf: Must Have - Grad nicht klar wie das ausgefüllt werden soll
      data.mustHave = this.getProfileMusthave();
      // Beruf: No Go - Grad nicht klar wie das ausgefüllt werden soll
      data.nogo = this.getProfileNogo();
      data.jobvalues = this.getProfileJobvalues();
      data.salary = this.getProfileSalary();
      data.selfrealization = this.getProfileSelfrealization();
      data.salarygoal = this.getProfileSalarygoal();

      return data;
    } catch (e) {
      Bugsnag.notify(e);
    }
  },

  getProfileMusthave: function () {
    let localStorageValues = JSON.parse(
      localStorage.getItem('profile-userdata-musthave-nogos'),
    );
    let localStorageCheckU = JSON.parse(
      localStorage.getItem('profile-userdata-checku-musthave-nogos'),
    );
    if (!localStorageValues) return null;
    if (!localStorageCheckU) localStorageCheckU = [];
    else
      localStorageCheckU = localStorageCheckU.answers
        .slice(0, 3)
        .map((entry) => entry[0]);
    return {
      subtitle: 'Mein Must-Have',
      entries: localStorageValues.answers
        .slice(0, 3)
        .concat(localStorageCheckU),
      module: localStorageValues.module,
      chapter: localStorageValues.chapter,
      exercise: localStorageValues.exercise,
    };
  },

  getProfileNogo: function () {
    let localStorageValues = JSON.parse(
      localStorage.getItem('profile-userdata-musthave-nogos'),
    );
    let localStorageCheckU = JSON.parse(
      localStorage.getItem('profile-userdata-checku-musthave-nogos'),
    );
    if (!localStorageValues) return null;
    if (
      !localStorageCheckU ||
      Object.keys(localStorageCheckU.answers).length <= 3
    )
      localStorageCheckU = [];
    else
      localStorageCheckU = localStorageCheckU.answers
        .map((entry) => entry[0])
        .slice(
          localStorageCheckU.answers.length >= 6
            ? -3
            : -localStorageCheckU.answers.length + 3,
        );
    return {
      subtitle: 'Mein No-Go',
      entries: localStorageValues.answers
        .slice(3, 6)
        .concat(localStorageCheckU),
      module: localStorageValues.module,
      chapter: localStorageValues.chapter,
      exercise: localStorageValues.exercise,
    };
  },

  getProfileJobvalues: function () {
    // Berufliche Werte
    //module2, chapter3, exercise5, task1, input 1-5
    let jobvaluestrings = dictionary.getText('profiletexts', 'jobvalues');
    let jobvalues = [];
    for (let string of jobvaluestrings) {
      jobvalues.push({ jobvalue: string });
    }
    //= [{"jobvalue": "Entscheidungsmacht"}, {"jobvalue": "Verantwortung"}, {"jobvalue": "Kontrolle"}, {"jobvalue": "Stress/Leistungsdruck"}, {"jobvalue": "Abwechslung"}, {"jobvalue": "Freiheit"}, {"jobvalue": "Flexibilität"}, {"jobvalue": "private Kontakte"}, {"jobvalue": "Teamarbeit"}, {"jobvalue": "Ansehen"}, {"jobvalue": "Feedback"}, {"jobvalue": "Kreaitivität"}, {"jobvalue": "Konkurrenz"}, {"jobvalue": "Lernen"}, {"jobvalue": "Praktisches"}, {"jobvalue": "Entwicklung"}, {"jobvalue": "Herausforderung"}, {"jobvalue": "Sicherheit"}, {"jobvalue": "Kompatibilität mit Familie"}, {"jobvalue": "Menschenkontakt"}, {"jobvalue": "Anderen helfen"}, {"jobvalue": "Freiraum"}, {"jobvalue": "Durchsetzungsvermögen"}, {"jobvalue": "Risiko"}]
    let localStorageValues = JSON.parse(
      localStorage.getItem('profile-userdata-jobvalues'),
    );
    if (!localStorageValues) return null;
    for (let [i, value] of localStorageValues.answers.entries()) {
      jobvalues[i].weight = value;
    }
    jobvalues = jobvalues.filter((x) => x.weight != '');
    jobvalues.sort((a, b) => b.weight - a.weight);
    jobvalues = jobvalues.slice(0, 5);
    jobvalues = jobvalues.map((x) => x.jobvalue);
    return {
      subtitle: 'Meine beruflichen Vorstellungen',
      entries: jobvalues,
      module: localStorageValues.module,
      chapter: localStorageValues.chapter,
      exercise: localStorageValues.exercise,
    };
  },

  getProfileSalary: function () {
    let localStorageValues = JSON.parse(
      localStorage.getItem('profile-userdata-salary-selfrealization-0'),
    );
    if (!localStorageValues) return null;
    //Gehaltsvorstellung
    //module2, chapter3, exercise6, task1, input 1
    return {
      subtitle: 'Meine Gehaltsvorstellung',
      entries: localStorageValues.answers,
      module: localStorageValues.module,
      chapter: localStorageValues.chapter,
      exercise: localStorageValues.exercise,
    };
  },
  getProfileSalarygoal: function () {
    let localStorageValues = JSON.parse(
      localStorage.getItem('profile-userdata-salary-selfrealization-1'),
    );
    if (!localStorageValues) return null;
    return {
      subtitle: 'Dafür ist mir Gehalt wichtig',
      entries: localStorageValues.answers[0],
      module: localStorageValues.module,
      chapter: localStorageValues.chapter,
      exercise: localStorageValues.exercise,
    };
  },

  getProfileSelfrealization: function () {
    let localStorageValues = JSON.parse(
      localStorage.getItem('profile-userdata-salary-selfrealization-2'),
    );
    if (!localStorageValues) return null;
    return {
      subtitle: 'So will ich mich selbst verwirklichen',
      entries: localStorageValues.answers[0],
      module: localStorageValues.module,
      chapter: localStorageValues.chapter,
      exercise: localStorageValues.exercise,
    };
  },
  getProfileInterests: function (modules) {
    try {
      let data = {};
      let localStorageValues = JSON.parse(
        localStorage.getItem('profile-userdata-hitlist'),
      );
      if (!localStorageValues) return null;
      // Interessen-Hitlist - Die 5 Werte werden momentan nur in ein Inputfeld eingetragen, das ändert sich vielleicht zukünftig, aber erstmal kann man die einzelnen Einträge nicht voneinandner trennen
      // module2, chapter2, exercise3, task1, input1-5
      /*let hitlist = []
            for (let i = 0; i < 5; i++) {
                let hitlistItem = localStorageValues.answers[i];
                (hitlistItem != "" ? hitlist.push(hitlistItem) : {})
            }*/
      data.hitlist = {
        subtitle: 'Meine Hitliste',
        entries: localStorageValues.answers[0], //hitlist.filter(Boolean),
        module: localStorageValues.module,
        chapter: localStorageValues.chapter,
        exercise: localStorageValues.exercise,
      };

      // Interessen-Shitlist - Die 5 Werte werden momentan nur in ein Inputfeld eingetragen, das ändert sich vielleicht zukünftig, aber erstmal kann man die einzelnen Einträge nicht voneinandner trennen
      // module2, chapter2 , exercise3, task1, input7-9
      localStorageValues = JSON.parse(
        localStorage.getItem('profile-userdata-shitlist'),
      );
      if (!localStorageValues) return null;
      /*
            let shitlist = []
            for (let i = 0; i < 3; i++) {
                let shitlistItem = localStorageValues.answers[i + 6];
                (shitlistItem != "" ? shitlist.push(shitlistItem) : {})
            }*/
      data.shitlist = {
        subtitle: 'Meine Shitliste',
        entries: localStorageValues.answers[0], //shitlist.filter(Boolean),
        module: localStorageValues.module,
        chapter: localStorageValues.chapter,
        exercise: localStorageValues.exercise,
      };

      data.riasec = this.getProfileRiasec();
      return data;
    } catch (e) {
      Bugsnag.notify(e);
    }
  },
  getProfileStrength: function (modules) {
    let data = {};
    let task = {};

    let localStorageValues = JSON.parse(
      localStorage.getItem('profile-userdata-strength-weaknesses'),
    );

    let localStorageValuesStrength = localStorageValues.answers[0];
    if (!localStorageValuesStrength) localStorageValuesStrength = [];

    let localStorageValuesWeaknesses = localStorageValues.answers[1]
    if (!localStorageValuesWeaknesses) localStorageValuesWeaknesses = [];

    //Stärken
    data.strengths = {
      subtitle: 'Meine Stärken',
      entries:
        localStorageValues.ordered && localStorageValues.ordered.strength
          ? [
              localStorageValues.ordered.strength.self,
              localStorageValues.ordered.strength.external,
            ]
          : [
              localStorageValuesStrength,
              this.feedback360.strength.slice(
                Math.max(
                  0,
                  Math.floor(
                    Math.random() * this.feedback360.strength.length - 3,
                  ),
                ),
                Math.min(3, this.feedback360.strength.length),
              ),
            ],
      responses: this.feedback360['strength-counter']
        ? this.feedback360['strength-counter']
        : 0,
      module: localStorageValues.module,
      chapter: localStorageValues.chapter,
      exercise: localStorageValues.exercise,
    };
    //Schwächen
    data.weaknesses = {
      subtitle: 'Meine Schwächen',
      entries:
        localStorageValues.ordered && localStorageValues.ordered.weaknesses
          ? [
              localStorageValues.ordered.weaknesses.self,
              localStorageValues.ordered.weaknesses.external,
            ]
          : [localStorageValuesWeaknesses, []],
      responses: 0,
      module: localStorageValues.module,
      chapter: localStorageValues.chapter,
      exercise: localStorageValues.exercise,
    };
    return data;
  },
  getProfileExpertise: function (modules) {
    let data = {};
    let task = {};

    let localStorageValues = JSON.parse(
      localStorage.getItem('profile-userdata-hitlist'),
    );
    if (!localStorageValues) return null;
    //Beste Fächer
    // Welche Exercise?
    //data.bestsubjects = modules[2].chapters[1].exercises[0].task[0].answers[1];
    let bestsubjects = JSON.parse(
      localStorage.getItem('profile-userdata-bestsubjects'),
    );
    data.bestsubjects = {
      subtitle: 'Meine besten Fächer',
      entries: bestsubjects.ordered
        ? [bestsubjects.ordered.self, bestsubjects.ordered.external]
        : [
            JSON.parse(localStorage.getItem('profile-userdata-bestsubjects'))
              .answers[0],
            [],
          ],
      responses: 0,
      module: localStorageValues.module,
      chapter: localStorageValues.chapter,
      exercise: localStorageValues.exercise,
    };
    //Fähigkeitsbereiche - Noch kein Input in der DB definiert
    // module2, chapter1, exercise3, task1, input 1-19
    data.skillAreas = this.getProfileSingleExpertise(
      'Meine Fähigkeitsbereiche',
      'profile-userdata-skillareas',
      this.modules,
    );
    data.professionalexpertise = this.getProfileSingleExpertise(
      'Fachkompetenzen',
      'profile-userdata-professionalexpertise',
      this.modules,
    );
    data.selfexpertise = this.getProfileSingleExpertise(
      'Selbstkompetenzen',
      'profile-userdata-selfexpertise',
      this.modules,
    );
    data.socialexpertise = this.getProfileSingleExpertise(
      'Sozialkompetenzen',
      'profile-userdata-socialexpertise',
      this.modules,
    );
    data.methodexpertise = this.getProfileSingleExpertise(
      'Methodenkompetenzen',
      'profile-userdata-methodexpertise',
      this.modules,
    );
    let strength_weakness = this.getProfileStrength();
    data.strength = strength_weakness.strengths;
    data.weaknesses = strength_weakness.weaknesses;

    return data;
  },
  getProfileExpertiseCheckU: function () {
    let checkU = {
      mode: 'empty',
    };
    checkU.skills = {
      title: 'Fähigkeiten und Fachkompetenzen',
      content: this.getProfileSingleExpertiseCheckU(
        'profile-userdata-skillareas',
      ),
    };
    checkU.social = {
      title: 'Soziale Kompetenzen',
      content: this.getProfileSingleExpertiseCheckU(
        'profile-userdata-socialexpertise',
      ),
    };
    if (checkU.social.content.length > 0 && checkU.skills.content.length > 0) {
      checkU.mode = 'complete';
    } else if (
      checkU.social.content.length > 0 ||
      checkU.skills.content.length > 0
    ) {
      checkU.mode = 'started';
    }
    return checkU;
  },
  getProfileSingleExpertiseCheckU: function (
    localStorageKey,
    sorted = true,
    array,
  ) {
    if (!array) {
      array = [];
    }

    let localStorageCheckU = JSON.parse(
      localStorage.getItem('profile-userdata-checku-expertise'),
    );

    if (!localStorageCheckU) localStorageCheckU = [];
    else
      localStorageCheckU = localStorageCheckU.answers
        .filter(
          (entry) => entry[2] === localStorageKey.split('profile-userdata-')[1],
        )
        .map((entry) => ({ name: entry[0], weight: (entry[1] / 100) * 5 }));
    array = array.concat(localStorageCheckU);

    // sort
    if (sorted) {
      array.sort((a, b) => b.weight - a.weight);
    }
    return array;
  },
  getProfileSingleExpertise: function (subtitle, localStorageKey, modules) {
    try {
      let data = [];
      let data360 = [];
      let localStorageValues = JSON.parse(
        localStorage.getItem(localStorageKey),
      );
      if (!localStorageValues) return null;

      if (!localStorageValues.ordered) {
        // get from task and sort

        // internal
        let task =
          this.modules[localStorageValues.module - 1].chapters[
            localStorageValues.chapter - 1
          ].exercises[localStorageValues.exercise - 1].tasks[
            localStorageValues.task - 1
          ];
        let inputs = task.inputs;
        //Bugsnag.notify(new Error(JSON.stringifiy(task)))
        if (localStorageKey == 'profile-userdata-skillareas')
          inputs = inputs.slice(1, 9);
        for (let i = 0; i < inputs.length; i++) {
          if (inputs[i] == undefined) {
            Bugsnag.notifiy(
              JSON.stringifiy(task) +
                JSON.stringifiy(i) +
                JSON.stringifiy(localStorageValues) +
                subtitle,
            );
            continue;
          }
          data[i] = {};
          data[i].expertise = inputs[i].title;
          data[i].weight = task.answers[i];
        }
        data = data.filter((x) => x.weight !== '');
        /*data = data.map(x => {
                    x.weight = parseInt(x.weight.split("-")[1],10)
                    return x
                })*/
        data = data.map((a) => ({
          name: a.expertise.split(' (')[0],
          weight: a.weight,
        }));

        // external (360)
        for (let i = 0; i < this.feedback360[localStorageKey].length; i++) {
          data360[i] = {};
          data360[i].expertise =
            inputs[
              localStorageKey == 'profile-userdata-skillareas' ? i : i * 2
            ].title;
          data360[i].weight = this.feedback360[localStorageKey][i];
        }
        data360 = data360
          .filter((x) => x.weight !== 0)
          .map((a) => ({
            name: a.expertise.split(' (')[0],
            weight: a.weight,
          }));

        // Check U
        if (this.mergeCheckUToExpertise) {
          data = this.getProfileSingleExpertiseCheckU(
            localStorageKey,
            false,
            data,
          );
        }

        // sort
        data.sort((a, b) => b.weight - a.weight);
        data360.sort((a, b) => b.weight - a.weight);
      } else {
        // get sorted from storage
        data = localStorageValues.ordered.self;
        data360 = localStorageValues.ordered.external;
      }

      let response360Counter = 0;
      if (this.feedback360[localStorageKey + '-counter']) {
        response360Counter = this.feedback360[localStorageKey + '-counter'];
      }

      return {
        subtitle: subtitle,
        entries: [data, data360],
        responses: response360Counter,
        module: localStorageValues.module,
        chapter: localStorageValues.chapter,
        exercise: localStorageValues.exercise,
      };
    } catch (e) {
      Bugsnag.notify(e);
    }
  },

  reorderProfileExpertise: function (key, self, external) {
    if (key === 'strength') {
      let dto = JSON.parse(
        localStorage.getItem('profile-userdata-strength-weaknesses'),
      );
      if (dto.ordered == null) {
        dto.ordered = {};
      }
      dto.ordered.strength = {
        self: self,
        external: external,
      };
      localStorage.setItem(
        'profile-userdata-strength-weaknesses',
        JSON.stringify(dto),
      );
    } else if (key === 'weaknesses') {
      let dto = JSON.parse(
        localStorage.getItem('profile-userdata-strength-weaknesses'),
      );
      if (dto.ordered == null) {
        dto.ordered = {};
      }
      dto.ordered.weaknesses = {
        self: self,
        external: external,
      };
      localStorage.setItem(
        'profile-userdata-strength-weaknesses',
        JSON.stringify(dto),
      );
    } else if (this.data.expertise.hasOwnProperty(key)) {
      key = ('profile-userdata-' + key).toLowerCase();

      let dto = JSON.parse(localStorage.getItem(key));
      dto.ordered = {
        self: self,
        external: external,
      };
      localStorage.setItem(key, JSON.stringify(dto));
    }
  },

  getProfileBestSubjects: function () {
    return {
      subtitle: 'Beste Fächer',
      entries: [[], []],
      responses: 0,
      module: localStorageValues.module,
      chapter: localStorageValues.chapter,
      exercise: localStorageValues.exercise,
    };
  },
  getImportantValues: function () {},
  getProfileRiasec: function () {
    let localStorageValues = JSON.parse(
      localStorage.getItem('profile-userdata-riasec'),
    );
    let localStorageCheckU = JSON.parse(
      localStorage.getItem('profile-userdata-checku-riasec'),
    );
    if (!localStorageValues) return null;
    if (!localStorageCheckU) localStorageCheckU = [];
    else
      localStorageCheckU = localStorageCheckU.answers.filter(
        (entry) => entry[1] !== 0,
      );

    // calculate check u
    let riasecMap = new Map();
    riasecMap.set('(R)', 'R: praktisch-technisch');
    riasecMap.set('(I)', 'I: intellektuell-forschend');
    riasecMap.set('(A)', 'A: künstlerisch-sprachlich');
    riasecMap.set('(S)', 'S: sozial');
    riasecMap.set('(E)', 'E: unternehmerisch');
    riasecMap.set('(C)', 'C: ordnend-verwaltend');

    let checkMap = new Map();
    for (let line of localStorageCheckU) {
      let key = line[0].substring(line[0].length - 3);
      if (!checkMap.has(key)) {
        checkMap.set(key, 0);
      }
      checkMap.set(key, checkMap.get(key) + line[1]);
    }
    let checkResults = Array.from(checkMap)
      .sort((a, b) => b[1] - a[1])
      .map((a) => riasecMap.get(a[0]));

    return {
      subtitle: 'Interessenstest (RIASEC)',
      entries: [localStorageValues.answers.filter(Boolean), checkResults],
      module: localStorageValues.module,
      chapter: localStorageValues.chapter,
      exercise: localStorageValues.exercise,
    };
  },
  evaluate360responses: function () {
    let _this = this;

    let arithmeticMean = function (fromKey, toKey) {
      let responseCounter = 0;
      let mean = new Array(
        userController.getUserData('feedback360responses')[0][fromKey].length,
      ).fill(0);
      let meanCount = new Array(
        userController.getUserData('feedback360responses')[0][fromKey].length,
      ).fill(0);
      for (let response of userController.getUserData('feedback360responses')) {
        for (const [index, value] of response[fromKey].entries()) {
          if (value != null) {
            mean[index] += parseInt(value);
            meanCount[index]++;
            if (index === 0) {
              responseCounter++;
            }
          }
        }
      }
      mean = mean.map((x, i) =>
        meanCount[i] != 0 ? parseInt(x / meanCount[i]) : 0,
      );
      _this.feedback360[toKey] = mean;
      _this.feedback360[toKey + '-counter'] = responseCounter;
    };
    if (
      userController.getUserData('feedback360responses') &&
      userController.getUserData('feedback360responses').length != 0
    ) {
      // finish feedback task
      feedbackController.finish();
      _this.feedback360.strength = [];
      this.feedback360['profile-userdata-skillareas'] = new Array(
        userController.getUserData('feedback360responses')[0]['skills'].length,
      ).fill(0);
      this.feedback360['profile-userdata-professionalexpertise'] = new Array(
        userController.getUserData('feedback360responses')[0][
          'subjects'
        ].length,
      ).fill(0);
      this.feedback360['profile-userdata-selfexpertise'] = new Array(
        userController.getUserData('feedback360responses')[0]['self'].length,
      ).fill(0);
      this.feedback360['profile-userdata-socialexpertise'] = new Array(
        userController.getUserData('feedback360responses')[0]['social'].length,
      ).fill(0);
      this.feedback360['profile-userdata-methodexpertise'] = new Array(
        userController.getUserData('feedback360responses')[0]['methods'].length,
      ).fill(0);
      for (const [index, response] of userController
        .getUserData('feedback360responses')
        .entries()) {
        for (let key in response) {
          switch (key) {
            case 'strength':
              _this.feedback360.strength = _this.feedback360.strength.concat(
                response['strength'].filter((x) => x != ''),
              );
              break;
            case 'skills':
              arithmeticMean('skills', 'profile-userdata-skillareas');
              break;
            case 'social':
              arithmeticMean('social', 'profile-userdata-socialexpertise');
              break;
            case 'self':
              arithmeticMean('self', 'profile-userdata-selfexpertise');
              break;
            case 'methods':
              arithmeticMean('methods', 'profile-userdata-methodexpertise');
              break;
            case 'subjects':
              arithmeticMean(
                'subjects',
                'profile-userdata-professionalexpertise',
              );
              break;
            case 'annotations':
              break;
            case 'interests':
              break;
            case 'jobs':
              break;
          }
        }
      }
      _this.feedback360['strength-counter'] = userController
        .getUserData('feedback360responses')
        .filter(
          (response) => response['strength'].filter((x) => x != '').length > 0,
        ).length;
    }
  },
};

export default Profile;
