import app from "../app";

var customUI = {
    name: 'customui',
    // extend app methods with debugger methods when app instance just created
    instance: {
        /**
         * Android and IOS not implemented!
         *
         * @returns "firefox", "chrome", "safari", "opera", "ie" or "ios"
         * */
        getUserAgent: function () {
            if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1) {
                return 'opera';
            } else if (navigator.userAgent.indexOf("Chrome") != -1) {
                return 'chrome';
            } else if (navigator.userAgent.indexOf("Safari") != -1) {
                return 'safari';
            } else if (navigator.userAgent.indexOf("Firefox") != -1) {
                return 'firefox';
            } else if ((navigator.userAgent.indexOf("MSIE") != -1) || (!!document.documentMode == true)) { //IF IE > 10
                return 'ie';
            } else if ((navigator.userAgent.toLowerCase().indexOf("ios") != -1) || (navigator.userAgent.toLowerCase().indexOf("iphone") != -1) || (navigator.userAgent.toLowerCase().indexOf("ipad") != -1) || (navigator.userAgent.toLowerCase().indexOf("mac") != -1)) { //ios
                return 'ios';
            } else {
                return null;
            }
        },
        loadMainToolbar: function (mainToolbar, page) {
            mainToolbar.empty();
            mainToolbar.addClass("main-toolbar toolbar tabbar tabbar-labels toolbar-bottom no-shadow no-hairline");
            mainToolbar.append(`
                <div class="toolbar-inner">
                    <a id="toolbar-link-to-notebook" href="#" class="link ${page === "notebook" ? "tab-link-active" : ""}">
                        <i class="icon svg-icon-check-circle"></i>
                        <span class="tabbar-label">To-Do</span>
                    </a>
                    <a id="toolbar-link-to-home" href="#" class="link ${page === "home" ? "tab-link-active" : ""}">
                        <i class="icon svg-icon-material_home"></i>
                        <span class="tabbar-label">Steps</span>
                    </a>
                    <a id="toolbar-link-to-profile" href="#" class="link ${page === "profile" ? "tab-link-active" : ""}">
                        <i class="icon svg-icon-material_person"></i>
                        <span class="tabbar-label">Profil</span>
                    </a>
                </div>
            `);
            mainToolbar.find('a#toolbar-link-to-notebook').on('click', () => {
                app.views.main.router.navigate("/notebook/");
                app.views.main.router.navigate("/", {reloadPrevious: true});
                app.views.main.router.history = ["/", "/notebook/"];
            });
            mainToolbar.find('a#toolbar-link-to-home').on('click', () => {
                app.views.main.router.navigate("/", {reloadAll: true});
            });
            mainToolbar.find('a#toolbar-link-to-profile').on('click', () => {
                app.views.main.router.navigate("/profile/");
                app.views.main.router.navigate("/", {reloadPrevious: true});
                app.views.main.router.history = ["/", "/profile/"];
            });
        },
        loadFormNavbar: function (navbarDiv, title, readyHandler, cancelHandler) {
            navbarDiv.addClass('form-navbar navbar no-shadow');
            navbarDiv.append(`
                <div class="navbar-bg"></div>
                <div class="navbar-inner sliding">
                  <div class="left">
                    <a href="#">
                      <span>Abbrechen</span>
                    </a>
                  </div>
                  <div class="title">${title}</div>
                  <div class="right">
                    <a href="#">
                      <span>Fertig</span>
                    </a>
                  </div>
                </div>
            `);
            navbarDiv.find('.right a').on('click', () => {
                readyHandler();
            });
            navbarDiv.find('.left a').on('click', () => {
                if (cancelHandler != null) {
                    cancelHandler();
                } else {
                    app.views.main.router.back();
                }
            });
        },
        loadMainFAB: function (mainFAB, step) {
            mainFAB.empty();
            mainFAB.addClass("main-fab fab fab-right-bottom color-white");
            mainFAB.attr("id", "main-fab");
            mainFAB.append(`
                <a href="#">
                    <i class="icon material-icons fab-add-icon">add</i>
                    <i class="icon material-icons">close</i>
                </a>
                <div class="fab-buttons fab-buttons-custom fab-buttons-two">
                    <a id="main-fab-idea" href="/idea/0/" class="fab-sub-link">
                        <i class="icon svg-icon-lightbulb"></i>
                    </a>
                    <a id="main-fab-notebook" href="#" class="fab-sub-open">
                        <i class="icon svg-icon-check-circle"></i>
                    </a>
                </div>
                <div class="fab-buttons fab-buttons-custom fab-buttons-three fab-buttons-hidden">
                    <a id="main-fab-note" href="/noteeditor/0/${step ? step : 0}/" class="fab-sub-link">
                        <i class="icon svg-icon-note-outline-black"></i>
                    </a>
                    <a id="main-fab-keydate" href="/keydate/0/${step ? step : 0}/" class="fab-sub-link">
                        <i class="icon material-icons">date_range</i>
                    </a>
                    <a id="main-fab-goal" href="/goal/0/${step ? step : 0}/" class="fab-sub-link">
                        <i class="icon svg-icon-check-circle"></i>
                    </a>
                </div>
            `);
            mainFAB.on('fab:close', () => {
                mainFAB.find(".fab-buttons-three").addClass('fab-buttons-hidden');
                mainFAB.find(".fab-buttons-two").removeClass('fab-buttons-hidden');
            });
            mainFAB.find(".fab-sub-link").on('click', () => {
                app.fab.close(mainFAB);
            });
            mainFAB.find(".fab-sub-open").on('click', () => {
                mainFAB.find(".fab-buttons-three").removeClass('fab-buttons-hidden');
                mainFAB.find(".fab-buttons-two").addClass('fab-buttons-hidden');
            });
        },

        popoverOpenBelow: function (link, popover) {
            popover.on('popover:open', () => {
                setTimeout(() => {
                    let bottom = popover.find('.popover-angle.on-bottom')
                        .removeClass('on-bottom')
                        .addClass('on-top');
                    if (bottom.length > 0) {
                        popover.css('top', parseInt(popover.css('top').split('px')[0]) + popover.height() + link.height() + popover.children('.popover-angle').height() + "px");
                    }
                });
            });
        }
    }
};

export default customUI
