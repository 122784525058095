import $$ from 'dom7';
import dateController from '../DateController';
import notificationController from '../NotificationController';
import Observer from '../Observer';
import domHelper from '../domHelper';

function AudioPlayer(source, title, subtitle) {
  this.DEFAULT_TITLE = 'Audio';
  this.DEFAULT_TIME = '0:00';
  this.DEFAULT_TIMESKIP = 10;
  this._audio = new Audio(source);
  this._title = null;
  this._subtitle = null;
  this._progress = null;
  this._startTime = null;
  this._endTime = null;
  this._isPlaying = false;
  this._playSpeed = 1;

  this._html = {
    element: null,
    mediaNotification: null,
  };
  this._exitMode = 'switch';

  if (title) {
    this.setTitle(title);
  }
  if (subtitle) {
    this.setSubtitle(subtitle);
  }

  this._audio.addEventListener('timeupdate', () => {
    this._updatePosition();
  });
  this._audio.addEventListener('canplay', () => {
    this._updatePosition();
  });

  this._initMediaSession(title || this.DEFAULT_TITLE, subtitle);

  this._playstateChangeObserver = new Observer();
}

AudioPlayer.prototype._createHtmlElement = function () {
  let _this = this; //helper

  let element = $$(document.createElement('div')).addClass('audioplayer')
    .append(`
            <div class="audioplayer-topic">
                <div class="audioplayer-title">${
                  this._title || this.DEFAULT_TITLE
                }</div>
                <div class="audioplayer-subtitle">${this._subtitle || ''}</div>
            </div>
            <div class="audioplayer-progress">
                <div class="audioplayer-progress-bar">
                    <span style="width: ${this._progress || 0}%;"></span>
                </div>
                <div class="audioplayer-progress-time">
                    <span class="audioplayer-progress-starttime">${
                      this._startTime || this.DEFAULT_TIME
                    }</span>
                    <span class="audioplayer-progress-endtime">${
                      this._endTime || this.DEFAULT_TIME
                    }</span>
                </div>
            </div>
            <div class="audioplayer-controls">
                <div class="audioplayer-controls-left"></div> 
                <div class="audioplayer-controls-middle">
                    <i class="audioplayer-backward icon material-icons">replay_10</i>
                    <i class="audioplayer-play icon material-icons ${
                      this._isPlaying ? 'hide' : ''
                    }">play_arrow</i>
                    <i class="audioplayer-pause icon material-icons ${
                      this._isPlaying ? '' : 'hide'
                    }">pause</i>
                    <i class="audioplayer-forward icon material-icons">forward_10</i>
                </div> 
                <div class="audioplayer-controls-right">
                    <span class="audioplayer-normalspeed ${
                      this._playSpeed === 1 ? 'hide' : ''
                    }">x1.5</span>
                    <span class="audioplayer-fastspeed ${
                      this._playSpeed === 1 ? '' : 'hide'
                    }">x1</span>
                </div> 
            </div>
        `);
  element.find('.audioplayer-play').on('click', () => {
    _this.play();
  });
  element.find('.audioplayer-pause').on('click', () => {
    _this.pause();
  });
  element.find('.audioplayer-backward').on('click', () => {
    _this.backward();
  });
  element.find('.audioplayer-forward').on('click', () => {
    _this.forward();
  });
  element.find('.audioplayer-normalspeed').on('click', () => {
    _this.normalPlayspeed();
  });
  element.find('.audioplayer-fastspeed').on('click', () => {
    _this.fastPlayspeed();
  });
  let bar = element.find('.audioplayer-progress-bar');
  bar.on('click', (event) => {
    _this._setRelativePlayerPosition(event.offsetX / bar.width());
  });

  return element;
};

/**
 * Returns the Dom7 element you can append somewhere.
 *
 * @return {null} the element
 */
AudioPlayer.prototype.getHTML = function () {
  // create element if not created yet
  if (!this._html.element) {
    this._html.element = this._createHtmlElement();
  }

  // apply exit mode
  domHelper.onElementRemoveOnce(this._html.element, () => this._performExit());

  // switch to opened
  if (this._exitMode === 'switch' && this.isShowing()) {
    this.hide();
  }

  return this._html.element;
};

/**
 * Shows a ci-notification with controls.
 */
AudioPlayer.prototype.show = function () {
  let _this = this; // helper

  if (!this._html.mediaNotification) {
    let notification = notificationController.createCiNotification(
      this._createHtmlElement(),
    );
    notification.addHideListener((reason) => {
      if (reason !== 'external') {
        this.pause();
      }
    });
    this._html.mediaNotification = notification.element;
  }

  setTimeout(() => {
    notificationController.showCiNotification(_this._html.mediaNotification);
  }, 0);
  return this;
};

/**
 * Hides the ci-notification.
 */
AudioPlayer.prototype.hide = function () {
  if (this._html.mediaNotification) {
    notificationController.hideCiNotification(this._html.mediaNotification);
  }
};

/**
 * Returns true, if the ci-notification is currently displayed.
 *
 * @return {*} true, is displayed
 */
AudioPlayer.prototype.isShowing = function () {
  if (this._html.mediaNotification) {
    return notificationController.isCiNotificationShowing(
      this._html.mediaNotification,
    );
  }
  return false;
};

/**
 * Specifies the behavior of the audio player while not displayed.
 *
 * states:
 *  0: switch to notification (default)
 *  1: stop playing
 *
 * @param state the state to apply
 */
AudioPlayer.prototype.setExitMode = function (state) {
  this._exitMode = state === 1 ? 'exit' : 'switch';
  if (
    this._exitMode === 'exit' &&
    this.isPlaying() &&
    !domHelper.isInDocument(this._html.element)
  ) {
    this.pause();
    this.hide();
  }
  return this;
};

AudioPlayer.prototype.play = function () {
  let _this = this; // helper

  let handlePlay = function () {
    _this._isPlaying = true;

    // update html elements
    for (let element in _this._html) {
      if (_this._html.hasOwnProperty(element) && _this._html[element]) {
        _this._html[element].find('.audioplayer-play').addClass('hide');
        _this._html[element].find('.audioplayer-pause').removeClass('hide');
      }
    }

    if ('mediaSession' in navigator) {
      navigator.mediaSession.playbackState = 'playing';
    }

    _this._playstateChangeObserver.notify('playing');
  };

  // play
  let promise = this._audio.play();
  if (promise != null) {
    promise
      .then(() => {
        handlePlay();
      })
      .catch(() => {
        _this._isPlaying = false;
      });
  } else {
    handlePlay();
  }
};

AudioPlayer.prototype.pause = function () {
  this._audio.pause();
  this._isPlaying = false;

  // update html elements
  for (let element in this._html) {
    if (this._html.hasOwnProperty(element) && this._html[element]) {
      this._html[element].find('.audioplayer-pause').addClass('hide');
      this._html[element].find('.audioplayer-play').removeClass('hide');
    }
  }

  if ('mediaSession' in navigator) {
    navigator.mediaSession.playbackState = 'paused';
  }
  this._playstateChangeObserver.notify('paused');
  return this;
};

AudioPlayer.prototype.isPlaying = function () {
  return this._isPlaying;
};

AudioPlayer.prototype.onPlayStateChanges = function (handler) {
  this._playstateChangeObserver.addOberserver(handler);
  return this;
};

AudioPlayer.prototype.forward = function () {
  let futurePosition = this._audio.currentTime + this.DEFAULT_TIMESKIP;
  this._setPlayerPosition(
    futurePosition > this._audio.duration
      ? this._audio.duration
      : futurePosition,
  );
  return this;
};

AudioPlayer.prototype.backward = function () {
  let futurePosition = this._audio.currentTime - this.DEFAULT_TIMESKIP;
  this._setPlayerPosition(futurePosition < 0 ? 0 : futurePosition);
  return this;
};

AudioPlayer.prototype._setPlayerPosition = function (position) {
  this._audio.currentTime = position;
};

AudioPlayer.prototype._setRelativePlayerPosition = function (position) {
  this._setPlayerPosition(this._audio.duration * position);
};

AudioPlayer.prototype.normalPlayspeed = function () {
  this.setPlayspeed(1);
  return this;
};

AudioPlayer.prototype.fastPlayspeed = function () {
  this.setPlayspeed(1.5);
  return this;
};

AudioPlayer.prototype.setPlayspeed = function (speed) {
  this._audio.playbackRate = speed;
  this._playSpeed = speed;

  // update html elements
  for (let element in this._html) {
    if (this._html.hasOwnProperty(element) && this._html[element]) {
      if (this._playSpeed === 1) {
        // normal speed
        this._html[element].find('.audioplayer-normalspeed').addClass('hide');
        this._html[element].find('.audioplayer-fastspeed').removeClass('hide');
      } else {
        // fast speed
        this._html[element].find('.audioplayer-fastspeed').addClass('hide');
        this._html[element]
          .find('.audioplayer-normalspeed')
          .removeClass('hide');
      }
    }
  }
  return this;
};

AudioPlayer.prototype.setTitle = function (title) {
  this._title = title;

  // update html elements
  for (let element in this._html) {
    if (this._html.hasOwnProperty(element) && this._html[element]) {
      this._html[element]
        .find('.audioplayer-title')
        .empty()
        .append(title || this.DEFAULT_TITLE);
    }
  }
  return this;
};

AudioPlayer.prototype.setSubtitle = function (subtitle) {
  this._subtitle = subtitle;

  // update html elements
  for (let element in this._html) {
    if (this._html.hasOwnProperty(element) && this._html[element]) {
      this._html[element]
        .find('.audioplayer-subtitle')
        .empty()
        .append(subtitle);
    }
  }
  return this;
};

AudioPlayer.prototype._setProgress = function (progress) {
  this._progress = progress;

  // update html elements
  for (let element in this._html) {
    if (this._html.hasOwnProperty(element) && this._html[element]) {
      this._html[element]
        .find('.audioplayer-progress-bar span')
        .attr('style', 'width: ' + (progress || 0) + '%;');
    }
  }
};

AudioPlayer.prototype._setStartTime = function (startTime) {
  this._startTime = startTime;

  // update html elements
  for (let element in this._html) {
    if (this._html.hasOwnProperty(element) && this._html[element]) {
      this._html[element]
        .find('.audioplayer-progress-starttime')
        .empty()
        .append('' + (this._startTime || this.DEFAULT_TIME));
    }
  }
};

AudioPlayer.prototype._setEndTime = function (endTime) {
  this._endTime = endTime;

  // update html elements
  for (let element in this._html) {
    if (this._html.hasOwnProperty(element) && this._html[element]) {
      this._html[element]
        .find('.audioplayer-progress-endtime')
        .empty()
        .append('' + (this._endTime || this.DEFAULT_TIME));
    }
  }
};

AudioPlayer.prototype._updatePosition = function () {
  this._setProgress((this._audio.currentTime / this._audio.duration) * 100);
  this._setStartTime(
    dateController.getMinutesStringFromFloat(this._audio.currentTime),
  );
  this._setEndTime(
    dateController.getMinutesStringFromFloat(
      this._audio.currentTime - this._audio.duration,
    ),
  );
};

AudioPlayer.prototype._initMediaSession = function (title, album) {
  let _this = this; //helper

  if ('mediaSession' in navigator) {
    navigator.mediaSession.metadata = new MediaMetadata({
      title: title,
      album: album,
    });

    navigator.mediaSession.setActionHandler('play', function () {
      _this.play();
    });
    navigator.mediaSession.setActionHandler('pause', function () {
      _this.pause();
    });
    navigator.mediaSession.setActionHandler('stop', function () {
      _this.pause();
    });
    navigator.mediaSession.setActionHandler('seekbackward', function () {
      _this.backward();
    });
    navigator.mediaSession.setActionHandler('seekforward', function () {
      _this.forward();
    });
    return true;
  }
  return false;
};

AudioPlayer.prototype._performExit = function () {
  if (this.isPlaying()) {
    switch (this._exitMode) {
      default:
      case 'switch':
        this.show();
        break;
      case 'exit':
        this.pause();
        break;
    }
  }
};

export default AudioPlayer;
