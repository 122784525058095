import userController from '../../core/UserController.js';
import loginController from './LoginController.js';
import app from '../../app.js';
import $$ from 'dom7';
import dictionary from '../../core/Dictionary';
import json from '../../download/accountOnboarding.json';

var loginView = {
  currentView: 'login',
  init: function (startView) {
    console.log(app.device.cordova);
    if (!app.device.cordova) {
      $$('.register-sublink').remove();
      $$('.login-back-link').remove();
      this.initCardContent();
    } else {
      $$('.accountonboarding-container').remove();
    }

    if (startView !== 'login') {
      $$('.login-login-container').removeClass('login-container-active');
      $$('.register-login-container').addClass('login-container-active');

      $$('.login-login-container').addClass('login-container-inactive');
      $$('.register-login-container').removeClass('login-container-inactive');
      this.currentView = 'register';
    }

    this.initListeners();
  },

  initListeners: function () {
    let currView = this.currentView;
    const switchViewFunction = () => {
      if (currView == 'login') {
        $$('.login-login-container').removeClass('login-container-active');
        $$('.register-login-container').addClass('login-container-active');

        $$('.login-login-container').addClass('login-container-inactive');
        $$('.register-login-container').removeClass('login-container-inactive');
        currView = 'register';
      } else {
        $$('.register-login-container').removeClass('login-container-active');
        $$('.login-login-container').addClass('login-container-active');

        $$('.register-login-container').addClass('login-container-inactive');
        $$('.login-login-container').removeClass('login-container-inactive');
        currView = 'login';
      }
    };

    $$('[data-name="switch-view"]').on('click', switchViewFunction);
    $$('#schoolcode-button').on('click', () => {
      switchViewFunction();
      $$('.item-schoolcode').attr('style', 'display: flex;');
    });
    $$('.login-button[data-name="login"]').on('click', this.login);
    $$('.login-button[data-name="register"]').on('click', this.register);
  },

  /**
   * Fetches the userinput for registrations and checks whether the input fulfills all conditions.
   * If all userinput is correct, calls Controller for registration
   */
  register: function () {
    let username = $$('[data-name="username"]').val(),
      email = $$('[data-name="email"]').val(),
      password = $$('[data-name="password"]').val(),
      passwordrepeat = $$('[data-name="password"]').eq(1).val(),
      schoolcode = $$('[data-name="schoolcode"]').val();

    let agbcheck = $$('[data-name="agb-check"]').prop('checked'),
      privacycheck = $$('[data-name="privacy-check"]').prop('checked');
    let mailregex = /\S+@\S+\.\S+/;
    if (!mailregex.test(email)) {
      app.dialog.alert(dictionary.getText('logintexts', 'emailwarning'));
    } else if (password != passwordrepeat) {
      app.dialog.alert(
        dictionary.getText('logintexts', 'passwordmatchingwarning'),
      );
    } else if (!/\d/.test(password)) {
      app.dialog.alert(
        dictionary.getText('logintexts', 'passwordnumberwarning'),
      );
    } else if (/\s/.test(password)) {
      app.dialog.alert(
        dictionary.getText('logintexts', 'passwordwhitespacewarning'),
      );
    } else if (!/[A-Za-z]/.test(password)) {
      app.dialog.alert(
        dictionary.getText('logintexts', 'passwordletterwarning'),
      );
    } else if (password.length < 8) {
      app.dialog.alert(
        dictionary.getText('logintexts', 'passwordlengthwarning'),
      );
    } else if (!agbcheck) {
      app.dialog.alert(dictionary.getText('logintexts', 'agbwarning'));
    } else if (!privacycheck) {
      app.dialog.alert(dictionary.getText('logintexts', 'privacywarning'));
    } else {
      if (app.device.cordova) {
        loginController.register(
          username,
          email,
          password,
          device.uuid,
          'mobile',
        );
      } else {
        if (!schoolcode) {
          app.dialog.alert(
            'Du musst einen Lizenzschlüssel deiner Schule eingeben!',
          );
        } else {
          loginController.register(
            username,
            email,
            password,
            schoolcode,
            'volume',
          );
        }
      }
    }
  },
  /**
   * Fetches the userinput for login, then calls Controller for login
   */
  login: function () {
    loginController.login(
      $$('[data-name="email-login"]').val(),
      $$('[data-name="password-login"]').val(),
    );
  },

  initCardContent: function () {
    for (let [index, value] of json.titles.entries()) {
      console.log(index);
      console.log(value);
      $$(`.accountOnboarding-card-${index + 1} .card-content .item-inner`).html(
        value,
      );
      $$(`.accountOnboarding-card-${index + 1} .content`).html(
        json.contents[index],
      );
    }
  },
};
export default loginView;
