import $$ from 'dom7';
import elementView from '../../../../../core/ui/ElementView.js';
import inputController from './InputController.js';

const inputView = {
  getClosingText: function (input) {
    const closingDiv = $$(document.createElement('div'));
    closingDiv.addClass('task-input-line');
    closingDiv.html(`
            <p>${input.title}</p>
        `);
    return closingDiv;
  },
  getNoteInput: function (input, answer, handler) {
    const inputdiv = $$(document.createElement('div'));
    inputdiv.addClass('task-input-line');
    inputdiv.append(`
            <label>${input.title}</label>
        `);
    const textinput = $$(
      document.createElement(input.size === 1 ? 'input' : 'textarea'),
    );
    textinput.addClass('task-input' + (input.size === 1 ? '' : ' resizable'));
    textinput.attr('type', 'text');
    textinput.attr('placeholder', 'Tippen, um Antwort einzugeben...');
    if (input.size === 3) {
      textinput.attr('style', 'min-height: 100px');
    }
    if (answer != null) {
      textinput.val(answer);
    }
    textinput.on('change', (event) => handler($$(event.target).val()));
    inputdiv.append(textinput);
    return inputdiv;
  },

  getCheckboxInput: function (input, answer, handler) {
    const inputId = (Math.random() * 10000000) | 0;
    const inputdiv = $$(document.createElement('div'));
    inputdiv.addClass('task-input-line');
    const form = $$(document.createElement('form'));
    inputdiv.append(`
            <label>${input.title}</label>
        `);
    input.answer == '' ? (input.answer = 0) : null;
    form.append(
      inputController.getColoredRadioButtons(input.values, answer, handler),
    );
    /*
        for (let i = 0; i < input.values; i++) {
            form.append(`
                <div style="display: inline-block; width: 10%; " class="checkboxtask-radiobutton">
                    <p>${i + 1}</p>
                    <input class="task-input" type="radio" name="radio-${inputId}" value="radio-${i}">
                </div>`);
        }
        if (answer != null) {
            form.find('input[value="' + answer + '"]').attr('checked', "true");
        }*/
    form.on('change', (event) => handler($$(event.target).val()));
    inputdiv.append(form);
    return inputdiv;
  },

  getCheckboxNoteInput: function (input, answer, handler) {
    //TODO: implement if needed
    return $$(document.createElement('div'));
  },

  getTableInput: function (input, answer, handler) {
    const inputdiv = $$(document.createElement('div'));
    inputdiv.addClass('table-scrollable task-input-line');
    const table = $$(document.createElement('table'));
    let row = $$(document.createElement('tr'));
    const corner = $$(document.createElement('th'));
    corner.addClass('first-row');
    corner.append(input.corner);
    row.append(corner);
    for (let i = 0; i < input.columns.length; i++) {
      let tablehead = $$(document.createElement('th'));
      tablehead.addClass('first-row');
      tablehead.append(input.columns[i]);
      row.append(tablehead);
    }
    table.append(row);

    for (let rowIndex = 0; rowIndex < input.rows.length; rowIndex++) {
      row = $$(document.createElement('tr'));
      let tablehead = $$(document.createElement('th'));
      tablehead.append(input.rows[rowIndex]);
      row.append(tablehead);
      for (let colIndex = 0; colIndex < input.columns.length; colIndex++) {
        let tabledata = $$(document.createElement('td'));
        let tableinput = $$(document.createElement('input'));
        tableinput.attr('type', 'text');
        if (answer && answer[rowIndex] && answer[rowIndex][colIndex]) {
          tableinput.val(answer[rowIndex][colIndex]);
        }
        tableinput.on('change', (event) =>
          handler($$(event.target).val(), rowIndex, colIndex),
        );
        tabledata.append(tableinput);
        row.append(tabledata);
      }
      table.append(row);
    }
    inputdiv.append(table);
    return inputdiv;
  },

  getTableNoteInput: function (input, answer, handler) {
    //TODO: implement if needed
    return $$(document.createElement('div'));
  },
  /*
    getListInput: function(input, answer, handler, task, inputindex){

            const inputdiv = $$(document.createElement('div'));
            inputdiv.addClass('task-input-line');
            inputdiv.append(`
                <label>${input.title}</label>
            `);
            const inputlist = $$(document.createElement('ol'))
            let containerdiv = $$(document.createElement('div'))
            inputlist.addClass("task-list-input list-input")
            for (let index = 0; index < input.length; index++){
                let textinput = $$(document.createElement('input'));
                textinput.attr("type", "text");
                textinput.attr("placeholder", "Tippen, um Antwort einzugeben...");
                if (answer != null) {
                    textinput.val(answer[index]);
                }
                textinput.on('change', (event) => handler(task,inputindex, index)($$(event.target).val()));
                let listelement = $$(document.createElement('li'));
                listelement.addClass("task-list-element")
                listelement.append(textinput);
                let listelementContainer = $$(document.createElement('div'))
                listelementContainer.addClass("task-input")
                listelementContainer.append(listelement)
                containerdiv.append(listelementContainer);
            }
            inputlist.append(containerdiv)
            inputdiv.append(inputlist)

            return inputdiv;
    },*/
  getListInput: function (
    title,
    length,
    expandable,
    placeholder,
    answers,
    handler,
    handlerparams,
    listkey,
  ) {
    if (expandable) {
      return this.getExpandableListInput(
        title,
        answers,
        placeholder,
        listkey,
        handler,
        handlerparams,
      );
    } else {
      const inputdiv = $$(document.createElement('div'));
      inputdiv.addClass('task-input-line');
      inputdiv.append(`
                    <label>${title}</label>
                `);
      let inputlist;

      inputlist = $$(document.createElement('ol'));

      let containerdiv = $$(document.createElement('div'));
      inputlist.addClass('list-input');
      for (let index = 0; index < length; index++) {
        let textinput = $$(document.createElement('input'));
        textinput.attr('type', 'text');
        textinput.attr('placeholder', placeholder);
        if (answers != null) {
          textinput.val(answers[index]);
        }
        textinput.on('change', (event) =>
          handler(handlerparams, index)($$(event.target).val()),
        );
        let listelement = $$(document.createElement('li'));
        listelement.addClass('list-element');
        listelement.append(textinput);
        let listelementContainer = $$(document.createElement('div'));
        listelementContainer.addClass('task-input');
        listelementContainer.append(listelement);
        containerdiv.append(listelementContainer);
      }
      inputlist.append(containerdiv);
      inputdiv.append(inputlist);

      return inputdiv;
    }
  },
  getExpandableListInput: function (
    title,
    userinput,
    placeholder,
    listkey,
    handler,
    handlerparams,
  ) {
    let listDiv = $$(document.createElement('div'));
    let _this = this;
    listDiv.addClass('list expandable-list');
    listDiv.html(`<div class="list-input">
                        <div class="list-topic block-title">${title}</div>
                        <div class="list-group">
                            <ul class="${listkey}-list"></ul>
                        </div>
                    </div>`);
    let inputList = listDiv.find('ul');
    let listItem;
    listItem = this.getListElement(
      placeholder,
      listkey,
      handler,
      handlerparams,
      0,
    );
    let lastItem = listItem;
    inputList.append(listItem);
    for (let [index, option] of userinput.entries()) {
      listItem = this.getListElement(
        placeholder,
        listkey,
        handler,
        handlerparams,
        index + 1,
      );
      inputList.find('input').eq(index).val(userinput[index]);
      inputList.append(listItem);
    }

    let addIcon = inputList.find('li:last-child').find('i');
    addIcon.html(`add`);
    addIcon.on('click', function (e) {
      let listLength = inputList.children().length;
      let listItem = _this.getListElement(
        placeholder,
        listkey,
        handler,
        handlerparams,
        listLength - 1,
      );
      let lastListItem = inputList
        .children()
        .eq(inputList.children().length - 1);
      listItem.insertBefore(lastListItem);
      listItem.find('input').val(lastListItem.find('input').val());
      lastListItem.find('input').val('');
      lastListItem.find('input').attr('id', listkey + '-list-' + listLength);

      lastListItem.find('input').off('change');
      lastListItem
        .find('input')
        .on('change', (event) =>
          handler(handlerparams, listLength)($$(event.target).val()),
        );
    });

    return listDiv;
  },
  getListElement: function (
    placeholder,
    listkey,
    handler,
    handlerparams,
    index,
  ) {
    let listItem = $$(document.createElement('li'));
    listItem.html(`
            <div class="item-content">
                <div class="item-inner">
                    <div class="item-media"><i class="icon material-icons ci-blue"></i></div>
                    <input id="${listkey}-list-${index}" class="list-input" type="text"
                           placeholder="${placeholder}">
                </div>
            </div>
        `);
    listItem
      .find('input')
      .on('change', (event) =>
        handler(handlerparams, index)($$(event.target).val()),
      );
    return listItem;
  },

  getColoredRadioButtons: function (count, selected, handleClick, low, high) {
    let buttons = [];
    for (let number = 0; number < count; number++) {
      let button = $$(document.createElement('span'));
      button.addClass('colored-radio-button');
      buttons.push(button);
    }

    let buttonDiv = $$(document.createElement('div'));
    buttonDiv.addClass('buttons');

    buttons.forEach((button, index) => {
      button.on('click', () => {
        handleClick(button, index, buttons);
      });
      inputView.paintColoredRadioButton(button, index < selected, selected);
      buttonDiv.append(button);
    });

    let container = $$(document.createElement('div'));
    container.addClass('colored-radio-buttons');
    container.append(buttonDiv);
    if (low && high) {
      container.append(`
                <div class="label">
                    <label>${low}</label>
                    <label>${high}</label>
                </div>
            `);
    }
    return container;
  },

  paintColoredRadioButton: function (button, selected, color) {
    button.removeClass('color-1 color-2 color-3 color-4 color-5');
    if (selected) {
      switch (color) {
        default:
          break;
        case 1:
          button.addClass('color-1');
          break;
        case 2:
          button.addClass('color-2');
          break;
        case 3:
          button.addClass('color-3');
          break;
        case 4:
          button.addClass('color-4');
          break;
        case 5:
          button.addClass('color-5');
      }
    }
  },

  isColoredRadioButtonSelected: function (button) {
    return (
      button.hasClass('color-1') ||
      button.hasClass('color-2') ||
      button.hasClass('color-3') ||
      button.hasClass('color-4') ||
      button.hasClass('color-5')
    );
  },

  getDropdownInput: function (input, userinput, handler) {
    let inputDiv = $$(document.createElement('div')).addClass(
      'task-input-line',
    );
    inputDiv.html(`<label>${input.title}</label>`);
    inputDiv.append(
      elementView.getDropdownMenu(
        userinput,
        input.options,
        input.placeholder,
        null,
      ),
    );
    inputDiv
      .find('li')
      .on('click', (event) => handler($$(event.target).html()));
    return inputDiv;
  },
};
export default inputView;
