import introView from '../../IntroView';
import interactionView from './InteractionView';
import resultsView from './ResultsView';
import app from '../../../../../../app';
import exerciseController from '../../../ExerciseController';
import taskController from '../../TaskController';
import noteeditorController from '../../../../../notebook/noteeditor/NoteeditorController';

var educationPathController = {
  task: null,

  firstIndex: 0,

  progress: 0,

  moduleNumber: null,
  chapterNumber: null,
  exerciseNumber: null,

  init: function (task, moduleNumber, chapterNumber, exerciseNumber) {
    this.moduleNumber = moduleNumber;
    this.chapterNumber = chapterNumber;
    this.exerciseNumber = exerciseNumber;
    this.task = task;
    this.displayIntro();
  },

  displayIntro: function () {
    // intro
    this.task.intro =
      'Kurzcheck: Uni, FH, Duales Studium oder Ausbildung- Was könnte für mich passen? Ganz einfach und schnell: Swipe nach rechts, wenn eine Aussage auf dich und deine Persönlichkeit zutrifft. Wenn nicht, dann einfach nach links wischen.';
    introView.addButton(introView.INFO_TEXT, () =>
      educationPathController.moreInfo(),
    );
    introView.addButton(introView.START_TEXT, () =>
      educationPathController.displayTask(),
    );
    if (this.hasResults()) {
      introView.addButton(introView.RESULTS_TEXT, () =>
        educationPathController.displayResults(),
      );
    }
    introView.init(this, this.task);
  },

  displayTask: function () {
    let questions = this.task.slides.map((slide) => slide.question);
    this.firstIndex = this.getFirstNotAnsweredQuestionsIndex();
    if (this.firstIndex >= questions.length) {
      this.emptyResults();
    } else {
      this.updateProgress(this.firstIndex);
    }

    interactionView.init(this, this.task, questions, this.firstIndex);
    if (this.firstIndex === 0) {
      interactionView.loadOverlay();
    }
  },

  displayResults: function (task) {
    if (task) {
      this.task = task;
    }
    resultsView.init(this, this.task, this.moduleNumber, this.getResults());
  },

  updateProgress: function (firstIndex) {
    this.progress = parseInt((firstIndex / this.task.slides.length) * 100);
    interactionView.setProgress(this.progress);
    if (this.progress >= 100) {
      this.finish();
      this.displayResults();
    }
  },

  getProgress: function () {
    return this.progress;
  },

  getFirstNotAnsweredQuestionsIndex: function () {
    let firstIndex = 0;
    if (
      this.task.answers &&
      this.task.answers.length > 0 &&
      this.task.answers[0] != null
    ) {
      for (let index = 0; index < this.task.answers.length; index++) {
        if (this.task.answers[index] == null) {
          break;
        }
        firstIndex = index;
      }
      firstIndex++;
    }
    return firstIndex;
  },

  saveAnswer(index, admit) {
    if (!this.task.answers) {
      this.task.answers = [];
    }
    this.task.answers[parseInt(index)] = admit;
    this.task.save();
    this.updateProgress(parseInt(index + (admit != null ? 1 : 0)));
  },

  finish: function () {
    this.task.finish();
  },

  getResults: function () {
    return {
      titles: ['Uni', 'FH', 'Duales Studium', 'Ausbildung'],
      results: this.evaluateResults(),
    };
  },

  evaluateResults: function () {
    let accumulated = [0, 0, 0, 0];
    this.task.slides.forEach((slide, index) => {
      if (this.task.answers[index]) {
        slide.values.forEach((val, ind) => {
          accumulated[ind] += val;
        });
      }
    });
    return accumulated;
  },

  hasResults: function () {
    if (!this.task) {
      this.task = taskController.getTask(6, 1, 8, 1);
    }
    return (
      this.task.answers &&
      this.task.answers.length &&
      this.task.answers.length > 0
    );
  },

  emptyResults: function () {
    if (this.hasResults()) {
      this.task.answers = [];
      this.task.save();
      this.firstIndex = 0;
      this.updateProgress(0);
    }
  },

  getGraphic: function () {
    if (!this.task) {
      this.task = taskController.getTask(6, 1, 8, 1);
    }
    return resultsView.getGraphic(this.getResults(), true);
  },

  copyResultToNote: function () {
    noteeditorController.newNoteWithContent(
      this.task.name,
      this.getGraphic(),
      6,
    );
  },

  nextExercise: function () {
    if (
      exerciseController.module.number !== this.moduleNumber ||
      exerciseController.chapter.number !== this.chapterNumber ||
      exerciseController.exercise.number !== this.exerciseNumber
    ) {
      exerciseController.init(
        this.moduleNumber,
        this.chapterNumber,
        this.exerciseNumber,
      );
      exerciseController.nextButton = {
        type: 'exercise',
        number: this.exerciseNumber,
      };
    }
    exerciseController.next(true);
  },

  moreInfo: function () {
    app.views.main.router.back(
      `/ex/${this.moduleNumber}/${this.chapterNumber}/${this.exerciseNumber}/0/`,
      { force: true },
    );
  },
};
export default educationPathController;
