import Filter from '../../../core/Filter';
import rankedView from './RankedView';
import unrankedView from './UnrankedView';
import userController from '../../../core/UserController';
import editController from './edit/EditController';

var expertiseController = {
  filter: null,

  key: null,
  expertise: null,
  entries: [],
  originalEntries: [],

  maxValue: 5,

  load: function (key) {
    // determine view
    let ranked = false;

    // map entries
    this.expertise = Object.assign(
      {},
      userController.getUserData('profile').getProfileData().expertise[key],
    ); // dont change profile

    this.key = key;
    this.originalEntries = [];
    let keys = ['self', 'external'];
    for (let expertiseIndex in keys) {
      for (let result of this.expertise.entries[expertiseIndex]) {
        if (typeof result === 'object') {
          ranked = true;
        }

        let entry;
        let entryIndex = this.originalEntries
          .map((ntry) => ntry.name)
          .indexOf(result.name);
        if (entryIndex === -1) {
          entry = {
            name: result.name,
            self: null,
            external: null,
          };
          this.originalEntries.push(entry);
        } else {
          entry = this.originalEntries[entryIndex];
        }
        entry[keys[expertiseIndex]] = result.weight;
      }
    }

    // number of 360 answers
    let externalCounter = this.expertise.responses;

    let taskPath =
      '/ex/' +
      this.expertise.module +
      '/' +
      this.expertise.chapter +
      '/' +
      this.expertise.exercise +
      '/0/';

    // unranked view
    if (!ranked) {
      unrankedView.init(this.expertise, externalCounter, taskPath, key, () =>
        this.delete(),
      );
      return;
    }

    this.initFilter();
    this.filter.apply('custom');

    // ranked view
    rankedView.init(
      this,
      this.expertise.subtitle,
      externalCounter,
      taskPath,
      key,
      () => this.delete(),
    );

    // start refresh cycle
    this.refreshFilter();
  },

  delete: function () {
    editController.delete(this.key);
  },

  refreshFilter: function () {
    if (rankedView.initialized) {
      this.entries = [...this.originalEntries].sort(this.getFilter().sort);
      rankedView.loadEntries(this.entries);
    }
  },

  initFilter: function () {
    this.filter = new Filter(this, {
      custom: {
        name: 'Benutzerdefiniert',
        sort: function (first, second) {
          return 0;
        },
      },
      self: {
        name: 'Selbsteinschätzung',
        sort: function (first, second) {
          if (!first.self && !second.self) {
            return 0;
          } else if (!first.self) {
            return second.self;
          } else if (!second.self) {
            return -first.self;
          } else {
            return second.self - first.self;
          }
        },
      },
      external: {
        name: 'Fremdeinschätzung',
        sort: function (first, second) {
          if (!first.external && !second.external) {
            return 0;
          } else if (!first.external) {
            return second.external;
          } else if (!second.external) {
            return -first.external;
          } else {
            return second.external - first.external;
          }
        },
      },
    });
  },

  getFilter: function (full) {
    if (!full) {
      return this.filter.current;
    } else {
      return this.filter;
    }
  },

  applyFilter: function (state) {
    return this.filter.apply(state);
  },

  unload: function () {
    rankedView.onLeave();
  },
};

export default expertiseController;
