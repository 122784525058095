import view from './InputView';

const inputController = {
  getInput: function (task, inputindex) {
    const input = task.inputs[inputindex];
    switch (input.type) {
      default:
      case 0:
        return view.getClosingText(input);
      case 1:
        return view.getNoteInput(
          input,
          task.getAnswer(inputindex),
          this.saveNoteAnswer(task, inputindex),
        );
      case 2:
        return view.getCheckboxInput(
          input,
          task.getAnswer(inputindex),
          this.saveCheckboxAnswer(task, inputindex),
        );
      case 3:
        return view.getDropdownInput(
          input,
          task.getAnswer(inputindex),
          this.saveDropdownAnswer(task, inputindex),
        );
      case 4:
        return view.getTableInput(
          input,
          task.getAnswer(inputindex),
          this.saveTableAnswer(task, inputindex),
        );
      case 5:
        return this.getListInput(
          input.title,
          input.length,
          input.expandable,
          'Tippen, um Antwort einzugeben...',
          task.getAnswer(inputindex),
          this.saveListAnswerToTask,
          [task, inputindex],
          'task',
        );
    }
  },

  saveNoteAnswer: function (task, inputindex) {
    return function (answer) {
      task.answers[inputindex] = answer;
      task.save();
    };
  },

  saveCheckboxAnswer: function (task, inputindex) {
    return function (answer) {
      task.answers[inputindex] = answer;
      task.save();
    };
  },

  saveDropdownAnswer: function (task, inputindex) {
    return function (answer) {
      task.answers[inputindex] = answer;
      task.save();
    };
  },

  saveTableAnswer: function (task, inputindex) {
    return function (answer, row, col) {
      if (task.answers[inputindex] == null || task.answers[inputindex] === '') {
        task.answers[inputindex] = [];
      }
      if (task.answers[inputindex][row] == null) {
        task.answers[inputindex][row] = [];
      }
      task.answers[inputindex][row][col] = answer;
      task.save();
    };
  },

  /**
   * Returns a bar of radio buttons.
   * @param count number of radio buttons in that bar
   * @param selected the index which button is selected on init. 0 for none.
   * @param saveCallback saveCallback(selected) is called on any change. Its parameter is the index which button is currently selected. 0 for none.
   * @param low description for low side (optional together with high)
   * @param high description for low side (optional together with low)
   * */
  /*
    * Example:
    *
        let save = function(res){
            console.log(res);
        };
        $$("...").append(inputController.getColoredRadioButtons(5,0,save));
        $$("...").append(inputController.getColoredRadioButtons(4,3,save, "klein", "groß"));

    * */
  getColoredRadioButtons: function (count, selected, saveCallback, low, high) {
    let previous = selected;
    let handleClick = function (button, index, buttons) {
      if (
        view.isColoredRadioButtonSelected(button) &&
        !(
          index + 1 < buttons.length &&
          buttons[index + 1] &&
          view.isColoredRadioButtonSelected(buttons[index + 1])
        )
      ) {
        buttons.forEach((btn, it) => {
          view.paintColoredRadioButton(
            btn,
            it <= index - 1,
            count < 10 ? index : index - 1 / 2 + 1,
          );
        });
        previous = index;
        saveCallback(index);
      } else {
        buttons.forEach((btn, it) => {
          view.paintColoredRadioButton(
            btn,
            it <= index,
            count < 10 ? index + 1 : index / 2 + 1,
          );
        });
        previous = index + 1;
        saveCallback(index + 1);
      }
    };
    return view.getColoredRadioButtons(count, selected, handleClick, low, high);
  },

  saveListAnswerToTask: function (handlerparams, listindex) {
    return function (answer) {
      if (
        handlerparams[0].answers[handlerparams[1]] == null ||
        handlerparams[0].answers[handlerparams[1]] === ''
      ) {
        handlerparams[0].answers[handlerparams[1]] = [];
      }
      handlerparams[0].answers[handlerparams[1]][listindex] = answer;
      handlerparams[0].save();
    };
  },
  /**
   * Returns a List of textinputs.
   * @param title to be displayed before list
   * @param length number of textinputs in that list
   * @param expandable true/false whether list should be expandable by user.
   * @param placeholder for textinputs in list
   * @param saved userinput for this list
   * @param handler is called on any change. Its parameter is the index which button is currently selected. 0 for none.
   * @param needed parameter for handling/saving input
   * */
  getListInput: function (
    title,
    length,
    expandable,
    placeholder,
    answers,
    handler,
    handlerparams,
    listkey,
  ) {
    if ((answers == '') | (answers == null)) answers = [];
    return view.getListInput(
      title,
      length,
      expandable,
      placeholder,
      answers,
      handler,
      handlerparams,
      listkey,
    );
  },

  /**
     * Return a dropdown-menu
     @param userinput previous user choice of user
     @param options avaialable options for dropdown
     **/
  getDropdownInput: function (input, userinput, handler) {
    return view.getDropdownInput(input, userinput, handler);
  },
};
export default inputController;
