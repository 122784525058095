import $$ from 'dom7';
import elementFactory from '../../../../../../core/ui/ElementFactory';

let controller;

var interactionView = {
  task: null,

  progressbar: null,

  swiper: null,

  backButton: null,

  init: function (cntrllr, task, questions, firstIndex, index) {
    controller = cntrllr;
    this.task = task;
    this.loadContent(questions, firstIndex);
    this.loadProgressbar();
    this.loadButtons();
    this.loadBackNavigation();
    if (index) {
      this.navigateToSlide(index);
    }
  },

  loadContent: function (questions, firstIndex) {
    if (firstIndex == null) {
      firstIndex = 0;
    }
    $$('#task-page-content')[0].scrollTop = 0;
    let content = $$('#task-content');
    content.empty();
    this.swiper = elementFactory.getSwiper(
      (index) => {
        this.updateBackButtonVisibility();
        controller.saveAnswer(index - 1, false);
      },
      (index) => {
        this.updateBackButtonVisibility();
        controller.saveAnswer(index - 1, true);
      },
      questions,
    );
    elementFactory.navigateSwiperForewardTo(this.swiper, firstIndex + 1);
    content.append(this.swiper);
  },

  loadButtons: function () {
    $$('#task-bottom .button-list').empty();
    this.backButton = $$(document.createElement('div'));
    this.backButton.attr('id', 'checkquestions-previous-slide-button');
    this.backButton.append(`
            <span>Rückgängig</span>
            <i class="icon material-icons">settings_backup_restore</i>
        `);
    this.backButton.on('click', () => {
      controller.saveAnswer(
        elementFactory.previousSwiperSlide(this.swiper) - 1,
      );
      this.updateBackButtonVisibility();
    });
    this.updateBackButtonVisibility();
    this.backButton.insertBefore('#checkquestions-progressbar');
  },

  updateBackButtonVisibility: function () {
    if (elementFactory.hasPreviousSwiperSlide(this.swiper)) {
      this.backButton.removeClass('hide');
    } else {
      this.backButton.addClass('hide');
    }
  },

  loadProgressbar: function () {
    this.progressbar = elementFactory.getProgressbar(controller.getProgress());
    this.progressbar.attr('id', 'checkquestions-progressbar');
    const content = $$('#task-content');
    content.append(this.progressbar);
  },

  loadOverlay: function () {
    let overlay = $$(document.createElement('div'));
    overlay.addClass('overlay');
    overlay.attr('id', 'checkquestions-overlay');
    overlay.append(`
            <img src="./static/media/swiper_preview_arrows/swiper-arrow-right.png" alt="Pfeil nach rechts" class="right">  
            <div class="swiper">
                <div class="swiper-card">
                    <span class="swiper-card-title">Ich stimme der Aussage zu.</span>
                </div>
            </div>  
        `);
    overlay
      .find('.swiper')
      .attr(
        'style',
        'margin-top: ' + $$('.swiper-card:first-child').offset().top + 'px;',
      );

    let first = true;
    overlay.on('click', (event) => {
      if (first) {
        const title = overlay.find('.swiper-card-title');
        title.empty();
        title.append('Ich stimme der Aussage nicht zu.');

        const img = overlay.find('img');
        img.attr(
          'src',
          './static/media/swiper_preview_arrows/swiper-arrow-left.png',
        );
        img.attr('alt', 'Pfeil nach links');
        img.removeClass('right');
        img.addClass('left');
      } else {
        overlay.attr('style', 'display: none;');
      }
      first = false;
    });

    overlay.insertBefore('#task-page-content');
  },

  setProgress: function (value) {
    if (this.progressbar) {
      elementFactory.setProgressbar(this.progressbar, value);
    }
  },

  navigateToSlide: function (index) {
    elementFactory.navigateSwiperForewardTo(this.swiper, index);
  },

  loadBackNavigation: function () {
    const back = $$('.page[data-name="task"] .navbar .left a.link')
      .off('click')
      .removeClass('back');
    back.on('click', () => {
      back.off('click').addClass('back');

      if (elementFactory.hasPreviousSwiperSlide(this.swiper)) {
        // last slide
        controller.saveAnswer(
          elementFactory.previousSwiperSlide(this.swiper) - 1,
        );
        this.updateBackButtonVisibility();

        this.loadBackNavigation();
      } else {
        //intro
        controller.displayIntro();
      }
    });
  },
};

export default interactionView;
