import $$ from 'dom7';
import app from '../../../app';

var expertiseView = {
  loadMainToolbar: function () {
    app.loadMainToolbar($$('.page[data-name="expertise"] .main-toolbar'));
  },

  loadMainFAB: function () {
    app.loadMainFAB($$('.page[data-name="expertise"] .main-fab'));
  },

  loadTitle: function (title) {
    $$('#expertise-title').empty().append(title);
  },

  loadExternalCounter: function (count) {
    $$('#expertise-external-counter i.expertise-extra-counter').remove();
    $$('#expertise-external-counter i').removeClass('selected');
    for (let index = 0; index < count && index < 3; index++) {
      $$($$('#expertise-external-counter i')[index]).addClass('selected');
    }
    for (let index = 3; index < count; index++) {
      $$('#expertise-external-counter').append(
        '<i class="icon material-icons selected">person</i>',
      );
    }
  },

  linkButton: function (path, id, del) {
    $$('.expertise-task-button')
      .off('click')
      .on('click', () => {
        app.views.current.router.navigate(path);
        if (app.popover.get('#expertise-popover-menu')) {
          app.popover.get('#expertise-popover-menu').close();
        }
      });

    $$('.expertise-edit-button')
      .off('click')
      .on('click', () => {
        app.views.current.router.navigate('/expertise/' + id + '/edit/');
        app.popover.get('#expertise-popover-menu').close();
      });

    $$('.expertise-delete-button')
      .off('click')
      .on('click', () => {
        app.popover.get('#expertise-popover-menu').close();
        del();
      });
  },
};
export default expertiseView;
