import $$ from 'dom7';
import elementFactory from '../../../../../../core/ui/ElementFactory';
import inputController from '../../input/InputController';

let controller;

var detailsView = {
  init: function (cntrllr, intro, results) {
    controller = cntrllr;
    this.loadTop();
    this.loadText(intro);
    this.loadContent(results);
    this.loadBottom();
    this.loadBackNavigation();
  },

  loadTop: function () {
    $$('#task-page-content .page-content-top')
      .empty()
      .append(
        elementFactory
          .getProgressbar(controller.getProgress())
          .attr('id', 'optionranking-progress'),
      ).append(`
                <h1 id="task-title" class="page-title">Optionen-Ranking</h1>
                <h2 id="task-subtitle" class="page-subtitle"></h2>
            `);
  },

  loadText: function (intro) {
    $$('#task-page-content')[0].scrollTop = 0;
    const content = $$('#task-content');
    content.empty();
    content.append(intro);
  },

  loadContent: function (results) {
    const content = $$('#task-content');

    if (!results || !results.length || results.length <= 0) {
      // if empty
      content.append(`
                <div class="block placeholder-container">
                    <label class="placeholder">Du hast keine Option bewertet.</label>
                </div>    
            `);
    } else {
      results.forEach((res) => {
        content.append(this.getCard(res.title, res.lists));
      });
    }
  },

  getCard: function (title, lists) {
    let content = $$(document.createElement('div')).addClass('content');

    lists.forEach((list) => {
      // options
      let options = $$(document.createElement('div')).addClass(
        'optionranking-details-card-options',
      );
      if (!list.options || !list.options.length || list.options.length <= 0) {
        // if empty
        options.append('Du hast keine Option bewertet.');
      } else {
        // options-list
        list.options.forEach((option) => {
          options.append(
            $$(document.createElement('div'))
              .addClass('optionranking-details-card-entry')
              .append(
                `
                                <div class="optionranking-details-card-entry-title">${option.name}</div>
                            `,
              )
              .append(inputController.getColoredRadioButtons(5, option.rate)),
          );
        });
      }

      // card-content
      content.append(
        $$(document.createElement('div'))
          .addClass('optionranking-details-card-row')
          .append(
            `
                            <div class="optionranking-details-card-question">${list.question}</div>
                        `,
          )
          .append(options),
      );
    });

    // card
    return $$(document.createElement('div'))
      .addClass('optionranking-details-card card card-line-small list')
      .append(
        $$(document.createElement('div'))
          .addClass('card-content accordion-item')
          .append(
            `
                        <a href="#" class="item-content item-link">
                            <div class="item-inner">
                                <span class="item-title">${title}</span>
                            </div>
                        </a>
                    `,
          )
          .append(
            $$(document.createElement('div'))
              .addClass('accordion-item-content')
              .append(content),
          ),
      );
  },

  loadBottom: function () {
    $$('#task-bottom .button-list').empty();
  },

  loadBackNavigation: function () {
    const back = $$('.page[data-name="task"] .navbar .left a.link')
      .off('click')
      .removeClass('back');
    back.on('click', () => {
      back.off('click').addClass('back');
      controller.previous();
    });
  },
};
export default detailsView;
