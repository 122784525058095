import { v4 as uuidv4 } from 'uuid';
import Profile from './Profile';

function EduIdea(id) {
  if (id) Object.assign(this, JSON.parse(localStorage.getItem(id)));
  else {
    this.id = uuidv4();
    this.last_modified = new Date().getTime();
  }
}

EduIdea.prototype.load = function () {};

EduIdea.prototype.save = function () {
  this.last_modified = new Date().getTime();
  localStorage.setItem(this.id, JSON.stringify(this));
  Profile.calcOptions();
};

EduIdea.prototype.getOptions = function () {
  if (this.options) {
    return this.options;
  } else {
    return [];
  }
};
EduIdea.prototype.getPro = function () {
  if (this.pro) {
    return this.pro;
  } else {
    return [];
  }
};
EduIdea.prototype.getContra = function () {
  if (this.contra) {
    return this.contra;
  } else {
    return [];
  }
};

EduIdea.prototype.setAttribute = function (key, index, value, nosave) {
  this[key] == undefined ? (this[key] = []) : null;
  if (index != undefined) {
    this[key][index] = value;
    this[key] = this[key].filter((item) => item);
  } else {
    this[key] = value;
  }
  if (!nosave) {
    this.save();
  }
};
export default EduIdea;
