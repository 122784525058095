import userController from '../../core/UserController';

function Task(json, module_number, chapter_number, exercise_number, number) {
  this.number = number;
  this.module_number = module_number;
  this.chapter_number = chapter_number;
  this.exercise_number = exercise_number;
  this.id = number;
  this.state = -1; // -1 = not started yet, 0 = in progress, 1 = done
  this.answers = [];
  this.last_synced = null;
  this.attributeLocalStorage = [];
  for (var key in json) {
    this[key] = json[key];
  } // Alle Attribute aus der JSON kopieren
  //deprecated?
  for (var input in this.inputs) {
    this.answers[input] = '';
  }

  var toLoad = JSON.parse(localStorage.getItem(this._id)); // Gespeicherte Daten vom Modul laden
  for (var key in toLoad) this[key] = toLoad[key]; //Copy Data from LS to object

  //Determine whether the task has been started
  if (this.state === 0)
    userController.startedTasks[
      this.module_number.toString() +
        this.chapter_number.toString() +
        this.exercise_number.toString() +
        this.number.toString()
    ] = this;

  //load attributes from localStorage defined by some other userinput not belonging to the task
  this.loadAttributesFromLS();

  //save Task to LS if this was the first time it got called
  if (!toLoad || !toLoad._id) {
    this.save(true);
  }
}

/*
Very bad way of handling this, but no better idea currently, because of no pointer change nested obj attributes
TODO: Rewrite recursivly
Function loads attribute dynamically from localStorage based on the keys inside 'attributeLocalStorage'
*/
Task.prototype.loadAttributesFromLS = function () {
  for (let attribute of this.attributeLocalStorage) {
    let objkeys = attribute.objKeys;
    let lsKeys = attribute.localStorageKeys;
    /*
        toModify = this[attribute.objKeys[0]]
        for(let index = 1; index < attribute.objKeys.length; index++){
            toModify = toModify[attribute.objKeys[index]]
        }
        toModify = JSON.parse(localStorage.getItem(attribute.localStorageKey)).answers*/

    let localStorageItem;
    switch (attribute.localStorageKeys.length) {
      case 1:
        localStorageItem = JSON.parse(localStorage.getItem(lsKeys[0]));
        break;
      case 2:
        localStorageItem = JSON.parse(localStorage.getItem(lsKeys[0]))[
          lsKeys[1]
        ];
        break;
      case 3:
        localStorageItem = JSON.parse(localStorage.getItem(lsKeys[0]))[
          lsKeys[1]
        ][lsKeys[2]];
        break;
      case 4:
        localStorageItem = JSON.parse(localStorage.getItem(lsKeys[0]))[
          lsKeys[1]
        ][lsKeys[2]][lsKeys[3]];
        break;
    }

    switch (attribute.objKeys.length) {
      case 1:
        this[objkeys[0]] = localStorageItem;
        break;
      case 2:
        this[objkeys[0]][objkeys[1]] = localStorageItem;
        break;
      case 3:
        this[objkeys[0]][objkeys[1]][objkeys[2]] = localStorageItem;
        break;
      case 4:
        this[objkeys[0]][objkeys[1]][objkeys[2]][objkeys[3]] = localStorageItem;
        break;
      case 5:
        this[objkeys[0]][objkeys[1]][objkeys[2]][objkeys[3]][objkeys[4]] =
          localStorageItem;
        break;
    }
  }
};

/* deprecated
// Sets user input for a certain key
Task.prototype.setUserInput = function(key, input){
      this[key] = input;
      this.user_input_keys.push(key);
};

// Returns all user input
Task.prototype.getAllUserInput = function(){
      var answers = {};
      for (var key of this.user_input_keys){
        if(this[key] != null){
          answers[key] = this[key];
        }
      }
      return answers;
};*/

// Return user input from a certain key
Task.prototype.getAnswer = function (index) {
  if (this.answers[index] != null) {
    return this.answers[index];
  } else {
    return null;
  }
};

/**
 * Saves content of Task, including answers to LocalStorage
 * @param {any} notLastModified set true if this.last_modified should not be updated and task should not be started
 */
Task.prototype.save = function (notLastModified) {
  var toSave = {};
  let modulesJson = userController.getModulesJson();
  var taskJson =
    modulesJson[this.module_number - 1].chapters[this.chapter_number - 1]
      .exercises[this.exercise_number - 1].tasks[this.number - 1];

  if (!notLastModified) {
    this.last_modified = Date.now();

    // task is started now
    if (this.state < 0) {
      this.state = 0;
    }
  }

  for (var key in this) {
    if (
      taskJson &&
      !(key in taskJson) &&
      typeof this[key] != 'function' &&
      !['module_number', 'chapter_number', 'exercise_number'].includes(key)
    ) {
      toSave[key] = this[key];
    }
  }
  //store id in LS for upload in DB.
  toSave._id = this._id;

  // save userdata variable data from object to LS
  localStorage.setItem(this._id, JSON.stringify(toSave));

  this.writeToExplicitKey();

  if (this.inputs != undefined) {
    for (const [index, input] in this.inputs.entries()) {
      if ('answerLocalStorage' in input) {
        // TODO: Comment what case 5 is. Deprecated?
        switch (input.type) {
          case 5:
            for (let i = 0; i < input.length; i++) {
              localStorage.setItem(
                input.answerLocalStorage + '-' + toString(i),
                JSON.stringify({
                  answers: this.answers,
                  module: this.module_number,
                  chapter: this.chapter_number,
                  exercise: this.exercise_number,
                  task: this.number,
                  input: index,
                }),
              );
            }
            break;
          default:
            localStorage.setItem(
              input.answerLocalStorage,
              JSON.stringify({
                answers: this.answers,
                module: this.module_number,
                chapter: this.chapter_number,
                exercise: this.exercise_number,
                task: this.number,
                input: index,
              }),
            );
        }
      }
    }
  }
};

Task.prototype.writeToExplicitKey = function () {
  // If there is a key in 'answerLocalStorage' save answers attrbute to different localStorage-key for separate access.
  if ('answerLocalStorage' in this) {
    localStorage.setItem(
      this.answerLocalStorage,
      JSON.stringify({
        answers: this.answers,
        module: this.module_number,
        chapter: this.chapter_number,
        exercise: this.exercise_number,
        task: this.number,
      }),
    );
  }
};

Task.prototype.finish = function () {
  if (this.state < 1) {
    this.state = 1;
    this.save();
  }
};

export default Task;
