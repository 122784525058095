import $$ from 'dom7';
import elementFactory from '../../../../../../core/ui/ElementFactory';

let controller;

var newView = {
  task: null,

  init: function (cntrllr, task, displayReady) {
    controller = cntrllr;
    this.task = task;
    this.loadContent();
    this.loadButtons(displayReady);
  },

  loadContent: function () {
    $$('#task-page-content')[0].scrollTop = 0;
    let content = $$('#task-content');
    content.empty();
    content.append(`
                <div class="list">
                <div class="list-topic">Veranstaltung aus dem ersten Semester</div>   
                    <div class="list-group">
                        <ul>
                            <li>
                                <div class="item-content">
                                    <div class="item-inner">
                                        <input id="coursecatalog-lecture-title-input" class="bold" type="text"
                                               placeholder="Tippen, um Veranstaltung einzugeben">
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="item-content">
                                    <div class="item-inner">
                                        <input id="coursecatalog-lecture-note-input" type="text"
                                               placeholder="Anmerkungen zu dieser Veranstaltung">
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
        `);
  },

  loadButtons: function (displayReady) {
    let readyButton = $$(document.createElement('button'));
    readyButton.addClass('button button-fill color-white');
    readyButton.append('fertig');
    readyButton.on('click', () => {
      this.close();
      controller.finish();
      controller.displayResults();
    });

    let nextButton = $$(document.createElement('button'));
    nextButton.addClass('button button-fill color-white');
    nextButton.append('weiter');
    nextButton.on('click', () => {
      let titleObj = $$('#coursecatalog-lecture-title-input');
      let title = titleObj.val();
      if (title !== '') {
        this.close();
        controller.displayRateLecuture(
          title,
          $$('#coursecatalog-lecture-note-input').val(),
        );
      } else {
        titleObj.focus();
        titleObj.addClass('missing-value');
      }
    });

    let otherIdea = $$(document.createElement('div'));
    otherIdea.attr('id', 'coursecatalog-other-idea-container');
    otherIdea.append(`
            <span id="coursecatalog-other-idea">Eine andere Idee bewerten</span>
        `);
    otherIdea.on('click', () => {
      this.close();
      controller.displayIntro();
    });

    const list = $$('#task-bottom .button-list');
    list.empty();
    list.addClass('shadowed horizontal');
    if (displayReady) {
      list.append(readyButton);
    } else {
      list.removeClass('horizontal');
    }
    list.append(nextButton);
    otherIdea.insertAfter(list);
  },

  close: function () {
    $$('#coursecatalog-other-idea-container').remove();
  },
};

export default newView;
