import $$ from 'dom7';
import app from '../../app.js';
import controller from './HomeController';
import dictionary from '../../core/Dictionary.js';
import userController from '../../core/UserController';

var homeView = {
  currentModule: null,

  currentChapter: null,

  currentExercise: null,

  currentSubblock: null,

  allSteps: null,

  nextPrctices: null,

  tasks: null,

  init: function (
    currentModule,
    currentChapter,
    currentExercise,
    currentSubblock,
    allSteps,
    nextPractices,
    name,
    firstBackground,
  ) {
    this.currentModule = currentModule;
    this.currentChapter = currentChapter;
    this.currentExercise = currentExercise;
    this.currentSubblock = currentSubblock;
    this.allSteps = allSteps;
    this.nextPrctices = nextPractices;
    this.firstBackground = firstBackground;
    this.loadMainToolbar();
    this.loadCurrentSubblock();
    this.loadNextSteps();
    this.loadNextPractices();
    dictionary.loadModuleToDOM('hometexts', $$('.page[data-name="home"]'));
    this.loadTitle(name);
    this.initPopover();
  },

  loadMainToolbar: function () {
    app.loadMainToolbar($$('.page[data-name="home"] .main-toolbar'), 'home');
  },

  loadTitle: function (name) {
    let title = $$('#home-title');
    title.html(title.html() + name + '!');
  },

  loadCurrentSubblock: function () {
    let card = $$('#home-current-subblock');
    card.addClass('card module-card lazy');
    card.attr('data-background', this.currentModule.getBackgroundImage());
    card.append(`
            <div class="card-header no-hairline">
                <h2 class="title">Schritt ${
                  this.currentModule.number
                } - Block ${this.currentChapter.number}:<br>
                ${this.currentChapter.name}</h2>
                <h3 class="subtitle">${this.currentExercise.name}</h3>
            </div>
            <div class="card-footer no-hairline">
                <i class="icon material-icons">timer</i>
                <span>${parseInt(
                  this.currentExercise.getRemainingMinutes(),
                )} min<span>
            </div>
        `);
    card.on('click', () => {
      app.views.main.history.push(`/mod/${this.currentModule.number}/`);
      app.views.main.router.navigate(
        `/ex/${this.currentModule.number}/${this.currentChapter.number}/${this.currentExercise.number}/${this.currentSubblock.number}/`,
      );
    });
  },

  loadNextSteps: function () {
    let div = $$('#home-steps');
    div.empty();
    this.allSteps.forEach((step) => {
      div.append(this.getStep(step));
    });
    $$('.cards-scroll')[0].scrollLeft =
      $$('#home-step-' + this.currentModule.number).offset().left -
      $$('.page[data-name="home"] .page-content-main').offset().left;
  },

  getStep: function (step) {
    const card = $$(document.createElement('div'));
    card.addClass('card module-card lazy');
    card.attr('id', 'home-step-' + step.number);
    card.attr('data-background', step.getBackgroundImage());
    card.append(`
            <div class="card-header no-hairline">
                <h2 class="title">Schritt ${step.number}</h2>
                <h3 class="subtitle">${step.name}</h3>
            </div>
            <div class="card-content no-hairline">
                    <div
                        class="gauge gauge-init"
                        data-type="circle"
                        data-value="${step.getPercentageFinishedSubblocks()}"
                        data-value-text="${parseInt(
                          step.getPercentageFinishedSubblocks() * 100,
                        )}%"
                        data-value-font-size="70"
                        data-value-font-weight="100"
                        data-label-text="geschafft"
                        data-label-font-size="30"
                        data-label-font-weight="100"
                        data-border-bg-color="transparent"
                        data-border-color="#F5F5F5"
                        data-label-text-color="#F5F5F5"
                        data-value-text-color="#F5F5F5"
                        data-border-width="9"
                    ></div>
            </div>
        `);
    card.on('click', () => {
      app.views.main.router.navigate(`/mod/${step.number}/`);
    });
    return card;
  },

  loadNextPractices: function () {
    let div = $$('#home-practices');
    div.empty();
    if (this.nextPrctices == []) {
    } else {
      this.nextPrctices.forEach((practice) => {
        div.append(this.getPractices(practice));
      });
    }

    // all tasks card
    div.prepend(
      $$(document.createElement('div'))
        .addClass('card module-card lazy')
        .attr('data-background', this.firstBackground)
        .attr('id', 'home-tasks-all-card')
        .append(
          `
                    <div class="card-header no-hairline">
                        <h2 class="title" data-dic-key="tasks-all"></h2>
                        <h3 class="subtitle" style="height: 1em"></h3>
                    </div>
                `,
        )
        .on('click', () => {
          app.views.current.router.navigate('/alltasks/');
        }),
    );
    $$('.cards-scroll')[1].scrollLeft = $$('#home-tasks-all-card').width() + 12; // 12px spacing
  },

  getPractices: function (practice) {
    const card = $$(document.createElement('div'));
    card.addClass('card module-card lazy');
    card.attr('data-background', practice.backgroundImage);
    card.append(`
            <div class="card-header no-hairline">
                <h2 class="title">Schritt ${practice.module_number} - Block ${practice.chapter_number}</h2>
                <h3 class="subtitle">${practice.name}</h3>
            </div>
        `);
    card.on('click', () => {
      app.views.main.router.navigate(
        `/task/${practice.module_number}/${practice.chapter_number}/${practice.exercise_number}/${practice.number}/`,
      );
    });
    return card;
  },
  /* Initializes Popover menu
   */
  initPopover: function () {
    if (app.dbconnector.isLoggedIn()) {
      $$('#register-popover').addClass('hide');
      $$('#reset-app-popover').addClass('hide');
      $$('#logout-popover').removeClass('hide');
    }
    $$('#logout-popover').on('click', () => {
      userController.logout();
    });
    $$('#reset-app-popover').on('click', () => {
      app.dialog.confirm(
        'Sicher das du die App zur&uumlcksetzen m&oumlchtest? Diese Aktion wird alle gespeicherten Daten l&oumlschen.',
        () => {
          app.dialog.close();
          app.dialog.confirm(
            'Ganz sicher?',
            () => {
              userController.afterLogout();
            },
            () => {},
          );
        },
        () => {},
      );
    });
  },
};
export default homeView;
