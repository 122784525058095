import $$ from 'dom7';

let controller;

var trainingOrStudiesView = {
  init: function (cntrllr, intro, lists) {
    controller = cntrllr;
    this.loadText(intro);
    this.loadContent(lists);
    this.loadBottom();
    this.loadBackNavigation();
  },

  loadText: function (intro) {
    $$('#task-page-content')[0].scrollTop = 0;
    const content = $$('#task-content');
    content.empty();
    content.append(intro);
  },

  loadContent: function (lists) {
    for (let title in lists) {
      let card = $$(document.createElement('div')).addClass(
        'jobranking-card-line-small card card-line-small list',
      );
      if (lists.hasOwnProperty(title)) {
        card.append(`
                        <div class="card-content accordion-item">
                            <a href="#" class="item-content item-link">
                                <div class="item-inner">
                                    <span class="item-title">${title}</span>
                                </div>
                            </a>
                            <div class ="accordion-item-content">
                                <div class="content"></div>
                            </div>
                        </div>
                    `);
        for (let field in lists[title]) {
          if (lists[title].hasOwnProperty(field)) {
            let list = $$(document.createElement('div')).addClass(
              'jobranking-dropdown-list',
            ).append(`
                                <div class="jobranking-dropdown-list-title">${field}</div>
                                <ul></ul>
                            `);
            for (let job of lists[title][field]) {
              list.find('ul').append(`
                                    <li>${job}</li>
                                `);
            }
            card.find('.content').append(list);
          }
        }
      }
      $$('#task-content').append(card);
    }
  },

  loadBottom: function () {
    let training = $$(document.createElement('button'))
      .addClass('button button-fill color-white')
      .append('Ausbildung')
      .on('click', () => {
        controller.training();
      });

    let studies = $$(document.createElement('button'))
      .addClass('button button-fill color-white')
      .append('Studium')
      .on('click', () => {
        controller.studies();
      });

    let both = $$(document.createElement('button'))
      .addClass('button button-fill color-white')
      .append('Beides')
      .on('click', () => {
        controller.both();
      });

    $$('#task-bottom .button-list')
      .empty()
      .removeClass('horizontal')
      .attr('id', 'jobrating-button-list')
      .append(
        $$(document.createElement('div'))
          .addClass('button-list shadowed horizontal')
          .append(training)
          .append(studies),
      )
      .append(
        $$(document.createElement('div'))
          .addClass('button-list shadowed')
          .append(both),
      );
  },

  loadBackNavigation: function () {
    const back = $$('.page[data-name="task"] .navbar .left a.link')
      .off('click')
      .removeClass('back');
    back.on('click', () => {
      back.off('click').addClass('back');
      controller.previous();
    });
  },
};
export default trainingOrStudiesView;
