import loginView from './LoginView.js';
import app from '../../app.js';
import userController from '../../core/UserController.js';
import syncController from '../../core/SynchronisationController';
import settingsController from '../settings/SettingsController';
import dictionary from '../../core/Dictionary';

const loginController = {
  init: function (startView = 'login') {
    loginView.init(startView);
  },

  /**
   * Calls UserController for registration. If Successful navigates to Main-page
   * @param username username for regisrations and future logins
   * @param email email for registration
   * @param password password used for future logins
   * @param licenseKey key for license in register form e.g. "123456789"
   * @param licenseType type of license in regsiter form e.g. "volume"
   */
  register: function (username, email, password, licenseKey, licenseType) {
    app.dialog.preloader(dictionary.getText('logintexts', 'registerwait'));
    userController
      .register(username, email, password, licenseKey, licenseType)
      .then((result) => {
        app.dialog.close();
        if (result) {
          app.views.main.router.navigate('/userinfo/1');
          settingsController.loadUserinfoInput(true);
        }
      });
  },
  /**
   * Calls UserController to attempt to login
   * Alerts if login was unsuccessful or successful
   * @param username username for login
   * @param password password for login
   */
  login: function (username, password) {
    app.dialog.preloader(dictionary.getText('logintexts', 'loginwait'));
    userController.login(username, password).then((result) => {
      app.dialog.close();
      if (result) {
        app.views.main.router.navigate('/');
        syncController.syncUserData(app);
        userController.downloadUserinfo();
        localStorage.setItem('skip-onboarding', 'true');
      }
    });
  },
};

export default loginController;
