import view from './AlltasksView';
import Filter from '../../../core/Filter';
import dictionary from '../../../core/Dictionary';
import userController from '../../../core/UserController';

const alltasksController = {
  sort: null,
  _tasks: [],

  /** called on page init */
  init() {
    this._loadTasks();

    // init sort
    this.sort = new Filter(this, {
      not_started: {
        name: dictionary.getText(
          'hometexts',
          'alltasks-tasks-sort-not_started',
        ),
        sort: function (first, second) {
          return first.stateNumber - second.stateNumber;
        },
      },
      started: {
        name: dictionary.getText('hometexts', 'alltasks-tasks-sort-started'),
        sort: function (first, second) {
          if (first.stateNumber === second.stateNumber) return 0;
          if (first.stateNumber === 0) return -1;
          if (second.stateNumber === 0) return 1;
          return 0;
        },
      },
      finished: {
        name: dictionary.getText('hometexts', 'alltasks-tasks-sort-finished'),
        sort: function (first, second) {
          return second.stateNumber - first.stateNumber;
        },
      },
    });
    this.sort.apply('started'); // triggers refresh

    view.init(this);
  },

  /** called on sort apply */
  refreshFilter() {
    // load tasks to view
    view.loadTasks([...this._tasks].sort(this.sort.current.sort));
  },

  /** prepare dto */
  _loadTasks() {
    let tasks = userController.getAllTasks();

    this._tasks = [];
    for (let task of tasks) {
      // dto for view
      let dto = {
        title: task.name,
        stateNumber: task.state,
        link:
          '/task/' +
          task.module_number +
          '/' +
          task.chapter_number +
          '/' +
          task.exercise_number +
          '/' +
          task.number +
          '/',
      };

      // number for sort
      switch (dto.stateNumber) {
        default:
        case -1:
          dto.state = 'not_started';
          break;
        case 0:
          dto.state = 'started';
          break;
        case 1:
          dto.state = 'finished';
          break;
      }

      this._tasks.push(dto);
    }
  },
};
export default alltasksController;
