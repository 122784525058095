import $$ from 'dom7';

let controller;

var selectionView = {
  rateButton: null,

  init: function (cntrllr, intro, ideas, selected) {
    controller = cntrllr;
    this.loadTop();
    this.loadText(intro);
    this.loadContent(ideas, selected);
    this.loadButtons();
    this.loadBackNavigation();
  },

  loadTop: function () {
    $$('#task-page-content .page-content-top').empty().append(`
                <h1 id="task-title" class="page-title">Ideen-Ranking</h1>
                <h2 id="task-subtitle" class="page-subtitle"></h2>
            `);
  },

  loadText: function (intro) {
    $$('#task-page-content')[0].scrollTop = 0;
    let content = $$('#task-content');
    content.empty();
    content.append(intro);
  },

  loadContent: function (ideas, selected) {
    selectionView.ideas = [];
    let content = $$('#task-content');
    ideas.forEach((idea) => {
      let card = $$(document.createElement('div'))
        .addClass('one-line-card idearanking-one-line-card')
        .append(idea.name)
        .on('click', () => {
          selectionView.clickIdea(card, idea.id);
        });
      if (selected.includes(idea.id)) {
        card.addClass('selected');
      }
      content.append(card);
    });
  },

  loadButtons: function () {
    this.rateButton = $$(document.createElement('button'));
    this.rateButton.addClass('button button-fill color-white');
    this.rateButton.append('Ideen vergleichen');
    this.rateButton.on('click', () => {
      controller.next();
    });

    const buttonsList = $$('#task-bottom .button-list');
    buttonsList.empty();
    buttonsList.append(this.rateButton);

    this.updateRateButtonVisibility();
  },

  loadBackNavigation: function () {
    const back = $$('.page[data-name="task"] .navbar .left a.link')
      .off('click')
      .removeClass('back');
    back.on('click', () => {
      back.off('click').addClass('back');
      controller.previous();
    });
  },

  clickIdea: function (card, id) {
    if (this.isSelectedIdea(card)) {
      this.unselectIdea(card);
      controller.unSelectIdea(id);
    } else {
      if (controller.selectIdea(id)) {
        this.selectIdea(card);
      }
    }
    this.updateRateButtonVisibility();
  },

  isSelectedIdea: function (card) {
    return card.hasClass('selected');
  },

  selectIdea: function (card) {
    card.addClass('selected');
  },

  unselectIdea: function (card) {
    card.removeClass('selected');
  },

  updateRateButtonVisibility: function () {
    if (controller.isIdeaSelectionValid()) {
      this.showRateButton();
    } else {
      this.hideRateButton();
    }
  },

  showRateButton: function () {
    this.rateButton.removeClass('disabled');
  },

  hideRateButton: function () {
    this.rateButton.addClass('disabled');
  },
};
export default selectionView;
