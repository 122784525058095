import $$ from 'dom7';
import inputController from '../steps/exercise/task/input/InputController';

const feedbackformView = {
  feedback: [],
  init: function () {
    this.initForm();
    this.initListeners();
  },
  initForm: function () {
    let _this = this;
    let now = new Date();
    let form = $$('#feedbackform');
    form.append(
      inputController.getColoredRadioButtons(5, 0, (res) => {
        _this.feedback[0] = res;
        _this.updateLink();
      }),
    );
    form.append(`
            <div class="">
            <label>
                Weshalb würdest du die App empfehlen? Was machen wir gut?
            </label>
            <div class="item-input-wrap"><textarea class="textarea textarea-shadowed textarea-white full-width" id="secondfeedbackinput"></textarea></div>
            </div>
            <div class="">
            <label>
                Was können wir noch besser machen?
            </label>
            <div class="item-input-wrap"><textarea class="textarea textarea-shadowed textarea-white full-width" id="thirdfeedbackinput"></textarea></div></div>
            <div class="">
                <a href="mailto:feedback@deep-app.de?subject=Feedback zur App DEEP! Berufsorientierung" class="link external button button-fill color-white full-width shadowed" target="_system" id="sendfeedbackbutton">Feedback absenden</a>
            </div>`);
  },
  initListeners: function () {
    let _this = this;
    $$('.page[data-name="feedbackform"] .textarea').on('change', () => {
      _this.feedback[1] = $$('#secondfeedbackinput').val();
      _this.feedback[2] = $$('#thirdfeedbackinput').val();

      _this.updateLink();
    });
  },
  updateLink: function () {
    $$('#sendfeedbackbutton').attr(
      'href',
      'mailto:feedback@deep-app.de?subject=[Feedback] zur App DEEP! Berufsorientierung&body=Hallo!%0D%0A%0D%0AIch bewerte die App mit ' +
        this.feedback[0] +
        ' Punkten.%0D%0A%0D%0ADas hat mir gut gefallen: %0D%0A' +
        this.feedback[1] +
        '%0D%0A%0D%0AIhr könntet folgendes besser machen:%0D%0A' +
        this.feedback[2] +
        '%0D%0A%0D%0AMit freundlichen Grüßen',
    );
  },
};

export default feedbackformView;
