import introView from '../../IntroView';
import interactionView from './InteractionView';
import resultsView from './ResultsView';
import barView from './BarView';
import app from '../../../../../../app';
import exerciseController from '../../../ExerciseController';
import generateideasController from '../GenerateideasController';
import $$ from 'dom7';
import taskController from '../../TaskController';

var checkQuestionsController = {
  task: null,

  firstIndex: null,
  allSlides: null,
  slides: null,
  blockName: null,

  progress: 0,

  results: null,
  leftRounds: null,

  init: function (task, withoutDisplay) {
    if (task) {
      this.task = task;
    }
    this.firstIndex = 0;
    this.progress = 0;
    this.results = null;

    // get all slides
    this.allSlides = [];
    for (let blockKey in this.task.slides) {
      if (this.task.slides.hasOwnProperty(blockKey)) {
        this.allSlides = this.allSlides.concat(this.task.slides[blockKey]);
      }
    }

    if (!withoutDisplay) {
      this.displayIntro();
    }
  },

  displayIntro: function () {
    introView.updateBackNavigation(() => generateideasController.init());
    //introView.addButton("mehr Info", () => checkQuestionsController.moreInfo());
    introView.addButton("Los geht's", () =>
      checkQuestionsController.displayTask(),
    );
    if (this.getFirstNotAnsweredQuestionsIndex() > 0) {
      introView.addButton('Ergebnisse', () => {
        checkQuestionsController.displayTask();
        checkQuestionsController.displayBar(false, true);
      });
    }
    introView.init(this, this.task);
  },

  displayTask: function (task, last) {
    if (task) {
      this.task = task;
    }

    if (last) {
      // find current slides to answer
      this.slides = [];
      this.firstIndex = this.getFirstNotAnsweredQuestionsIndex();
      for (let blockKey in this.task.slides) {
        if (this.task.slides.hasOwnProperty(blockKey)) {
          if (this.firstIndex > this.task.slides[blockKey].length) {
            this.firstIndex -= this.task.slides[blockKey].length;
          } else {
            this.slides = this.task.slides[blockKey];
            this.blockName = blockKey;
            break;
          }
        }
      }
      this.firstIndex = 0;

      this.updateProgress(this.slides.length - 1);
      interactionView.init(
        this,
        this.task,
        this.slides.map((slide) => slide.question),
        this.slides.length - 1,
      );
    } else {
      // find current slides to answer
      this.slides = [];
      this.firstIndex = this.getFirstNotAnsweredQuestionsIndex();
      for (let blockKey in this.task.slides) {
        if (this.task.slides.hasOwnProperty(blockKey)) {
          if (this.firstIndex >= this.task.slides[blockKey].length) {
            this.firstIndex -= this.task.slides[blockKey].length;
          } else {
            this.slides = this.task.slides[blockKey];
            this.blockName = blockKey;
            break;
          }
        }
      }

      this.updateProgress(this.firstIndex);
      if (this.firstIndex >= this.slides.length) {
        this.displayBar();
      } else {
        interactionView.init(
          this,
          this.task,
          this.slides.map((slide) => slide.question),
          this.firstIndex,
        );
      }
    }

    if (
      this.task.answers == null ||
      !this.task.answers[0] ||
      !Object.keys(this.task.answers[0]).length
    ) {
      interactionView.loadOverlay();
    }
  },

  displayBar: function (task, resultsOnly) {
    if (task) {
      this.task = task;
    }
    if (!this.results) {
      this.evaluateResults();
    }
    this.leftRounds = 1;
    for (let blockKey in this.task.slides) {
      if (this.task.slides.hasOwnProperty(blockKey)) {
        if (this.blockName === blockKey) {
          break;
        } else {
          this.leftRounds++;
        }
      }
    }
    this.leftRounds = Object.keys(this.task.slides).length - this.leftRounds;
    barView.init(
      this,
      this.task,
      this.results.bar,
      this.leftRounds,
      resultsOnly,
    );
  },

  displayResults: function (task, resultsOnly) {
    if (task) {
      this.task = task;
    }
    if (!this.results) {
      this.evaluateResults();
    }
    resultsView.init(this, this.task, this.results.questions, resultsOnly);
  },

  updateProgress: function (firstIndex) {
    this.progress = parseInt((firstIndex / this.slides.length) * 100);
    interactionView.setProgress(this.progress);
    if (this.progress >= 100) {
      this.finish();
      this.displayBar();
    }
  },

  getProgress: function () {
    return this.progress;
  },

  getFirstNotAnsweredQuestionsIndex: function () {
    let firstIndex = 0;
    if (
      this.task.answers &&
      this.task.answers[0] &&
      Object.keys(this.task.answers[0]).length > 0
    ) {
      // count answers
      for (let slide of this.allSlides) {
        if (this.task.answers[0][slide._id] == null) {
          break;
        }
        firstIndex++;
      }
    }
    return firstIndex;
  },

  saveAnswer(index, admit) {
    if (!this.task.answers || !this.task.answers[0]) {
      this.task.answers = [{}];
    }
    this.task.answers[0][this.slides[index]._id] = admit;
    this.task.save();
    this.updateProgress(parseInt(index + (admit != null ? 1 : 0)));
  },

  finish() {
    this.task.finish();
  },

  evaluateResults: function () {
    let slides = this.allSlides.slice(
      0,
      this.getFirstNotAnsweredQuestionsIndex(),
    );

    this.results = {
      questions: [],
      bar: {},
    };

    // init arrays
    let categoriesCount = [];
    let categoriesMax = [];
    for (let key in this.task.recommendations) {
      categoriesCount.push(0);
      categoriesMax.push(0);
    }
    // count max answers
    for (let slide of slides) {
      for (let category of slide.conclusion) {
        categoriesMax[category - 1]++;
      }
    }

    for (let answerKey in this.task.answers[0]) {
      if (
        this.task.answers[0].hasOwnProperty(answerKey) &&
        this.task.answers[0][answerKey]
      ) {
        let slide = this.getSlideById(answerKey);

        let categories = '';
        let numbers = slide.conclusion;
        let numberOfNumbers = numbers.length;
        for (
          let indexOfCategory = 0;
          indexOfCategory < numberOfNumbers;
          indexOfCategory++
        ) {
          if (indexOfCategory > 0 && indexOfCategory < numberOfNumbers - 1) {
            categories += ', ';
          } else if (
            indexOfCategory > 0 &&
            indexOfCategory === numberOfNumbers - 1
          ) {
            categories += ' oder ';
          }
          categories += this.task.recommendations[numbers[indexOfCategory] - 1];

          categoriesCount[numbers[indexOfCategory] - 1]++;
        }

        this.results.questions.push({
          title: slide.question,
          content:
            'Du hast dieser Aussage zugestimmt. Du könntest dich für ' +
            categories +
            ' interessieren.',
        });
      }
    }

    let bars = [];
    for (
      let indexOfCategory = 0;
      indexOfCategory < categoriesCount.length;
      indexOfCategory++
    ) {
      bars.push({
        key: this.task.recommendations[indexOfCategory],
        value:
          categoriesCount[indexOfCategory] / categoriesMax[indexOfCategory],
      });
    }
    bars.sort((first, second) => second.value - first.value);
    for (let pair of bars) {
      this.results.bar[pair.key] = pair.value;
    }
  },

  getSlideById: function (id) {
    for (let slide of this.allSlides) {
      if (slide._id === id) {
        return slide;
      }
    }
  },

  nextExercise: function () {
    exerciseController.next(true);
  },

  moreInfo: function () {
    //TODO: link to possible task
    app.views.main.router.back('/ex/6/1/1/0/', { force: true });
    setTimeout(() => {
      $$('.page-current .page-content')[0].scrollTop = 0;
    }, 0);
  },

  hasResults: function () {
    if (!this.task) {
      this.task = taskController.getTask(0, 1, 1, 1);
    }
    return (
      this.task.answers &&
      this.task.answers.length &&
      this.task.answers.length > 0
    );
  },

  getGraphic: function () {
    if (!this.task) {
      this.task = taskController.getTask(0, 1, 1, 1);
    }

    if (!this.results) {
      // get all slides
      this.allSlides = [];
      for (let blockKey in this.task.slides) {
        if (this.task.slides.hasOwnProperty(blockKey)) {
          this.allSlides = this.allSlides.concat(this.task.slides[blockKey]);
        }
      }
      this.evaluateResults();
    }

    // filter top5
    let top5 = {};
    let count = 5;
    for (let key in this.results.bar) {
      if (this.results.bar.hasOwnProperty(key)) {
        if (count < 1) {
          break;
        }
        count--;
        top5[key] = this.results.bar[key];
      }
    }

    return barView.getBars(top5, true);
  },

  restart: function () {
    this.init(null, true);
    this.displayTask();
  },

  isEnded: function () {
    return !this.leftRounds;
  },
};
export default checkQuestionsController;
