import view from './ExerciseView';
import userController from '../../../core/UserController';
import taskController from './task/TaskController';
import notificationController from '../../../core/NotificationController';
import app from '../../../app';

const exerciseController = {
  module: null,

  chapter: null,

  exercise: null,

  nextButton: null,

  init: function (moduleNumber, chapterNumber, exerciseNumber) {
    this.module = userController.getModule(moduleNumber);
    this.chapter = this.module.chapters[chapterNumber - 1];
    this.exercise = this.chapter.exercises[exerciseNumber - 1];
    this.chapter.exercises.length > exerciseNumber
      ? (this.nextButton = {
          number: exerciseNumber + 1,
          type: 'exercise',
          title: 'Nächstes Thema',
        })
      : this.module.chapters.length > chapterNumber
      ? (this.nextButton = {
          number: chapterNumber + 1,
          type: 'chapter',
          title: 'Nächster Block',
        })
      : userController.getUserData('modulesJson').length > moduleNumber
      ? (this.nextButton = {
          number: moduleNumber + 1,
          type: 'module',
          title: 'Nächster Schritt',
        })
      : (this.nextButton = {
          title: 'Schritte abschließen',
        });
  },

  load: function (moduleNumber, chapterNumber, exerciseNumber, subblockNumber) {
    this.init(moduleNumber, chapterNumber, exerciseNumber);
    view.init(this.module, this.chapter, this.exercise, this.nextButton);
    this.exercise.subblocks.length === 1 ? (subblockNumber = 1) : null;
    if (subblockNumber !== 0) {
      this.openSubblock(subblockNumber);
    }
  },
  /**
   * @param  noReload -> set true, if you activate this function from a non-exercise page
   */
  next: function (noReload) {
    //Set currently opened subblock to finished
    view.forOpenAccordions((accordion) => {
      this.exercise.setFinishSubblock(accordion, true);
    });
    // decide route and save Bookmark
    let route;
    if (
      this.exercise.module_number !== 10 ||
      this.exercise.chapter_number !== 1 ||
      this.exercise.number !== 4
    ) {
      switch (this.nextButton.type) {
        case 'exercise':
          route =
            '/ex/' +
            this.module.number +
            '/' +
            this.chapter.number +
            '/' +
            this.nextButton.number +
            '/0/';

          this.saveSubblock(
            this.module.number,
            this.chapter.number,
            this.nextButton.number,
            1,
          );
          break;
        case 'chapter':
          route =
            '/ex/' +
            this.module.number +
            '/' +
            this.nextButton.number +
            '/1/0/';
          this.saveSubblock(this.module.number, this.nextButton.number, 1, 1);
          break;
        case 'module':
          route = '/ex/' + this.nextButton.number + '/1/1/0/';
          this.saveSubblock(this.nextButton.number, 1, 1, 1);
          break;
      }
    } else {
      route = '/';
    }
    //Route to next Exercise

    app.views.main.router.navigate(route, {
      reloadCurrent: !noReload,
    });
  },

  /* is called by view when subblock opens */
  saveSubblock: function (module, chapter, exercise, subblock) {
    if (!module) {
      module = this.module.number;
    }
    if (!chapter) {
      module = this.chapter.number;
    }
    if (!exercise) {
      module = this.exercise.number;
    }
    localStorage.setItem(
      'currentSubblock',
      JSON.stringify({
        module: module,
        chapter: chapter,
        exercise: exercise,
        subblock: subblock,
      }),
    );

    notificationController.initLocalNotifications();
  },

  /* is called by view to initialize tasks */
  getInlineTask: function (taskNumber) {
    return taskController.getTaskInputElements(
      this.exercise.tasks[taskNumber - 1],
    );
  },

  /* is called by view to separate all inline tasks from exercises content */
  getInlineTasksFromContent(content) {
    return content.find('*[id^="task"]');
  },

  openSubblock: function (subblockNumber) {
    view.openSubblock(subblockNumber);
  },

  updateTime: function (moduleNumber, chapterNumber, exerciseNumber) {
    let module = userController.getModule(moduleNumber);
    let chapter = module.chapters[chapterNumber - 1];
    let exercise = chapter.exercises[exerciseNumber - 1];
    exercise.updateTime(performance.now() - this.entryTime);
  },
};
export default exerciseController;
