import $$ from 'dom7';
import app from '../app.js';
import dictionary from './Dictionary';
import touchController from './TouchController';
import weeklyNotifications from '../download/notifications/weeklyNotifications';

const WEEKS_OF_NOTIFICATIONS = weeklyNotifications.notifications.length;

function CiNotification(content) {
  let _this = this;
  this._hideListener = new Set();

  this.element = $$(document.createElement('div'))
    .addClass('ci-notification')
    // close X
    .append(
      $$(document.createElement('div'))
        .addClass('ci-notification-close-icon')
        .append('x')
        .on('click', () => {
          this._hide('x');
        }),
    )
    // head
    .append(
      `
                    <div class="ci-notification-head">
                        <i class="icon png-icon-deep"></i>
                        <span class="ci-notification-title">${dictionary.getText(
                          'misctexts',
                          'notification-title',
                        )}</span>
                    </div>
        `,
    )
    // content
    .append(content);
  $$('#app').prepend(this.element);
  this.element.attr(
    'style',
    'top: -' + parseInt(this.element.outerHeight(false) + 20) + 'px',
  );
  setTimeout(() => {
    touchController.makeDraggable(this.element, {
      maxTop: this.element.outerHeight(false) - 20,
      maxRight: 0,
      maxBottom: 0,
      maxLeft: 0,
      absoluteOrigin: true,
      noBorderTop: true,
    });
  }, 0);
}

let showCiNotification = function (dom) {
  dom.addClass('showing');
  dom.attr('style', 'top: 17px;');
  setTimeout(() => {
    dom.addClass('wake');
  }, 300);
};

let hideCiNotification = function (dom) {
  dom.removeClass('showing wake');
  setTimeout(() => {
    dom.attr('style', 'top: -' + parseInt(dom.outerHeight(false) + 30) + 'px');
  }, 0);
};

let isCiNotificationShowing = function (dom) {
  return dom.hasClass('showing');
};

CiNotification.prototype.show = function () {
  showCiNotification(this.element);
};

CiNotification.prototype.hide = function () {
  this._hide('external');
};

CiNotification.prototype._hide = function (reason) {
  this._hideListener.forEach((listener) => listener(reason));
  hideCiNotification(this.element);
};

CiNotification.prototype.isShowing = function () {
  isCiNotificationShowing(this.element);
};

CiNotification.prototype.addHideListener = function (listener) {
  this._hideListener.add(listener);
};

CiNotification.prototype.removeHideListener = function (listener) {
  this._hideListener.delete(listener);
};

CiNotification.prototype.removeAllHideListeners = function () {
  this._hideListener.clear();
};

var notificationController = {
  notifications: [],
  initNotifications: function () {
    this.loadNotifications();
    let _this = this;
    setInterval(function () {
      let date = new Date();
      date.setSeconds(0);
      date.setMilliseconds(0);
      _this.notifications.forEach(function (notification, index) {
        if (date > notification.date) {
          _this.fireNotification(notification.notificationContent);
          _this.notifications.splice(index, 1);
          localStorage.setItem(
            'notificationsstorage',
            JSON.stringify(_this.notifications),
          );
        }
      });
    }, 60 * 10);
  },
  loadNotifications: function () {
    let toLoad = JSON.parse(localStorage.getItem('notificationsstorage'));
    for (let notification in toLoad) {
      if (toLoad[notification].date != undefined) {
        toLoad[notification].date = new Date(toLoad[notification].date);
      }
    }
    this.notifications = toLoad ? toLoad : [];
  },
  fireNotification: function (notificationContent) {
    let toFire = app.notification.create(notificationContent);
    toFire.open();
  },

  setTimedNotification: function (date, notificationContent) {
    this.notifications.push({
      date: date,
      notificationContent: notificationContent,
    });
    localStorage.setItem(
      'notificationsstorage',
      JSON.stringify(this.notifications),
    );
  },

  /**
   *
   * @param {any} app
   */
  initLocalNotifications: function (app) {
    const _this = this;
    document.addEventListener(
      'deviceready',
      function () {
        window.cordova.plugins.notification.local.hasPermission((granted) => {
          console.warn('Testing permission');

          if (granted == false) {
            console.warn('No permission');

            // If app doesnt have permission request it
            window.cordova.plugins.notification.local.requestPermission(
              (granted) => {
                console.warn('Ask for permission');
                if (granted == true) {
                  console.warn('Permission accepted');

                  // If app is given permission try again
                  _this.initConditionalLocalNotifications();
                } else {
                  alert('We need permission to show you notifications');
                }
              },
            );
          } else {
            console.warn('sending notification');

            _this.initConditionalLocalNotifications(app);
          }
        });
      },
      false,
    );
  },

  initConditionalLocalNotifications: function (app) {
    const currentSubblock = JSON.parse(localStorage.getItem('currentSubblock'));

    if (
      currentSubblock.module === 1 &&
      currentSubblock.chapter === 1 &&
      currentSubblock.exercise === 1 &&
      currentSubblock.subblock === 1
    ) {
      window.cordova.plugins.notification.local.schedule({
        id: 1000,
        title: 'Wo stehst du gerade?',
        text: 'Fange doch bei Schritt 1 an und finde heraus, wie weit du schon mit deiner Berufs- und Studienwahl bist und wie die DEEP-App dir aktuell helfen kann.',
        trigger: { in: 1, unit: 'day' },
        icon: 'res://ic_launcher',
        smallIcon: 'res://ic_launcher',
      });
    }

    window.cordova.plugins.notification.local.schedule({
      id: 1001,
      title: 'Deine Karriere wartet auf dich',
      text:
        'Mach doch weiter bei Schritt ' +
        currentSubblock.module +
        ' und gehe voran auf deinem Weg zur richtigen Berufs- und Studienentscheidung',
      trigger: { in: 3, unit: 'day' },
      icon: 'res://ic_launcher',
      smallIcon: 'res://ic_launcher',
    });

    window.cordova.plugins.notification.local.schedule({
      id: 1002,
      title: 'Jede Woche wartet eine neue Herausforderung auf dich',
      text: 'Deine berufliche Zukunft ist eine wichtige Entscheidung. Nimm dir die Zeit für Information und Reflexion.',
      trigger: { in: 7, unit: 'day' },
      icon: 'res://ic_launcher',
      smallIcon: 'res://ic_launcher',
    });

    window.cordova.plugins.notification.local.schedule({
      id: 1003,
      title: 'Jede Woche wartet eine neue Herausforderung auf dich',
      text: 'Deine berufliche Zukunft ist eine wichtige Entscheidung. Nimm dir die Zeit für Information und Reflexion.',
      trigger: { in: 14, unit: 'day' },
      icon: 'res://ic_launcher',
      smallIcon: 'res://ic_launcher',
    });

    window.cordova.plugins.notification.local.schedule({
      id: 1004,
      title: 'Du willst gerade nicht?',
      text: 'Nagut,  wir wollen dich nur ein bisschen motivieren weiterzumachen. Bist du schon alle zehn Schritte in der DEEP-App gegangen? Besuche uns doch einmal wieder und nimm dir Zeit für deine Zukunft.',
      trigger: { in: 21, unit: 'day' },
      icon: 'res://ic_launcher',
      smallIcon: 'res://ic_launcher',
    });

    window.cordova.plugins.notification.local.schedule({
      id: 1005,
      title: 'Du willst gerade nicht?',
      text: 'Nagut,  wir wollen dich nur ein bisschen motivieren weiterzumachen. Bist du schon alle zehn Schritte in der DEEP-App gegangen? Besuche uns doch einmal wieder und nimm dir Zeit für deine Zukunft.',
      trigger: { in: 21, unit: 'day' },
      icon: 'res://ic_launcher',
      smallIcon: 'res://ic_launcher',
    });

    window.cordova.plugins.notification.local.schedule({
      id: 1006,
      title:
        'Du bist schon bei Schritt ' +
        currentSubblock.module +
        ', Glückwunsch!',
      text: 'Hast du deine Entscheidung schon getroffen? Wirklich alles bedacht und alle Ideen geprüft? Mache weiter in der DEEP!-App und generiere und prüfe deine Ideen.',
      trigger: { in: 60, unit: 'day' },
      icon: 'res://ic_launcher',
      smallIcon: 'res://ic_launcher',
    });
  },

  /** Called on startup to re-initialize all weekly notations with random sentences from weeklyNotifications.json
   * Since Repeating notifications cannot handle dynamic content we have to schedule different notification and cannot use one repeating notification.
   * */
  initWeeklyNotifications: function () {
    let weeklyNotifications =
      require('../download/notifications/weeklyNotifications').notifications.map(
        (e) => e,
      );
    for (let index = 0; index < WEEKS_OF_NOTIFICATIONS; index++) {
      let randomNotificationIndex = Math.floor(
        Math.random() * weeklyNotifications.length,
      );
      cordova.plugins.notification.local.schedule({
        id: index,
        title: 'Continue your Berufsorientierung',
        text: weeklyNotifications[randomNotificationIndex],
        trigger: { in: index + 1, unit: 'day' },
        icon: 'res://ic_launcher',
        smallIcon: 'res://ic_launcher',
      });
      weeklyNotifications.splice(randomNotificationIndex, 1);
    }
  },

  createCiNotification: function (content) {
    return new CiNotification(content);
  },

  showCiNotification: function (notification) {
    if (notification instanceof CiNotification) {
      notification.show();
    } else {
      showCiNotification(notification);
    }
  },

  hideCiNotification: function (notification) {
    if (notification instanceof CiNotification) {
      notification.hide();
    } else {
      hideCiNotification(notification);
    }
  },

  isCiNotificationShowing: function (notification) {
    if (notification instanceof CiNotification) {
      return notification.isShowing();
    } else {
      return isCiNotificationShowing(notification);
    }
  },
};

export default notificationController;
