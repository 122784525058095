import onboardingView from './OnboardingView.js';
import $$ from 'dom7';

const onboardingController = {
  init: function () {
    this.initView();
    this.initFinishedListener();
  },
  initView: function () {
    onboardingView.init();
  },
  initFinishedListener: function () {
    $$('a').on('click', (e) => {
      localStorage.setItem('skip-onboarding', 'true');
    });
  },
};

export default onboardingController;
