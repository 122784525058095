import $$ from 'dom7';
import taskView from '../../TaskView';

let controller;

var reflectionView = {
  init: function (cntrllr, title, mode) {
    controller = cntrllr;
    this._loadContent();
    this._initBar();
    this._initButton();
    this.updateBar(mode);
  },

  // instead of own html
  _loadContent: function () {
    //card
    $$('#task-content').empty().append(`
                <div id="reflection-card" class="white-card">
                    <div id="reflection-bar">
                        <span id="reflection-audio">Audio</span>
                        <span id="reflection-questions">Reflexions&shyfragen</span>
                        <span id="reflection-text">Text</span>
                    </div>
                    <div id="reflection-content"></div>
                </div>
            `);

    //button
    $$('#task-bottom > .button-list').empty().append(`
                <button id="reflection-button" class="button button-fill color-white"></button>
            `);
  },

  _initBar: function () {
    let _this = this;
    $$('#reflection-audio').on('click', () => {
      if (_this.updateBar('audio')) {
        controller.showAudio();
      }
    });
    $$('#reflection-text').on('click', () => {
      if (_this.updateBar('text')) {
        controller.showText();
      }
    });
    $$('#reflection-questions').on('click', () => {
      if (_this.updateBar('questions')) {
        controller.showQuestions();
      }
    });
  },

  updateBar: function (target) {
    let element = $$('#reflection-' + target);
    if (!element.hasClass('selected')) {
      $$('#reflection-bar')
        .children()
        .each((index, child) => {
          $$(child).removeClass('selected');
        });
      element.addClass('selected');
      return true;
    }
    return false;
  },

  _initButton: function () {
    $$('#reflection-button').on('click', () => {
      controller.next();
    });
  },

  setButton: function (text) {
    $$('#reflection-button').empty().append(text);
  },

  setContent: function (content) {
    $$('#reflection-content').empty().append(content);
  },

  scrollUp: function () {
    taskView.scrollUp();
  },
};
export default reflectionView;
