import view from './ReflectionView';
import app from '../../../../../../app';
import mediaController from '../../../../../../core/MediaController';
import introView from '../../IntroView';
import userController from '../../../../../../core/UserController';
import taskView from '../../TaskView';

var reflectionController = {
  exercise: null,
  task: null,

  _allChapters: null,
  _current: null,
  _mode: null,

  audioPlayer: null,

  init: function (task) {
    //init variables
    this.task = task;
    this._allChapters = this.task.chapters;

    // default preferences
    this._current = 0;
    this._mode = 'audio';
    // load last preferences
    if (this.task.answers) {
      if (this.task.answers[0] != null) {
        this._mode = this.task.answers[0];
      }
    }

    // view
    let initTask = () => {
      taskView.init(this.task);
      view.init(reflectionController, this.task.name, this._mode);
      this._loadChapter();
    };
    let introTask = userController.getTask(0, 2, 1, 1);
    if (introTask.intro && !(introTask.answers && introTask.answers[1])) {
      introView.setButtons(introView.BUTTONSET_START);
      introView.init(
        {
          displayTask: () => {
            reflectionController._save(introTask, 1, true);
            initTask();
          },
        },
        introTask,
      );
    } else {
      initTask();
    }
  },

  showAudio: function () {
    this.setMode('audio');
  },

  showText: function () {
    this.setMode('text');
  },

  showQuestions: function () {
    this.setMode('questions');
  },

  next: function () {
    if (this._isLastChapter()) {
      this.finish();
      // navigate to profile
      app.views.main.router.navigate('/profile/');
      app.views.main.router.navigate('/', { reloadPrevious: true });
      app.views.main.router.history = ['/', '/profile/'];
    } else {
      this.setCurrent(this._current + 1);
    }
  },

  setMode: function (mode) {
    this._mode = mode;
    this._save(this.task, 0, this.mode);
    this._loadChapter();
  },

  _save: function (task, index, value) {
    if (!task.answers) {
      task.answers = [];
    }
    task.answers[index] = value;
    task.save();
  },

  setCurrent: function (current) {
    if (this.audioPlayer) {
      this.audioPlayer.setExitMode(1);
    }

    this._current = current;

    // save
    if (!this.task.answers) {
      this.task.answers = [];
    }
    this.task.save();

    view.scrollUp();
    this._loadChapter();
  },

  _loadChapter: function () {
    //content
    let content = this._allChapters[this._current];
    if (this._mode === 'audio') {
      this.audioPlayer = mediaController.getAudioPlayer(
        content.audio,
        this.task.name,
        content.subtitle,
      );
      this.audioPlayer.setExitMode(0);
      view.setContent(this.audioPlayer.getHTML());
    } else if (this._mode === 'questions') {
      view.setContent(content.questions);
    } else {
      view.setContent(content.text);
    }

    //button
    if (this._isLastChapter()) {
      view.setButton('fertig');
    } else {
      view.setButton('weiter');
    }
  },

  _isLastChapter: function () {
    return this._current >= this._allChapters.length - 1;
  },

  finish: function () {
    this.task.finish();
  },
};
export default reflectionController;
