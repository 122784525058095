import Notebook from '../../../model/notebook/Notebook.js';
import view from './KeydateView.js';
import Keydate from '../../../model/notebook/Keydate';
import app from '../../../app.js';

var keydateController = {
  keydate: null,

  created: null,

  load: function (number, step) {
    if (number === 0 || number === '0') {
      this.keydate = new Keydate(-1);
      if (!isNaN(step) && step > 0) {
        this.keydate.step = step;
      }
      this.created = false;
      view.init(this, this.keydate, true);
      this.initCalendars();
    } else {
      this.keydate = Notebook.getKeydate(number);
      this.created = true;
      view.init(this, this.keydate);
      this.initCalendars();
    }
  },
  initCalendars: function () {
    let startCal = app.calendar.create({
      inputEl: '#keydate-input-start',
      timePicker: true,
      dateFormat: {
        month: 'numeric',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      },
    });
    let endCal = app.calendar.create({
      inputEl: '#keydate-input-end',
      timePicker: true,
      dateFormat: {
        month: 'numeric',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      },
    });
  },

  delete: function () {
    Notebook.deleteKeydate(this.keydate);
  },

  save: function (values) {
    if (!this.created) {
      let step = this.keydate.step;
      this.keydate = Notebook.createKeydate();
      if (step > 0) {
        this.keydate.step = step;
      }
      view.keydate = this.keydate;
      this.created = true;
    }
    for (let key in values) {
      this.keydate[key] = values[key];
    }
    if (!this.keydate.start && this.keydate.end) {
      this.keydate.start = this.keydate.end;
    }
    this.keydate.save();
  },

  export: function () {
    //TODO: implement
  },

  showDisplayMode: function () {
    view.enableDisplayMode();
  },

  showEditMode: function () {
    view.enableEditMode();
  },
};

export default keydateController;
