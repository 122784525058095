import $$ from 'dom7';

let controller;

var selectionView = {
  rateButton: null,

  init: function (cntrllr, intro, options, selected) {
    controller = cntrllr;
    this.loadTop();
    this.loadText(intro);
    this.loadContent(options, selected);
    this.loadButtons();
    this.loadBackNavigation();
  },

  loadTop: function () {
    $$('#task-page-content .page-content-top').empty().append(`
                <h1 id="task-title" class="page-title">Optionen-Ranking</h1>
                <h2 id="task-subtitle" class="page-subtitle"></h2>
            `);
  },

  loadText: function (intro) {
    $$('#task-page-content')[0].scrollTop = 0;
    let content = $$('#task-content');
    content.empty();
    content.append(intro);
  },

  loadContent: function (options, selected) {
    selectionView.options = [];
    let content = $$('#task-content');
    options.forEach((option) => {
      let card = $$(document.createElement('div'))
        .addClass('one-line-card optionranking-one-line-card')
        .append(option.getName())
        .on('click', () => {
          selectionView.clickOption(card, option.id);
        });
      if (selected.includes(option.id)) {
        card.addClass('selected');
      }
      content.append(card);
    });
  },

  loadButtons: function () {
    this.rateButton = $$(document.createElement('button'));
    this.rateButton.addClass('button button-fill color-white');
    this.rateButton.append('Optionen vergleichen');
    this.rateButton.on('click', () => {
      controller.next();
    });

    const buttonsList = $$('#task-bottom .button-list');
    buttonsList.empty();
    buttonsList.append(this.rateButton);

    this.updateRateButtonVisibility();
  },

  loadBackNavigation: function () {
    const back = $$('.page[data-name="task"] .navbar .left a.link')
      .off('click')
      .removeClass('back');
    back.on('click', () => {
      back.off('click').addClass('back');
      controller.previous();
    });
  },

  clickOption: function (card, id) {
    if (this.isSelectedOption(card)) {
      this.unselectOption(card);
      controller.unSelectOption(id);
    } else {
      if (controller.selectOption(id)) {
        this.selectOption(card);
      }
    }
    this.updateRateButtonVisibility();
  },

  isSelectedOption: function (card) {
    return card.hasClass('selected');
  },

  selectOption: function (card) {
    card.addClass('selected');
  },

  unselectOption: function (card) {
    card.removeClass('selected');
  },

  updateRateButtonVisibility: function () {
    if (controller.isOptionSelectionValid()) {
      this.showRateButton();
    } else {
      this.hideRateButton();
    }
  },

  showRateButton: function () {
    this.rateButton.removeClass('disabled');
  },

  hideRateButton: function () {
    this.rateButton.addClass('disabled');
  },
};
export default selectionView;
