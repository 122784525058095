import $$ from 'dom7';
import app from '../../../app.js';
import inputController from '../../steps/exercise/task/input/InputController';

let controller;

const ideaView = {
  idea: null,

  mode: null,
  kind: null,
  listValues: {
    options: null,
    pro: null,
    contra: null,
  },

  displayUnused: true,

  init: function (cntrllr, idea, newIdea) {
    controller = cntrllr;
    this.mode = null;
    this.idea = idea;
    this.loadMainToolbar();
    this.initTabbarListener();
    if (newIdea) {
      this.enableCreateMode();
    } else {
      this.enableDisplayMode();
    }
  },

  loadMainToolbar: function () {
    app.loadMainToolbar($$('.page[data-name="idea"] .main-toolbar'));
  },

  initTabbarListener: function () {
    let _this = this;
    $$('#idea-page-content .toolbar-inner a').each((index, tab) =>
      $$(tab).on('click', () => {
        _this.kind = $$(tab).attr('href').split('#idea-tab-')[1];
        _this.loadContent();
      }),
    );
  },

  enableDisplayMode: function () {
    if (this.mode === 'display') {
      return;
    }
    this.kind = this.idea.isStudies ? 'studies' : 'job';
    this.loadNormalNavbar();
    this.loadTitle();
    this.loadContent();
    $$('#idea-page-content .list').addClass('disabled-list');
    $$('#idea-page-content input, #idea-page-content .page-content-main i').on(
      'click',
      () => controller.showEditMode(),
    );
    this.hideUnused();
    this.mode = 'display';
  },

  enableEditMode: function () {
    if (this.mode === 'edit') {
      return;
    }
    this.kind = this.idea.isStudies ? 'studies' : 'job';
    this.loadFormNavbar(
      'Idee bearbeiten',
      () => {
        controller.save(ideaView.getValues());
        controller.showDisplayMode();
        // app.views.main.router.back();
      },
      () => {
        controller.showDisplayMode();
        // app.views.main.router.back();
      },
    );
    this.loadTitle('edit');
    this.unhideAll();
    this.loadContent();
    $$('#idea-page-content .list').removeClass('disabled-list');
    this.mode = 'edit';
  },

  enableCreateMode: function () {
    if (this.mode === 'create') {
      return;
    }
    this.kind = 'job';
    this.loadFormNavbar(
      'Idee erstellen',
      () => {
        controller.save(ideaView.getValues());
        controller.showDisplayMode();
      },
      () => {
        app.views.main.router.back();
      },
    );
    this.loadTitle('create');
    this.unhideAll();
    this.loadContent();
    $$('#idea-page-content .list').removeClass('disabled-list');
    this.mode = 'create';
  },

  loadNormalNavbar: function () {
    const navbar = $$('.page[data-name="idea"] .navbar');
    navbar.removeClass('form-navbar');
    navbar.empty();
    navbar.addClass('navbar-transparent no-hairline');
    navbar.append(`
            <div class="navbar-inner">
                <div class="left">
                    <a class="link back">
                        <i class="icon material-icons">navigate_before</i>
                    </a>
                </div>
                <div class="right">
                    <a class="link popover-open" href="#" data-popover="#idea-popover-menu">
                        <i class="icon material-icons">more_vert</i>
                    </a>
                </div>
            </div>
        `);
    $$('#idea-popover-delete').on('click', () => {
      app.popover.close('#idea-popover-menu');
      app.dialog.confirm('Idee wirklich löschen?', function () {
        controller.delete();
        app.views.main.router.back({
          reloadCurrent: true,
        });
      });
    });
    $$('#idea-popover-export').on('click', () => {
      app.popover.close('#idea-popover-menu');
      controller.export();
    });
    $$('#idea-popover-edit').on('click', () => {
      app.popover.close('#idea-popover-menu');
      controller.showEditMode();
    });
  },

  loadFormNavbar: function (title, onReady, onCancel) {
    const navbar = $$('.page[data-name="idea"] .navbar');
    navbar.removeClass('navbar no-shadow navbar-transparent no-hairline');
    navbar.empty();
    app.loadFormNavbar(navbar, title, onReady, onCancel);
  },

  loadTitle: function (title) {
    if (title === 'create' || title === 'edit') {
      $$('#idea-title').addClass('hide');
      $$('#idea-tabbar').removeClass('hide');
    } else {
      $$('#idea-tabbar').addClass('hide');
      $$('#idea-title').removeClass('hide');
    }
  },

  loadContent: function () {
    if (this.kind === 'job') {
      // job
      $$('#idea-name').attr(
        'placeholder',
        'Tippen, um Ausbildungsidee festzulegen',
      );
      $$('#idea-search').attr('href', 'https://berufe.tv');
    } else if (this.kind === 'studies') {
      // studies
      $$('#idea-name').attr(
        'placeholder',
        'Tippen, um Studiumsidee festzulegen',
      );
      $$('#idea-search').attr('href', 'https://hochschulkompass.de');
    }
    this.loadIdea();
  },

  loadIdea: function () {
    let _this = this;

    // tabbar
    if (this.kind === 'job') {
      app.tab.show('#idea-tab-job', false);
    } else if (this.kind === 'studies') {
      app.tab.show('#idea-tab-studies', false);
    }
    // name
    $$('#idea-name, #studies-name').val(this.idea.name);
    // notes
    $$('#idea-notes, #studies-notes').val(this.idea.notes);
    // options
    $$('#idea-list-options')
      .empty()
      .append(
        inputController.getListInput(
          'Mögliche Optionen',
          1,
          true,
          'Tippen, um ' +
            (_this.kind === 'job' ? 'Unternehmen' : 'Hochschule') +
            ' einzugeben',
          _this.idea.options,
          _this._saveListInput,
          ['options'],
          'idea-options',
        ),
      );
    // pro
    $$('#idea-list-pro')
      .empty()
      .append(
        inputController.getListInput(
          'Gründe dafür',
          1,
          true,
          'Tippen, um Gründe dafür einzugeben',
          _this.idea.pro,
          _this._saveListInput,
          ['pro'],
          'idea-pro',
        ),
      );
    // contra
    $$('#idea-list-contra')
      .empty()
      .append(
        inputController.getListInput(
          'Gründe dagegen',
          1,
          true,
          'Tippen, um Gründe dagegen einzugeben',
          _this.idea.contra,
          _this._saveListInput,
          ['contra'],
          'idea-contra',
        ),
      );

    // reset values of list inputs
    for (let key in this.listValues) {
      if (this.listValues.hasOwnProperty(key)) {
        this.listValues[key] = null;
      }
    }
  },

  getValues: function () {
    let values = {};

    // tabbar
    values.isStudies = this.kind === 'studies';
    // normal inputs
    $$('.idea-input').each((index, input) => {
      input = $$(input);
      let key = input.attr('id').split('-')[1];
      values[key] = input.val();
    });
    // list inputs
    for (let key in this.listValues) {
      if (this.listValues.hasOwnProperty(key) && this.listValues[key]) {
        values[key] = this.listValues[key];
      }
    }

    return values;
  },

  hideUnused: function () {
    if (this.displayUnused) {
      return;
    }
    $$('#idea-page-content .list').each((index, list) => {
      list = $$(list);
      let empty = true;
      list.find('li').each((index, li) => {
        li = $$(li);
        let input = li.find('input');
        if (input) {
          if (input.val()) {
            empty = false;
          } else {
            li.addClass('hide');
          }
        }
      });
      if (empty) {
        list.addClass('hide');
      }
    });
  },

  unhideAll: function () {
    $$('#idea-page-content .list.hide, #idea-page-content li.hide').removeClass(
      'hide',
    );
  },

  _saveListInput: function (handlerparams, index) {
    return function (answer) {
      if (!ideaView.listValues[handlerparams[0]]) {
        ideaView.listValues[handlerparams[0]] = {};
      }
      ideaView.listValues[handlerparams[0]][index] = answer;
    };
  },
};
export default ideaView;
