import $$ from 'dom7';
import AudioPlayer from './ui/AudioPlayer';
import globalElementsController from './ui/GlobalElementsController';

const mediaController = {
  MEDIA_PATH: './static/media/',

  AUDIO_SUFFIX: '.m4a',

  currentPlayer: null,

  getChapterBackgroundIllustraion: function (illustraion) {
    return this.MEDIA_PATH + 'chapter_background_illustrations/' + illustraion;
  },

  _getAudio: function (path, suffix) {
    // separate suffix
    let pathArray = path.split('/');
    if (pathArray[pathArray.length - 1].includes('.')) {
      let filenameArray = path.split('.');
      path = filenameArray.slice(0, -1).join('.');
      suffix = '.' + filenameArray[filenameArray.length - 1];
    }
    return (
      this.MEDIA_PATH + path + (suffix != null ? suffix : this.AUDIO_SUFFIX)
    );
  },

  _setCurrentPlayer: function (id) {
    if (this.currentPlayer && this.currentPlayer !== id) {
      globalElementsController.getElement(this.currentPlayer).pause().hide();
    }
    this.currentPlayer = id;
  },

  getAudioPlayer: function (path, title, subtitle) {
    let _this = this;
    if (path) {
      let audioPath = this._getAudio(path);
      let id = 'audioplayer-' + audioPath;
      if (!globalElementsController.hasElement(id)) {
        globalElementsController.putElement(
          id,
          new AudioPlayer(audioPath, title, subtitle),
        );
      }

      return globalElementsController
        .getElement(id)
        .onPlayStateChanges((state) =>
          state === 'playing' ? _this._setCurrentPlayer(id) : null,
        );
    } else {
      return globalElementsController.getElement(this.currentPlayer);
    }
  },

  getStepsAudioPlayer: function (title, subtitle) {
    return this.getAudioPlayer('steps_audio/' + title, title, subtitle);
  },

  getQRCodeFromLink: function (link, color, backgroundColor) {
    return $$(document.createElement('img'))
      .addClass('qr-code')
      .attr(
        'src',
        'https://api.qrserver.com/v1/create-qr-code/?data=' +
          link +
          '&size=512x512&format=svg&ecc=H&margin=0&color=' +
          (color ? color : '000000') +
          '&bgcolor=' +
          (backgroundColor ? backgroundColor : 'ffffff'),
      )
      .attr('title', 'QR-Code')
      .attr('alt', link);
  },

  copyTextToClipboard: function (text) {
    function fallbackCopyTextToClipboard(text) {
      var input = document.createElement('input');
      input.type = 'text';
      input.value = text;

      /* Avoid scrolling to bottom */
      input.style.top = '0';
      input.style.left = '0';
      input.style.position = 'fixed';

      /* Select the text field */
      document.body.appendChild(input);
      input.focus();
      input.select();
      input.setSelectionRange(0, 99999); /* For mobile devices */

      try {
        document.execCommand('copy');
      } catch (err) {
        console.error('copying to clipboard failed', err);
      }

      document.body.removeChild(input);
    }

    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text);
      return;
    }
    navigator.clipboard.writeText(text).then(
      function () {
        // do nothing
      },
      function () {
        fallbackCopyTextToClipboard(text);
      },
    );
  },
};
export default mediaController;
