import app from '../../../../../../app';
import $$ from 'dom7';
import introView from '../../IntroView';
import generateideasController from '../GenerateideasController';
import trainingOrStudiesView from './TrainingOrStudiesView';
import profilePreviewView from './ProfilePreviewView';
import ratingView from './RatingView';
import resultsView from './ResultsView';
import profileController from '../../../../../profile/ProfileController';
import dictionary from '../../../../../../core/Dictionary';
import taskController from '../../TaskController';

var jobratingController = {
  task: null,

  mode: {
    studies: null,

    training: null,

    jobs: null,
  },

  current: {
    profile: null,
    field: null,
    round: 0,
  },

  fields: null,
  profiles: null,

  firstField: 0,
  rounds: 4,

  init: function (task) {
    if (task) {
      this.task = task;
    }
    this.intro.display();
  },

  intro: {
    display: function () {
      introView.init(this, jobratingController.task);
    },

    next: function () {
      jobratingController.trainingOrStudies.display();
    },

    previous: function () {
      generateideasController.init();
    },

    moreInfo: function () {
      app.views.main.router.navigate('/mod/6/', { animate: false });
      app.views.main.router.navigate('/', {
        reloadPrevious: true,
        animate: false,
      });
      app.views.main.router.history = ['/', '/mod/6/'];
    },
  },

  trainingOrStudies: {
    display: function () {
      // total lists of jobs
      let joblists = {};
      let studyTitle = jobratingController.task.trainingOrStudies.studiesTitle;
      joblists[studyTitle] = {};
      for (let study of jobratingController.task.fields.studies) {
        joblists[studyTitle][study.name] = [];
        for (let job of study.jobs) {
          joblists[studyTitle][study.name].push(job);
        }
      }
      let trainingTitle =
        jobratingController.task.trainingOrStudies.trainingTitle;
      joblists[trainingTitle] = {};
      for (let training of jobratingController.task.fields.training) {
        joblists[trainingTitle][training.name] = [];
        for (let job of training.jobs) {
          joblists[trainingTitle][training.name].push(job);
        }
      }

      trainingOrStudiesView.init(
        this,
        jobratingController.task.trainingOrStudies.intro,
        joblists,
      );
    },

    next: function () {
      jobratingController.jobsOrField.display();
    },

    previous: function () {
      jobratingController.intro.display();
    },

    training: function () {
      jobratingController.mode.studies = false;
      jobratingController.mode.training = true;
      jobratingController.fields = null;
      this.next();
    },

    studies: function () {
      jobratingController.mode.studies = true;
      jobratingController.mode.training = false;
      jobratingController.fields = null;
      this.next();
    },

    both: function () {
      jobratingController.mode.studies = true;
      jobratingController.mode.training = true;
      jobratingController.fields = null;
      this.next();
    },
  },

  jobsOrField: {
    display: function () {
      introView.addButton('Berufsfelder', () =>
        jobratingController.jobsOrField.field(),
      );
      introView.addButton('Berufe', () =>
        jobratingController.jobsOrField.jobs(),
      );
      introView.init(this, {
        name: 'Berufe-Rating',
        intro: jobratingController.task.jobsOrField,
      });
    },

    next: function () {
      if (jobratingController.rating.initRating()) {
        jobratingController.results.display();
      } else {
        jobratingController.profilePreview.display();
      }
    },

    previous: function () {
      jobratingController.trainingOrStudies.display();
    },

    jobs: function () {
      jobratingController.mode.jobs = true;
      this.next();
    },

    field: function () {
      jobratingController.mode.jobs = false;
      this.next();
    },
  },

  profilePreview: {
    fromRating: null,

    display: function (fromRating) {
      this.fromRating = fromRating;

      let profileElement = profileController.getProfileElement(
        jobratingController.profiles[jobratingController.current.profile],
      );

      profilePreviewView.init(
        this,
        jobratingController.task.profilePreview,
        profileController.getName(
          jobratingController.profiles[jobratingController.current.profile],
        ),
        profileElement,
        this.fromRating,
      );
    },

    next: function () {
      jobratingController.rating.display();
    },

    previous: function () {
      if (this.fromRating) {
        // navigate to last field of last profile
        if (
          jobratingController.current.profile > 0 &&
          jobratingController.current.field === jobratingController.firstField
        ) {
          jobratingController.current.profile--;
          jobratingController.current.field =
            jobratingController.profiles.length - 1;
        }

        jobratingController.rating.display();
      } else {
        jobratingController.jobsOrField.display();
      }
    },

    getProgress: function () {
      return jobratingController.rating.getProgress();
    },
  },

  rating: {
    display: function () {
      let field = jobratingController.fields[jobratingController.current.field];
      let profile =
        jobratingController.profiles[jobratingController.current.profile];
      let fields = [];
      let answer = jobratingController.task.answer[profile]
        ? jobratingController.task.answer[profile][field.id]
        : null;
      if (jobratingController.mode.jobs) {
        // jobs
        field.jobs.forEach((job, index) => {
          fields.push({
            name: job,
            answer: answer != null && answer[index] != null ? answer[index] : 0,
          });
        });
      } else {
        // professional fields
        fields.push({
          name: field.name,
          answer:
            answer != null && answer.length != null && answer.length > 0
              ? Math.round(answer.reduce((a, b) => a + b, 0) / answer.length) // average
              : 0,
        });
      }

      ratingView.init(
        this,
        profileController.getName(profile),
        'Wie gut passenden deine ' +
          profileController.getName(profile, true) +
          ' zu de' +
          (jobratingController.mode.jobs ? 'n Berufen' : 'm Berufsfeld') +
          '?',
        fields,
        !jobratingController.current.field,
      );
    },

    next: function () {
      if (
        jobratingController.current.field <
        jobratingController.fields.length - 1
      ) {
        jobratingController.current.field++;
        jobratingController.rating.display();
      } else if (
        jobratingController.current.profile <
        jobratingController.profiles.length - 1
      ) {
        jobratingController.current.profile++;
        jobratingController.current.field = jobratingController.firstField;
        jobratingController.profilePreview.display(true);
      } else {
        jobratingController.finish();
        jobratingController.results.display();
      }
    },

    previous: function (del) {
      if (jobratingController.current.field > jobratingController.firstField) {
        jobratingController.current.field--;
        if (del) {
          this.delete();
        }
        jobratingController.rating.display();
      } else if (jobratingController.current.profile > 0) {
        jobratingController.current.profile--;
        jobratingController.current.field =
          jobratingController.fields.length - 1;
        if (del) {
          this.delete();
        }
        jobratingController.profilePreview.display(true);
      } else {
        jobratingController.profilePreview.display();
      }
    },

    back: function () {
      this.previous(true);
    },

    displayProfilePreview: function () {
      jobratingController.profilePreview.display(true);
    },

    save: function (indexOfJobIfNotField, value) {
      let profile =
        jobratingController.profiles[jobratingController.current.profile];
      let field = jobratingController.fields[jobratingController.current.field];

      if (jobratingController.task.answer == null) {
        jobratingController.task.answer = {};
      }

      if (jobratingController.task.answer[profile] == null) {
        jobratingController.task.answer[profile] = {};
      }

      if (jobratingController.mode.jobs) {
        // save job
        if (jobratingController.task.answer[profile][field.id] == null) {
          jobratingController.task.answer[profile][field.id] = [];
        }

        jobratingController.task.answer[profile][field.id][
          indexOfJobIfNotField
        ] = value;
      } else {
        // save professional field
        jobratingController.task.answer[profile][field.id] = [];
        field.jobs.forEach(() => {
          jobratingController.task.answer[profile][field.id].push(value);
        });
      }
      jobratingController.task.save();
    },

    delete: function () {
      let profile =
        jobratingController.profiles[jobratingController.current.profile];
      let field = jobratingController.fields[jobratingController.current.field];

      if (jobratingController.task.answer[profile][field.id] != null) {
        jobratingController.task.answer[profile][field.id] = null;
      }
    },

    getProgress: function () {
      return (
        ((jobratingController.fields.length *
          (jobratingController.current.profile > 0
            ? jobratingController.current.profile
            : 0) +
          jobratingController.current.field) /
          (jobratingController.fields.length *
            jobratingController.profiles.length)) *
        100
      );
    },

    initRating: function () {
      // init variables
      jobratingController.current.profile = 0;
      jobratingController.current.field = 0;

      //get all profiles
      jobratingController.profiles = jobratingController.task.profile;

      // get all fields from category
      jobratingController.fields = [];
      if (jobratingController.mode.studies) {
        jobratingController.fields = jobratingController.fields.concat(
          jobratingController.task.fields.studies,
        );
      }
      if (jobratingController.mode.training) {
        jobratingController.fields = jobratingController.fields.concat(
          jobratingController.task.fields.training,
        );
      }

      // dont use all fields, if not in last round (called after init current field)
      let quarterFields = function () {
        let roundLength = Math.ceil(
          jobratingController.fields.length / jobratingController.rounds,
        );
        jobratingController.current.round =
          parseInt(jobratingController.current.field / roundLength) + 1;
        jobratingController.firstField =
          (jobratingController.current.round - 1) * roundLength;
        if (jobratingController.current.round < jobratingController.rounds) {
          let end = jobratingController.firstField + roundLength;
          jobratingController.fields = jobratingController.fields.slice(0, end);
        }
      };

      if (jobratingController.task.answer == null) {
        jobratingController.task.answer = {};
      }

      jobratingController.current.field = 0;
      jobratingController.current.profile = 0;

      // get current field
      for (
        let fieldIndex = 0;
        fieldIndex < jobratingController.fields.length;
        fieldIndex++
      ) {
        for (let profile of jobratingController.profiles) {
          // is not answered?
          if (
            jobratingController.task.answer[profile] == null ||
            !jobratingController.task.answer[profile][
              jobratingController.fields[fieldIndex].id
            ]
          ) {
            // first not filled field
            jobratingController.current.field = fieldIndex;
            quarterFields();
            // get current profile
            for (let prfl of jobratingController.profiles) {
              for (
                let fldndx = jobratingController.firstField;
                fldndx < jobratingController.fields.length;
                fldndx++
              ) {
                // first field is not answered?
                if (
                  jobratingController.task.answer[prfl] == null ||
                  !jobratingController.task.answer[prfl][
                    jobratingController.fields[fldndx].id
                  ]
                ) {
                  // first not filled field in profile
                  jobratingController.current.field = fldndx;
                  jobratingController.current.profile =
                    jobratingController.profiles.indexOf(prfl);
                  return false; // not finished
                }
              }
            }
            jobratingController.current.field =
              jobratingController.fields.length - 1;
            jobratingController.current.profile =
              jobratingController.profiles.length - 1;
            return false; // not finished
          }
        }
      }
      jobratingController.current.field = jobratingController.fields.length - 1;
      jobratingController.current.profile =
        jobratingController.profiles.length - 1;
      jobratingController.current.round = jobratingController.rounds;
      return true; // finished
    },
  },

  results: {
    display: function () {
      // view
      resultsView.init(
        this,
        jobratingController.task.results.replace(
          'XX',
          jobratingController.current.round,
        ),
        this.calculateResults(),
      );
    },

    calculateResults: function () {
      let results = [];
      // calc results
      jobratingController.fields.forEach((field, index) => {
        let sum = 0;
        let max = 0;
        for (let profile of jobratingController.profiles) {
          if (
            jobratingController.task.answer[profile] &&
            jobratingController.task.answer[profile][field.id]
          ) {
            max += 4;
            sum +=
              jobratingController.task.answer[profile][field.id].reduce(
                (a, b) => a + b,
                0,
              ) / jobratingController.task.answer[profile][field.id].length;
          }
        }
        results.push({
          title: field.name,
          text: `
                            Mehr Infos zur Berufsgruppe ${
                              field.name
                            } findest du hier: <a href="${
            field.link
          }" class="link external">${field.link.replace('https://', '')}</a>
                        `,
          percent: Math.round((sum / max) * 100),
        });
      });
      // sort
      results.sort((a, b) => b.percent - a.percent);

      // add numbers
      results.forEach((field, index) => {
        field.title = index + 1 + '. ' + field.title;
      });

      return results;
    },

    previous: function () {
      jobratingController.rating.display();
    },

    continueTask: function () {
      if (this.hasNextRound()) {
        jobratingController.rating.initRating();
        jobratingController.profilePreview.display();
      } else {
        jobratingController.intro.moreInfo();
      }
    },

    hasNextRound: function () {
      return jobratingController.current.round < jobratingController.rounds;
    },

    goToProfile: function () {
      app.views.main.router.navigate('/profile/');
      app.views.main.router.navigate('/', { reloadPrevious: true });
      app.views.main.router.history = ['/', '/profile/'];
    },

    getProgress: function () {
      return 100;
    },
  },

  finish: function () {
    this.task.finish();
  },

  hasResults: function () {
    if (!this.task) {
      this.task = taskController.getTask(0, 1, 1, 2);
    }
    return (
      this.task.answer &&
      typeof this.task.answer === 'object' &&
      Object.keys(this.task.answer).length > 0
    );
  },

  getGraphic: function () {
    //init
    if (!this.task) {
      this.task = taskController.getTask(0, 1, 1, 2);
    }
    if (!this.profiles) {
      this.profiles = this.task.profile;
    }

    // get all answered fields
    let fieldsSave = this.fields;
    this.fields = [];
    this.fields = this.fields.concat(this.task.fields.studies);
    this.fields = this.fields.concat(this.task.fields.training);
    this.fields = this.fields.filter((field) => {
      for (let profile in jobratingController.task.answer) {
        if (
          jobratingController.task.answer.hasOwnProperty(profile) &&
          jobratingController.task.answer[profile][field.id]
        ) {
          return true;
        }
      }
      return false;
    });

    // get graphic
    let graphic = resultsView.getGraphic(
      jobratingController.results.calculateResults().slice(0, 5),
    );

    // undo fields
    this.fields = fieldsSave;

    return graphic;
  },
};
export default jobratingController;
