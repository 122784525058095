import homeView from './HomeView';
import userController from '../../core/UserController';

const homeController = {
  load: function () {
    const allModules = userController.getAllModules();
    /* Hier kann eigentlich UserData.getNextModule() aufgerufen werden, dies enthält jedoch keinen Exercise,
         dadurch gibt es noch einen Error, deswegen von Hand ein Modul und eine Chapter mit Exercise ausgewählt */
    //TODO: replace test-contents
    let currentSubblock = JSON.parse(localStorage.getItem('currentSubblock'));
    const module = userController.getModule(currentSubblock.module);
    const chapter = module.chapters[currentSubblock.chapter - 1];
    const exercise = chapter.exercises[currentSubblock.exercise - 1];
    const subblock = exercise.subblocks[currentSubblock.subblock - 1];
    const name = userController.getUserData('prename')
      ? userController.getUserData('prename')
      : 'du';
    const steps = userController.getAllModules();
    const practices = userController.startedTasks.map(function (e) {
      e['backgroundImage'] = userController
        .getModule(e.module_number)
        .getBackgroundImage();
      return e;
    });
    const firstBackground = userController.getModule(1).getBackgroundImage();

    homeView.init(
      module,
      chapter,
      exercise,
      subblock,
      steps,
      practices,
      name,
      firstBackground,
    );
  },
};
export default homeController;
