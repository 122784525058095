import $$ from 'dom7';
import app from '../../../../../../app';

let controller;

var interactionView = {
  goal: null,

  mode: null,

  lastActionNumber: 0,

  init: function (cntrllr, goal, newGoal) {
    this.mode = null;
    controller = cntrllr;
    this.goal = goal;
    this.loadPage();
    this.loadMainToolbar();
    if (newGoal) {
      this.enableNewMode();
    } else {
      this.enableDisplayMode();
    }
  },

  loadPage: function () {
    const page = $$('.page[data-name="task"]');
    page.removeClass('background-image background-mountain tasks-page');
  },

  loadMainToolbar: function () {
    app.loadMainToolbar($$('.page[data-name="task"] .main-toolbar'));
  },

  enableDisplayMode: function () {
    if (this.mode === 'display') {
      return;
    }
    this.loadNormalNavbar();
    this.loadTitle('Mein To-Do');
    this.loadContent();
    this.loadGoal();
    $$('#task-content .list').addClass('disabled-list');
    let inputs = $$('#task-content input');
    inputs.on('click', () => {
      controller.showEditMode();
    });
    this.hideUnused();
    this.mode = 'display';
  },

  enableEditMode: function () {
    if (this.mode === 'edit') {
      return;
    }
    this.loadFormNavbar(
      'To-do bearbeiten',
      () => {
        controller.save(interactionView.getValues());
        controller.showDisplayMode();
        // app.views.main.router.back();
      },
      () => {
        controller.showDisplayMode();
        // app.views.main.router.back();
      },
    );
    this.loadTitle('To-Do bearbeiten');
    this.loadContent();
    this.loadGoal();
    controller.initCalendar();
    this.mode = 'edit';
  },

  enableNewMode: function () {
    if (this.mode === 'create') {
      return;
    }
    this.loadFormNavbar(
      'To-do erstellen',
      () => {
        controller.save(interactionView.getValues());
        controller.showDisplayMode();
      },
      () => {
        app.views.main.router.back();
      },
    );
    this.loadTitle('Neues To-Do');
    this.loadContent();
    this.loadGoal();
    this.mode = 'create';
  },

  loadNormalNavbar: function () {
    const navbar = $$('.page[data-name="task"] .navbar');
    navbar.removeClass('form-navbar');
    navbar.empty();
    navbar.addClass('navbar-transparent no-hairline');
    navbar.append(`
            <div class="navbar-inner">
                <div class="left">
                    <a class="link back">
                        <i class="icon material-icons">navigate_before</i>
                    </a>
                </div>
                <div class="right">
                    <a class="link popover-open" href="#" data-popover="#goal-popover-menu">
                        <i class="icon material-icons">more_vert</i>
                    </a>
                </div>
            </div>
        `);
    if ($$('#goal-popover-menu').length < 1) {
      const popover = $$(document.createElement('div'));
      popover.addClass('popover');
      popover.attr('id', 'goal-popover-menu');
      popover.append(`
                <div class="popover-inner">
                <div class="list">
                    <ul>
                        <li>
                            <a id="goal-popover-delete" class="list-button item-link" href="#">To-do löschen</a>
                        </li>
                        <li>
                            <a id="goal-popover-export" class="list-button item-link" href="#">To-do exportieren</a>
                        </li>
                        <li>
                            <a id="goal-popover-edit" class="list-button item-link" href="#">To-do bearbeiten</a>
                        </li>
                    </ul>
                </div>
            </div>
            `);
      popover.find('#goal-popover-delete').on('click', () => {
        app.popover.close('#goal-popover-menu');
        app.dialog.confirm('To-do wirklich löschen?', function () {
          controller.delete();
          app.views.main.router.back();
        });
      });
      popover.find('#goal-popover-export').on('click', () => {
        app.popover.close('#goal-popover-menu');
        controller.export();
      });
      popover.find('#goal-popover-edit').on('click', () => {
        app.popover.close('#goal-popover-menu');
        controller.showEditMode();
      });
      popover.insertAfter('#task-page-content');
    }
  },

  loadFormNavbar: function (title, onReady, onCancel) {
    const navbar = $$('.page[data-name="task"] .navbar');
    navbar.removeClass('navbar no-shadow navbar-transparent no-hairline');
    navbar.empty();
    app.loadFormNavbar(navbar, title, onReady, onCancel);
  },

  loadTitle: function (title) {
    const titleElement = $$('#task-title');
    titleElement.empty();
    titleElement.attr('id', 'goal-title');
    titleElement.append(title);
  },

  loadContent: function () {
    this.lastActionNumber = 0;
    const content = $$('#task-content');
    content.empty();
    content.append(`
            <div class="list">
                <div class="list-topic">Spezifisch</div>
                <div class="list-group">
                    <ul>
                        <li>
                            <div class="item-content">
                                <div class="item-inner">
                                    <input id="goal-input-specific-title" class="goal-input bold" type="text" placeholder="Tippen, um Ziel zu definieren">
                                </div>
                            </div>
                        </li>
                        <li>
                           <div class="item-content">
                                <div class="item-inner">
                                    <input id="goal-input-specific-description" class="goal-input" type="text" placeholder="Genaue Beschreibung">
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="list">
                <div class="list-topic">Messbar</div>
                <div class="list-group">
                    <ul>
                        <li>
                            <div class="item-content">
                                <div class="item-inner">
                                    <input id="goal-input-measurable" class="goal-input" type="text" placeholder="Wie überprüfst du dein Ziel?">
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="list">
                <div class="list-topic">Aktionsorientiert</div>
                <div class="list-group">
                    <ul>
                        <li>
                            <div class="item-content">
                                <div class="item-inner goal-action-inner">
                                    <i class="icon svg-icon-check-circle-gray goal-actionoriented-icon-on hide"></i>
                                    <i class="icon material-icons goal-actionoriented-icon-off">radio_button_unchecked</i>
                                    <input id="goal-input-actionoriented-0" class="goal-input" type="text" placeholder="Tippen, um konkrete Maßnahme einzugeben">
                                </div>
                            </div>
                        </li>
                        <li id="goal-new-action-component">
                           <div class="item-content">
                                <div id="goal-new-action" class="item-inner">
                                    <i id="goal-new-action-icon" class="icon material-icons">add</i>
                                    <label>Weitere Maßnahme hinzufügen</label>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="list">
                <div class="list-topic">Relevant</div>
                <div class="list-group">
                    <ul>
                        <li>
                            <div class="item-content">
                                <div class="item-inner">
                                    <input id="goal-input-relevant" class="goal-input" type="text" placeholder="Warum ist das Ziel für dich relevant?">
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="list ">
                <div class="list-topic">Terminiert</div>
                <div class="list-group">
                    <ul>
                        <li id="goal-date-component">
                            <div class="item-content">
                               <div class="item-inner">
                                    <div class="item-title">Datum</div>
                                    <div class="item-after">
                                        <div class="item-input-wrap">
                                            <input class="goal-input" type="text" placeholder="Datum auswählen" readonly="readonly" id="goal-input-terminates"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        `);

    /* init new action button */
    $$('#goal-new-action').on('click', this.loadNewAction);

    /* init check button */
    this.initCheckButton($$('#goal-input-actionoriented-0').parent());
  },

  loadNewAction: function () {
    interactionView.lastActionNumber++;
    let component = $$(document.createElement('li'));
    component.append(`
            <div class="item-content">
                <div class="item-inner goal-action-inner">
                    <i class="icon svg-icon-check-circle-gray goal-actionoriented-icon-on hide"></i>
                    <i class="icon material-icons goal-actionoriented-icon-off">radio_button_unchecked</i>
                    <input id="goal-input-actionoriented-${interactionView.lastActionNumber}" class="goal-input" type="text" placeholder="Tippen, um konkrete Maßnahme einzugeben">
                </div>
            <div>
        `);

    /* init check button */
    interactionView.initCheckButton(component.find('.goal-action-inner'));

    component.insertBefore('#goal-new-action-component');

    return component.find('input');
  },

  initCheckButton: function (element) {
    const input = element.find('input');
    const iconOff = element.find('.goal-actionoriented-icon-off');
    const iconOn = element.find('.goal-actionoriented-icon-on');
    iconOff.on('click', () => {
      iconOn.removeClass('hide');
      iconOff.addClass('hide');

      if (interactionView.mode === 'display') {
        let values = {
          actionoriented: {},
        };
        values['actionoriented'][input.attr('id').split('-')[3]] = {};
        values['actionoriented'][input.attr('id').split('-')[3]][
          'state'
        ] = true;
        controller.save(values, true);
      }
    });
    iconOn.on('click', () => {
      iconOff.removeClass('hide');
      iconOn.addClass('hide');

      if (interactionView.mode === 'display') {
        let values = {
          actionoriented: {},
        };
        values['actionoriented'][input.attr('id').split('-')[3]] = {};
        values['actionoriented'][input.attr('id').split('-')[3]][
          'state'
        ] = false;
        controller.save(values, true);
      }
    });
  },

  loadGoal: function () {
    let inputs = $$('.goal-input');
    inputs.each((index, input) => {
      input = $$(input);
      let keys = input.attr('id').split('-');
      let key = keys[2];
      let subkey = keys[3];
      if (key === 'actionoriented' && this.goal[key]) {
        for (subkey = 0; subkey < this.goal[key].length; subkey++) {
          if (!this.goal[key][subkey]) {
            continue;
          }
          if (subkey > 0) {
            input = interactionView.loadNewAction();
          }
          input.val(this.goal[key][subkey]['action']);
          if (this.goal[key][subkey]['achieved']) {
            input
              .parent()
              .find('.goal-actionoriented-icon-on')
              .removeClass('hide');
            input
              .parent()
              .find('.goal-actionoriented-icon-off')
              .addClass('hide');
          }
        }
      } else if (subkey && this.goal[key]) {
        input.val(this.goal[key][subkey]);
      } else {
        input.val(this.goal[key]);
      }
      if (key === 'terminates' && this.goal[key]) {
        input.addClass('input-with-value');
      }
    });
  },

  getValues: function () {
    let values = {};
    let inputs = $$('.goal-input');
    inputs.each((index, input) => {
      input = $$(input);
      let keys = input.attr('id').split('-');
      let key = keys[2];
      let subkey = keys[3];
      if (key === 'actionoriented') {
        if (values[key] == null) {
          values[key] = {};
        }
        values[key][subkey] = {};
        values[key][subkey]['value'] = input.val();
        values[key][subkey]['state'] = input
          .parent()
          .find('.goal-actionoriented-icon-off')
          .hasClass('hide');
      } else if (subkey) {
        if (values[key] == null) {
          values[key] = {};
        }
        values[key][subkey] = input.val();
      } else {
        values[key] = input.val();
      }
    });
    return values;
  },

  hideUnused: function () {
    //TODO: für verstecken leerer inputs Kommentare entfernen:
    /*        $$('#goal-new-action-component').hide();
        $$('#task-content .list').each((index, list) => {
            list = $$(list);
            let empty = true;
            list.find('li').each((index, li) => {
                li = $$(li);
                let input = li.find('input');
                if (input) {
                    if (input.val()) {
                        empty = false;
                    } else {
                        li.hide();
                    }
                }
            });
            if (empty) {
                list.hide();
            }
        });
*/
    const complexDate = $$('#goal-date-component');
    complexDate.hide();

    //        if (this.goal.terminates) {
    let simpleDate = $$(document.createElement('li'));
    simpleDate.append(`
                <div id="goal-simple-date" class="item-content">
                    <div class="item-inner">
                        <i id="goal-date-icon" class="icon material-icons">date_range</i>
                        <label>${
                          this.goal.terminates
                            ? this.goal.terminates
                            : 'Datum auswählen'
                        }</label>
                    </div>
                </div>
            `);
    simpleDate.on('click', () => {
      controller.showEditMode();
    });
    simpleDate.insertBefore(complexDate);
    //        }
  },
};
export default interactionView;
