function Keydate(number) {
  this.title = '';
  this.location = '';
  this.notes = '';
  this.start = '';
  this.end = '';
  this.step = 0;
  this.number = number;
  this.reminderIndex = -1;
  this.last_modified = null;
  this.last_synced = null;
}

//Saves Keydate to LocalStorage
Keydate.prototype.save = function () {
  this.last_modified = new Date().getTime();
  var toSave = {};
  for (var key in this) {
    toSave[key] = this[key];
  }
  toSave.last_modified = Date.now();
  toSave._id = this.number;
  localStorage.setItem(this.number, JSON.stringify(toSave));
};

// Deletes Keydate in LocalStorage
Keydate.prototype.delete = function () {
  localStorage.removeItem(this.number);
};

// Loads content of Note from LocalStorage
Keydate.prototype.load = function () {
  let toLoad = JSON.parse(localStorage.getItem(this.number));
  if (!toLoad)
    toLoad = JSON.parse(localStorage.getItem('Keydate: ' + this.number));
  for (var key in toLoad) {
    this[key] = toLoad[key];
  }
};

// Returns true, if this has no Content
Keydate.prototype.isEmpty = function () {
  let empty = true;
  for (var key in this) {
    if (
      ![
        'number',
        'save',
        'load',
        'delete',
        'isEmpty',
        'reminderIndex',
        'wholeday',
        'last_modified',
        'last_synced',
      ].includes(key) &&
      this[key]
    ) {
      empty = false;
    }
  }
  return empty;
};

export default Keydate;
