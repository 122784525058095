import $$ from 'dom7';
import app from '../../app.js';
import dictionary from '../../core/Dictionary';
import elementFactory from '../../core/ui/ElementFactory';
import userController from '../../core/UserController';
let dicKey = 'settingtexts';

var settingsView = {
  init: function () {
    this.loadContent();
  },

  loadContent: function () {},

  /**
   * Function to load Setting Page, where user can enter personal information.
   * @param int afterregistration set to 1, routing came from registrations. Determines how routing works on this page
   */
  loadUserinfoView: function (afterregistration) {
    dictionary.loadModuleToDOM(
      'settingtexts',
      $$(".page[data-name='userinfo']"),
    );
    let fedstatesDictionary = JSON.parse(
      dictionary.getText('settingtexts', 'fedstatesDictionary'),
    );
    let year = new Date().getFullYear();
    let years = [
      year - 4,
      year - 3,
      year - 2,
      year - 1,
      year,
      year + 1,
      year + 2,
      year + 3,
      year + 4,
      year + 5,
    ];
    $$('#username-input').append(
      elementFactory.getUnderlinedInput(
        dictionary.getText(dicKey, 'username'),
        userController.getUserData('username'),
        'person',
        'text',
      ),
    );
    let birthday = userController.getUserData('birthday');
    if (birthday == null || !Boolean(birthday)) birthday = '';
    typeof birthday === 'string'
      ? (birthday = birthday.toString())
      : (birthday = birthday.toISOString());
    birthday == 'Invalid Date' ? (birthday = '') : null;
    if (!birthday || birthday == '') {
      let birthdayinput = elementFactory.getUnderlinedInput(
        dictionary.getText(dicKey, 'birthday'),
        birthday,
        'date_range',
        'text',
      );
      birthdayinput.find('input').attr('onFocus', "(this.type='date')");
      $$('#birthday-input').append(birthdayinput);
    } else {
      let birthdayinput = elementFactory.getUnderlinedInput(
        dictionary.getText(dicKey, 'birthday'),
        birthday.toString().split('T')[0],
        'date_range',
        'date',
      );
      $$('#birthday-input').append(birthdayinput);
    }

    let graduationyear = userController.getUserData('graduationdate');
    graduationyear.toString() == 'Invalid Date' ? (graduationyear = '') : null;
    graduationyear == false
      ? (graduationyear = '')
      : (graduationyear = new Date(
          userController.getUserData('graduationdate'),
        ).getFullYear());
    $$('#graduationdate-input').append(
      elementFactory.getDropdownMenu(
        graduationyear,
        years,
        dictionary.getText(dicKey, 'graduationdate'),
        'school',
        false,
      ),
    );
    $$('#gender-input').append(
      elementFactory.getDropdownMenu(
        userController.getUserData('gender'),
        dictionary.getText(dicKey, 'genderoptions'),
        dictionary.getText(dicKey, 'gender'),
        'person',
        false,
      ),
    );
    $$('#state-input').append(
      elementFactory.getDropdownMenu(
        userController.getUserData('state') &&
          fedstatesDictionary[userController.getUserData('state')],
        dictionary.getText(dicKey, 'stateoptions'),
        dictionary.getText(dicKey, 'state'),
        'room',
        false,
      ),
    );
    $$('#state-input .item-icon i')
      .removeClass('material-icons-outlined')
      .addClass('material-icons');

    !afterregistration
      ? $$('#skip-link').addClass('hide')
      : $$('#authchange-link').addClass('hide');
    !app.dbconnector.isLoggedIn()
      ? $$('#authchange-link').addClass('hide')
      : null;
    /* Listeners for saving inputs */
    $$('#save-to-profile').on('click', function () {
      userController.setUserData('username', $$('#username-input input').val());
      $$('#birthday-input input').val()
        ? userController.setUserData(
            'birthday',
            new Date($$('#birthday-input input').val()),
          )
        : null;
      userController.setUserData(
        'graduationdate',
        new Date($$('#graduationdate-input input').val()),
      );
      userController.setUserData('gender', $$('#gender-input input').val());
      userController.setUserData(
        'state',
        fedstatesDictionary[$$('#state-input input').val()],
      );
      //TODO: Move this part to Settingscontroller
      if (app.dbconnector.isLoggedIn()) {
        app.dialog.preloader(dictionary.getText(dicKey, 'awaitsync'));
        userController.uploadUserinfo().then(function (result) {
          app.dialog.close();
          if (!result) {
            app.dialog.alert(dictionary.getText(dicKey, 'failsync'));
          } else {
            if (true) {
              app.views.main.router.navigate('/');
            } else {
              app.views.main.router.navigate('/settings/', {
                reloadPrevious: true,
              });
            }
          }
        });
      } else {
        app.dialog.alert('Profil gespeichert!');
      }
    });
  },
  loadAuthChange: function () {
    if (app.dbconnector.isLoggedIn()) {
      $$('#email-input').append(
        elementFactory.getUnderlinedInput(
          dictionary.getText(dicKey, 'newEmail'),
          '',
          'mail',
          'text',
        ),
      );
      $$('#password-input').append(
        elementFactory.getUnderlinedInput(
          dictionary.getText(dicKey, 'newPassword'),
          '',
          'lock',
          'password',
        ),
      );
      $$('#passwordconfirm-input').append(
        elementFactory.getUnderlinedInput(
          dictionary.getText(dicKey, 'newPasswordConfirm'),
          '',
          'lock',
          'password',
        ),
      );
      $$('#password-input').append(
        $$(document.createElement('span')).addClass('input-clear-button'),
      );
      $$('#authchange-button').on('click', () => {
        app.dialog.preloader(dictionary.getText(dicKey, 'waitAuthChange'));
        const objectToUpload = {};
        if ($$('#email-input').find('input').val()) {
          objectToUpload.email = $$('#email-input').find('input').val();
        }
        if ($$('#password-input').find('input').val()) {
          if (
            $$('#password-input').find('input').val() ==
            $$('#passwordconfirm-input').find('input').val()
          ) {
            objectToUpload.password = $$('#password-input').find('input').val();
          } else {
            app.dialog.alert(dictionary.getText(dicKey, 'passwordsNotSame'));
          }
        }
        userController.changeAuthaAndLogout(objectToUpload);
      });
    } else {
      $$('#authchange-disclaimer').attr(
        'data-dic-key',
        'authchangeLoginRequired',
      );
      $$('#authchange-button').addClass('hide');
    }

    dictionary.loadModuleToDOM(
      'settingtexts',
      $$(".page[data-name='authchange']"),
    );
  },
};
export default settingsView;
