'use strict';

import axios from 'axios';
import userController from '../core/UserController';

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const unique_app_id = localStorage.getItem('uid-user');
let unique_360_id = -1;

const axiosCookieJarSupport = require('axios-cookiejar-support');
const tough = require('tough-cookie');

class Connector {
  axiosInstance; //why is the declaration outside of the constructor necessary here? Usually IntelliJ had no problems when they're only specified inside.
  source;
  CancelToken;

  constructor(cordova, url) {
    // 'https://db1.deep-app.de') {
    this._jwt = ''; //used across methods (i.e. if a login was successful, the module download automatically uses the retrieved jwt)
    this.cordova = cordova;
    this.url = url;
    //create a custom instance if there's need for headers or something later
    this.axiosInstance = this.createCustomInstance();
  }

  /**
   * Return a custom AxiosInstance with the connector's jwt and a fresh CancelToken
   */
  createCustomInstance() {
    this.CancelToken = axios.CancelToken;
    this.source = this.CancelToken.source();
    this._jar = new tough.CookieJar();
    let instance = axios.create({
      baseURL: this.url,
      cancelToken: this.source.token, //cancel requests by calling source.cancel();
      validateStatus: function (/*status*/) {
        return true;
      },
      // withCredentials: true,		//send cookie jar with every request; better to only send with specific ones
    });

    if (this.cordova) {
      axiosCookieJarSupport(instance);
      instance.defaults.jar = this._jar;
    }

    return instance;
  }

  set jwt(token) {
    this._jwt = token;
  }

  /**
   * Cancel any ongoing request and supply a cancellation message.
   * Create a new instance with current jwt for new requests after cancellation.
   * @param message	The cancellation message.
   */
  cancel(message) {
    this.source.cancel(message);
    this.axiosInstance = this.createCustomInstance();
  }

  /**
   * Create a new threesixty request and return the response
   * @return {Promise}	The response of the server. On success (status code: 200), the data property contains an id property with the newly created feedback request id. On failure, data contains errors.
   */
  createNewThreesixtyRequest = async (uid) => {
    try {
      return await this.axiosInstance.get('/threesixty/new', {
        headers: { Authorization: `Bearer ${this._jwt}` },
        params: { uid: uid },
      });
    } catch (e) {
      if (axios.isCancel(e)) {
        console.log('Request canceled.', e.message);
      } else {
        console.error('Error during createNewThreesityRequest.', e.message);
      }
    }
  };

  /**
   * DEPRECATED: Create a new threesixty request with secret and return the response.
   * @return {Promise}	The response of the server. On success (status code: 200), the data property contains an id property with the newly created feedback request id. On failure, data contains errors.
   */
  createNewThreesixtyRequestLegacy = async (secret) => {
    try {
      return await this.axiosInstance.get('/threesixty/new', {
        params: { secret: secret },
      });
    } catch (e) {
      if (axios.isCancel(e)) {
        console.log('Request canceled.', e.message);
      } else {
        console.error('Error during createNewThreesityRequest.', e.message);
      }
    }
  };

  /**
   * Retrieve the results from a threesixty request by supplying its id and secret.
   * @return {Promise}	The response of the server. On success (status code: 200), the data property contains a 360request with its responses. On failure, data contains errors.
   */
  getThreesixtyResponses = async (uid) => {
    try {
      return await this.axiosInstance.get('/threesixty', {
        headers: { Authorization: `Bearer ${this._jwt}` },
      });
    } catch (e) {
      if (axios.isCancel(e)) {
        console.log('Request canceled.', e.message);
      } else {
        console.error('Error during getThreesixtyResponses.', e.message);
      }
    }
  };

  /**
   * DEPRECATED Retrieve the results from a threesixty request by supplying its id and secret.
   * @return {Promise}	The response of the server. On success (status code: 200), the data property contains a 360request with its responses. On failure, data contains errors.
   */
  getThreesixtyResponsesLegacy = async (requestId, secret) => {
    try {
      return await this.axiosInstance.get('/threesixty', {
        headers: { 'Content-Type': `application/json` },
        params: { id: requestId, secret: secret },
      });
    } catch (e) {
      if (axios.isCancel(e)) {
        console.log('Request canceled.', e.message);
      } else {
        console.error('Error during getThreesixtyResponses.', e.message);
      }
    }
  };

  /**
   * Post a response to a threesixty request.
   * @return {Promise}	The response of the server. On success: status code is 200. On failure, data contains errors.
   */
  postThreesixtyResponse = async (requestId, content) => {
    try {
      return await this.axiosInstance.post('/threesixty', {
        content: content,
        params: { id: requestId },
      });
    } catch (e) {
      if (axios.isCancel(e)) {
        console.log('Request canceled.', e.message);
      } else {
        console.error('Error during get360results.', e.message);
      }
    }
  };
  /**
   * register a new user using the local strategy.
   * @return {Promise}	The response of the server. On success (status code: 200), the data property contains a token property with a valid jwt for the new user. On failure, data contains errors.
   */
  registerUserLocal = async (
    email,
    plainPassword,
    username,
    licenseKey,
    licenseType,
  ) => {
    try {
      const req = await this.axiosInstance.post('/user/register', {
        email,
        password: plainPassword,
        username,
        licenseKey,
        licenseType,
      });
      if (req.data.token) {
        this.jwt = req.data.token;
        localStorage.setItem('isLoggedIn', 'true');
      }
      return req;
    } catch (e) {
      if (axios.isCancel(e)) {
        console.log('Request canceled.', e.message);
      } else {
        console.error('Error during registerUserLocal.', e.message);
      }
    }
  };

  /**
   * login a user using the local strategy. If successful, set the received token on the connector instance.
   * @param email
   * @param plainPassword
   * @return {Promise}	The response of the server. On success (status code: 200), the data property contains a token property with a valid jwt for the user. On failure, data contains errors.
   */
  loginUserLocal = async (email, plainPassword) => {
    try {
      const req = await this.axiosInstance.post(
        '/user/auth',
        {
          email: email,
          password: plainPassword,
        },
        {
          withCredentials: true, //not necessary for cookie jar, but when using
        },
      );
      if (req.data.token) {
        this.jwt = req.data.token;
        localStorage.setItem('isLoggedIn', 'true');
      }
      return req;
    } catch (e) {
      if (axios.isCancel(e)) {
        return { data: { errors: { msg: [e.message] } }, status: 218 }; //return the message handed to source.cancel
      } else {
        console.error('Error during loginUserLocal.', e.message);
      }
    }
  };

  /**
   * Use an unexpired token to receive a fresh token.
   * @return {Promise}	The response of the server. On success (status code: 200), the data property contains a token property with a new valid jwt for the user. On failure, data contains errors.
   */
  refresh = async () => {
    try {
      const req = await this.axiosInstance.get('/user/refresh', {
        withCredentials: true, //send cookies from cookie-jar
      });
      if (req.data.token) {
        this.jwt = req.data.token;
        //if(!this._jwt) localStorage.setItem("isLoggedIn", false)
      } else if (req.status === 400) {
        userController.afterLogout(false);
      }
      return req;
    } catch (e) {
      if (axios.isCancel(e)) {
        return { data: { errors: { msg: [e.message] } }, status: 218 }; //return the message handed to source.cancel
      } else {
        console.error('Error during refreshToken.', e.message);
      }
    }
  };

  /**
   * Download a module from the server
   * @param {number} number 		Which module to download.
   * @param {function} [onProgress]	A function to handle progress. Is served the amount loaded in percent (e.g. 45 for 45%) or -1 if progress couldn't be determined.
   * @return {Promise}	The response of the server. On success (status code: 200), the data property contains the requested module as json. On failure, data contains errors.
   */
  downloadModule = async (number, onProgress) => {
    //TODO: should I test number to be a number?
    try {
      return await this.axiosInstance.get(`/resource/module/${number}`, {
        //is that with the url done cleanly?
        headers: { Authorization: `Bearer ${this._jwt}` }, // add bearer authorization as module route should only be accessed by registered users
        onDownloadProgress: function (progressEvent) {
          //not useful right now, but could display download progress for larger downloads
          const totalLength = progressEvent.lengthComputable
            ? progressEvent.total
            : progressEvent.target.getResponseHeader('content-length') ||
              progressEvent.target.getResponseHeader(
                'x-decompressed-content-length',
              ); //detect total length with fallbacks
          if (totalLength) {
            //if a length was retrieved
            onProgress(Math.round((100 * progressEvent.loaded) / totalLength));
          } else {
            onProgress(-1);
          }
        },
      });
    } catch (e) {
      if (axios.isCancel(e)) {
        console.log('Request canceled.', e.message);
      } else {
        console.error('Error during downloadModule.', e.message);
      }
    }
  };

  downloadUserData = async () => {
    try {
      return await this.axiosInstance.get(`/user/`, {
        headers: { Authorization: `Bearer ${this._jwt}` },
      });
    } catch (e) {
      if (axios.isCancel(e)) {
        console.log('Request canceled.', e.message);
      } else {
        console.error('Error during downloadUserData.', e.message);
      }
    }
  };

  updateUserData = async (userdata) => {
    try {
      return await this.axiosInstance.post(`/user/update`, userdata, {
        headers: { Authorization: `Bearer ${this._jwt}` },
      });
    } catch (e) {
      if (axios.isCancel(e)) {
        console.log('Request canceled.', e.message);
      } else {
        console.error('Error during downloadUserData.', e.message);
      }
    }
  };

  downloadNewUserInput = async (level) => {
    try {
      return await this.axiosInstance.post(
        `/input/pull`,
        { level: level },
        {
          headers: { Authorization: `Bearer ${this._jwt}` },
        },
      );
    } catch (e) {
      if (axios.isCancel(e)) {
        console.log('Request canceled.', e.message);
      } else {
        console.error('Error during downloadUserData.', e.message);
      }
    }
  };

  updateUserInput = async (newUserInput) => {
    try {
      return await this.axiosInstance.post(`/input/push`, newUserInput, {
        headers: { Authorization: `Bearer ${this._jwt}` },
      });
    } catch (e) {
      if (axios.isCancel(e)) {
        console.log('Request canceled.', e.message);
      } else {
        console.error('Error during downloadUserData.', e.message);
      }
    }
  };

  /**
   * Returns true if localStorage flag for logout is not set and this._jwt is set.
   * */
  isLoggedIn = () => {
    return localStorage.getItem('isLoggedIn') === 'true';
  };

  isConnectedWithJWT = () => {
    return Boolean(this._jwt);
  };

  /**
   *
   * */
  secureLoginWithJWT = async () => {
    //console.log(!Boolean(this._jwt))
    let isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
    if (isLoggedIn && !Boolean(this._jwt)) {
      await this.refresh();
      return Boolean(this._jwt);
    }
    return Boolean(this._jwt);
  };

  /**
   * logout a user. If used inside browser, the control cookie from the browser's store must be cleared externally.
   * @return {Promise}	The response of the server. On success (status code: 200), the data property contains a token property with a valid jwt for the user. On failure, data contains errors.
   */
  logout = () => {
    try {
      this.jwt = null;
      let error;
      localStorage.setItem('isLoggedIn', 'false');
      this._jar.removeAllCookies((err) => {
        error = err;
      });
      return error ? error : true;
    } catch (e) {
      console.error('Error during logoutUser.', e.message);
    }
  };
}

export default Connector;
