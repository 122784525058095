import $$ from 'dom7';
import app from '../../../../app';
import controller from './TaskController';

var taskView = {
  task: null,

  init: function (task, loadnothing) {
    this.task = task;
    if (!loadnothing) {
      this.loadMainToolbar();
      this.loadMainFAB();
      this.loadTitle();
    }
  },

  load: function (task, loadWithoutContent, asCard) {
    this.init(task);
    if (!loadWithoutContent) {
      this.loadContent();
    }
    this.loadFinishButton();
    if (asCard) {
      this.makeTaskCard();
    }
  },

  loadMainToolbar: function () {
    app.loadMainToolbar($$('.page[data-name="task"] .main-toolbar'));
  },

  loadMainFAB: function () {
    app.loadMainFAB(
      $$('.page[data-name="task"] .main-fab'),
      this.task && this.task.module_number ? this.task.module_number : null,
    );
  },

  updateBackNavigation: function (execute) {
    const back = $$('.page[data-name="task"] .navbar .left a.link')
      .off('click')
      .removeClass('back');
    back.on('click', () => {
      execute();
    });
  },

  loadTitle: function (title, subtitle) {
    const tit = $$('#task-title');
    tit.empty();
    tit.append(title ? title : this.task.name);
    if (subtitle) {
      const sub = $$('#task-title');
      sub.empty();
      sub.append(subtitle);
    }
  },

  loadContent: function () {
    const content = $$('#task-content');
    content.addClass('task-container');
    content.empty();
    this.getInputs(this.task).forEach((input) => {
      content.append(input);
    });
  },

  getInputs: function (task) {
    let inputs = [];
    controller
      .getTaskInputElements(task)
      .forEach((input) => inputs.push(input));
    return inputs;
  },

  loadFinishButton: function () {
    let button = $$(document.createElement('button'));
    button.addClass('button button-fill color-white');
    button.attr('id', 'finish-task-button');
    button.append('Aufgabe abschließen');
    button.on('click', () => {
      controller.finishTask();
      app.views.main.router.back();
    });
    $$('#task-bottom .button-list').append(button);
  },

  makeTaskCard: function () {
    $$('#task-content').addClass('card');
  },

  scrollUp: function () {
    $$('#task-page-content')[0].scrollTop = 0;
  },
};
export default taskView;
