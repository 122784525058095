import view from './NotebookView';
import Notebook from '../../model/notebook/Notebook.js';
import Filter from '../../core/Filter';
import dateController from '../../core/DateController';

var notebookController = {
  goalsFilter: new Filter(
    {
      refreshFilter: function () {
        //do nothing
      },
    },
    {
      all: {
        is: function (goal) {
          return true;
        },
      },
      today: {
        is: function (goal) {
          if (goal.terminates == null) {
            return false;
          }
          let now = new Date();
          let date = dateController.getDateFromString(goal.terminates);
          return dateController.isSameDay(now, date);
        },
      },
      week: {
        is: function (goal) {
          if (goal.terminates == null) {
            return false;
          }
          let now = new Date();
          let date = dateController.getDateFromString(goal.terminates);
          return dateController.isSameWeek(now, date);
        },
      },
      month: {
        is: function (goal) {
          if (goal.terminates == null) {
            return false;
          }
          let now = new Date();
          let date = dateController.getDateFromString(goal.terminates);
          return dateController.isSameMonth(now, date);
        },
      },
    },
  ),

  keydatesFilter: new Filter(
    {
      refreshFilter: function () {
        //do nothing
      },
    },
    {
      all: {
        is: function (keydate) {
          return true;
        },
      },
      today: {
        is: function (keydate) {
          if (keydate.start == null) {
            return false;
          }
          let now = new Date();
          let date = dateController.getDateFromString(keydate.start);
          return dateController.isSameDay(now, date);
        },
      },
      week: {
        is: function (keydate) {
          if (keydate.start == null) {
            return false;
          }
          let now = new Date();
          let date = dateController.getDateFromString(keydate.start);
          return dateController.isSameWeek(now, date);
        },
      },
      month: {
        is: function (keydate) {
          if (keydate.start == null) {
            return false;
          }
          let now = new Date();
          let date = dateController.getDateFromString(keydate.start);
          return dateController.isSameMonth(now, date);
        },
      },
    },
  ),

  notesFilter: new Filter(
    {
      refreshFilter: function () {
        //do nothing
      },
    },
    {
      dates: {
        sort: function (first, second) {
          return second.last_modified - first.last_modified;
        },
      },
      steps: {
        sort: function (first, second) {
          if (first.step !== 0 && second.step !== 0) {
            if (first.step !== second.step) {
              return first.step - second.step;
            }
          } else if (first.step !== 0) {
            return -1;
          } else if (second.step !== 0) {
            return 1;
          }
          return second.last_modified - first.last_modified;
        },
      },
    },
  ),

  load: function () {
    Notebook.init();
    let notes = Notebook.getNotes();
    let goals = Notebook.getAllCreatedGoals();
    let keydates = Notebook.getKeydates();
    view.init(this, goals, keydates, notes);
  },

  refresh: function (target) {
    if (view.initialized) {
      Notebook.init();
      if (target == null) {
        view.loadGoals(Notebook.getAllCreatedGoals());
        view.loadNotes(Notebook.getNotes());
        view.loadKeydates(Notebook.getKeydates());
      } else if (target === 'goals') {
        view.loadGoals(Notebook.getAllCreatedGoals());
      } else if (target === 'keydates') {
        view.loadKeydates(Notebook.getKeydates());
      } else if (target === 'notes') {
        view.loadNotes(Notebook.getNotes());
      }
    } else {
      this.load();
    }
  },

  getFilter: function (target, state) {
    if (target === 'goals') {
      return this.goalsFilter.get(state);
    } else if (target === 'keydates') {
      return this.keydatesFilter.get(state);
    } else if (target === 'notes') {
      return this.notesFilter.get(state);
    }
  },

  uninit: function () {
    view.onLeave();
  },
};
export default notebookController;
