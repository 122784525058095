import $$ from 'dom7';
const domHelper = {
  /**
   * Checks is element is in document
   *
   * @param element dom7 element
   * @return {boolean} true if in document
   */
  isInDocument: function (element) {
    return document.body.contains(element[0]);
  },

  /**
   * Executes handler on remove element from document
   *
   * @param element the dom7 element listen to
   * @param handler action performed on event
   */
  onElementRemoveOnce: function (element, handler) {
    let _this = this;
    // observing element exit
    let removeNodeObserver = new MutationObserver(function () {
      if (!_this.isInDocument(element)) {
        handler();
        removeNodeObserver.disconnect();
      }
    });
    // start Observing after node inserted
    let insertNodeObserver = new MutationObserver(function () {
      setTimeout(() => {
        removeNodeObserver.observe(document, {
          childList: true,
          subtree: true,
        });
      }, 100);
      insertNodeObserver.disconnect();
    });
    insertNodeObserver.observe(document, { childList: true, subtree: true });
  },

  /**
   * Executes handler on insert element into document
   *
   * @param element the dom7 element listen to
   * @param handler action performed on event
   */
  onElementInsertOnce: function (element, handler) {
    let _this = this;
    // start Observing after node inserted
    let insertNodeObserver = new MutationObserver(function () {
      if (_this.isInDocument(element)) {
        handler();
        insertNodeObserver.disconnect();
      }
    });
    insertNodeObserver.observe(document, { childList: true, subtree: true });
  },

  /**
   * Checks is parent contains child.
   *
   * @param parent dom7 parent
   * @param child dom7 child
   * @return {boolean} contains
   */
  isDescendant: function (parent, child) {
    parent = parent[0];
    let node = child[0].parentNode;
    while (node != null) {
      if (node === parent) {
        return true;
      }
      node = node.parentNode;
    }
    return false;
  },

  /**
   * Get unique query selector of element.
   *
   * @param element dom7 element
   * @return {string} selector
   */
  getQuerySelector: function (element) {
    if (element[0].tagName.toLowerCase() === 'html') {
      return 'HTML';
    }
    var str = element[0].tagName;
    str += element[0].id !== '' ? '#' + element[0].id : '';
    if (element[0].className) {
      var classes = element[0].className.split(/\s/);
      for (var i = 0; i < classes.length; i++) {
        str += '.' + classes[i];
      }
    }
    return this.getQuerySelector(element[0].parentNode) + ' > ' + str;
  },

  /**
   * @problem: Sometimes .cloneNode(true) doesn't copy the styles and your are left
   * with everything copied but no styling applied to the clonedNode (it looks plain / ugly).
   *
   * @solution: call synchronizeCssStyles to copy styles from source (src) element to
   * destination (dest) element.
   */
  adoptComputedStyle(src, dest, recursively) {
    // if recursively = true, then we assume the src dom structure and destination dom structure are identical (ie: cloneNode was used)

    // get HTMLElements
    src = this.getHTMLElement(src);
    dest = this.getHTMLElement(dest);

    // window.getComputedStyle vs document.defaultView.getComputedStyle

    let copyComputedStyle = function (from, to) {
      let computed_style_object = false;
      //trying to figure out which style object we need to use depense on the browser support
      //so we try until we have one
      computed_style_object =
        from.currentStyle || document.defaultView.getComputedStyle(from, null);

      //if the browser dose not support both methods we will return null
      if (!computed_style_object) return null;

      let stylePropertyValid = function (name, value) {
        //checking that the value is not a undefined
        return (
          typeof value !== 'undefined' &&
          //checking that the value is not a object
          typeof value !== 'object' &&
          //checking that the value is not a function
          typeof value !== 'function' &&
          //checking that we dosent have empty string
          value.length > 0 &&
          //checking that the property is not int index ( happens on some browser
          value != parseInt(value)
        );
      };

      //we iterating the computed style object and compy the style props and the values
      Array.from(computed_style_object).forEach((key) => {
        //checking if the property and value we get are valid since browser have different implementations
        if (stylePropertyValid(key, computed_style_object[key])) {
          //applying the style property to the target element
          return to.style.setProperty(
            key,
            computed_style_object.getPropertyValue(key),
            computed_style_object.getPropertyPriority(key),
          );
        }
      });
    };

    let copyRecursively = function (from, to) {
      copyComputedStyle(from, to);

      let srcElements = from.getElementsByTagName('*');
      let dstElements = to.getElementsByTagName('*');

      for (let i = srcElements.length; i--; ) {
        let srcElement = srcElements[i];
        let dstElement = dstElements[i];
        //  console.log(i + " >> " + vSrcElement + " :: " + vDstElement);
        copyComputedStyle(srcElement, dstElement);
      }
    };

    if (recursively) {
      copyRecursively(src, dest);
    } else {
      copyComputedStyle(src, dest);
    }

    return dest;
  },

  getHTMLElement(element) {
    if (element instanceof HTMLElement) {
      return element;
    } else {
      return $$(element)[0];
    }
  },
};
export default domHelper;
