let importHelper = {
  misctexts: require('../download/dictionary/misctexts'),
  hometexts: require('../download/dictionary/hometexts'), // TODO: add future jsons
  logintexts: require('../download/dictionary/loginregistertexts'),
  profiletexts: require('../download/dictionary/profiletexts'),
  settingtexts: require('../download/dictionary/settingtexts'),
};

var dictionary = {
  language: null,

  _defaultModule: 'misctexts',

  /**
   * Returns text as string from an imported json. The wanted text is identified by a key inside the json.
   * the key has the following format:
   *
   * key: this-is-a-key
   *
   * json: {
   *     "this-is-a-key": "this is the wanted text"
   * }
   *
   * or
   *
   * json: {
   *     "this":{
   *         "is": {
   *             "a": {
   *                 "key": "this is the wanted text"
   *             }
   *         }
   *     }
   * }
   *
   * or
   *
   * a mixture of both
   *
   * @param module the key for imported module
   * @param key the key to identify the wanted text.
   * @return {*|boolean} the text or undefined, if not found
   */
  getText: function (module, key) {
    let text;
    if (module && key) {
      text = this._getTextFromModule(this._getModule(module), key.split('-'));
    } else if (!key) {
      key = module;
    }
    return text ? text : this.getTextFromDefaultModule(key);
  },

  getTextFromDefaultModule: function (key) {
    return this._getTextFromModule(
      this._getModule(this._defaultModule),
      key.split('-'),
    );
  },

  /**
   * Tags with the class attribute 'data-dic-key', will use this attribute to look up strings in the dictionary.
   * Loads strings from given module or else from default module.
   *
   * @param module to load strings from
   * @param dom dom to load strings into
   */
  loadModuleToDOM: function (module, dom) {
    let _this = this;
    dom.find('[data-dic-key]').each(function () {
      this.innerHTML = _this.getText(module, this.dataset.dicKey);
    });
    return dom;
  },

  _getModule: function (module) {
    module = module.replace('/', '-');
    if (importHelper[module]) {
      return importHelper[module]; // cant require dynamically
    } else {
      console.error('Dictionary: Cannot find module ' + module);
    }
    return false;
  },

  _getTextFromModule: function (module, key) {
    let combinedKey = key.join('-');
    if (module.hasOwnProperty(combinedKey)) {
      return module[combinedKey];
    }
    for (let index = key.length - 1; index > 0; index--) {
      let combinedKey = key.slice(0, index).join('-');
      if (module.hasOwnProperty(combinedKey)) {
        return this._getTextFromModule(module[combinedKey], key.slice(index));
      }
    }
    return undefined; //not found
  },
};
export default dictionary;
