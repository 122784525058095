import Note from './Note.js';
import Goal from './Goal.js';
import Keydate from './Keydate.js';
import { v4 as uuidv4 } from 'uuid';

var Notebook = {
  notes: [],
  noteNumbers: [],
  goals: [],
  goalNumbers: [],
  keydates: [],
  keydateNumbers: [],
  // Loads all the Notes and Goals from localStorage
  init: function () {
    this.initGoals();
    this.initNotes();
    this.initKeydates();
    this._convertNotebookObjects();
  },
  initGoals: function () {
    this.goals = [];

    let storedGoals = JSON.parse(localStorage.getItem('goalNumbers'));
    if (storedGoals != null) {
      this.goalNumbers = storedGoals;
    }
    for (let goal of this.goalNumbers) {
      this.goals[goal] = new Goal(goal);
    }
  },
  initNotes: function () {
    this.notes = [];
    let storedNotes = JSON.parse(localStorage.getItem('noteNumbers'));
    if (storedNotes != null) {
      this.noteNumbers = storedNotes;
    }
    for (let noteNumber of this.noteNumbers) {
      let note = new Note(noteNumber);
      note.load();
      this.notes.push(note);
    }
  },
  initKeydates: function () {
    this.keydates = [];

    let storedKeydates = JSON.parse(localStorage.getItem('keydateNumbers'));
    if (storedKeydates != null) {
      this.keydateNumbers = storedKeydates;
    }
    for (let keydateNumber of this.keydateNumbers) {
      let keydate = new Keydate(keydateNumber);
      keydate.load();
      this.keydates.push(keydate);
    }
  },
  clear: function () {
    this.notes = [];
    this.noteNumbers = [];
    this.goals = [];
    this.goalNumbers = [];
    this.keydates = [];
    this.keydateNumbers = [];
  },
  createNote: function () {
    let number = uuidv4();
    let note = new Note(number);
    this.noteNumbers.push(number);
    this.notes.push(note);
    localStorage.setItem('noteNumbers', JSON.stringify(this.noteNumbers));
    return note;
  },
  deleteNote: function (note) {
    this.noteNumbers.forEach((val, index) => {
      if (val === note.number) {
        Notebook.noteNumbers.splice(index, 1);
      }
    });
    localStorage.setItem('noteNumbers', JSON.stringify(this.noteNumbers));
    for (let key in this.notes) {
      if (this.notes[key]) {
        if (this.notes[key].number === note.number) {
          this.notes.splice(key, 1);
        }
      }
    }
    note.delete();
    note = null;
  },
  setNotes: function (notes) {
    this.notes = notes;
    this.noteNumbers = [];
    for (let note of this.notes) {
      this.noteNumbers.push(note.number);
    }
  },
  setNoteNumber: function (note, newNumber) {
    this.noteNumbers.forEach((val, index) => {
      if (val === note.number) {
        Notebook.noteNumbers.splice(index, 1);
      }
    });
    note.number = Number(newNumber);
    this.noteNumbers.push(newNumber);
    localStorage.setItem('noteNumbers', JSON.stringify(this.noteNumbers));
  },
  getNote: function (number) {
    for (let key in this.notes) {
      if (this.notes[key]) {
        if (this.notes[key].number === number) {
          return this.notes[key];
        }
      }
    }
    return null;
  },
  getNotes: function () {
    return this.notes;
  },
  getKeydates: function () {
    return this.keydates;
  },
  // Create a new Keydate-Object and saves it to LocalStorage
  createKeydate: function () {
    let number = uuidv4();
    let keydate = new Keydate(number);
    keydate.save();
    this.keydates.push(keydate);
    this.keydateNumbers.push(number);
    localStorage.setItem('keydateNumbers', JSON.stringify(this.keydateNumbers));
    return keydate;
  },
  deleteKeydate: function (keydate) {
    this.keydateNumbers.forEach((val, index) => {
      if (val === keydate.number) {
        Notebook.keydateNumbers.splice(index, 1);
      }
    });
    localStorage.setItem('keydateNumbers', JSON.stringify(this.keydateNumbers));
    this.keydates.forEach((val, index) => {
      if (val.number === keydate.number) {
        Notebook.keydates.splice(index, 1);
      }
    });
    keydate.delete();
  },
  getNextAvailableKeydateNumber: function () {
    let available = 1;
    while (this.keydateNumbers.filter((num) => num === available).length > 0) {
      available++;
    }
    return available;
  },
  getKeydate: function (number) {
    for (let key in this.keydates) {
      if (this.keydates[key]) {
        if (this.keydates[key].number === number) {
          return this.keydates[key];
        }
      }
    }
    return null;
  },
  addGoal: function (goal) {
    this.goalNumbers.push(goal.number);
    this.goals.push(goal);
    localStorage.setItem('goalNumbers', JSON.stringify(this.goalNumbers));
  },
  removeGoal: function (number) {
    this.goalNumbers.forEach((val, index) => {
      if (val === number) {
        Notebook.goalNumbers.splice(index, 1);
      }
    });
    this.goals.forEach((val, index) => {
      if (val.number === number) {
        Notebook.goals.splice(index, 1);
      }
    });
    localStorage.setItem('goalNumbers', JSON.stringify(this.goalNumbers));
  },
  getGoalByNumber: function (number) {
    for (let key in this.goals) {
      if (this.goals[key]) {
        if (this.goals[key].number === number) {
          return this.goals[key];
        }
      }
    }
    return null;
  },
  getAllCreatedGoals: function () {
    let goals = [];
    for (var index in this.goals) {
      let current = this.goals[index];
      if (current && current.created) {
        goals.push(current);
      }
    }
    return goals;
  },
  getNextAvailableNoteNumber: function () {
    let available = 1;
    while (this.noteNumbers.filter((num) => num === available).length > 0) {
      available++;
    }
    return available;
  },
  _convertNotebookObjects: function () {
    if (!localStorage.getItem('notebook-objects-converted')) {
      let goalIDs = [],
        noteIDs = [],
        keydateIDs = [];
      for (let goal of this.goals) {
        if (goal) {
          goal.number = uuidv4();
          goal.save();
          goalIDs.push(goal.number);
          localStorage.setItem('goalNumbers', JSON.stringify(goalIDs));
        }
      }
      for (let keydate of this.keydates) {
        if (keydate) {
          keydate.number = uuidv4();
          keydate.save();
          keydateIDs.push(keydate.number);
          localStorage.setItem('keydateNumbers', JSON.stringify(keydateIDs));
        }
      }
      for (let note of this.notes) {
        if (note) {
          note.number = uuidv4();
          note.save();
          noteIDs.push(note.number);
          localStorage.setItem('noteNumbers', JSON.stringify(noteIDs));
        }
      }
      localStorage.setItem('notebook-objects-converted', 'true');
    }
  },
};

export default Notebook;
