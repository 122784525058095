function Note(number) {
  this.title = '';
  this.content = '';
  this.number = number;
  this.step = 0;
  this.creation_time = new Date().getTime();
  this.last_modified = this.creation_time;
  this.last_synced = 1;
}

Note.prototype.getTitle = function () {
  return this.title;
};

Note.prototype.setTitle = function (title) {
  this.title = title;
  this.save();
};

// Returns all content
Note.prototype.getContent = function () {
  return this.content;
};

// Sets all content
Note.prototype.setContent = function (content) {
  this.content = content;
  this.save();
};

// Adds a single entry to content
Note.prototype.addContent = function (content) {
  this.content += content;
  this.save();
};

// Saves content of Note to LocalStorage
Note.prototype.save = function () {
  this.last_modified = new Date().getTime();
  var toSave = {};
  for (var key in this) {
    toSave[key] = this[key];
  }
  toSave.last_modified = Date.now();
  toSave._id = this.number;
  localStorage.setItem(this.number, JSON.stringify(toSave));
};

// Deletes Note in LocalStorage
Note.prototype.delete = function () {
  localStorage.removeItem(this.number);
};

// returns whether this note is empty and has no title
Note.prototype.isEmpty = function () {
  return this.content === '' && this.title === '';
};

// Loads content of Note from localStorage
Note.prototype.load = function () {
  let toLoad = JSON.parse(localStorage.getItem(this.number));
  if (!toLoad)
    toLoad = JSON.parse(localStorage.getItem('Note: ' + this.number));
  for (var key in toLoad) {
    this[key] = toLoad[key];
  }
  if (toLoad && !toLoad._id) this.save();
};

module.exports = Note;
