import $$ from 'dom7';
import expertiseView from './ExpertiseView';

const unrankedView = {
  init: function (expertise, count, path, id, del) {
    expertiseView.loadTitle(expertise.subtitle);
    expertiseView.loadMainToolbar();
    expertiseView.loadMainFAB();

    $$('#expertise-entries').addClass('hide');
    $$('#expertise-page-content .filter-cards').addClass('hide');

    $$('.expertise-card-unranked').remove();

    let card = $$(document.createElement('div')).addClass(
      'card margin-bottom expertise-card-unranked',
    ).html(`
                <div class="card-header black">Meine Selbsteinschätzung</div>
                <div class="card-content"><ol></ol></div>
            `);
    let list = card.find('ol');
    for (let entry of expertise.entries[0]) {
      list.append($$(document.createElement('li')).html(entry));
    }

    if (expertise.entries[0].length > 0) {
      card.insertAfter($$('#expertise-entries'));
    }

    card = $$(document.createElement('div')).addClass(
      'card margin-bottom expertise-card-unranked',
    ).html(`
                <div class="card-header black">Meine Fremdeinschätzung</div>
                <div class="card-content"><ol></ol></div>
            `);

    list = card.find('ol');
    for (let entry of expertise.entries[1]) {
      list.append($$(document.createElement('li')).html(entry));
    }

    if (expertise.entries[1].length > 0) {
      card.insertAfter($$('#expertise-entries'));
    }

    expertiseView.loadExternalCounter(count);
    expertiseView.linkButton(path, id, del);
  },
};

export default unrankedView;
