//import controller from "./educationPathController";
import taskView from '../../TaskView';
import $$ from 'dom7';
import elementFactory from '../../../../../../core/ui/ElementFactory';
import app from '../../../../../../app';

let controller;

var resultsView = {
  task: null,

  moduleNumber: null,

  init: function (cntrllr, task, moduleNumber, result) {
    this.moduleNumber = moduleNumber;
    controller = cntrllr;
    this.task = task;
    taskView.loadTitle('Deine Ergebnisse');
    this.loadButtons(moduleNumber);
    this.loadContent(result);
  },

  loadContent: function (result) {
    $$('#task-page-content')[0].scrollTop = 0;
    const content = $$('#task-content');
    content.empty();
    content.append(this.getGraphic(result));
  },

  loadButtons: function (moduleNumber) {
    //save
    let saveButton = $$(document.createElement('button'))
      .addClass('button button-fill color-white')
      .append('Ergebnis als Notiz abspeichern')
      .on('click', (e) => {
        controller.copyResultToNote();
      });
    // next-Button
    let nextButton = $$(document.createElement('button'));
    nextButton.addClass('button button-fill color-white');
    nextButton.append('Nächstes Thema');
    nextButton.on('click', (e) => {
      controller.nextExercise();
    });
    const buttonList = $$('#task-bottom .button-list').removeClass(
      'horizontal',
    );
    buttonList.empty();
    buttonList.append(saveButton);
    buttonList.append(nextButton);

    // FAB
    let fab = $$(document.createElement('div'));
    fab.addClass('main-fab');
    fab.insertBefore('#task-page-content');
    app.loadMainFAB(fab, moduleNumber);
  },

  getGraphic: function (result, external) {
    let gauge = elementFactory.getGauge(result.titles, result.results);
    if (!external) {
      return gauge;
    }
    return $$(document.createElement('div'))
      .addClass('white-card external-result')
      .append(gauge);
  },
};

export default resultsView;
