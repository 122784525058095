import $$ from 'dom7';
import elementFactory from '../../../../../../core/ui/ElementFactory';
import introView from '../../IntroView';

let controller;

var resultsView = {
  init: function (cntrllr, text, results) {
    controller = cntrllr;
    this.loadTop();
    this.loadContent(text, results);
    this.loadBottom();
    this.loadBackNavigation();
  },

  loadTop: function () {
    // scroll up
    $$('#task-page-content')[0].scrollTop = 0;

    $$('#task-page-content .page-content-top')
      .empty()
      .append(
        elementFactory
          .getProgressbar(controller.getProgress())
          .attr('id', 'jobrating-progress'),
      ).append(`
                <h1 id="task-title" class="page-title">Berufe-Rating</h1>
                <h2 id="task-subtitle" class="page-subtitle"></h2>
            `);
  },

  loadContent: function (text, results) {
    this._loadGraphic(results, $$('#task-content').empty().append(text));
  },

  loadBottom: function () {
    let continueTask = $$(document.createElement('button'))
      .addClass('button button-fill color-white')
      .append(
        controller.hasNextRound() ? 'Übung fortführen' : introView.INFO_TEXT,
      )
      .on('click', () => {
        controller.continueTask();
      });

    let profile = $$(document.createElement('button'))
      .addClass('button button-fill color-white')
      .append('zum Profil')
      .on('click', () => {
        controller.goToProfile();
      });

    $$('#task-bottom .button-list')
      .empty()
      .append(continueTask)
      .append(profile);
  },

  loadBackNavigation: function () {
    const back = $$('.page[data-name="task"] .navbar .left a.link')
      .off('click')
      .removeClass('back');
    back.on('click', () => {
      back.off('click').addClass('back');
      controller.previous();
    });
  },

  getGraphic: function (results) {
    return this._loadGraphic(
      results,
      $$(document.createElement('div')).addClass(
        'external-result jobrating-external-result white-card',
      ),
    );
  },

  _loadGraphic: function (results, content) {
    results.forEach((result) => {
      let card = $$(document.createElement('div')).addClass(
        'jobranking-card-line-small card card-line-small list',
      ).append(`
                    <div class="card-content accordion-item">
                        <a href="#" class="item-content item-link">
                            <div class="item-inner">
                                <span class="item-title">
                                    <span class="title">${result.title}</span>
                                    <span class="subtitle">(${result.percent}%)</span>
                                </span>
                            </div>
                        </a>
                        <div class ="accordion-item-content">
                            <div class="content"></div>
                        </div>
                    </div>
            `);
      card.find('.content').append(result.text);

      content.append(card);
    });

    return content;
  },
};
export default resultsView;
