import $$ from 'dom7';
import app from '../app';

class PDF {
  contents = [];
  head = {};
  static getImagePath = (path) => _getImage(path);

  constructor(title) {
    this.setTitle(title ? title : 'Deep');
  }

  setTitle(title) {
    this.head.title = title;
    return this;
  }

  setCharset(charset) {
    this.head.charset = charset;
    return this;
  }

  addStylesheet(uri) {
    if (!this.head.stylesheets) {
      this.head.stylesheets = [];
    }
    this.head.stylesheets.push(uri);
    return this;
  }

  addStyles(styles) {
    if (!this.head.styles) {
      this.head.styles = '';
    }
    this.head.styles += styles;
    return this;
  }

  setIcon(uri) {
    this.head.icon = uri;
    return this;
  }

  addContent(content) {
    if (Array.isArray(content)) {
      for (let element of content) {
        this.addContent(element);
      }
    } else if (typeof content === 'string') {
      this.contents.push(content);
    } else {
      this.contents.push(
        $$(document.createElement('div')).append($$(content)).html(),
      );
    }
    return this;
  }

  addPage(content) {
    let agent = app.getUserAgent(); // different a4 heights on different agents
    this.contents.push(
      '<div class="page' + (agent ? ' page-user-agent-' + agent : '') + '">\n',
    );
    this.addContent(content);
    this.contents.push('</div>\n');
    return this;
  }

  addPageBreak() {
    this.contents.push('<div class="page-break"></div>\n');
    return this;
  }

  export() {
    let html = '';
    html += '<!DOCTYPE html>\n<html lang="de">\n';
    // head
    html += '<head>\n<base href="' + document.documentURI + '">\n';
    for (let attr in this.head) {
      if (this.head.hasOwnProperty(attr)) {
        switch (attr) {
          default:
            break;
          case 'title':
            html += '<title>' + this.head[attr] + '</title>\n';
            break;
          case 'stylesheets':
            for (let stylesheet of this.head[attr]) {
              if (app.device.android) {
                html +=
                  '<link rel="stylesheet" href="' +
                  _getStylesheet(stylesheet, 'android') +
                  '">\n';
                html +=
                  '<link rel="stylesheet" href="' +
                  _getStylesheet(stylesheet) +
                  '">\n';
              } else if (app.device.ios) {
                html +=
                  '<link rel="stylesheet" href="' +
                  _getStylesheet(stylesheet, 'ios') +
                  '">\n';
                html +=
                  '<link rel="stylesheet" href="' +
                  _getStylesheet(stylesheet) +
                  '">\n';
              } else {
                html +=
                  '<link rel="stylesheet" href="' +
                  _getStylesheet(stylesheet) +
                  '">\n';
              }
            }
            break;
          case 'styles':
            html += '<style>' + this.head[attr] + '</style>\n';
            break;
          case 'icon':
            if (app.device.android) {
              html +=
                '<link rel="icon" href="' +
                _getImage(this.head[attr], 'android') +
                '">';
              html +=
                '<link rel="icon" href="' + _getImage(this.head[attr]) + '">';
            } else if (app.device.ios) {
              html +=
                '<link rel="icon" href="' +
                _getImage(this.head[attr], 'ios') +
                '">';
              html +=
                '<link rel="icon" href="' + _getImage(this.head[attr]) + '">';
            } else {
              html +=
                '<link rel="icon" href="' + _getImage(this.head[attr]) + '">';
            }
            break;
          case 'charset':
            html += '<meta charset="' + this.head[attr] + '">';
            break;
        }
      }
    }
    html += '</head>\n';
    // body
    html += '<body>\n';
    for (let content of this.contents) {
      html += content;
    }
    html += '</body>\n';
    html += '</html>';

    let mobile = true;
    try {
      // mobile
      cordova.plugins.pdf
        .fromData(html, {
          documentSize: 'A4',
          landscape: 'portrait',
          type: 'share',
          fileName: this.head.title + '.pdf',
        })
        .then((status) => (status === 'ok' ? 'ok' : (mobile = false)))
        .catch(() => (mobile = false));
    } catch (e) {
      mobile = false;
    }
    if (!mobile) {
      // browser
      let newWindow = window.open();
      newWindow.onafterprint = () => {
        newWindow.close();
      };
      let newDocument = new DOMParser().parseFromString(html, 'text/html');
      newWindow.document.replaceChild(
        newWindow.document.importNode(newDocument.documentElement, true),
        newWindow.document.documentElement,
      );
      // listen for css loading
      let cssnum = newWindow.document.styleSheets.length;
      let ti = setInterval(function () {
        if (newWindow.document.styleSheets.length > cssnum) {
          console.log('loaded');
          setTimeout(() => {
            newWindow.print();
          }, 1);
          clearInterval(ti);
        }
      }, 10);
    }
  }
}

export default PDF;

// paths differ between platforms
let _getStylesheet = function (path, platform) {
  if (!path.startsWith('/')) {
    path = '/' + path;
  }
  switch (platform) {
    default:
    case 'browser':
      return '.' + path;
    case 'android':
      return 'file:///android_asset/www' + path;
    case 'ios':
      return 'www' + path;
  }
};
let _getImage = function (path, _mobile) {
  //TODO: not working!
  return _getStylesheet(path, _mobile);
};

// create print listeners
let beforePrint = function () {};
let afterPrint = function () {};
if (window.matchMedia) {
  let mediaQueryList = window.matchMedia('print');
  mediaQueryList.addListener(function (mql) {
    if (mql.matches) {
      beforePrint();
    } else {
      afterPrint();
    }
  });
}
window.onbeforeprint = beforePrint;
window.onafterprint = afterPrint;
