import $$ from 'dom7';
import elementFactory from '../../../../../../core/ui/ElementFactory';

let controller;

var resultsView = {
  init: function (cntrllr, result) {
    controller = cntrllr;
    this.loadContent(result);
    this.loadButtons();
  },

  loadContent: function (result) {
    $$('#task-page-content')[0].scrollTop = 0;
    let content = $$('#task-content');
    content.empty();

    let graphic = this.getGraphic(result);

    //TODO: avg displayed correctly in firefox?
    content.append(`
            <div id="coursecatalog-lecture-results-intro">Du bist nun alle Veranstaltungen aus dem ersten Semester durchgegangen. Anhand deiner Bewertungen kannst du selbst entscheiden, ob dieser Studiengang zu dir passt oder nicht.</div>
            <div id="coursecatalog-lecture-results-avg">
                <span>Ø ${('' + result.avg).replace('.', ',')}</span>
                <span>(${graphic.answers[Math.round(result.avg) - 1]})</span>
            </div>
        `);
    content.append(graphic.gauge);
  },

  loadButtons: function () {
    let moreInfoButton = $$(document.createElement('button'));
    moreInfoButton.addClass('button button-fill color-white');
    moreInfoButton.append('mehr Info');
    moreInfoButton.on('click', () => {
      controller.moreInfo();
    });
    let repeatButton = $$(document.createElement('button'));
    repeatButton.addClass('button button-fill color-white');
    repeatButton.append('wiederholen');
    repeatButton.on('click', () => {
      controller.displayIntro();
    });

    const list = $$('#task-bottom .button-list');
    list.empty();
    list.addClass('horizontal shadowed');
    list.append(moreInfoButton);
    list.append(repeatButton);
  },

  getGraphic: function (result, external) {
    if (external) {
      let gauge = elementFactory.getGauge(
        result.titles,
        result.values,
        external ? null : 'Zu den Veranstaltungen gehe ich hin....',
      );

      return $$(document.createElement('div'))
        .addClass('white-card external-result coursecatalog')
        .append(gauge);
    } else {
      let answers = [
        'auf jeden Fall',
        'eher',
        'unter Umständen',
        'eher nicht',
        'gar nicht',
      ];

      let gauge = elementFactory.getGauge(
        answers,
        result.results,
        external ? null : 'Zu den Veranstaltungen gehe ich hin....',
      );

      return {
        gauge: gauge,
        answers: answers,
      };
    }
  },
};

export default resultsView;
