import $$ from 'dom7';
import mediaController from '../../../../../../core/MediaController';

let controller;

const feedbackView = {
  init: function (cntrllr, link) {
    controller = cntrllr;
    this.loadQR(link);
    this.loadButtons();
  },

  loadQR: function (link) {
    $$('#task-content')
      .empty()
      .append(
        $$(document.createElement('div'))
          .attr('id', 'feedback360-qr-code')
          .append(mediaController.getQRCodeFromLink(link, '151C23', 'F5F5F5'))
          .append(
            '<input type="hidden" value="' + link + '" id="feedback360-link">',
          ),
      );
  },

  loadButtons: function () {
    const list = $$('#task-bottom > .button-list');
    list.empty();

    list.append(
      $$(document.createElement('button'))
        .addClass('button button-fill color-white')
        .append('Link kopieren')
        .on('click', () => {
          controller.copyLink();
        }),
    );

    list.append(
      $$(document.createElement('button'))
        .addClass('button button-fill color-white')
        .append('Nächstes Thema')
        .on('click', () => {
          controller.next();
        }),
    );
  },
};
export default feedbackView;
