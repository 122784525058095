import $$ from 'dom7';
import app from '../../../app.js';
import dateController from '../../../core/DateController';

let controller;

var noteeditorView = {
  note: null,
  editor: null,
  mode: null,
  step: 0,

  init: function (cntrllr, note, allSteps, newNote) {
    this.mode = null;
    this.note = note;
    controller = cntrllr;
    this.loadMainToolbar();
    this.loadStepsContext(allSteps);
    if (newNote) {
      this.enableCreateMode();
    } else {
      this.enableDisplayMode();
    }
  },

  loadMainToolbar: function () {
    app.loadMainToolbar($$('.page[data-name="noteeditor"] .main-toolbar'));
  },

  loadStepsContext: function (allSteps) {
    let steps = $$('#noteeditor-popover-steps ul');
    steps.empty();
    let noStep = $$(document.createElement('li'));
    noStep.append(`
                <a class="list-button item-link" href="#">kein Schritt</a>
            `);
    noStep.find('a').on('click', () => {
      noteeditorView.step = 0;
      noteeditorView.loadStep(noteeditorView.step);
      app.popover.close('#noteeditor-popover-steps');
    });
    steps.append(noStep);
    allSteps.forEach((step) => {
      let element = $$(document.createElement('li'));
      element.append(`
                <a class="list-button item-link" href="#">Schritt ${step}</a>
            `);
      element.find('a').on('click', () => {
        noteeditorView.step = step;
        noteeditorView.loadStep(noteeditorView.step);
        app.popover.close('#noteeditor-popover-steps');
      });
      steps.append(element);
    });
  },

  enableDisplayMode: function () {
    if (this.mode === 'display') {
      return;
    }
    this.closeEditor();
    this.loadNormalNavbar();
    this.loadTitle('Meine Notiz');
    $$('#noteeditor-page-content .list').addClass('disabled-list');
    this.hideUnused();
    this.loadNote();
    this.mode = 'display';
  },

  enableEditMode: function (focusTiny) {
    if (this.mode === 'edit') {
      return;
    }
    this.loadFormNavbar(
      'Notiz bearbeiten',
      () => {
        controller.save(noteeditorView.getValues());
        controller.showDisplayMode();
        // app.views.main.router.back();
      },
      () => {
        controller.showDisplayMode();
        // app.views.main.router.back();
      },
    );
    this.loadTitle('Notiz bearbeiten');
    this.unhideAll();
    $$('#noteeditor-page-content .list').removeClass('disabled-list');
    this.loadEditor(focusTiny);
    this.loadNote();
    this.mode = 'edit';
  },

  enableCreateMode: function () {
    if (this.mode === 'create') {
      return;
    }
    this.loadFormNavbar(
      'Notiz erstellen',
      () => {
        controller.save(noteeditorView.getValues());
        controller.showDisplayMode();
      },
      () => {
        app.views.main.router.back();
      },
    );
    this.loadTitle('Neue Notiz');
    this.unhideAll();
    $$('#noteeditor-page-content .list').removeClass('disabled-list');
    this.loadNote();
    this.loadEditor();
    this.mode = 'create';
  },

  loadNormalNavbar: function () {
    const navbar = $$('.page[data-name="noteeditor"] .navbar');
    navbar.removeClass('form-navbar');
    navbar.empty();
    navbar.addClass('navbar-transparent no-hairline');
    navbar.append(`
            <div class="navbar-inner">
                <div class="left">
                    <a class="link back">
                        <i class="icon material-icons">navigate_before</i>
                    </a>
                </div>
                <div class="right">
                    <a class="link popover-open" href="#" data-popover="#noteeditor-popover-menu">
                        <i class="icon material-icons">more_vert</i>
                    </a>
                </div>
            </div>
        `);
    $$('#noteeditor-popover-delete').on('click', () => {
      app.popover.close('#noteeditor-popover-menu');
      app.dialog.confirm('Notiz wirklich löschen?', function () {
        controller.delete();
        app.views.main.router.back();
      });
    });
    $$('#noteeditor-popover-export').on('click', () => {
      app.popover.close('#noteeditor-popover-menu');
      controller.export();
    });
    $$('#noteeditor-popover-edit').on('click', () => {
      app.popover.close('#noteeditor-popover-menu');
      controller.showEditMode();
    });
  },

  loadFormNavbar: function (title, onReady, onCancel) {
    const navbar = $$('.page[data-name="noteeditor"] .navbar');
    navbar.removeClass('navbar no-shadow navbar-transparent no-hairline');
    navbar.empty();
    app.loadFormNavbar(navbar, title, onReady, onCancel);
  },

  loadTitle: function (title) {
    $$('#noteeditor-title').empty();
    $$('#noteeditor-title').append(title);
  },

  loadNote: function () {
    let contentEl = $$('#noteeditor-input-content');
    contentEl.empty();
    contentEl.append(this.note.getContent());
    let links = contentEl.find('a');
    links.each((index, link) => {
      link = $$(link);
      link.on('click', (event) => {
        $$('#noteeditor-input-content').blur();
        controller.openExternalLink(link.attr('href'), event);
      });
    });
    let created = $$('#noteeditor-created');
    created.empty();
    created.append(
      this.note.creation_time
        ? dateController.getRelativeStringFromDate(this.note.creation_time) +
            ' um ' +
            dateController.getStringFromTime(this.note.creation_time)
        : '',
    );
    noteeditorView.loadStep(this.note.step);
  },

  loadStep: function (step) {
    let stepEl = $$('#noteeditor-step');
    stepEl.empty();
    stepEl.append('Schritt' + (step > 0 ? ' ' + step : ''));
  },

  getValues: function () {
    let title = null;
    $$('#noteeditor-page-content iframe').each((index, element) => {
      title = $$(element.contentWindow.document)
        .find('#tinymce')
        .children(':first-child')
        .text();
    });
    return {
      step: noteeditorView.step,
      content: noteeditorView.editor.getContent(),
      title: title ? title : 'Neue Notiz',
    };
  },

  loadEditor: function (focus) {
    //to access plugins and skins while running
    tinyMCE.baseURL = './static/tinymce';

    //skin and content_css need to be false, if css is not loaded with webpack.
    tinyMCE.init({
      selector: '#noteeditor-input-content',
      menubar: false,
      plugins: [
        'advlist autolink image lists spellchecker table emoticons link',
      ], //autolink not working for mobile for some reason
      toolbar:
        'undo redo | bold italic underline strikethrough | ' +
        'alignleft aligncenter alignright alignjustify | ' +
        'bullist numlist outdent indent | table image unlink | ' +
        'fontselect fontsizeselect | formatselect | forecolor backcolor',
      contextmenu: false, //"copy paste cut" -> paste not working with f7
      toolbar_mode: 'wrap',
      statusbar: false,
      height: '60vh',
      mobile: {
        toolbar:
          'undo redo | bold italic underline strikethrough | ' +
          'alignleft aligncenter alignright alignjustify | ' +
          'bullist numlist outdent indent | link unlink |' +
          'fontselect fontsizeselect | formatselect | forecolor backcolor',
        toolbar_mode: 'sliding',
      },
      extended_valid_elements:
        'a[rel|rev|charset|hreflang|tabindex|accesskey|type|name|href|target|title|class=external|onfocus|onblur],svg[*],defs[*],pattern[*],desc[*],metadata[*],g[*],mask[*],path[*],line[*],marker[*],rect[*],circle[*],ellipse[*],polygon[*],polyline[*],linearGradient[*],radialGradient[*],stop[*],image[*],view[*],text[*],textPath[*],title[*],tspan[*],glyph[*],symbol[*],switch[*],use[*]',
      content_style: `
                body {
                    margin: 8px 25px;
                }
                p {
                    font-family: Mukta-Mahee-Regular, sans-serif;
                    font-size: 16px;
                    letter-spacing: 0.01px;
                    line-height: 26px;
                    color: #151C23 !important;
                    
                    margin: 0;
                }
                .noteeditor-no-show {
                    pointer-events: none !important;
                    background-color: #F5F5F5;
                    
                    width: 100%;
                    height: 50px;
                    
                    border-radius: 10px;
                    box-shadow: 0 0 6px #00000029;
                    
                    margin-top: 5px;
                }
                .noteeditor-no-show > * {
                    display: none;
                }
                .noteeditor-no-show > *.show {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    
                    color: #151C2380;
                    font-style: italic;
                    text-align: center;
                    
                    width: 100%;
                    height: 100%;
                }
            `,
      setup: function (editor) {
        editor.on('init', function () {
          $$('.tox-tinymce-aux').insertBefore($$('#noteeditor-input-content'));
          if (focus) {
            $$('#noteeditor-input-content_ifr').focus();
          }
          noteeditorView.loadLinkListener();
        });
        editor.on('execcommand', function (e) {
          if (e.command === 'createlink') {
            noteeditorView.loadLinkListener();
          }
        });
      },
    });
    this.editor = tinyMCE.activeEditor;
  },

  loadLinkListener: function () {
    $$('#noteeditor-input-content_ifr').each((index, element) => {
      $$(element.contentWindow.document)
        .find('a')
        .each((ix, el) => {
          // open links external
          $$(el).on('click', function (event) {
            controller.openExternalLink($$(this).attr('href'), event);
          });
        });
    });
  },

  closeEditor: function () {
    if (this.editor) {
      tinyMCE.remove();
      this.editor = null;
    }
  },

  hideUnused: function () {
    let input = $$('#noteeditor-input-content');
    input.on('click', () => {
      controller.showEditMode(true);
    });

    let step = $$('#keydate-steps-filter a');
    step.addClass('noteeditor-disabled');
    step.on('click', () => {
      controller.showEditMode();
    });
  },

  unhideAll: function () {
    $$('#keydate-steps-filter a').removeClass('noteeditor-disabled');
  },

  insertToEditor: function (element, showInEditor) {
    if (this.mode === 'edit' || this.mode === 'create') {
      let container = $$(
        document.createElement(typeof element === 'string' ? 'p' : 'div'),
      ).append(element);
      if (!showInEditor) {
        container.addClass('noteeditor-no-show');
        container.append(`
                    <div class="show">Ergebnis</div>
                `);
      }

      $$('#noteeditor-page-content iframe').each((index, iframe) => {
        $$(iframe.contentWindow.document).find('#tinymce').append(container);
      });
    }
  },

  emptyEditor: function () {
    if (this.mode === 'edit' || this.mode === 'create') {
      $$('#noteeditor-page-content iframe').each((index, iframe) => {
        $$(iframe.contentWindow.document).find('#tinymce').empty();
      });
    }
  },
};
export default noteeditorView;
