import $$ from 'dom7';

let controller;

const resultsView = {
  count: 0,

  init: function (cntrllr, answers, outro) {
    this.setController(cntrllr);
    this.count = 0;
    this.loadText(outro);
    this.loadContent(answers);
    this.loadButtons();
  },

  init20Gummybears: function (answers) {
    this.count++;
    this.loadContent(answers);
    $$('#task-gummybear-button-details').remove();
    $$(document.createElement('div'))
      .addClass('task-gummybear-result-heading')
      .append('Ergebnisse mit 10 Gummibärchen')
      .insertBefore($$('#task-gummybear-result-card-0'));
    $$(document.createElement('div'))
      .addClass('task-gummybear-result-heading')
      .append('Ergebnisse mit 20 Gummibärchen')
      .insertBefore($$('#task-gummybear-result-card-1'));
  },

  loadText: function (outro) {
    const content = $$('#task-content');
    content.empty().append(outro);
  },

  loadContent: function (answers) {
    $$('#task-page-content')[0].scrollTop = 0;
    $$('#task-content').append(this.getTable(answers, this.count));
  },

  getTable: function (answers, count, external) {
    let card = $$(document.createElement('div'))
      .attr('id', 'task-gummybear-result-card-' + count)
      .addClass('task-gummybear-result-card');

    if (external) {
      card.addClass('external-result');
    } else {
      card.addClass('white-card');
    }

    for (let answer in answers) {
      let gummybears = [null];
      // init gummybears
      for (let index = 1; index <= 5; index++) {
        // create gummybears
        gummybears.push(
          $$(document.createElement('i')).addClass(
            'icon svg-icon-gummybear-empty',
          ),
        );
      }

      // load answers
      for (let i = 1; i <= answers[answer].value; i++) {
        gummybears[i].addClass('selected');
      }

      // create row
      let entry = $$(document.createElement('div')).addClass(
        'task-gummybear-result-card-entry',
      );
      entry.append(`
                <span class="task-gummybear-result-card-entry-title">${answers[answer].title}</span>
                <div  class="task-gummybear-result-card-entry-content"></div>
            `);
      // add gummybears
      gummybears.forEach((gummybear) => {
        if (gummybear) {
          entry
            .find('.task-gummybear-result-card-entry-content')
            .append(gummybear);
        }
      });

      card.append(entry);
    }

    // display gummybears correctly
    card
      .find('.task-gummybear-result-card-entry-content i')
      .each((index, icon) => {
        icon = $$(icon);
        if (
          icon.hasClass('selected') &&
          icon.hasClass('svg-icon-gummybear-empty')
        ) {
          icon.removeClass('svg-icon-gummybear-empty');
          icon.addClass(
            'svg-icon-gummybear-' + controller.getRandomGummybear(),
          );
        } else if (
          !icon.hasClass('selected') &&
          !icon.hasClass('svg-icon-gummybear-empty')
        ) {
          icon.removeClass('svg-icon-gummybear-red');
          icon.removeClass('svg-icon-gummybear-lightgreen');
          icon.removeClass('svg-icon-gummybear-darkgreen');
          icon.removeClass('svg-icon-gummybear-yellowgreen');
          icon.addClass('svg-icon-gummybear-empty');
        }
      });

    return card;
  },

  loadButtons: function () {
    let next = $$(document.createElement('button'));
    next.addClass('button button-fill color-white');
    next.append('nächstes Thema');
    next.on('click', () => {
      controller.nextTopic();
    });

    let details = $$(document.createElement('button'))
      .addClass('button button-fill color-white')
      .attr('id', 'task-gummybear-button-details');
    details.append('Details');
    details.on('click', () => {
      controller.displayDetails();
    });

    const list = $$('#task-bottom .button-list');
    list.empty();
    list.addClass('horizontal shadowed');
    list.append(next);
    list.append(details);
  },

  setController: function (cntrllr) {
    controller = cntrllr;
  },
};
export default resultsView;
