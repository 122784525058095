import ratingView from './AddtestresultsView';
import app from '../../../../../../app';
import introView from '../../IntroView';
import taskView from '../../TaskView';

var addtestresultsController = {
  task: undefined,
  mode: undefined,

  init: function (task) {
    this.task = task;

    ratingView.init(this);
    taskView.loadMainFAB();
    this.displayIntro();
  },

  displayIntro: function () {
    this.mode = 'intro';
    ratingView.removeProgress();
    introView.setButtons(introView.BUTTONSET_START);
    introView.init(this, this.task);
  },

  displayOutro: function () {
    this.mode = 'outro';
    ratingView.removeProgress();
    introView.clearButtons();
    introView.addButton('Zurück zum Profil', () => this.next());
    introView.init(this, {
      name: this.task.outroTitle,
      intro: this.task.outro,
    });
  },

  displayRating: function (backwards) {
    this.mode = 'rating';
    this.rating.init(backwards);
  },

  next: function () {
    switch (this.mode) {
      case 'intro':
        this.displayRating();
        break;
      case 'outro':
        app.views.main.router.back(`/profile/`, { force: true });
        break;
      case 'rating':
        this.rating.next();
        break;
      default:
        break;
    }
  },

  previous: function () {
    switch (this.mode) {
      case 'intro':
        app.views.main.router.back();
        break;
      case 'outro':
        this.displayRating(true);
        break;
      case 'rating':
        this.rating.previous();
        break;
      default:
        break;
    }
  },

  save: function (index, value) {
    this.rating.save(index, value);
  },

  rating: {
    current: undefined,

    init: function (backwards) {
      if (!backwards) {
        this.current = 0;
      }
      this.load();
    },

    next: function () {
      if (this._isLast()) {
        addtestresultsController.displayOutro();
      } else {
        this.current++;
        this.load();
      }
    },

    previous: function () {
      if (this.current === 0) {
        addtestresultsController.displayIntro();
      } else {
        this.current--;
        this.load();
      }
    },

    load: function () {
      // get answers
      let dto = [];
      for (let topic of addtestresultsController.task.slides[this.current]
        .topics) {
        dto.push({
          title: topic,
          value:
            addtestresultsController.task.answer &&
            addtestresultsController.task.answer[
              addtestresultsController.task.slides[this.current].id
            ] &&
            addtestresultsController.task.answer[
              addtestresultsController.task.slides[this.current].id
            ][topic]
              ? addtestresultsController.task.answer[
                  addtestresultsController.task.slides[this.current].id
                ][topic]
              : 0,
        });
      }

      // load view
      ratingView.loadRating(
        dto,
        addtestresultsController.task.slides[this.current].title,
        (this.current / addtestresultsController.task.slides.length) * 100,
        this._isLast(),
      );
    },

    save: function (index, value) {
      if (!addtestresultsController.task.answer) {
        addtestresultsController.task.answer = {};
      }
      if (
        !addtestresultsController.task.answer[
          addtestresultsController.task.slides[this.current].id
        ]
      ) {
        addtestresultsController.task.answer[
          addtestresultsController.task.slides[this.current].id
        ] = {};
      }

      addtestresultsController.task.answer[
        addtestresultsController.task.slides[this.current].id
      ][addtestresultsController.task.slides[this.current].topics[index]] =
        value;

      this._updateAnswers();

      addtestresultsController.task.save();
    },

    _updateAnswers: function () {
      let toLocalStorage = [];
      for (let id in addtestresultsController.task.answer) {
        if (addtestresultsController.task.answer.hasOwnProperty(id)) {
          for (let key in addtestresultsController.task.answer[id]) {
            if (addtestresultsController.task.answer[id].hasOwnProperty(key)) {
              toLocalStorage.push([
                key,
                addtestresultsController.task.answer[id][key],
                addtestresultsController.task.slides.filter(
                  (slide) => slide.id === id,
                )[0].key,
              ]);
            }
          }
        }
      }
      addtestresultsController.task.answers = toLocalStorage.sort(
        (first, second) => second[1] - first[1],
      );
    },

    _isLast: function () {
      return !(this.current < addtestresultsController.task.slides.length - 1);
    },
  },
};
export default addtestresultsController;
