import $$ from 'dom7';
import touchController from '../TouchController';
import domHelper from '../domHelper';
import { v4 as uuidv4 } from 'uuid';

const elementView = {
  getFadeoutListing: function (heading, entries) {
    let list = $$(document.createElement('ol'));
    for (let entry of entries) {
      list.append(`
                <li>${entry}</li>
            `);
    }
    let container = $$(document.createElement('div')).addClass(
      'fadeout-listening',
    );
    container.append(`
            <h3>${heading}</h3>
        `);
    container.append(list);
    return container;
  },

  getQuoteCard: function (quote) {
    return $$(document.createElement('div')).addClass('white-card quote-card')
      .append(`
           <i class="icon material-icons">format_quote</i>
           ${quote}
        `);
  },

  getThemeCard: function (theme, icon, words) {
    let list = $$(document.createElement('ol'));
    list.addClass('theme-card-list');
    words.forEach((word) => {
      list.append(`<li>${word}</li>`);
    });
    return this.getThemeCardFromObj(theme, icon, list);
  },

  getThemeCardFromObj: function (theme, icon, obj) {
    let card = $$(document.createElement('div'));
    card.addClass('theme-card white-card');
    card.append(`
            <div class="theme-card-theme">
                ${
                  icon.startsWith('md ')
                    ? '<i class="icon material-icons">' + icon.slice(3) + '</i>'
                    : '<i class="icon svg-icon-' + icon + '-blue"></i>'
                }
                <span>${theme}</span>
            </div>
        `);
    card.append(obj);
    return card;
  },

  getWordCollection: function (words, green, red, arrow) {
    let container = $$(document.createElement('div')).addClass(
      'word-collection-container',
    );
    let firstNumberOfLetters = 0;
    let secondNumberOfLetters = 0;
    let rowDivs = [];
    for (let row = 0; row < 2; row++) {
      rowDivs[row] = $$(document.createElement('div'));
      rowDivs[row].addClass('word-collection-row');
      container.append(rowDivs[row]);
    }
    for (let index = 0; index < words.length; index++) {
      if (!words[index]) {
        continue;
      }
      let element = $$(document.createElement('span'));
      element.addClass('word');
      if (!(red.includes(index) && green.includes(index))) {
        if (red.includes(index)) {
          element.addClass('red-word');
        }
        if (green.includes(index)) {
          element.addClass('green-word');
        }
      }
      element.append('' + words[index]);
      if (firstNumberOfLetters <= secondNumberOfLetters) {
        firstNumberOfLetters += words[index].length;
        rowDivs[0].append(element);
      } else {
        secondNumberOfLetters += words[index].length;
        rowDivs[1].append(element);
      }
    }
    if (arrow) {
      let element = $$(document.createElement('span'));
      element.addClass('word arrow');
      element.append(`
                <i class="icon material-icons">navigate_next</i>
            `);
      element.on('click', arrow);
      rowDivs[1].append(element);
    }
    return $$(document.createElement('div'))
      .addClass('word-collection')
      .append(container);
  },

  getTitledWordCollection: function (greenTitle, redTitle, green, red) {
    let container = $$(document.createElement('div'));

    let load = function (words, color) {
      let row = $$(document.createElement('div')).addClass(
        'word-collection-row',
      );
      container.append(row);

      for (let index = 0; index < words.length; index++) {
        if (!words[index]) {
          continue;
        }
        let element = $$(document.createElement('span'));
        element.addClass('word');
        element.addClass(color + '-word');
        element.append('' + words[index]);
        row.append(element);
      }
    };

    load(green, 'green');
    load(red, 'red');

    return this._getTitledWordCollectionFrame(greenTitle, redTitle, container);
  },

  getTitledWordCollectionPlaceholder: function (greenTitle, redTitle, content) {
    return this._getTitledWordCollectionFrame(
      greenTitle,
      redTitle,
      content.addClass('word-collection-placeholder'),
    );
  },

  _getTitledWordCollectionFrame: function (greenTitle, redTitle, content) {
    return $$(document.createElement('div'))
      .addClass('word-collection')
      .append(
        '<div class="word-collection-title"><span>' +
          greenTitle +
          '</span><span>' +
          redTitle +
          '</span></div>',
      )
      .append(content.addClass('word-collection-container'));
  },

  getProgressbar: function (initValue) {
    let bar = $$(document.createElement('div'));
    bar.addClass('ci-progressbar');
    bar.append(`
            <span style="width: ${initValue != null ? initValue : 0}%;"><\span>
        `);
    return bar;
  },

  setProgressbar: function (bar, value) {
    bar
      .find('span')
      .attr('style', 'width: ' + (value != null ? value : 0) + '%;');
  },

  createSwiper: function (id) {
    let container = $$(document.createElement('div'));
    container.attr('swiper-id', id);
    container.addClass('swiper');
    return container;
  },

  getSwiperId: function (swiper) {
    return swiper.attr('swiper-id');
  },

  getSwiperCard: function (title, index) {
    let card = $$(document.createElement('div'));
    card.addClass('swiper-card');
    card.attr('index', index);
    card.attr('style', 'z-index: -' + index + ';');
    card.append(`
            <span class="swiper-card-title">${title}</span>
            <span class="swiper-card-number">${index}</span>
        `);
    return card;
  },

  initSwiperDragAndDrop: function (swiper, card, handleDrag, handleDrop) {
    //for desktops
    let pressed = false;

    let touchposition = { x: null, y: null };
    let elementposition = { x: null, y: null };
    let swiperposition = { x: null, y: null };

    let colorHandler = this.getSwiperHandler(
      () => {
        card.removeClass('green');
        card.addClass('red');
      },
      () => {
        card.removeClass('red');
        card.addClass('green');
      },
      () => {
        card.removeClass('red');
        card.removeClass('green');
      },
    );

    //for scrolled page-content:
    swiper.on('DOMNodeInserted', (event) => {
      if (!$$(event.relatedNode).hasClass('swiper')) {
        swiperposition.x = swiper.offset().left;
        swiperposition.y = swiper.offset().top;
      }
    });

    //save origin:
    card.on(
      'touchstart',
      (event) => {
        touchposition.x = event.changedTouches[0].pageX;
        touchposition.y = event.changedTouches[0].pageY;
        elementposition.x =
          card.offset().left + (swiperposition.x - swiper.offset().left);
        elementposition.y =
          card.offset().top + (swiperposition.y - swiper.offset().top);
        handleDrag(event.changedTouches[0]);
        colorHandler.handleDrag(event.changedTouches[0]);
      },
      false,
    );
    card.on(
      'mousedown',
      (event) => {
        pressed = true;
        touchposition.x = event.pageX;
        touchposition.y = event.pageY;
        elementposition.x =
          card.offset().left + (swiperposition.x - swiper.offset().left);
        elementposition.y =
          card.offset().top + (swiperposition.y - swiper.offset().top);
        handleDrag(event);
        colorHandler.handleDrag(event);
      },
      false,
    );

    //display motion:
    card.on(
      'touchmove',
      (event) => {
        event.preventDefault();
        let vektor = {
          x: event.changedTouches[0].pageX - touchposition.x,
          y: event.changedTouches[0].pageY - touchposition.y,
        };
        card.attr(
          'style',
          'top: ' +
            parseInt(elementposition.y + vektor.y) +
            'px; left: ' +
            parseInt(elementposition.x + vektor.x) +
            'px;',
        );
        colorHandler.handleDrop(event.changedTouches[0]);
      },
      false,
    );
    card.on(
      'mousemove',
      (event) => {
        event.preventDefault();
        if (pressed) {
          let vektor = {
            x: event.pageX - touchposition.x,
            y: event.pageY - touchposition.y,
          };
          if (event.pageX !== 0 && event.pageY !== 0) {
            card.attr(
              'style',
              'top: ' +
                parseInt(elementposition.y + vektor.y) +
                'px; left: ' +
                parseInt(elementposition.x + vektor.x) +
                'px;',
            );
          }
          colorHandler.handleDrop(event);
        }
      },
      false,
    );

    //return to origin:
    card.on(
      'touchcancel',
      () => {
        card.attr(
          'style',
          'top: ' +
            parseInt(elementposition.y) +
            'px; left: ' +
            parseInt(elementposition.x) +
            'px;',
        );
      },
      false,
    );
    card.on(
      'touchend',
      (event) => {
        card.attr(
          'style',
          'top: ' +
            parseInt(elementposition.y) +
            'px; left: ' +
            parseInt(elementposition.x) +
            'px;',
        );
        handleDrop(event.changedTouches[0]);
        card.removeClass('red');
        card.removeClass('green');
      },
      false,
    );
    card.on(
      'mouseup',
      (event) => {
        event.preventDefault();
        pressed = false;
        card.attr(
          'style',
          'top: ' +
            parseInt(elementposition.y) +
            'px; left: ' +
            parseInt(elementposition.x) +
            'px;',
        );
        handleDrop(event);
        card.removeClass('red');
        card.removeClass('green');
      },
      false,
    );
  },

  getSwiperHandler: function (onLeft, onRight, onMiddle) {
    if (!onMiddle) {
      onMiddle = function () {
        // do nothing
      };
    }
    let dragStartX = null;
    let dragStartY = null;
    return {
      handleDrag: function (event) {
        dragStartX = event.pageX;
        dragStartY = event.pageY;
      },
      handleDrop: function (event) {
        if (dragStartX && dragStartY) {
          if (dragStartX - event.pageX < -50) {
            onRight();
          } else if (dragStartX - event.pageX > 50) {
            onLeft();
          } else {
            onMiddle();
          }
        }
      },
    };
  },

  addToSwiper: function (swiper, element, prepend) {
    if (prepend) {
      swiper.prepend(element);
    } else {
      swiper.append(element);
    }
  },

  removeTopCardFromSwiper: function (swiper, onRemove) {
    let toRemove = $$(swiper.children()[0]);
    onRemove(toRemove);
    toRemove.remove();
  },

  getSwipersLastIndex: function (swiper) {
    let index = 0;
    swiper.find('.swiper-card').forEach((card) => {
      let cardix = parseInt($$(card).attr('index'));
      index = index > cardix ? index : cardix;
    });
    return index;
  },

  getSwipersTopCardIndex: function (swiper) {
    return this.getSwiperCardIndex($$(swiper.children()[0]));
  },

  getSwiperCardIndex: function (card) {
    return $$(card).attr('index');
  },

  getGauge: function (titles, values, legendTitle) {
    const colors = ['#B1F2F9', '#31FFC0', '#FF2E68', '#3030F2', '#31BAFF'];
    const startcolor = colors.length - values.length;
    let svg = '<svg viewbox="0 0 500 500" preserveaspectratio="xMinYMin meet">';
    let accumulated = 0;
    let firstUsedColorIndex = null;
    for (let index = values.length - 1; index >= 0; index--) {
      accumulated += values[index];
      if (values[index] > 0) {
        if (firstUsedColorIndex == null) {
          firstUsedColorIndex = startcolor + index;
        }
        svg += `
                <circle cx="250" cy="250" r="200" fill="transparent" stroke-width="35"
                        stroke="${colors[startcolor + index]}"
                        stroke-dasharray="${
                          (values[index] * 1256.6) / 100
                        } 1256.6"
                        transform="rotate(${
                          (-360 * accumulated) / 100 - 90
                        } 250 250)"
                        stroke-linecap="round"
                />
            `;
      }
    }
    svg += `
            <circle cx="250" cy="250" r="200" fill="transparent" stroke-width="35"
                    stroke="${colors[firstUsedColorIndex]}"
                    stroke-dasharray="${1256.6 / 100} 1256.6"
                    transform="rotate(266 250 250)"
                    stroke-linecap="round"
            />
        `;
    svg += '</svg>';
    let gauge = $$(document.createElement('div'));
    gauge.addClass('gauge');
    gauge.append(svg);

    let legend = $$(document.createElement('div'));
    legend.addClass('legend');
    if (legendTitle != null) {
      legend.append(`
                <div class="legend-title">${legendTitle}</div>
            `);
    }
    titles.forEach((title, index) => {
      legend.append(`
                <div class="legend-entry">
                    <div class="legend-color" style="background-color: ${
                      colors[startcolor + index]
                    };"></div>
                    <span>${title}</span>
                </div>
            `);
    });

    let container = $$(document.createElement('div'));
    container.addClass('ci-gauge');
    container.append(gauge);
    container.append(legend);
    return container;
  },

  getBarChart: function (keys, values) {
    let container = $$(document.createElement('div')).addClass('barchart');
    for (let index = 0; index < keys.length; index++) {
      container.append(`
                <div class="barchart-entry">
                    <div class="barchart-value" style="height: ${
                      values[index] * 1.23
                    }px;"></div>
                    <div class="barchart-key">${keys[index]}</div>
                </div>
            `);
    }
    return container;
  },

  getProgressBarsCard: function (getBar, keys, values) {
    let container = $$(document.createElement('div')).addClass(
      'progressbarscard white-card',
    );
    for (let index = 0; index < keys.length; index++) {
      let entry = $$(document.createElement('div')).addClass(
        'progressbarscard-entry',
      );
      entry.append(`
                <div class="progressbarscard-key">${keys[index]}</div>
            `);
      let bar = getBar(values[index] * 100);
      bar.addClass('progressbarscard-value');
      entry.append(bar);
      container.append(entry);
    }
    return container;
  },

  /**
   * Returns a dropdown-menu
   * @param previously saved userinput
   * @param options for dropdown
   * @param placeholder for dropdown
   * @param icon in front of dropdown, pass null if there is none
   **/
  getDropdownMenu: function (
    userinput,
    options,
    placeholder,
    icon,
    whitebackground,
  ) {
    let dropdown = $$(document.createElement('div'));
    let whitebackgroundClass = whitebackground ? 'white-background' : '';
    dropdown.addClass('dropdown-menu');
    dropdown.html(`<div class="item-content ${whitebackgroundClass}">
                            <div class="item-inner">
                                <input class="dropdown-input" type="text"
                                       placeholder="${placeholder}" value="${userinput}" readonly>
                                <div class="item-media expand-dropdown"><i class="icon material-icons">expand_more</i></div>
                            </div>
                            <ul class="dropdown-options-list collapsed"></ul>
                        </div>
            `);
    for (let index = 0; index < options.length; index++) {
      dropdown.find('ul')
        .append(`<li class="dropdown-options-item">${options[index]}</li>
                `);
    }

    dropdown.find('li').on('click', function (e, obj) {
      let dropdowninput = dropdown.find('.dropdown-input');
      dropdowninput.removeAttr('readonly');
      dropdowninput.val(e.target.innerHTML);
      dropdowninput.attr('readonly', '');
    });

    dropdown.find('.expand-dropdown').on('click', function () {
      _toggleDropdownMenu(dropdown, options.length, whitebackground);
    });
    dropdown.find('li').on('click', function () {
      _toggleDropdownMenu(dropdown, options.length, whitebackground);
    });
    if (icon) {
      let mediaicon = $$(document.createElement('div'));
      mediaicon
        .addClass('item-media item-icon')
        .html(`<i class="icon material-icons-outlined">${icon}</i>`);
      //`<div class="item-media"><i class="icon material-icons">${icon}</i></div>`
      mediaicon.insertBefore(dropdown.find('.dropdown-input'));
    }

    return dropdown;
  },

  /**
   * Returns a labeled textinput as seen in "Interessen und Pr�ferenzen strukturiert beschreiben"
   * @param {any} label
   * @param {any} placeholder
   * @param {any} userinput
   */
  getLabeledTextInput: function (label, placeholder, userinput) {
    return $$(document.createElement('div'))
      .addClass('list')
      .html(
        `<div class="list-input"><div class=" list-topic block-title">${label}</div><div class="list-group"><ul><li><div class="item-content"><div class="item-inner"><input type="text" placeholder="${placeholder}" value="${userinput}"></div></li></ul></div></div></div>`,
      );
  },

  /**
   * Returns an underlined textinput as seen in Login/Register
   * @param {any} placeholder
   * @param {any} userinput
   * @param {any} icon
   */
  getUnderlinedInput: function (placeholder, userinput, icon, type) {
    let container = $$(document.createElement('div'));

    container
      .addClass('underlined-textinput-item item')
      .html(
        container.html() +
          `<div class="item-inner"><div class="item-input-wrap"><input class="font-h3" type="${type}" placeholder="${placeholder}" value="${userinput}"></input><span class="input-clear-button"></span></div></div>`,
      );
    if (icon) {
      container
        .find('.item-inner')
        .prepend(
          $$(document.createElement('div'))
            .addClass('item-media')
            .html(`<span class="icon material-icons-outlined">${icon}</span>`),
        );
    }

    return container;
  },

  getItemCard: function (title, subtitle, icon, link) {
    return `<div class="card card-line card-representer list">
                    <div class="card-content">
                        <a href="${link}" class="item-content item-link">
                            <div class="item-inner">
                                ${title}
                                <div class="item-subtitle">
                                    <i class="icon ${icon}"></i>
                                    <span>${subtitle}</span>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
        `;
  },

  getSlider: function (value, callback) {
    // init slider
    let buttonEl = $$(document.createElement('span')).addClass(
      'ci-slider-button',
    );
    let rangeEl = $$(document.createElement('span')).addClass(
      'ci-slider-range',
    );
    let valueEl = $$(document.createElement('span')).addClass(
      'ci-slider-value',
    );
    let slider = $$(document.createElement('div')).addClass('ci-slider');
    slider.append(buttonEl);
    slider.append(rangeEl);
    slider.append(valueEl);
    touchController.makeDraggable(
      buttonEl,
      {
        maxTop: 0, // maximum amount of pixels the element is draggable in top direction
        //maxRight: 0,                                              // maximum amount of pixels the element is draggable in right direction
        maxBottom: 0, // maximum amount of pixels the element is draggable in bottom direction
        //maxLeft: 0,                                               // maximum amount of pixels the element is draggable in left direction
        noBorderTop: false, // if true, its possible to drag the element out of the window in top direction
        absoluteOrigin: false, // on failed drag the element moves back to its first position instead of moving to the last position
        checkMovement: (x, y) => Math.abs(x) < rangeEl.width() / 2, // function takes x and y variable as parameters return true, to perform movement
      },
      null,
      (x, y) => {
        valueEl
          .empty()
          .append(
            '' +
              Math.round(((rangeEl.width() / 2 + x) / rangeEl.width()) * 100),
          );
      },
      (x, y) => {
        callback(
          Math.round(((rangeEl.width() / 2 + x) / rangeEl.width()) * 100),
        );
      },
    );

    // set value after touch origin is registered
    domHelper.onElementInsertOnce(slider, () => {
      setTimeout(() => {
        valueEl.append('' + value);
        buttonEl.css(
          'left',
          (value / 100) * rangeEl.width() +
            rangeEl.offset().left -
            buttonEl.width() / 2 +
            'px',
        );
      });
    });

    return slider;
  },

  getSliderCard: function (titles, sliders) {
    let card = $$(document.createElement('div')).addClass(
      'slider-card white-card',
    );
    for (let index = 0; index < sliders.length; index++) {
      card.append(
        $$(document.createElement('div'))
          .append('<span class="font-h2">' + titles[index] + '</span>')
          .append(sliders[index]),
      );
    }
    return card;
  },

  getSortableList: function (initArray, onChange) {
    let list = $$(document.createElement('div')).addClass('sortable-list');

    let stack = $$(document.createElement('div')).addClass(
      'sortable-cards-stack',
    );
    $$('.page-current').append(stack);

    let separator = $$(document.createElement('div')).addClass(
      'sortable-cards-separator',
    );
    stack.append(separator);

    let wordMap = new Map();

    // calculate position of card in list
    let getPosition = function (card) {
      let childrenCount = list.children().length;
      let margin = parseInt(
        window.getComputedStyle(card[0]).marginTop.split('px')[0],
      );
      let separatorHeight =
        separator.offset().top + separator.height() / 2 <
        card.offset().top + card.height() / 2
          ? separator.height() + card.height() / 2
          : 0;
      let position = -Math.round(
        -(
          (card.offset().top -
            list.offset().top +
            card.height() / 2 +
            separatorHeight) /
          (card.height() + margin * 2 + 1)
        ),
      );
      if (position < 0) {
        position = 0;
      }
      if (position >= childrenCount) {
        position = null;
      }
      return position;
    };

    for (let word of initArray) {
      // create card
      let id = uuidv4();
      wordMap.set(id, word);
      let card = $$(document.createElement('div'))
        .addClass('white-card sortable-card')
        .attr('id', id).append(`
                    <i class="icon material-icons">drag_handle</i>
                    ${word}
                `);

      // make sortable
      let originalSibling; // save next card for return
      touchController.makeDraggable(
        card,
        {
          maxRight: 0, // maximum amount of pixels the element is draggable in right direction
          maxLeft: 0, // maximum amount of pixels the element is draggable in left direction
          absoluteOrigin: true, // on failed drag the element moves back to its first position instead of moving to the last position
        },
        () => {
          originalSibling = card.next().length > 0 ? card.next() : null;
          stack.append(card);
        },
        () => {
          let position = getPosition(card);
          if (position != null) {
            separator.insertBefore($$(list.children()[position]));
          } else {
            list.append(separator);
          }
        },
        () => {
          let position = getPosition(card);
          if (position != null) {
            card.insertBefore($$(list.children()[position]));
          } else {
            list.append(card);
          }
          stack.append(separator);

          //save
          let res = [];
          list.children().each((index, child) => {
            res.push(wordMap.get($$(child).attr('id')));
          });
          onChange(res);
        },
        () => {
          if (originalSibling) {
            card.insertBefore(originalSibling);
          } else {
            list.append(card);
          }
          stack.append(separator);
        },
      );

      list.append(card);
    }

    return list;
  },
};

function _toggleDropdownMenu(dropdown, length, whitebackground) {
  let dropdownLineHeight;
  whitebackground ? (dropdownLineHeight = 50) : (dropdownLineHeight = 50);
  let dropdownList = dropdown.find('ul');
  let dropdownContainer = dropdown.find('.item-content');
  let dropdownMargin = parseInt(
    getComputedStyle(document.documentElement, null)
      .getPropertyValue('--dropdown-item-margin')
      .split('px')[0],
    10,
  );
  let dropdownFontSize = parseInt(
    getComputedStyle(document.documentElement, null)
      .getPropertyValue('--dropdown-font-size')
      .split('px')[0],
    10,
  );
  let dropdownContainerHeight =
    dropdownLineHeight + (dropdownMargin * 2 + dropdownFontSize) * length + 10;
  if (dropdownList.hasClass('collapsed')) {
    dropdownList.removeClass('collapsed');
    dropdownContainer.css('height', dropdownContainerHeight.toString() + 'px');
  } else {
    dropdownList.addClass('collapsed');
    dropdownContainer.css('height', dropdownLineHeight + 'px');
  }
}

export default elementView;
